import * as React from 'react';
import Navigation, { Orientation, ILink } from 'Views/Components/Navigation/Navigation';
import * as Models from 'Models/Entities';
import { Model } from 'Models/Model';
import { IIconProps } from 'Views/Components/Helpers/Common';
import { SecurityService } from 'Services/SecurityService';
import { getModelDisplayName } from 'Util/EntityUtils';

interface AdminLink extends IIconProps {
	path: string;
	label: string;
	entity: {new (args: any): Model};
	isMember?: boolean;
}

const getPageLinks = (): AdminLink[] => [
	{
		path: '/admin/ahidatastoreentity',
		label: getModelDisplayName(Models.AhiDatastoreEntity),
		entity: Models.AhiDatastoreEntity,
		isMember: false
	},
	{
		path: '/admin/assetentity',
		label: getModelDisplayName(Models.AssetEntity),
		entity: Models.AssetEntity,
		isMember: false
	},
	{
		path: '/admin/auditentity',
		label: getModelDisplayName(Models.AuditEntity),
		entity: Models.AuditEntity,
		isMember: false
	},
	{
		path: '/admin/deviceentity',
		label: getModelDisplayName(Models.DeviceEntity),
		entity: Models.DeviceEntity,
		isMember: false
	},
	{
		path: '/admin/dicommappingentity',
		label: getModelDisplayName(Models.DicomMappingEntity),
		entity: Models.DicomMappingEntity,
		isMember: false
	},
	{
		path: '/admin/doctorentity',
		label: getModelDisplayName(Models.DoctorEntity),
		entity: Models.DoctorEntity,
		isMember: false
	},
	{
		path: '/admin/holterreportentity',
		label: getModelDisplayName(Models.HolterReportEntity),
		entity: Models.HolterReportEntity,
		isMember: false
	},
	{
		path: '/admin/measuremententity',
		label: getModelDisplayName(Models.MeasurementEntity),
		entity: Models.MeasurementEntity,
		isMember: false
	},
	{
		path: '/admin/patiententity',
		label: getModelDisplayName(Models.PatientEntity),
		entity: Models.PatientEntity,
		isMember: false
	},
	{
		path: '/admin/reportentity',
		label: getModelDisplayName(Models.ReportEntity),
		entity: Models.ReportEntity,
		isMember: false
	},
	{
		path: '/admin/reporttemplateentity',
		label: getModelDisplayName(Models.ReportTemplateEntity),
		entity: Models.ReportTemplateEntity,
		isMember: false
	},
	{
		path: '/admin/serviceentity',
		label: getModelDisplayName(Models.ServiceEntity),
		entity: Models.ServiceEntity,
		isMember: false
		
	},
	{
		path: '/admin/sessionentity',
		label: getModelDisplayName(Models.SessionEntity),
		entity: Models.SessionEntity,
		isMember: false
	},
	{
		path: '/admin/sharelinkentity',
		label: getModelDisplayName(Models.ShareLinkEntity),
		entity: Models.ShareLinkEntity,
		isMember: false
	},
	{
		path: '/admin/siteentity',
		label: getModelDisplayName(Models.SiteEntity),
		entity: Models.SiteEntity,
		isMember: false
	},
	{
		path: '/admin/sitegroupentity',
		label: getModelDisplayName(Models.SiteGroupEntity),
		entity: Models.SiteGroupEntity,
		isMember: false
	},
	{
		path: '/admin/structuredreportentity',
		label: getModelDisplayName(Models.StructuredReportEntity),
		entity: Models.StructuredReportEntity,
		isMember: false
	},
	{
		path: '/admin/studyentity',
		label: getModelDisplayName(Models.StudyEntity),
		entity: Models.StudyEntity,
		isMember: false
	},
	{
		path: '/admin/studyfileentity',
		label: getModelDisplayName(Models.StudyFileEntity),
		entity: Models.StudyFileEntity,
		isMember: false
	},
	{
		path: '/admin/superadminentity',
		label: getModelDisplayName(Models.SuperAdminEntity),
		entity: Models.SuperAdminEntity,
		isMember: true
	},
	{
		path: '/admin/userentity',
		label: getModelDisplayName(Models.UserEntity),
		entity: Models.UserEntity,
		isMember: true
	},
	{
		path: '/admin/usersiteaccessentity',
		label: getModelDisplayName(Models.UserSiteAccessEntity),
		entity: Models.UserSiteAccessEntity,
		isMember: false
	},
	{
		path: '/admin/usersiterequestentity',
		label: getModelDisplayName(Models.UserSiteRequestEntity),
		entity: Models.UserSiteRequestEntity,
		isMember: false
	},
	{
		path: '/admin/hl7providerentity',
		label: getModelDisplayName(Models.HL7ProviderEntity),
		entity: Models.HL7ProviderEntity,
		isMember: false
	},
];

export default class PageLinks extends React.Component {
	private filter = (link: AdminLink) => SecurityService.canRead(link.entity)

	public render() {
		return <Navigation
			className='nav__admin'
			orientation={Orientation.VERTICAL}
			linkGroups={this.getAdminNavLinks()}
			{...this.props} />;
	}

	private getAdminNavLinks = () : ILink[][] => {
		const links = getPageLinks();
		
		const userLinks = links.filter(link => link.isMember).filter(this.filter);
		const entityLinks = links.filter(link => ! link.isMember).filter(this.filter);

		const linkGroups: ILink[][] = [];
		
		const homeLinkGroup: ILink[] = [
			{ path: '/admin', label: 'Home', icon: 'home', iconPos: 'icon-left' },
			// { path: '/admin/settings', label: 'Settings', icon: 'settings', iconPos: 'icon-left', isDisabled: true }
			
		];
		linkGroups.push(homeLinkGroup);

		const entityLinkGroup: ILink[] = [];
		if (userLinks.length > 0) {
			entityLinkGroup.push(
				{
					path: '/admin/users',
					label: 'Users',
					icon: 'person-group',
					iconPos: 'icon-left',
					subLinks: [
						{path: '/admin/user', label: 'All Users'},
						...userLinks.map(link => ({path: link.path, label: link.label}))
					]
				}
			);
		}
		if (entityLinks.length > 0) {
			entityLinkGroup.push(
				{
					path: '/admin/entities',
					label: 'Entities',
					icon: 'list',
					iconPos: 'icon-left',
					subLinks: entityLinks.map(link => ({
						path: link.path,
						label: link.label,
					}))
				}
			);
		}
		linkGroups.push(entityLinkGroup);
		
		const otherlinkGroup: ILink[] = [];
		if (otherlinkGroup.length > 0) {
			linkGroups.push(otherlinkGroup);
		}

		const bottomlinkGroup: ILink[] = [];
		bottomlinkGroup.push(
			{
				path: '/admin/',
				label: 'Utility',
				icon: 'file',
				iconPos: 'icon-left',
				subLinks: [
					{
						path: '/admin/graphiql',
						label: 'GraphiQL',
						useATag: true,
					},
					{
						path: '/api/hangfire',
						label: 'Hangfire',
						useATag: true,
					},
					{
						path: '/api/swagger',
						label: 'OpenAPI',
						useATag: true,
					},
					{
						path: 'https://gitlab.codebots.dev',
						label: 'Git',
						useATag: true,
					},
				],
			}
		);

		bottomlinkGroup.push({ path: '/logout', label: 'Logout', icon: 'room', iconPos: 'icon-left' });
		linkGroups.push(bottomlinkGroup);
		
		return linkGroups;
	}
}
