import {inject, observer} from 'mobx-react';
import * as React from 'react';
import Navigation, {Orientation} from 'Views/Components/Navigation/Navigation';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import {getFrontendNavLinks} from 'Views/FrontendNavLinks';
import PatientPageWrappingTileTile from 'Views/Tiles/Patients/PatientPageWrappingTileTile';

const PatientPagePage: React.FunctionComponent = () => {

	const navigationLinks = getFrontendNavLinks();

	return (
		<SecuredPage groups={['SuperAdmin', 'User']}>
			<Navigation
				linkGroups={navigationLinks}
				orientation={Orientation.VERTICAL}
			/>

			<div className="body-content">
				<PatientPageWrappingTileTile/>
			</div>
		</SecuredPage>
	);
};

export default inject('store')(observer(PatientPagePage));