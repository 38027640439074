import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React from 'react';

interface ConfirmedAlreadyModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
}
const ConfirmedAlreadyModal = (props: ConfirmedAlreadyModalProps) => {
	const { isOpen, onClose, onConfirm } = props;
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>This study is already confirmed</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<p>Are you sure you want to make changes to the report?</p>
				</ModalBody>

				<ModalFooter>
					<Button colorScheme="red" mr={3} onClick={onConfirm}>
                        Confirm
					</Button>
					<Button onClick={onClose}>Cancel</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ConfirmedAlreadyModal;