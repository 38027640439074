import React from 'react';
import { inject, observer } from 'mobx-react';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import * as Models from 'Models/Entities';
import { Model } from 'Models/Model';
import { IFilter } from 'Views/Components/Collection/CollectionFilterPanel';
import { makeEnumFetchFunction } from 'Util/EntityUtils';
import { store } from 'Models/Store';
import _ from 'lodash';

const DeviceDashboardWrappingTileTile: React.FunctionComponent = () => {
	const getAdditionalFilters = () => {
		const filters = [] as Array<IFilter<Model>>;

		filters.push({
			path: 'site.id',
			comparison: 'equal',
			value1: undefined,
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Site',
			enumResolveFunction: makeEnumFetchFunction(_.chain(store.sites).keyBy('id').mapValues('siteName').value()),
		});

		filters.push({
			path: 'installDate',
			comparison: 'range',
			value1: undefined,
			value2: undefined,
			active: false,
			displayType: 'datepicker',
			displayName: 'Install Date',
		});

		filters.push({
			path: 'lastContact',
			comparison: 'range',
			value1: undefined,
			value2: undefined,
			active: false,
			displayType: 'datepicker',
			displayName: 'Last Contact Date',
		});

		return filters;
	};

	const contents = (
		<div className="device-dashboard__container">
			<EntityCRUD
				modelType={Models.DeviceEntity}
				additionalFilters={getAdditionalFilters()}
				removeDeleteButton
				removeViewAction
				removeGoButton
				removeCreatedFilter
				removeModifiedFilter
				removeApplyFilter
				dateFormat="d/m/Y"
				altFormat="d/m/Y"
			/>
		</div>
	);

	return contents;
};

export default inject('store')(observer(DeviceDashboardWrappingTileTile));
