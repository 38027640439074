import { useState } from 'react';
import Axios from 'axios';
import { SERVER_URL } from 'Constants';
import { getEmailPattern } from 'Validators/Functions/Email';
import {
	userType,
	accessLevel,
	userTypeOptions,
	accessLevelOptions,
} from '../../Models/Enums';
import { getErrorMessages } from 'Util/GraphQLUtils';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
	Input,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Button,
	Select,
	useToast,
	Box,
	Flex,
	Link,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate, Link as RouteLink } from 'react-router-dom';

let formSubmitted: boolean = false;
type FormValues = {
	username: string;
	firstName: string;
	lastName: string;
	email: string;
	password: string;
	confirmPassword: string;
	userType: userType | null;
	accessLevel: accessLevel | null;
	providerNo: string;
	asarNo: string;
	echo: boolean;
	stressEcho: boolean;
	excersiseStress: boolean;
	holter: boolean;
	kids: boolean;
	uniqueSiteId: string;
};

export const RegistrationPage = () => {
	const toast = useToast();
	const [registrationComplete, setRegistrationComplete] = useState(false);
	const navigate = useNavigate();

	const onSubmit = (formData: FormValues) => {
		Axios.get(
			`${SERVER_URL}/api/entity/SiteEntity/ValidateAccessToken/${formData.uniqueSiteId}`
		)
			.then(() => {
				Axios.post(`${SERVER_URL}/api/register/user-entity`, {
					name: `${formData.firstName} ${formData.lastName}`,
					email: formData.email,
					password: formData.password,
					userType: formData.userType as userType,
					initials: formData.firstName[0] + formData.lastName[0],
					asarNumber: formData.asarNo,
					providerNumber: formData.providerNo,
				})
					.then(() => {
						onRequestSuccess(formData);
					})
					.catch(response => {
						const errorMessages = getErrorMessages(response).map(
							(error: any) => {
								const message =
                  typeof error.message === 'object'
                  	? JSON.stringify(error.message)
                  	: error.message;
								return <p>{message}</p>;
							}
						);
						toast({
							title: 'Registration failed',
							description: errorMessages,
							position: 'bottom-right',
							status: 'error',
							duration: 5000,
							isClosable: true,
						});
					});
			})
			.catch(response => {
				const errorMessages = getErrorMessages(response).map((error: any) => {
					const message =
            typeof error.message === 'object'
            	? JSON.stringify(error.message)
            	: error.message;
					return <p>{message}</p>;
				});
				toast({
					title: 'Registration failed',
					description: errorMessages,
					position: 'bottom-right',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			});
	};

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<FormValues>();

	const onRequestSuccess = (formData: FormValues) => {
		setRegistrationComplete(true);
		Axios.post(
			`${SERVER_URL}/api/entity/UserSiteRequestEntity/CreateInitialSiteRequest`,
			{
				email: formData.email,
				accessKey: formData.uniqueSiteId,
				accessLevel: formData.accessLevel,
			}
		)
			.then(() => {
				console.log('created site request');
			})
			.catch(e => {
				console.log(e);
			});
	};

	if (!registrationComplete) {
		return (
			<div className="body-content">
				<div className="login-container">
					<div className="logo" />
					<div className="form-container">
						<Link
							width={{ base: '80%', lg: '60%' }}
							margin="auto"
							display="flex"
							alignItems="center"
							mb="10px"
							as={RouteLink}
							to="/login"
						>
							<ChevronLeftIcon></ChevronLeftIcon> Back to log in
						</Link>

						<form className="request" onSubmit={handleSubmit(onSubmit)}>
							<Box width={{ base: '80%', lg: '60%' }} margin="auto">
								<div>
									<h4>Request an account</h4>
									<p>
                    You must have the unique site ID of the practice you wish to
                    gain access to before completing your account request.
                    Please contact the practice for this ID.
									</p>
								</div>
								<Flex
									flexDirection={{ base: 'column', md: 'row' }}
									alignItems={{ base: 'center', md: 'flex-start' }}
								>
									<FormControl
										display="flex"
										flexDir="column"
										width="100%"
										variant="floating"
										isInvalid={!!errors.firstName}
										id="request_firstName"
										mr={{ base: '0', md: '5px' }}
									>
										<Input
											type="text"
											size="lg"
											width="100%"
											{...register('firstName', {
												required: true,
											})}
										/>
										<FormLabel>First Name</FormLabel>
										{formSubmitted && errors.firstName?.type === 'required' ? (
											<FormErrorMessage>
                        First Name is required
											</FormErrorMessage>
										) : (
											''
										)}
									</FormControl>
									<FormControl
										display="flex"
										flexDir="column"
										width="100%"
										variant="floating"
										isInvalid={!!errors.lastName}
										id="request_lastName"
										ml={{ base: '0', md: '5px' }}
									>
										<Input
											type="text"
											size="lg"
											width="100%"
											{...register('lastName', {
												required: true,
											})}
										/>
										<FormLabel>Last Name</FormLabel>
										{formSubmitted && errors.lastName?.type === 'required' ? (
											<FormErrorMessage>Last Name is required</FormErrorMessage>
										) : (
											''
										)}
									</FormControl>
								</Flex>
								<FormControl
									display="flex"
									flexDir="column"
									width="100%"
									variant="floating"
									isInvalid={!!errors.email}
									id="request_email"
								>
									<Input
										type="text"
										size="lg"
										width="100%"
										{...register('email', {
											required: true,
											pattern: getEmailPattern(),
										})}
									/>
									<FormLabel>Email Address</FormLabel>
									{formSubmitted && errors.email?.type === 'required' ? (
										<FormErrorMessage>Email is required</FormErrorMessage>
									) : (
										''
									)}
									{formSubmitted && errors.email?.type === 'pattern' ? (
										<FormErrorMessage>Email format invalid</FormErrorMessage>
									) : (
										''
									)}
								</FormControl>
								<Flex
									flexDirection={{ base: 'column', md: 'row' }}
									alignItems={{ base: 'center', md: 'flex-start' }}
								>
									<FormControl
										display="flex"
										flexDir="column"
										width="100%"
										variant="floating"
										isInvalid={!!errors.password}
										id="request_password"
										mr={{ base: '0', md: '5px' }}
									>
										<Input
											type="password"
											size="lg"
											width="100%"
											{...register('password', {
												required: true,
												minLength: 6,
											})}
										/>
										<FormLabel>Password</FormLabel>
										{formSubmitted && errors.password?.type === 'required' ? (
											<FormErrorMessage>Password is required</FormErrorMessage>
										) : (
											''
										)}
										{formSubmitted && errors.password?.type === 'minLength' ? (
											<FormErrorMessage>
                        Password must be at least 6 characters long
											</FormErrorMessage>
										) : (
											''
										)}
									</FormControl>
									<FormControl
										display="flex"
										flexDir="column"
										width="100%"
										variant="floating"
										isInvalid={!!errors.confirmPassword}
										id="request_confirmPassword"
										ml={{ base: '0', md: '5px' }}
									>
										<Input
											type="password"
											size="lg"
											width="100%"
											{...register('confirmPassword', {
												required: true,
												minLength: 6,
												validate: {
													passwordsMatch: (_, values) =>
														values.confirmPassword === values.password,
												},
											})}
										/>
										<FormLabel>Confirm Password</FormLabel>
										{formSubmitted &&
                    errors.confirmPassword?.type === 'required' ? (
												<FormErrorMessage>Password is required</FormErrorMessage>
											) : (
												''
											)}
										{formSubmitted &&
                    errors.confirmPassword?.type === 'minLength' ? (
												<FormErrorMessage>
                        Password must be at least 6 characters long
												</FormErrorMessage>
											) : (
												''
											)}
										{formSubmitted &&
                    errors.confirmPassword?.type === 'passwordsMatch' ? (
												<FormErrorMessage>Passwords must match</FormErrorMessage>
											) : (
												''
											)}
									</FormControl>
								</Flex>
								<Flex
									flexDirection={{ base: 'column', md: 'row' }}
									alignItems={{ base: 'center', md: 'flex-start' }}
								>
									<FormControl
										variant="floating"
										isInvalid={!!errors.userType}
										id="request_userType"
										mr={{ base: '0', md: '5px' }}
									>
										<Select
											size="lg"
											display="flex"
											flexDir="column"
											width="100%"
											{...register('userType', {
												required: true,
											})}
										>
											{Object.keys(userTypeOptions).map(option => (
												<option value={option} key={option}>
													{userTypeOptions[option]}
												</option>
											))}
										</Select>
										<FormLabel>User Level</FormLabel>
										{formSubmitted && errors.userType?.type === 'required' ? (
											<FormErrorMessage>User Type is required</FormErrorMessage>
										) : (
											''
										)}
									</FormControl>
									<FormControl
										variant="floating"
										isInvalid={!!errors.accessLevel}
										id="request_accessLevel"
										ml={{ base: '0', md: '5px' }}
									>
										<Select
											display="flex"
											flexDir="column"
											size="lg"
											width="100%"
											{...register('accessLevel', {
												required: true,
											})}
										>
											{Object.keys(accessLevelOptions).map(option => (
												<option value={option} key={option}>
													{accessLevelOptions[option]}
												</option>
											))}
										</Select>
										<FormLabel>Access Level</FormLabel>
										{formSubmitted &&
                    errors.accessLevel?.type === 'required' ? (
												<FormErrorMessage>
                        Access Level is required
												</FormErrorMessage>
											) : (
												''
											)}
									</FormControl>
								</Flex>
								<FormControl
									display="flex"
									flexDir="column"
									width="100%"
									variant="floating"
									isInvalid={!!errors.uniqueSiteId}
									id="request_uniqueSiteId"
								>
									<Input
										type="text"
										size="lg"
										width="100%"
										{...register('uniqueSiteId', {
											required: true,
										})}
									/>
									<FormLabel>Unique Site ID</FormLabel>
									{formSubmitted && errors.uniqueSiteId?.type === 'required' ? (
										<FormErrorMessage>
                      Unique Site ID is required
										</FormErrorMessage>
									) : (
										''
									)}
								</FormControl>
								<Button
									margin="1rem 0 !Important"
									width="100%"
									type="submit"
									className="submit-request"
									id="submit-request"
									colorScheme="blue"
									onClick={() => (formSubmitted = true)}
								>
                  Request Access
								</Button>
							</Box>
						</form>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="body-content">
				<div className="login-container">
					<div className="logo" />
					<div className="form-container">
						<Box width={{ base: '80%', lg: '60%' }} margin="auto">
							<div className="request">
								<h4 className="confirmation-title">
                  Thank you. Your request has been sent.
								</h4>
								<p>
                  You have been sent an confirmation email. Once you confirm your
                  email an administrator will review your account request for
                  approval.
								</p>
								<Button
									type="button"
									width="100%"
									className="return-to-login"
									id="return-to-login"
									colorScheme="blue"
									onClick={() => {
										navigate('/login');
									}}
								>
                  Return to Login
								</Button>
							</div>
						</Box>
					</div>
				</div>
			</div>
		);
	}
};
