import * as React from 'react';
import { observer } from 'mobx-react';

import { accessLevel, accessLevelOptions, studyTypeOptions, userTypeOptions } from 'Models/Enums';
import { Link } from 'react-router-dom';
import { store } from 'Models/Store';
import {
	Colors, Display, Sizes, Button, 
} from 'Views/Components/Button/Button';
import {
	ButtonGroup,
} from 'Views/Components/Button/ButtonGroup';
import { action, observable, runInAction } from 'mobx';
import { UserEntity, UserSiteAccessEntity, UserSiteRequestEntity } from 'Models/Entities';
import { Alignment } from 'Views/Components/Button/ButtonGroup';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import Axios from 'axios';
import { SERVER_URL } from 'Constants';
import alert from 'Util/ToastifyUtils';
import { getErrorMessages } from 'Util/GraphQLUtils';
import CustomSpinner from 'Views/Components/Spinner/CustomSpinner';
import {withRouter, WithRouterProps}  from "../../../Util/withRouter";

interface IRequestSiteAccessState {
	accessKey: string;
	accessLevel: accessLevel | null;
	errors: { [attr: string]: string };
}

const defaultRequestSiteAccessState = {
	accessKey: '',
	accessLevel: null,
	errors: {},
};

const fieldErrorMessages = {
	accessKey: 'Unique Site ID is required.',
	accessLevel: 'Access Level is required.',
};

interface IModalContentsProps {
	user: UserEntity;
}

@observer
class ModalContents extends React.Component<IModalContentsProps> {
	@observable
	public requestSiteAccessState: IRequestSiteAccessState = defaultRequestSiteAccessState;

	@observable
	public user: UserEntity = this.props.user;

	@observable
	public loading: boolean = false;

	@action
	public validateField = (field: string) => {
		if (this.requestSiteAccessState[field] === '' || this.requestSiteAccessState[field] === null) {
			this.requestSiteAccessState.errors[field] = fieldErrorMessages[field];
		} else {
			delete this.requestSiteAccessState.errors[field];
		}
	};

	@action
	onSubmit = (event: any) => {
		event.preventDefault();

		Object.keys(this.requestSiteAccessState).forEach(field => field !== 'errors' && this.validateField(field));
		
		if (Object.keys(this.requestSiteAccessState.errors).length === 0 && this.requestSiteAccessState.accessLevel !== null) {
			runInAction(() => this.loading = true);
			Axios.get(`${SERVER_URL}/api/entity/SiteEntity/ValidateAccessToken/${this.requestSiteAccessState.accessKey}/${this.user.id}`).then(res => {
				const userSiteRequest = new UserSiteRequestEntity();

				userSiteRequest.accessLevel = this.requestSiteAccessState.accessLevel || 'VIEW_ONLY' as accessLevel;
				userSiteRequest.siteRequested = res.data;
				userSiteRequest.siteRequestedId = res.data.id;
				userSiteRequest.userRequestingSite = this.user;
				userSiteRequest.userRequestingSiteId = this.user.id;

				userSiteRequest.save().then(data => {
					runInAction(() => this.loading = false);
					store.modal.hide();
					alert(
						<div>
							<h6>Site Request Sent</h6>
						</div>,
						'success',
					);
				}).catch(error => {
					runInAction(() => this.loading = false);
					const errorMessages = getErrorMessages(error).map((e: any) => {
						const message = typeof e.message === 'object' 
							? JSON.stringify(e.message)
							: e.message;
						return (<p>{message}</p>);
					});
					alert(
						<div>
							<h6>Site Request Failed</h6>
							{errorMessages}
						</div>,
						'error',
					);
				});
			}).catch(error => {
				runInAction(() => this.loading = false);
				const errorMessages = getErrorMessages(error).map((e: any) => {
					const message = typeof e.message === 'object' 
						? JSON.stringify(e.message)
						: e.message;
					return (<p>{message}</p>);
				});
				alert(
					<div>
						<h6>Site Request Failed</h6>
						{errorMessages}
					</div>,
					'error',
				);
			});
		}
	};

	render() {
		return (
			<div className="reqest__modal">
				<h4>Request Access</h4>
				<p>Request Access to a site</p>
				<form className="request" onSubmit={this.onSubmit}>
					<TextField 
						model={this.requestSiteAccessState}
						id="request_accessKey"
						className="request-accessKey"
						modelProperty="accessKey"
						label="Unique Site ID"
						isRequired
						errors={this.requestSiteAccessState.errors.accessKey} 
					/>
					<Combobox
						model={this.requestSiteAccessState}
						className="request-accessLevel"
						modelProperty="accessLevel"
						label="Access Level"
						isRequired
						errors={this.requestSiteAccessState.errors.accessLevel}
						options={Object.keys(accessLevelOptions).map(option => ({ display: accessLevelOptions[option], value: option }))}
					/>
					{this.loading ? (
						<CustomSpinner />
					) : (
						<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
							<Button 
								colors={Colors.Primary} 
								display={Display.Outline} 
								sizes={Sizes.Medium} 
								buttonProps={{ id: 'cancel' }} 
								onClick={() => store.modal.hide()}
							>
								Cancel
							</Button>
							<Button 
								type="submit"
								colors={Colors.Primary} 
								display={Display.Solid} 
								sizes={Sizes.Medium} 
								buttonProps={{ id: 'submit' }} 
							>
								Send request
							</Button>
						</ButtonGroup>
					)}
				</form>
			</div>
		);
	}
}

interface ProfileTileProps extends WithRouterProps {
	user: UserEntity;
	loading: boolean;
	updateUser: () => void;
	updateProviderNumber: (e: any, site: UserSiteAccessEntity) => void;
	updateAsarNumber: (e: any) => void;
	updateAddress: (e: any) => void;
	userType: string;
};

@observer
class ProfileTile extends React.Component<ProfileTileProps> {
	public render() {
		let contents = null;

		const { user, loading, updateUser, updateProviderNumber, updateAsarNumber, updateAddress, userType, location} = this.props;

		if (!loading) {
			contents = (
				<div className="profile-container">
					<div className="profile-detail">
						<h4>{user.name}</h4>
						<h5>{userTypeOptions[user.userType]}</h5>
					</div>
					<div className="profile-content">
						<div className="profile-information">
							<p>Your Information</p>
							<div className="profile-information__container">
								<span className="email-span">
									<p className="information-label">Email address:</p>
									<p className="email-value">{user.email}</p>
								</span>
								<span className="password-span">
									<p className="information-label">Password:</p>
									<Link to="/reset-password-request" className="password-link">Reset Password</Link>
								</span>
								{(userType === 'REPORTING_DOCTOR' || userType === 'SITE_USER') && (
									user.sitess.map(site => site.accessLevel === 'FULL_ACCESS' && (
										<div key={`provider-no-for-${site.site.siteName}`} className="site-group-container">
											<p className="siteName">{site.site.siteName}</p>
											<div key={`site-group-${site.id}`} className="site-group">
												<span>
													<p className="information-label">Provider No.</p>
													<input type="text" value={site.providerNumber} placeholder="Enter Provider No." onChange={e => updateProviderNumber(e, site)} />
												</span>
												<span>	
													<p className="information-label-hl7">HL7 Messaging Enabled</p>
													<input className="hl7" type="checkbox" value="Enable" disabled checked={!!site.hl7MessagingEnabled} />
												</span>
											</div>
										</div>
									))
								)}
								<span>
									<p className="information-label">ASAR No.</p>
									<input type="text" value={user.asarNumber} placeholder="Enter ASAR No." onChange={e => updateAsarNumber(e)} />
								</span>
								<span>
									<p className="information-label">Home address:</p>
									<input className="address" type="text" value={user.address} placeholder="Enter home address" onChange={e => updateAddress(e)} />
								</span>
								<Button colors={Colors.Primary} display={Display.Solid} sizes={Sizes.Medium} buttonProps={{ id: 'save' }} onClick={() => updateUser()}>Save</Button>
							</div>
						</div>
						<div className="profile-permissions">
							{userType === 'SITE_USER' && (
								<div className="profile-scanTypes">
									<p>Scan Types</p>
									<div className="profile-scanTypes__container">
										<div className="profile-scanTypes__list">
											{user.userServicess.length > 0 ? user.userServicess.map(service => (
												<div key={service.id} className="profile-scanTypes__item">
													<span className="icon-left icon-check" />
													<p>{studyTypeOptions[service.userServices.studyType]}</p>
												</div>
											)) : (
												<p>No Services Available</p>
											)}
										</div>
										<Button colors={Colors.Grey} display={Display.Text} sizes={Sizes.Small} buttonProps={{ id: 'edit' }}>Edit</Button>
									</div>
								</div>
							)}
							<div className="profile-sites">
								<p>Your sites</p>
								<div className="profile-sites__container">
									{user.sitess.map(userSiteAccess => (
										<div key={`site-list-item-${userSiteAccess.site.siteName}`} className="profile-sites__site">
											<p>
												{`${userSiteAccess.site?.siteName}`}
												<span className="site-accessLevel">
													{` - ${accessLevelOptions[userSiteAccess.accessLevel]}`}
												</span>
											</p>
											<Link to={`/site/${userSiteAccess.siteId}`} state={ {prevPath: location.pathname} } className="site-link"><Button colors={Colors.Grey} display={Display.Text} sizes={Sizes.Small} buttonProps={{ id: 'view' }}>View</Button></Link>
										</div>
									))}
									<Button colors={Colors.Primary} display={Display.Text} sizes={Sizes.Small} buttonProps={{ id: 'request-site' }} onClick={() => this.requestSiteAccess()}>
										<span className="icon-left icon-plus" />
										Request access to a site
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return contents;
	};

	@action
	requestSiteAccess = () => {
		store.modal.show('Request Access', <ModalContents user={this.props.user} />);
	};
}

export default withRouter(ProfileTile);