import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
	getModelName, getAttributes, 
} from 'Util/EntityUtils';
import { SuperAdminSiteGroupEntity } from 'Models/Security/Acl/SuperAdminSiteGroupEntity';
import { UserPatientEntity } from 'Models/Security/Acl/UserPatientEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';

import { lowerCaseFirst } from 'Util/StringUtils';
import { gql } from '@apollo/client';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';
import { CRUD } from '../CRUDOptions';
import {store} from "../Store";

export interface ISiteGroupEntityAttributes extends IModelAttributes {
	name: string;
	sites: Array<Models.SiteEntity | Models.ISiteEntityAttributes>;
}

@entity('SiteGroupEntity', 'Site Group')
export default class SiteGroupEntity extends Model implements ISiteGroupEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new SuperAdminSiteGroupEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
	];

	/**
	 * Name
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	
	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Sites',
		displayType: 'reference-multicombobox',
		order: 20,
		referenceTypeFunc: () => Models.SiteEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'sites',
			oppositeEntity: () => Models.SiteEntity,
		}),
	})
	public sites: Models.SiteEntity[] = [];

	constructor(attributes?: Partial<ISiteGroupEntityAttributes>) {
		super(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ISiteGroupEntityAttributes>) {
		super.assignAttributes(attributes);
		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.sites !== undefined && Array.isArray(attributes.sites)) {
				for (const model of attributes.sites) {
					if (model instanceof Models.SiteEntity) {
						this.sites.push(model);
					} else {
						this.sites.push(new Models.SiteEntity(model));
					}
				}
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		sites {
			${Models.SiteEntity.getAttributes().join('\n')}
		}
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			sites: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'sites',
						],
					},
				],
			},
		);
	}

	public async saveSiteGroup() {
		const relationPath = {
			sites: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'sites',
						],
					},
				],
			},
		);
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		return this.name;
	}

	public static fetchSingleSiteGroup() {
		const model = new SiteGroupEntity();
		const modelName = lowerCaseFirst(getModelName(SiteGroupEntity));

		return gql`
			query ${modelName} ($args:[WhereExpressionGraph]) {
				${modelName} (where: $args) {
					${getAttributes(SiteGroupEntity).join('\n')}
					${model.defaultExpands}
				}
			}`;
	}

	public static fetchSiteGroupFilter() {
		const modelName = lowerCaseFirst(getModelName(SiteGroupEntity));
		
		return gql`
		query ${modelName}s ($args:[WhereExpressionGraph]) {
			${modelName}s (where: $args) {
				id
				name
			}
		}`;
	}
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(SiteGroupEntity.prototype, 'created');
CRUD(modifiedAttr)(SiteGroupEntity.prototype, 'modified');
