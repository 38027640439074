import * as React from 'react';
import { observer } from 'mobx-react';
import {Route, Routes} from 'react-router';
import { IModelType, Model } from 'Models/Model';
import {withRouter, WithRouterProps}  from "../../../Util/withRouter";
import EntityCollection, { AdditionalBulkActions, IEntityCollectionProps, viewActionOptions } from './EntityCollection';
import EntityAttributeList from './EntityAttributeList';
import EntityEdit from './EntityEdit';
import { getModelDisplayName, getModelName } from 'Util/EntityUtils';
import SecuredAdminPage from '../Security/SecuredAdminPage';
import { SecurityService } from 'Services/SecurityService';
import { expandFn, showExpandFn, ICollectionItemActionProps } from '../Collection/Collection';
import { IEntityContextMenuActions } from '../EntityContextMenu/EntityContextMenu';
import { EntityFormMode } from '../Helpers/Common';
import { IFilter } from '../Collection/CollectionFilterPanel';
import { IWhereCondition } from '../ModelCollection/ModelQuery';

interface IEntityCRUDProps<T extends Model> extends WithRouterProps{
	/** The type of model to render */
	modelType: IModelType;
	/** Function to determine the expanded content of the list */
	expandList?: expandFn<T>;
	/** Function to determine whether expand button, to display expand list, shows */
	showExpandButton?: any;
	/** Number of entities per page */
	perPage?: number;
	/** Context menu actions for each row */
	actionsMore?: any;
	/** Url suffix to add to the route */
	URLExtension?: string;
	/** Additional actions for the bulk actions menu */
	additionalBulkActions?: Array<AdditionalBulkActions<T>>;
	/** Additional table actions for the collection view */
	additionalTableActions?: any;
	/** Additional filters to add to the collection view */
	additionalFilters?: Array<IFilter<T>>;
	/** Remove the view action from the collection */
	removeViewAction?: boolean;
	/** Override for the collection component */
	collectionComponent?: () => React.ReactNode;
	/** Override for the create component */
	createComponent?: () => React.ReactNode;
	/** If this is set to true then remove the created date filter from the collection view */
	removeCreatedFilter?: boolean;
	/** If this is set to true then remove the modified date filter from the collection view */
	removeModifiedFilter?: boolean;
	/** Change the filter orientation to row */
	filterOrientationRow?: boolean;
	/** Override for the view component */
	viewComponent?: () => React.ReactNode;
	/** Override for the edit component */
	editComponent?: () => React.ReactNode;
	/** Custom action for view on the collection. If this function returns undefined then it will disable view */
	collectionViewAction?: (options: viewActionOptions<T>) => ICollectionItemActionProps<T> | undefined;
	/** Custom action for create on the collection. If this function returns undefined then it will disable create */
	collectionCreateAction?: (options: viewActionOptions<T>) => React.ReactNode;
	/** Custom action for delete on the collection. If this function returns undefined then it will disable delete */
	collectionDeleteAction?: (options: viewActionOptions<T>) => ICollectionItemActionProps<T> | undefined;
	/** Custom action for update on the collection. If this function returns undefined then it will disable update */
	collectionUpdateAction?: (options: viewActionOptions<T>) => ICollectionItemActionProps<T> | undefined;
	/** Custom props to be passed to the entity collection for when it is being rendered. */
	entityCollectionProps?: Partial<IEntityCollectionProps<T>>;
	/** Override for disabling bulk export on the bulk select all */
	disableBulkExport?: boolean;
	/** Override for disabling bulk delete on the bulk select all */
	disableBulkDelete?: boolean;

	removeGoButton?: boolean;
	removeCreateButton?: boolean;
	removeEditButton?: boolean;
	removeDeleteButton?: boolean;
	selectableItems?: boolean;
	removeApplyFilter?: boolean;
	extraConditions?: Array<IWhereCondition<T>>[];
	dateFormat?: string;
	altFormat?: string;
}

/**
 * This component is used to render a CRUD (create, read, update, delete) view for a specific entity type.
 */
@observer
class EntityCRUD<T extends Model> extends React.Component<IEntityCRUDProps<T>> {
	private url = () => {
		const { URLExtension, params } = this.props;

		if (URLExtension) {
			return `${params.url}/${URLExtension}`;
		}

		return params.url;

	};

	public render() {
		const { modelType, collectionComponent, createComponent, editComponent, viewComponent, params } = this.props;
		const entityCollectionPage = () => {
			return (
				<SecuredAdminPage canDo={SecurityService.canRead(modelType)}>
					<this.renderEntityCollection  />
				</SecuredAdminPage>
			);
		};
		
		const entityCreatePage = () => {
			return (
				<SecuredAdminPage canDo={SecurityService.canCreate(modelType)}>
					<this.renderEntityCreate  />
				</SecuredAdminPage>
			);
		};
		
		const entityViewPage = () => {
			return (
				<SecuredAdminPage canDo={SecurityService.canRead(modelType)}>
					<this.renderEntityView  />
				</SecuredAdminPage>
			);
		};
		
		const entityEditPage = () => {
			return (
				<SecuredAdminPage canDo={SecurityService.canUpdate(modelType)}>
					<this.renderEntityEdit />
				</SecuredAdminPage>
			);
		};

		return (
			<div className={`crud--${getModelName(modelType)}`}>
				<Routes>
					<Route path={``} element={collectionComponent != undefined ? collectionComponent() : entityCollectionPage() } />
					<Route path={`/view/:id`} element={viewComponent != undefined ? viewComponent() : entityViewPage()} />
					<Route path={`/create`} element={createComponent != undefined ? createComponent() : entityCreatePage()} />
					<Route path={`/edit/:id`} element={editComponent != undefined ? editComponent() : entityEditPage()} />
				</Routes>
			</div>
		);

	};

	protected renderEntityCollection = () => {
		const {
			modelType,
			expandList,
			perPage,
			actionsMore,
			additionalBulkActions,
			additionalFilters,
			additionalTableActions,
			collectionViewAction,
			collectionCreateAction,
			collectionUpdateAction,
			collectionDeleteAction,
			entityCollectionProps,
			filterOrientationRow,
			disableBulkDelete,
			disableBulkExport,
			removeCreatedFilter,
			removeModifiedFilter,
			removeGoButton,
			removeCreateButton,
			selectableItems,
			removeEditButton,
			removeDeleteButton,
			removeViewAction,
			removeApplyFilter,
			extraConditions,
			dateFormat,
			altFormat,
		} = this.props;

		return (
			<EntityCollection
				modelType={modelType}
				expandList={expandList}
				perPage={perPage}
				actionsMore={actionsMore}
				url={this.url()}
				disableBulkDelete={disableBulkDelete}
				disableBulkExport={disableBulkExport}
				additionalBulkActions={additionalBulkActions}
				additionalTableActions={additionalTableActions}
				additionalFilters={additionalFilters}
				filterOrientationRow={filterOrientationRow}
				viewAction={collectionViewAction}
				createAction={collectionCreateAction}
				deleteAction={collectionDeleteAction}
				updateAction={collectionUpdateAction}
				removeCreatedFilter={removeCreatedFilter}
				removeModifiedFilter={removeModifiedFilter}
				removeGoButton={removeGoButton}
				removeCreateButton={removeCreateButton}
				removeEditButton={removeEditButton}
				removeDeleteButton={removeDeleteButton}
				selectableItems={selectableItems}
				removeViewAction={removeViewAction}
				removeApplyFilter={removeApplyFilter}
				extraConditions={extraConditions}
				dateFormat={dateFormat}
				altFormat={altFormat}
				{...entityCollectionProps}
			/>
		);
	};
	
	protected renderEntityCreate = () => {
		const { modelType } = this.props;
		const modelDisplayName = getModelDisplayName(modelType);
		return (
			// @ts-ignore
			<EntityAttributeList
				model={new modelType()}
				sectionClassName="crud__create"
				title={`Create New ${modelDisplayName}`}
				formMode={EntityFormMode.CREATE}
				modelType={modelType}
			/>
		);

	};

	protected renderEntityEdit = () => {
		const { modelType } = this.props;
		// @ts-ignore
		return <EntityEdit modelType={modelType} formMode={EntityFormMode.EDIT} />;

	};

	protected renderEntityView = () => {
		const { modelType } = this.props;
		// @ts-ignore
		return <EntityEdit modelType={modelType} formMode={EntityFormMode.VIEW} />;
	};
}
export default withRouter(EntityCRUD);

