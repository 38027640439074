import React, { useState } from 'react';
import {Button, ButtonGroup, Input} from '@chakra-ui/react'

interface TextEditButtonProps {
    initialText: string;
    onUpdate: (newText: string) => void;
}

const TextEditButton: React.FC<TextEditButtonProps> = ({ initialText, onUpdate }) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [text, setText] = useState<string>(initialText);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setText(initialText);
        setIsEditing(false);
    };

    const handleSubmit = () => {
        onUpdate(text);
        setIsEditing(false);
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            {isEditing ? (
                <>
                    <Input
                        type="text"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        style={{marginBottom: '10px'}} 
                    />
                    <div style={{display: 'flex', gap: '5px'}}>
                        <ButtonGroup variant='outline' spacing='4'>
                            <Button colorScheme='blue' onClick={handleSubmit}>Submit</Button>
                            <Button colorScheme='red' onClick={handleCancel}>Cancel</Button>
                        </ButtonGroup>
                    </div>
                </>
            ) : (
                <button 
                    onClick={handleEditClick}
                    style={{
                        minWidth: '100px',
                        minHeight: '30px',
                        textAlign: 'center',
                    }}
                >
                    {text}
                </button>
            )}
        </div>
    );
};

export default TextEditButton;