import { SERVER_URL } from 'Constants';
import {inject, observer} from 'mobx-react';
import {store} from 'Models/Store';
import React, {useEffect} from 'react';
import { useNavigate } from "react-router";
import Navigation, {Orientation} from 'Views/Components/Navigation/Navigation';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import {getFrontendNavLinks} from 'Views/FrontendNavLinks';
import UserDashboardWrappingTileTile from 'Views/Tiles/UserDashboardWrappingTileTile';


const UserDashboardPage = () => {
	const navigate = useNavigate();

	useEffect(() => {
		if (!store.userGroups.map(ug => ug.name).includes('SuperAdmin') && store.userType !== 'SITE_ADMIN') {
			navigate(`${SERVER_URL}/patientdashboard`)
		} 
	}, []);

	const navigationLinks = getFrontendNavLinks();

	return (
		<SecuredPage groups={['SuperAdmin', 'User']}>
			<Navigation
				linkGroups={navigationLinks}
				orientation={Orientation.VERTICAL}
			/>

			<div className="body-content">
				<UserDashboardWrappingTileTile/>
			</div>
		</SecuredPage>
	);
}

export default inject('store')(observer(UserDashboardPage));
