import { gql } from '@apollo/client';
import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity,
} from 'Models/Model';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getAttributes,
	getCreatedModifiedCrudOptions, getModelName,
} from 'Util/EntityUtils';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import * as React from 'react';
import { lowerCaseFirst } from '../../Util/StringUtils';
import { SuperAdminAssetEntity } from '../Security/Acl/SuperAdminAssetEntity';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';
import { CRUD } from '../CRUDOptions';
import { UserAssetEntity } from '../Security/Acl/UserAssetEntity';
import { VisitorsAssetEntity } from '../Security/Acl/VisitorsAssetEntity';

export interface IHL7ProviderEntityAttributes extends IModelAttributes {
	name: string;
}

@entity('HL7ProviderEntity', 'HL7Provider')
export default class HL7ProviderEntity extends Model implements IHL7ProviderEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsAssetEntity(),
		new SuperAdminAssetEntity(),
		new UserAssetEntity(),
	];

	/**
     * Fields to exclude from the JSON serialization in create operations.
     */
	public static excludeFromCreate: string[] = [
	];

	/**
     * Fields to exclude from the JSON serialization in update operations.
     */
	public static excludeFromUpdate: string[] = [
	];
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Provider Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;

	/**
     * Assigns fields from a passed in JSON object to the fields in this model.
     * Any reference objects that are passed in are converted to models if they are not already.
     * This function is called from the constructor to assign the initial fields.
     */
	@action
	public assignAttributes(attributes?: Partial<IHL7ProviderEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
		}
	}

	/**
     * Additional fields that are added to GraphQL queries when using the
     * the managed model APIs.
     */
	public defaultExpands = '';

	/**
     * The save method that is called from the admin CRUD components.
     */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}

	/**
     * Returns the string representation of this entity to display on the UI.
     */
	public getDisplayName() {
		return this.name;
	}
	public static getFetchHl7ProviderEntity() {
		const model = new HL7ProviderEntity();
		const modelName = lowerCaseFirst(getModelName(HL7ProviderEntity));

		return gql`
			query ${modelName} ($args:[WhereExpressionGraph]) {
				${modelName} (where: $args) {
					${getAttributes(HL7ProviderEntity).join('\n')}
					${model.defaultExpands}
				}
			}`;
	}

	public listExpands = ''
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(HL7ProviderEntity.prototype, 'created');
CRUD(modifiedAttr)(HL7ProviderEntity.prototype, 'modified');
