import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsDeviceEntity } from 'Models/Security/Acl/VisitorsDeviceEntity';
import { SuperAdminDeviceEntity } from 'Models/Security/Acl/SuperAdminDeviceEntity';
import { UserDeviceEntity } from 'Models/Security/Acl/UserDeviceEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import * as React from 'react';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';
import { CRUD } from '../CRUDOptions';

export interface IDeviceEntityAttributes extends IModelAttributes {
	deviceName: string;
	deviceMacAddress: string;
	lastContact: Date;
	installDate: Date;
	recentLogs: string;
	command: string;
	version: string;
	siteId?: string;
	site?: Models.SiteEntity | Models.ISiteEntityAttributes;
}

@entity('DeviceEntity', 'Device')
export default class DeviceEntity extends Model implements IDeviceEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsDeviceEntity(),
		new SuperAdminDeviceEntity(),
		new UserDeviceEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
	];
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Device Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public deviceName: string;

	@Validators.Required()
	@Validators.Unique()
	@observable
	@attribute()
	@CRUD({
		name: 'Device Mac Address',
		displayType: 'textfield',
		order: 20,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public deviceMacAddress: string;

	@observable
	@attribute()
	@CRUD({
		name: 'Last Contact',
		displayType: 'datetimepicker',
		order: 30,
		headerColumn: true,
		searchable: false,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public lastContact: Date;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Install Date',
		displayType: 'datepicker',
		order: 40,
		headerColumn: true,
		searchable: false,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public installDate: Date;

	@observable
	@attribute()
	@CRUD({
		name: 'Recent Logs',
		displayType: 'textfield',
		order: 50,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public recentLogs: string;

	@observable
	@attribute()
	@CRUD({
		name: 'Command',
		displayType: 'textfield',
		order: 60,
		searchable: false,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public command: string;

	@observable
	@attribute()
	@CRUD({
		name: 'Version',
		displayType: 'textfield',
		order: 70,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public version: string;

	@observable
	@attribute()
	@CRUD({
		name: 'Site',
		displayType: 'reference-combobox',
		headerColumn: true,
		order: 80,
		referenceTypeFunc: () => Models.SiteEntity,
		displayFunction: (attr: any, that: Model) => <p>{that['site']?.siteName}</p>,
	})
	public siteId?: string;

	@observable
	@attribute({ isReference: true })
	public site: Models.SiteEntity;

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IDeviceEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.deviceName !== undefined) {
				this.deviceName = attributes.deviceName;
			}
			if (attributes.deviceMacAddress !== undefined) {
				this.deviceMacAddress = attributes.deviceMacAddress;
			}
			if (attributes.lastContact !== undefined) {
				if (attributes.lastContact === null) {
					this.lastContact = attributes.lastContact;
				} else {
					this.lastContact = moment(attributes.lastContact).toDate();
				}
			}
			if (attributes.installDate !== undefined) {
				if (attributes.installDate === null) {
					this.installDate = attributes.installDate;
				} else {
					this.installDate = moment(attributes.installDate).toDate();
				}
			}
			if (attributes.recentLogs !== undefined) {
				this.recentLogs = attributes.recentLogs;
			}
			if (attributes.command !== undefined) {
				this.command = attributes.command;
			}
			if (attributes.version !== undefined) {
				this.version = attributes.version;
			}
			if (attributes.site !== undefined) {
				if (attributes.site === null) {
					this.site = attributes.site;
				} else if (attributes.site instanceof Models.SiteEntity) {
					this.site = attributes.site;
					this.siteId = attributes.site.id;
				} else {
					this.site = new Models.SiteEntity(attributes.site);
					this.siteId = this.site.id;
				}
			} else if (attributes.siteId !== undefined) {
				this.siteId = attributes.siteId;
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		site {
			${Models.SiteEntity.getAttributes().join('\n')}
			${Models.SiteEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		return this.deviceName;
	}
	
	public listExpands = `
		site {
			${Models.SiteEntity.getAttributes().join('\n')}
		}
	`;
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(DeviceEntity.prototype, 'created');
CRUD(modifiedAttr)(DeviceEntity.prototype, 'modified');
