import { Sha256 } from '@aws-crypto/sha256-js';
import { HttpRequest } from '@smithy/protocol-http';
import { SignatureV4 } from '@smithy/signature-v4';
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import { IoClose, IoPause, IoPlay } from 'react-icons/io5';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Flex,
	Box,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
} from '@chakra-ui/react';
import { RenderingEngine, Enums } from '@cornerstonejs/core';
import type { Types } from '@cornerstonejs/core';
import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import * as cornerstone from '@cornerstonejs/core';
import dicomParser from 'dicom-parser';
import ReactResizeDetector from 'react-resize-detector';
import {
	ZoomTool,
	utilities,
	WindowLevelTool,
	ToolGroupManager,
	Enums as csToolsEnums,
} from '@cornerstonejs/tools';

cornerstoneDICOMImageLoader.external.cornerstone = cornerstone;
cornerstoneDICOMImageLoader.external.dicomParser = dicomParser;

const config = {
	maxWebWorkers: navigator.hardwareConcurrency || 1,
	startWebWorkersOnDemand: true,
};
cornerstoneDICOMImageLoader.webWorkerManager.initialize(config);

const { MouseBindings } = csToolsEnums;

export const xhrRequest = async (url: string): Promise<ArrayBuffer> => {
	const parsedUrl = new URL(url);
	const queryParams = Array.from(parsedUrl.searchParams.entries()).reduce((obj, [key, value]) => {
		obj[key] = value;
		return obj;
	}, {} as Record<string, string>);

	const signer = new SignatureV4({
		credentials: {
			accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID!,
			secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY!,
		},
		service: 'medical-imaging',
		region: process.env.REACT_APP_AWS_REGION!,
		sha256: Sha256,
	});

	const request = new HttpRequest({
		method: 'GET',
		protocol: 'https:',
		hostname: parsedUrl.hostname,
		path: parsedUrl.pathname,
		query: queryParams,
		headers: {
			host: 'dicom-medical-imaging.ap-southeast-2.amazonaws.com',
			accept: 'application/dicom; transfer-syntax=1.2.840.10008.1.2.4.202',
		},
	});

	const signedRequest = await signer.sign(request);

	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);
		for (const [key, value] of Object.entries(signedRequest.headers)) {
			if (key.toLowerCase() !== 'host') {
				xhr.setRequestHeader(key, value);
			}
		}
		xhr.responseType = 'arraybuffer';
		xhr.onload = () => {
			if (xhr.status === 200) {
				resolve(xhr.response);
			} else {
				reject(new Error(`Failed to load image: ${xhr.statusText}`));
			}
		};
		xhr.onerror = () => reject(new Error('Network error'));
		xhr.send();
	});
};

interface ImageViewerViewportProps {
	studyFileId: string;
	index: number;
	currentFileCallback: (arg0: string, arg1: string) => void;
	deselectFileCallback: (arg0: string) => void;
	cachedFileCallback: (arg0: string) => void;
	url: string;
	displayPlaybackToggle: boolean;
}

export interface ImageViewerViewportRef {
	stopPlaybackIfActive: () => void;
}

export const ImageViewerViewport = React.forwardRef((props: ImageViewerViewportProps, ref) => {
	const {
		studyFileId,
		index,
		currentFileCallback,
		cachedFileCallback,
		deselectFileCallback,
		url,
		displayPlaybackToggle
	} = props;
	const [currentFileId, setCurrentFileId] = useState<string>('');
	const [cachedStudyFiles, setCachedStudyFiles] = useState<string[]>([]);
	const [framerate, setFramerate] = useState<number>(0);
	const [numFrames, setNumFrames] = useState<number>(0);
	const [isPlaying, setIsPlaying] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [displaySlider, setDisplaySlider] = useState<boolean>(false);
	const [displayControls, setDisplayControls] = useState<boolean>(true);
	const [viewport1, setViewport1] = useState<Types.IViewport>();
	const [isViewportReady, setIsViewportReady] = useState(false);
	const initializedRef = useRef(false);
	const renderingEngineRef = useRef<RenderingEngine | null>(null);

	const toolGroupId = `MYTOOLGROUP_${index}`;
	const existingToolGroup = ToolGroupManager.getToolGroup(toolGroupId);
	const [toolGroup, setToolGroup] = useState<any>(existingToolGroup || ToolGroupManager.createToolGroup(toolGroupId));
	const playbackEnabled = true;

	useImperativeHandle(ref, () => ({
		stopPlaybackIfActive,
	}));

	const escapeSelector = (selector: string): string =>
		selector.replace(/\./g, '');

	const validSelector = `#element_echo_${escapeSelector(studyFileId)}`;

	const togglePlayback = () => {
		if (!isViewportReady) {
			console.warn('Viewport is not fully initialized and ready for playback.');
			return;
		}

		if (viewport1 && !viewport1.isDisabled) {
			const element = document.querySelector(validSelector) as HTMLDivElement;
			if (element) {
				try {
					if (isPlaying) {
						utilities.cine.stopClip(element);
						setIsPlaying(false);
					} else if (utilities.cine.playClip && element) {
						utilities.cine.playClip(element, { framesPerSecond: framerate, loop: true });
						setIsPlaying(true);
					}
				} catch (error) {
					console.warn('Playback operation failed:', error);
				}
			} else {
				console.warn('Viewport element does not exist.');
			}
		} else {
			console.warn('Viewport is not enabled for playback.');
		}
	};

	const startUp = async () => {
		if (renderingEngineRef.current) {
			return;
		}

		const renderingEngineId = `myRenderingEngine2_${index}`;
		const renderingEngine = new RenderingEngine(renderingEngineId);
		renderingEngineRef.current = renderingEngine;

		const viewportId = `ECHO_STACK_${index}`;
		const element = document.querySelector(validSelector) as HTMLDivElement;
		
		if (!element) {
			console.error('Invalid element for the selector:', validSelector);
			return;
		}

		element.oncontextmenu = e => e.preventDefault();

		const viewportInput: Types.PublicViewportInput = {
			viewportId,
			type: Enums.ViewportType.STACK,
			element,
		};

		if (renderingEngine.getViewport(viewportId)?.element) {
			utilities.cine.stopClip(renderingEngine.getViewport(viewportId).element);
			setIsPlaying(false);
		}
		
		renderingEngine.enableElement(viewportInput);

		const imageIds: string[] = [];
		setIsLoading(true);

		try {
			const dataSet = await cornerstoneDICOMImageLoader.wadouri.dataSetCacheManager.load(url, xhrRequest);

			setCurrentFileId(studyFileId);
			currentFileCallback(studyFileId, validSelector);
			cachedFileCallback(studyFileId);
			setCachedStudyFiles(prev => [...prev, studyFileId]);

			const numFrames = dataSet.intString('x00280008');
			const frameRate = dataSet.intString('x00082144');
			setFramerate(frameRate);
			setNumFrames(numFrames);

			if (!numFrames) {
				imageIds.push(`wadouri:${url}`);
			} else {
				for (let i = 1; i <= numFrames; i++) {
					imageIds.push(`wadouri:${url}?frame=${i}`);
				}
			}

			const viewport = renderingEngine.getViewport(viewportId) as Types.IStackViewport;
			setViewport1(viewport);

			if (typeof viewport.setStack === 'function') {
				await viewport.setStack(imageIds);
			} else {
				console.error('setStack is not a function on the retrieved viewport');
			}

			setIsLoading(false);
			setIsViewportReady(true);

			if (imageIds.length > 0) {
				utilities.stackPrefetch.enable(viewport.element);
			}

			viewport.render();

			if (numFrames && numFrames > 1 && playbackEnabled && !isPlaying && element) {
				if (utilities.cine.playClip) {  
					utilities.cine.playClip(element, { loop: true, framesPerSecond: frameRate });
					setIsPlaying(true);
				}
			} else {
				setIsPlaying(false);
			}
		} catch (error) {
			console.error('Error loading DICOM dataset:', error);
			setIsLoading(false);
		}
	};

	const updateFramerate = (newFramerate: number) => {
		const element = document.querySelector(validSelector) as HTMLDivElement;
		if (isPlaying && element) {
			setFramerate(newFramerate);
			if (playbackEnabled && utilities.cine.playClip) {
				utilities.cine.playClip(element, { framesPerSecond: newFramerate, loop: true });
			}
		} else {
			setFramerate(newFramerate);
		}
	};

	useEffect(() => {
		if (!initializedRef.current) {
			startUp();
			initializedRef.current = true;
		}

		return () => {
			stopPlaybackIfActive();

			if (renderingEngineRef.current) {
				const viewportId = `ECHO_STACK_${index}`;
				renderingEngineRef.current.disableElement(viewportId);
				renderingEngineRef.current.destroy();
				cornerstoneDICOMImageLoader.webWorkerManager.terminate();
			}
			deselectFileCallback(validSelector);
		};
	}, []);

	const stopPlaybackIfActive = () => {
		const element = document.querySelector(validSelector) as HTMLDivElement;
		if (isPlaying && viewport1) {
			try {
				utilities.cine.stopClip(element);
				setIsPlaying(false);
			} catch (error) {
				console.error('Error stopping playback:', error);
			}
		}
	};

	const onResize = (studyFileId: string) => {
		const element = document.querySelector(validSelector) as HTMLDivElement;
		if (renderingEngineRef.current) {
			renderingEngineRef.current.resize();  
		}
		if (viewport1) {
			viewport1.render(); 
		}
	};

	useEffect(() => {
		setDisplayControls(!displayControls); 
	  }, [displayPlaybackToggle]);

	return (
		<Flex position="relative" width="100%" height="100%" justifyContent="center">
			{isLoading ? (
				<></>
			) : (
				''
			)}

			<ReactResizeDetector
				skipOnMount
				refreshMode="debounce"
				refreshRate={200}
				onResize={() => onResize(currentFileId)}
			>
				<div
					id={`element_echo_${escapeSelector(studyFileId)}`}
					style={{ width: '100%', height: '100%', opacity: isLoading ? '0' : '1' }}
				></div>
			</ReactResizeDetector>

			{displaySlider && displayControls ? (
				<Flex
					position="absolute"
					bottom="70px"
					left="50%"
					transform="translateX(-50%)"
					background="#373151"
					borderRadius="5px"
					width="200px"
					padding="8px"
					alignItems="center"
					justifyContent="space-between"
					border="1px solid #5686ce"
				>
					<Slider
						aria-label="slider-ex-1"
						defaultValue={framerate}
						min={0}
						max={120}
						step={1}
						onChangeEnd={val => updateFramerate(val)}
					>
						<SliderTrack>
							<SliderFilledTrack />
						</SliderTrack>
						<SliderThumb />
					</Slider>
				</Flex>
			) : (
				''
			)}
			{displayControls ? (
				<Flex
					position="absolute"
					bottom="20px"
					left="50%"
					transform="translateX(-50%)"
					background="#373151"
					borderRadius="5px"
					width="170px"
					padding="8px"
					alignItems="center"
					justifyContent="space-between"
				>
					<Box>
						{isPlaying ? (
							<IoPause
								fontSize="20px"
								color="#fff"
								onClick={() => togglePlayback()}
								cursor="pointer"
							/>
						) : (
							<IoPlay
								fontSize="20px"
								color="#fff"
								onClick={() => togglePlayback()}
								cursor="pointer"
							/>
						)}
					</Box>
					<Flex
						border="1px solid #5686ce"
						background="#373151"
						borderRadius="5px"
						padding="2px 8px"
						margin="0px 8px"
						alignItems="center"
						width="95px"
						justifyContent="space-between"
					>
						<ChevronLeftIcon
							color="#5686ce"
							onClick={() => updateFramerate(framerate - 1)}
							cursor="pointer"
						></ChevronLeftIcon>
						<Box
							color="#5686ce"
							cursor="pointer"
							onClick={() => setDisplaySlider(!displaySlider)}
						>
							{framerate} FPS
						</Box>
						<ChevronRightIcon
							color="#5686ce"
							onClick={() => updateFramerate(framerate + 1)}
							cursor="pointer"
						></ChevronRightIcon>
					</Flex>
					<IoClose
						color="#fff"
						fontSize="22px"
						onClick={() => setDisplayControls(false)}
						cursor="pointer"
					></IoClose>
				</Flex>
			) : (
				''
			)}
		</Flex>
	);
});

export default ImageViewerViewport;