import { DeleteIcon } from "@chakra-ui/icons";
import {
	useDisclosure,
	Button,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	DrawerBody,
	Card,
	Text,
	Heading,
	Stack,
	Flex,
	IconButton,
	ButtonGroup,
	Tooltip, ModalOverlay, Modal, ModalHeader, ModalContent, ModalBody, ModalCloseButton, ModalFooter
} from "@chakra-ui/react";
import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import {SessionEntity} from "../../../Models/Entities";
import {sessionPeriodOptions, sessionTypeOptions} from "../../../Models/Enums";
import EndSessionTile from "./EndSessionTile";
import { NewSessionTile } from "./NewSessionTile";
import { useQuery } from "@apollo/client";
import { store } from "Models/Store";

const SessionsUpcomingTile = observer(() => {
	const { isOpen: isOpenNew, onOpen: onOpenNew, onClose: onCloseNew } = useDisclosure()
	const { isOpen: isOpenEnd, onOpen: onOpenEnd, onClose: onCloseEnd } = useDisclosure()
	const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure()
	
	const [ hasOpenSession, setHasOpenSession ] = useState();
	const [ currentSession, setCurrentSession ] = useState<SessionEntity>();
	
	const { loading, data } = useQuery(SessionEntity.getUpcomingSessions(), {variables: { userId: [store.userId] }});
	
	const onDeleteSession = (s: SessionEntity) => {
		setCurrentSession(new SessionEntity(s));
		onOpenModal();
	}
	
	const onEndSession = (s: SessionEntity) => {
		setCurrentSession(s);
		onOpenEnd();
	}
	
	const deleteSession = () => {
		currentSession?.delete().then(() => {
			store.apolloClient.refetchQueries({
				include: "active",
			});
			onCloseModal();
		});
	}
	
	
	useEffect(() => {
		if (!loading) {
			setHasOpenSession(data.sessionEntitysConditional.some((s:SessionEntity) => s.open));
		}
		
		store.apolloClient.refetchQueries({
			include: "active",
		});
	}, [data])
	
	return (
		<div className="sessions__crud-list">
			<Flex>
				<ButtonGroup ml='auto'>
					<Button colorScheme='blue' onClick={onOpenNew}>New Session</Button>
				</ButtonGroup>
			</Flex>

			{!loading && (
				<Stack spacing={5} mt={5} maxW="450px">
					{data?.sessionEntitysConditional.map((s: SessionEntity) =>
						<Card>
							<Flex flex='1' flexDirection='column' p={4}>
								<Heading as='h4' mb={10}>{s.site?.siteName ? s.site?.siteName : 'Office'} - { new Date(s.sessionDate).toLocaleDateString('en-GB')}</Heading>
							
								<Text>Session Type: {sessionTypeOptions[s.sessionType]}</Text>
								<Text>Period: {sessionPeriodOptions[s.sessionPeriod]}</Text>
								
								<ButtonGroup ml='auto'>
									{s.open ? (
										<Button colorScheme='red' onClick={() => onEndSession(s)}>End Session</Button>
									) : (
										<Tooltip hidden={!hasOpenSession} label='Close open sessions'>
											<Button colorScheme='green' isDisabled={hasOpenSession} onClick={() =>{
												let session = new SessionEntity(s);
												session.open = true;
												session.site = s.site;
												session.siteId = s.siteId
												session.save()
											}}>Start Session</Button>
										</Tooltip>
									)}
									<IconButton aria-label='delete session' onClick={() => onDeleteSession(s)} icon={<DeleteIcon />}></IconButton>
								</ButtonGroup>
							</Flex>
						</Card>
					)}
				</Stack>
			)}
			
			<Drawer placement='left' isOpen={isOpenNew} onClose={onCloseNew} size='xl'>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>Create a session</DrawerHeader>

					<DrawerBody pt={10}>
						<NewSessionTile drawerToggle={onCloseNew}/>
					</DrawerBody>
				</DrawerContent>
			</Drawer>

			<Drawer placement='left' isOpen={isOpenEnd} onClose={onCloseEnd} size='xl'>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>Close session</DrawerHeader>

					<DrawerBody pt={10}>
						<EndSessionTile drawerToggle={onCloseEnd} currentSession={currentSession}/>
					</DrawerBody>
				</DrawerContent>
			</Drawer>

			<Modal isOpen={isOpenModal} onClose={onCloseModal}>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalHeader>Delete Session</ModalHeader>
					<ModalBody>
						Are you sure?
					</ModalBody>

					<ModalFooter>
						<Button colorScheme='blue' mr={3} onClick={onCloseModal}>
							Close
						</Button>
						<Button variant='ghost' onClick={deleteSession}>Yes, delete session</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</div>
	);
});

export default SessionsUpcomingTile;
