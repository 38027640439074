import {inject, observer} from 'mobx-react';
import * as React from 'react';
import Navigation, {Orientation} from 'Views/Components/Navigation/Navigation';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import {getFrontendNavLinks} from 'Views/FrontendNavLinks';
import DeviceDashboardWrappingTileTile from 'Views/Tiles/DeviceDashboardWrappingTileTile';

const DeviceDashboardPage: React.FunctionComponent = () => {
	
	const navigationLinks = getFrontendNavLinks();

	console.log('render me')

	return (
		<SecuredPage groups={['SuperAdmin']}>
			<Navigation
				linkGroups={navigationLinks}
				orientation={Orientation.VERTICAL}
			/>

			<div className="body-content">
				<DeviceDashboardWrappingTileTile/>
			</div>
		</SecuredPage>
	);
}

export default inject('store')(observer(DeviceDashboardPage));
