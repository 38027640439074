import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { observer } from 'mobx-react';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import Tabs from 'Views/Components/Tabs/Tabs';
import StudyCrudTile from 'Views/Tiles/Patients/StudyCrudTile';
import PatientCrudTile from 'Views/Tiles/Patients/PatientCrudTile';

@observer

class PatientDashboardPage extends React.Component {
	render() {
		return (
			<SecuredPage groups={['SuperAdmin', 'User']}>
				<Navigation
					linkGroups={getFrontendNavLinks()}
					orientation={Orientation.VERTICAL}
				/>
				<div className="body-content">
					<div className="patients-container">
						<Tabs
							tabs={[
								{
									name: 'View by Study',
									component: <StudyCrudTile {...this.props} />,
									key: 'study',
								},
								{
									name: 'View by Patient',
									component: <PatientCrudTile {...this.props} />,
									key: 'patient',
								}
							]}
							defaultTab={0}
						/>
					</div>
				</div>
			</SecuredPage>
		)
	}
}


export default PatientDashboardPage;

