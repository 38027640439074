import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Select,
    Checkbox,
    Input,
    ButtonGroup,
    Text,
    Flex, Box,
} from '@chakra-ui/react';
import {accessLevel as AccessLevelType} from "../../../Models/Enums";

interface User {
    id: string;
    name: string;
    email: string;
}
interface UsaOptionsModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (accessLevel: AccessLevelType, featureSessions: boolean, hl7Messaging: boolean, providerNumber: string) => void;
    users: User[] | undefined; 
    models: Array<any>; 
}

const UsaOptionsModal: React.FC<UsaOptionsModalProps> = ({ isOpen, onClose, onConfirm, users, models }) => {
    const [accessLevel, setAccessLevel] = useState<AccessLevelType>("FULL_ACCESS");
    const [featureSessions, setFeatureSessions] = useState<boolean>(true);
    const [hl7Messaging, setHl7Messaging] = useState<boolean>(true);
    const [providerNumber, setProviderNumber] = useState<string>('');
    const [isClicked, setIsClicked] = useState(false);

    const handleConfirm = () => {
        if (isClicked) return;
        onConfirm(accessLevel, featureSessions, hl7Messaging, providerNumber);
        setAccessLevel('FULL_ACCESS');
        setProviderNumber('');
        setIsClicked(true);
    };
    
    const handleCancel = () => {
        setAccessLevel('FULL_ACCESS');
        setProviderNumber('');
        onClose();
    }
    
    const getUserPrompt = () => {
        if(users?.length === 1){
            return(
                <>
                    <Text fontSize="xl" color="gray.800" textAlign="center" fontWeight="bold">
                        {`${users[0].name} is being granted access to the following sites:`}
                    </Text>
                </>
            )
        } else{
            let numUsers = users?.length || 0;
            return(
                <>
                    <Text fontSize="xl" color="gray.800" textAlign="center" fontWeight="bold">
                        {`${numUsers} users are being granted access to sites:`}
                    </Text>
                </>
            )
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg">
            <ModalOverlay />
            <ModalContent maxWidth="60%" width="100%">
                <ModalHeader>
                    {getUserPrompt()}
                </ModalHeader>
                <ModalBody>
                    <Flex direction="column" align="center" justify="center">
                        <Box maxHeight="200px" overflowY="auto" mb={4} borderWidth="3px" borderRadius="md" p={2} textAlign="center">
                            <Text fontSize="lg" color="gray.600" mb={1}>Users</Text>
                            <Text fontSize="xl" color="gray.800">
                                {users?.map(user => user.name).join(', ')}
                            </Text>
                        </Box>
                        <Box mb={4} />
                        <Box maxHeight="200px" overflowY="auto" mb={4} borderWidth="3px" borderRadius="md" p={2} textAlign="center">
                            <Text fontSize="lg" color="gray.600" mb={1}>Sites</Text>
                            <Text fontSize="xl" color="gray.800">
                                {models.map(model => model.siteName).join(', ')}
                            </Text>
                        </Box>
                        <Box mb={8} />
                        <Flex direction="column" gap={4} width="100%">
                            <Flex justify="space-between" align="center">
                                <Flex align="center" flex="1">
                                    <Box mr={4}>
                                        <Text fontSize="lg" color="gray.800" fontWeight="bold">Access Level:</Text>
                                    </Box>
                                    <Select
                                        placeholder="Select access level"
                                        value={accessLevel}
                                        onChange={(e) => {
                                            const newValue = e.target.value as AccessLevelType;
                                            if (newValue === 'VIEW_ONLY' || newValue === 'FULL_ACCESS') {
                                                setAccessLevel(newValue);
                                            }
                                        }}
                                        flex="1"
                                        textAlign="center"
                                        fontSize="lg"
                                    >
                                        <option value="VIEW_ONLY">View Only</option>
                                        <option value="FULL_ACCESS">Full Access</option>
                                    </Select>
                                </Flex>
                                <Flex align="center" flex="1">
                                    <Box ml={6} mr={2}>
                                        <Text fontSize="lg" color="gray.800" fontWeight="bold">Provider Number:</Text>
                                    </Box>
                                    <Input
                                        placeholder="Enter provider number"
                                        value={providerNumber}
                                        onChange={(e) => setProviderNumber(e.target.value)}
                                        flex="1"
                                        textAlign="center"
                                        fontSize="lg"
                                    />
                                </Flex>
                            </Flex>
                            <Flex justify="space-between" align="center">
                                <Flex align="center" flex="1">
                                    <Checkbox
                                        isChecked={featureSessions}
                                        onChange={() => setFeatureSessions(!featureSessions)}
                                        mr={4}
                                    >
                                        <Text fontSize="lg" color="gray.800" fontWeight="bold">Feature Sessions</Text>
                                    </Checkbox>
                                </Flex>
                                <Flex align="center" flex="1">
                                    <Checkbox
                                        isChecked={hl7Messaging}
                                        onChange={() => setHl7Messaging(!hl7Messaging)}
                                        ml={4}
                                    >
                                        <Text fontSize="lg" color="gray.800" fontWeight="bold">HL7 Messaging Enabled</Text>
                                    </Checkbox>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup spacing={4}>
                        <Button colorScheme="blue" onClick={handleConfirm} fontSize="lg">
                            Confirm
                        </Button>
                        <Button variant="outline" onClick={handleCancel} fontSize="lg">
                            Cancel
                        </Button>
                    </ButtonGroup>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};


export default UsaOptionsModal;
