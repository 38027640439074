import { IUserResult, store } from 'Models/Store';
import { SERVER_URL } from 'Constants';
import axios from 'axios';
import { getEmailPattern } from 'Validators/Functions/Email';
import { getErrorMessages } from 'Util/GraphQLUtils';
import {
	Flex,
	Input,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Button,
	ButtonGroup,
	useToast,
	Box,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate, Navigate } from 'react-router-dom';

let formSubmitted: boolean = false;
type FormValues = {
	username: string;
	password: string;
};

export const LoginPage = () => {
	const onLoginSuccess = (userResult: IUserResult) => {
		store.setLoggedInUser(userResult);
	};

	const navigate = useNavigate();
	const toast = useToast();

	const callLoginApi = (formData: FormValues) => {
		axios
			.post(`${SERVER_URL}/api/authorization/login`, {
				username: formData.username,
				password: formData.password,
			})
			.then(({ data }) => {
				onLoginSuccess(data);
				navigate('/');
			})
			.catch(response => {
				const errorMessages = getErrorMessages(response).map((error: any) => {
					const message =
            typeof error.message === 'object'
            	? JSON.stringify(error.message)
            	: error.message;
					return <p>{message}</p>;
				});

				toast({
					title: 'Login failed',
					description: errorMessages,
					position: 'bottom-right',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			});
	};

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<FormValues>();

	if (store.loggedIn) {
		return <Navigate to="/" />;
	} else {
		return (
			<Flex justifyContent="center" alignItems="center" gridArea="body">
				<Box
					className="login-container"
					flex="1"
					mb={{ base: '35%', lg: '140px' }}
				>
					<div className="logo" />
					<form onSubmit={handleSubmit(callLoginApi)}>
						<Flex
							flexDirection="column"
							alignItems="center"
							width={{ base: '80%', lg: '60%' }}
							maxWidth="600px"
							margin="auto"
						>
							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.username}
								id="login_username"
							>
								<Input
									type="text"
									size="lg"
									{...register('username', {
										required: true,
										pattern: getEmailPattern(),
									})}
								/>
								<FormLabel>Email Address</FormLabel>
								{formSubmitted && errors.username?.type === 'required' ? (
									<FormErrorMessage>Email address is required</FormErrorMessage>
								) : (
									''
								)}
								{formSubmitted && errors.username?.type === 'pattern' ? (
									<FormErrorMessage>Email format invalid</FormErrorMessage>
								) : (
									''
								)}
							</FormControl>

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.password}
								id="login_password"
							>
								<Input
									type="password"
									size="lg"
									variant="outline"
									{...register('password', { required: true })}
								/>
								<FormLabel>Password</FormLabel>
								{formSubmitted && errors.password?.type === 'required' ? (
									<FormErrorMessage>Password is required</FormErrorMessage>
								) : (
									''
								)}
							</FormControl>

							<p className="link-forgotten-password">
								<a
									className="link-forgotten-password link-rm-txt-dec"
									onClick={() => navigate('/reset-password-request')}
								>
                  Forgot your password?
								</a>
							</p>
							<ButtonGroup mb="2" width="100%">
								<Button
									width="100%"
									type="submit"
									className="login-submit"
									id="login_submit0"
									colorScheme="blue"
									onClick={() => (formSubmitted = true)}
								>
                  Login
								</Button>
							</ButtonGroup>
							<ButtonGroup width="100%">
								<Button
									width="100%"
									id="register"
									onClick={() => navigate('/register')}
									variant="outline"
									colorScheme="gray"
								>
                  Request an account
								</Button>
							</ButtonGroup>
						</Flex>
					</form>
				</Box>
			</Flex>
		);
	}
};
