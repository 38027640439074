import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React from 'react';

interface ConfirmationModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
}
const ConfirmationModal = (props: ConfirmationModalProps) => {
	const { isOpen, onClose, onConfirm } = props;
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Confirm Urgency Change</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<p>Are you sure you want to change the urgency?</p>
				</ModalBody>

				<ModalFooter>
					<Button colorScheme="red" mr={3} onClick={onConfirm}>
						Confirm
					</Button>
					<Button onClick={onClose}>Cancel</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ConfirmationModal;