import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { observable } from 'mobx';
import { IAcl } from '../Security/IAcl';
import { VisitorsUserServicesReferenceManyToMany } from '../Security/Acl/VisitorsUserServicesReferenceManyToMany';
import { SuperAdminUserServicesReferenceManyToMany } from '../Security/Acl/SuperAdminUserServicesReferenceManyToMany';
import { UserUserServicesReferenceManyToMany } from '../Security/Acl/UserUserServicesReferenceManyToMany';
import { UserUserServiceEntity } from '../Security/Acl/UserUserServiceEntity';

export interface IUsersUserServicesAttributes extends IModelAttributes {
	usersId: string;
	userServicesId: string;

	users: Models.UserEntity | Models.IUserEntityAttributes;
	userServices: Models.ServiceEntity | Models.IServiceEntityAttributes;
}

@entity('UsersUserServices')
export default class UsersUserServices extends Model implements IUsersUserServicesAttributes {
	public static acls: IAcl[] = [
		new VisitorsUserServicesReferenceManyToMany(),
		new SuperAdminUserServicesReferenceManyToMany(),
		new UserUserServicesReferenceManyToMany(),
		new UserUserServiceEntity(),
	];

	@observable
	@attribute()
	public usersId: string;

	@observable
	@attribute()
	public userServicesId: string;

	@observable
	@attribute({ isReference: true })
	public users: Models.UserEntity;

	@observable
	@attribute({ isReference: true })
	public userServices: Models.ServiceEntity;

	constructor(attributes?: Partial<IUsersUserServicesAttributes>) {
		super(attributes);

		if (attributes) {
			if (attributes.usersId) {
				this.usersId = attributes.usersId;
			}
			if (attributes.userServicesId) {
				this.userServicesId = attributes.userServicesId;
			}

			if (attributes.users) {
				if (attributes.users instanceof Models.UserEntity) {
					this.users = attributes.users;
					this.usersId = attributes.users.id;
				} else {
					this.users = new Models.UserEntity(attributes.users);
					this.usersId = this.users.id;
				}
			} else if (attributes.usersId !== undefined) {
				this.usersId = attributes.usersId;
			}

			if (attributes.userServices) {
				if (attributes.userServices instanceof Models.ServiceEntity) {
					this.userServices = attributes.userServices;
					this.userServicesId = attributes.userServices.id;
				} else {
					this.userServices = new Models.ServiceEntity(attributes.userServices);
					this.userServicesId = this.userServices.id;
				}
			} else if (attributes.userServicesId !== undefined) {
				this.userServicesId = attributes.userServicesId;
			}
		}
	}
}
