import { action, observable, computed } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsUserSiteAccessEntity } from 'Models/Security/Acl/VisitorsUserSiteAccessEntity';
import { SuperAdminUserSiteAccessEntity } from 'Models/Security/Acl/SuperAdminUserSiteAccessEntity';
import { UserUserSiteAccessEntity } from 'Models/Security/Acl/UserUserSiteAccessEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import * as Enums from '../Enums';
import { CRUD } from '../CRUDOptions';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';

export interface IUserSiteAccessEntityAttributes extends IModelAttributes {
	accessLevel: Enums.accessLevel;
	featureSessions: boolean;
	providerNumber: string;
	hl7MessagingEnabled: boolean;

	userId?: string;
	user?: Models.UserEntity | Models.IUserEntityAttributes;
	siteId?: string;
	site?: Models.SiteEntity | Models.ISiteEntityAttributes;
}

@entity('UserSiteAccessEntity', 'User Site Access')
export default class UserSiteAccessEntity extends Model implements IUserSiteAccessEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsUserSiteAccessEntity(),
		new SuperAdminUserSiteAccessEntity(),
		new UserUserSiteAccessEntity(),
		new UserUserSiteAccessEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
	];

	/**
	 * Access Level
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Access Level',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => AttrUtils.standardiseEnum(attr, Enums.accessLevelOptions),
		enumResolveFunction: makeEnumFetchFunction(Enums.accessLevelOptions),
		displayFunction: (attribute: Enums.accessLevel) => Enums.accessLevelOptions[attribute],
	})
	public accessLevel: Enums.accessLevel;

	/**
	 * A flag to enable the Sessions feature
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Feature Sessions',
		displayType: 'checkbox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public featureSessions: boolean;

	/**
	 * Provider Number of the user with site access
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Provider Number',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public providerNumber: string;

	/**
	 * Can the user send and recieve private hl7 messages
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'HL7 Messaging Enabled',
		displayType: 'checkbox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public hl7MessagingEnabled: boolean;

	@observable
	@attribute()
	@CRUD({
		name: 'User',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.UserEntity,
	})
	public userId?: string;

	@observable
	@attribute({ isReference: true })
	public user: Models.UserEntity;

	@observable
	@attribute()
	@CRUD({
		name: 'Site',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.SiteEntity,
	})
	public siteId?: string;

	@observable
	@attribute({ isReference: true })
	public site: Models.SiteEntity;

	@CRUD({
		name: 'User',
		displayType: 'hidden',
		headerColumn: true,
	})
	@computed
	public get userName(): string {
		return this.user?.name || '';
	}

	@CRUD({
		name: 'Site',
		displayType: 'hidden',
		headerColumn: true,
	})
	@computed
	public get siteName(): string {
		return this.site?.siteName || '';
	}

	constructor(attributes?: Partial<IUserSiteAccessEntityAttributes>) {
		super(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IUserSiteAccessEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.accessLevel !== undefined) {
				this.accessLevel = attributes.accessLevel;
			}
			if (attributes.featureSessions !== undefined) {
				this.featureSessions = attributes.featureSessions;
			}
			if (attributes.providerNumber !== undefined) {
				this.providerNumber = attributes.providerNumber;
			}
			if (attributes.hl7MessagingEnabled !== undefined) {
				this.hl7MessagingEnabled = attributes.hl7MessagingEnabled;
			}
			if (attributes.userId !== undefined) {
				this.userId = attributes.userId;
			}
			if (attributes.user !== undefined) {
				if (attributes.user === null) {
					this.user = attributes.user;
				} else if (attributes.user instanceof Models.UserEntity) {
					this.user = attributes.user;
					this.userId = attributes.user.id;
				} else {
					this.user = new Models.UserEntity(attributes.user);
					this.userId = this.user.id;
				}
			}
			if (attributes.siteId !== undefined) {
				this.siteId = attributes.siteId;
			}
			if (attributes.site !== undefined) {
				if (attributes.site === null) {
					this.site = attributes.site;
				} else if (attributes.site instanceof Models.SiteEntity) {
					this.site = attributes.site;
					this.siteId = attributes.site.id;
				} else {
					this.site = new Models.SiteEntity(attributes.site);
					this.siteId = this.site.id;
				}
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		user {
			${Models.UserEntity.getAttributes().join('\n')}
		}
		site {
			${Models.SiteEntity.getAttributes().join('\n')}
			${Models.SiteEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		if (this.site) {
			return this.site.siteName;
		}
		
		return '';
	}

	public listExpands = `
    site {
        siteName
    }
	user {
		name
	}
	`;
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(UserSiteAccessEntity.prototype, 'created');
CRUD(modifiedAttr)(UserSiteAccessEntity.prototype, 'modified');
