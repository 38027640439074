import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import { getCreatedModifiedCrudOptions } from 'Util/EntityUtils';
import { VisitorsAuditEntity } from 'Models/Security/Acl/VisitorsAuditEntity';
import { SuperAdminAuditEntity } from 'Models/Security/Acl/SuperAdminAuditEntity';
import { UserAuditEntity } from 'Models/Security/Acl/UserAuditEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { CRUD } from '../CRUDOptions';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';

export interface IAuditEntityAttributes extends IModelAttributes {
	eventDetail: string;
	eventType: string;

	superAdminId?: string;
	superAdmin?: Models.SuperAdminEntity | Models.ISuperAdminEntityAttributes;
	userId?: string;
	user?: Models.UserEntity | Models.IUserEntityAttributes;
}

@entity('AuditEntity', 'Audit')
export default class AuditEntity extends Model implements IAuditEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsAuditEntity(),
		new SuperAdminAuditEntity(),
		new UserAuditEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
	];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Event Detail',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public eventDetail: string;

	@observable
	@attribute()
	@CRUD({
		name: 'Event Type',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public eventType: string;

	@observable
	@attribute()
	@CRUD({
		name: 'Super Admin',
		displayType: 'reference-combobox',
		order: 30,
		referenceTypeFunc: () => Models.SuperAdminEntity,
	})
	public superAdminId?: string;

	@observable
	@attribute({ isReference: true })
	public superAdmin: Models.SuperAdminEntity;

	@observable
	@attribute()
	@CRUD({
		name: 'User',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.UserEntity,
	})
	public userId?: string;

	@observable
	@attribute({ isReference: true })
	public user: Models.UserEntity;

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAuditEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.eventDetail !== undefined) {
				this.eventDetail = attributes.eventDetail;
			}
			if (attributes.eventType !== undefined) {
				this.eventType = attributes.eventType;
			}
			if (attributes.superAdminId !== undefined) {
				this.superAdminId = attributes.superAdminId;
			}
			if (attributes.superAdmin !== undefined) {
				if (attributes.superAdmin === null) {
					this.superAdmin = attributes.superAdmin;
				} else if (attributes.superAdmin instanceof Models.SuperAdminEntity) {
					this.superAdmin = attributes.superAdmin;
					this.superAdminId = attributes.superAdmin.id;
				} else {
					this.superAdmin = new Models.SuperAdminEntity(attributes.superAdmin);
					this.superAdminId = this.superAdmin.id;
				}
			}
			if (attributes.userId !== undefined) {
				this.userId = attributes.userId;
			}
			if (attributes.user !== undefined) {
				if (attributes.user === null) {
					this.user = attributes.user;
				} else if (attributes.user instanceof Models.UserEntity) {
					this.user = attributes.user;
					this.userId = attributes.user.id;
				} else {
					this.user = new Models.UserEntity(attributes.user);
					this.userId = this.user.id;
				}
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		superAdmin {
			${Models.SuperAdminEntity.getAttributes().join('\n')}
		}
		user {
			${Models.UserEntity.getAttributes().join('\n')}
		}
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		return this.id;
	}
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AuditEntity.prototype, 'created');
CRUD(modifiedAttr)(AuditEntity.prototype, 'modified');
