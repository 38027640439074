import { IAcl } from '../IAcl';

export class UserStudyEntity implements IAcl {
	public group?: string;

	public isVisitorAcl = false;

	public constructor() {
		this.group = 'User';
	}

	public canRead(): boolean {
		return true;
	}

	public canCreate(): boolean {
		return false;
	}

	public canUpdate(): boolean {
		return true;
	}

	public canDelete(): boolean {
		return true;
	}
}
