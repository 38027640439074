import { useEffect, useState } from 'react';
import { fetchImageSetMetadata } from './awsImageSetUtils';

interface FetchImageSetUrlsResult {
	data: ImageEntity[];
	loading: boolean;
	error: Error | null;
}

export interface ImageEntity {
	url: string;
	sopUID: string;
	imageSetId: string;
	imageFrameId: string;
	sopInstanceUID: string;
	numberOfStages: string;
	stageName: string;
	viewName: string;
	viewNumber: string;
	numberOfViews: string;
	numFrames: string;
	frameRate: string;
	stageNumber: string;
}

const useFetchHealthImagingUrls = (studyInstanceUid: string): FetchImageSetUrlsResult => {
	const [data, setData] = useState<ImageEntity[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<Error | null>(null);

	useEffect(() => {
		const fetchMetaData = async () => {
			try {
				const urlsArray = await fetchImageSetMetadata(studyInstanceUid);
				const combinedData = urlsArray.map(url => {
					const match = url.url.match(/\/instances\/([^?\/]+)/);
					const sopUID = match ? match[1] : null;
					return { ...url,
						sopUID: sopUID as string
					 };
				}).filter(item => item.sopUID !== null);
				setData(combinedData);
			} catch (err) {
				setError(err as Error);
			} finally {
				setLoading(false);
			}
		};

		fetchMetaData();
	}, [studyInstanceUid]);

	return { data, loading, error };
};

export default useFetchHealthImagingUrls;
