import { useQuery } from '@apollo/client';
import { Box, Flex, Heading, Input, Select, useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { SERVER_URL } from '../../../../Constants';
import { DoctorEntity, HolterReportEntity, StudyEntity, UserEntity } from '../../../../Models/Entities';
import { store } from '../../../../Models/Store';
import { AutoResizeTextarea } from '../../../Components/AutoExpandTextArea/AutoExpandTextArea';
import SaveModal from '../../Modals/SaveModal';

interface  HolterReportTileProps {
	study: StudyEntity;
	togglePanels: (newPanel: string) => void;
	refetch: () => void;
}
const HolterReportTile = (props: HolterReportTileProps) => {
	const {study, togglePanels, refetch} = props;
	const [correspondence, setCorrespondence] = useState('');
	const [report, setReport] = useState('');
	const [assignedDoctors, setAssignedDoctors] = useState<string[]>([''])
	const [assignedDoctor, setAssignedDoctor] = useState<string>('')
	const [initialDoctor, setInitialDoctor] = useState<string>('')

	const navigate = useNavigate();
	const toast = useToast();

	const { loading: userLoading, data: user, error: userError} = useQuery(UserEntity.getFetchSingleQueryProfilePage(), {
		variables: {
			args: [{
				path: 'id',
				comparison: 'equal',
				value: store.userId,
			}]
		}
	})

	const { userEntity: loggedInUser } = user || {};

	useEffect(() => {
		if (study.holterReport?.correspondence) {
			setCorrespondence(study.holterReport?.correspondence);
		}

		if (study.holterReport?.reportData) {
			setReport(study.holterReport?.reportData)
		}

		fetchDoctors();
		getInitialReportingDoctor();
	}, [study])

	const getInitialReportingDoctor = () => {
		if (study.doctorss.length <= 0) {
			return;
		}

		const studyReportingDoctor = study.doctorss.find(d => d.doctorType === 'REPORTING');

		if (studyReportingDoctor) {
			const { prefix, firstName, lastName } = studyReportingDoctor;
			setAssignedDoctor(`${prefix} ${firstName} ${lastName}`);
			setInitialDoctor(`${prefix} ${firstName} ${lastName}`);
		}
	};

	const fetchDoctors = async () => {
		const reportingDoctorsResponse = await axios.get(`${SERVER_URL}/api/entity/SiteEntity/GetReportingDoctors/${study.patient.siteId}`);

		if (reportingDoctorsResponse.data !== '') {
			const reportingDoctorsData = reportingDoctorsResponse.data;
			const doctorsWithPrefix = reportingDoctorsData.map((doctor: string) => {
				if (!doctor.includes('Dr')) {
					return 'Dr ' + doctor;
				}
				return doctor;
			});

			setAssignedDoctors(doctorsWithPrefix);
		}
	};

	const formatDate = (date: string): string => {
		const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
		return new Date(date).toLocaleDateString(undefined, options).split('/').join('-');
	};

	const splitFullName = (fullName: string) => {
		interface NameParts {
			prefix: string;
			firstName: string;
			lastName: string;
		}

		const namePattern = /^(Dr\.?|Mr\.|Mrs\.|Ms\.)?\s*([\w\-']+)\s+(.*)$/;
		const matches = fullName.match(namePattern);

		if (matches) {
			const prefix = matches[1] || '';
			const firstName = matches[2];
			const lastName = matches[3];

			return {
				prefix,
				firstName,
				lastName,
			} as NameParts;
		} else {
			return null;
		}
	};

	const saveObservations =  async (urgency: boolean, exit: boolean, confirm: boolean, changes: boolean) => {
		const updateReport = new HolterReportEntity(study.holterReport)

		updateReport.correspondence = correspondence;
		updateReport.reportData = report;

		const updateStudy = new StudyEntity(study)
		updateStudy.urgent = urgency;

		const assignedDoctorParts = splitFullName(assignedDoctor);

		if (initialDoctor !== assignedDoctor) {
			if (assignedDoctor === 'N/A') {
				// Remove any existing Dr
				if (study.doctorss[0]) {
					const deleteDoctor = new DoctorEntity(study.doctorss[0]);
					deleteDoctor.delete().then(() => {
						toast({
							title: 'Assigned doctor updated',
							description: 'Assigned doctor updated',
							position: 'bottom-right',
							status: 'success',
							duration: 5000,
							isClosable: true,
						});
					})
				}
			} else {
				let updateDoctor = new DoctorEntity();
				if (assignedDoctorParts) {
					if (study.doctorss[0]) {
						updateDoctor = new DoctorEntity(study.doctorss[0]);
					}

					updateDoctor.prefix = assignedDoctorParts.prefix || 'Dr';
					updateDoctor.firstName = assignedDoctorParts.firstName;
					updateDoctor.lastName = assignedDoctorParts.lastName;
					updateDoctor.doctorType = 'REPORTING';
					updateDoctor.studyId = study.id;
					updateDoctor.save().then(() => {
						toast({
							title: 'Assigned doctor updated',
							description: 'Assigned doctor updated',
							position: 'bottom-right',
							status: 'success',
							duration: 5000,
							isClosable: true,
						});
					})
				} else {
					toast({
						title: 'Could not update reporting doctor',
						description: 'Doctor update failed',
						position: 'bottom-right',
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				}
			}
		}

		if (confirm) {
			updateReport.reportStatus = 'CONFIRMED_HOLTER';
			updateReport.reportingDoctor = store.userId || '';
		} else if (changes) {
			updateReport.reportStatus = 'CARDIOLOGIST_CHANGES_PENDING';
			updateReport.reportingDoctor = store.userId || '';
		} else if (exit) {
			if (updateReport.reportStatus !== 'CARDIOLOGIST_CHANGES_PENDING') {
				updateReport.reportStatus = 'AWAITING_CARDIOLOGIST_CONFIRMATION';
			}
		}

		await updateReport?.save().then(() => {
			toast({
				title: 'Report Saved',
				description: 'Report Saved',
				position: 'bottom-right',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
		});

		if (confirm && study.holterReport) {
			await axios.get(`${SERVER_URL}/holterReportSignature?reportId=${study.holterReport.id}`).then(res => {
				toast({
					title: 'Holter Report Signed',
					description: 'Report Signed',
					position: 'bottom-right',
					status: 'success',
					duration: 5000,
					isClosable: true,
				});
			})
		}
		updateStudy.assignedDoctor = assignedDoctor;

		await updateStudy.save().then(() => {
			toast({
				title: 'Study Saved',
				description: 'Study Updated',
				position: 'bottom-right',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
		});


		if (updateReport.reportStatus === 'CONFIRMED_HOLTER') {
			await axios.get(`${SERVER_URL}/api/entity/StudyEntity/HolterPdf/${study.id}`);
		}

		// RD confirm report always send
		// RD save and exit send prov
		// RD save - no send
		// CS send only exit
		
		// Trigger hl7 method
		if ((store.userType === 'REPORTING_DOCTOR' && (confirm || exit)) ||
			(store.userType === 'CARDIAC_SCIENTIST' && exit)) {
			sendHl7();
		}

		if (exit) {
			navigate('/');
		} else {
			refetch();
			togglePanels('');
			setTimeout(() => {
				togglePanels('testData');
			}, 200);
		}
	}

	const sendHl7 = async () => {
		const { hL7ProviderId } = study.patient.site;

		if (hL7ProviderId) {
			axios.post(
				`${SERVER_URL}/api/entity/StudyEntity/hl7message/` + hL7ProviderId,
				{
					studyId: study.id,
					to: null,
					cc: null,
					documentTitle: study.studyType,
					correction: null,
					reportDetails: {
						firstName: study.patient.firstName,
						surname: study.patient.lastName,
						dob: new Date(study.patient.dob).toISOString(),
						requestDate: new Date().toISOString(),
						observationDate: new Date(study.studyDate).toISOString(),
					},
				},
			)
		}
	};

	const isEditable = () => {
		if ((store.userType === 'SITE_ADMIN' || store.userType === 'SITE_USER') && store.userSiteAccess[0].accessLevel === 'FULL_ACCESS') {
			return false;
		}

		if (initialDoctor.toLowerCase().includes(loggedInUser?.name.toLowerCase())) {
			// Backward compat
			return true;
		}

		if (study.holterReport?.reportStatus === 'CONFIRMED_HOLTER' && store.userId !== study.holterReport?.reportingDoctor) {
			return false;
		}

		const patientSiteId = study.patient.siteId;

		for (const siteAccess of loggedInUser?.sitess || []) {
			if (siteAccess.siteId === patientSiteId) {
				// Found a match for the siteId, extract the access level
				return siteAccess.accessLevel !== 'VIEW_ONLY';
			}
		}

		return study.holterReport?.reportStatus !== 'CONFIRMED_HOLTER';
	}

	return (
		<Box paddingLeft='20px' paddingBottom='100px'>
			<label style={{ display: 'block' }}>
				<Heading as='h5' size='md'>Patient Details</Heading>
			</label>

			<Flex gap="1rem" marginBottom="1rem" marginTop="1rem">
				<Box style={{ flex: '1' }}>
					<label style={{ display: 'block' }}>
						<Heading as='h6' size='md'>Name</Heading>
					</label>

					<Flex gap="0.5rem">
						<Input
							variant="dark-mode-border"
							style={{ flex: '1' }}
							value={study.patient.name}
							disabled
						/>
					</Flex>
				</Box>
				<Box style={{ flex: '1' }}>
					<label style={{ display: 'block' }}>
						<Heading as='h6' size='md'>Date of Birth</Heading>
					</label>

					<Flex gap="0.5rem">
						<Input
							variant="dark-mode-border"
							style={{ flex: '1' }}
							value={formatDate(study.patient.dob.toString())}
							disabled
						/>
					</Flex>
				</Box>
				<Box style={{ flex: '1' }}>
					<label style={{ display: 'block' }}>
						<Heading as='h6' size='md'>Exam Date</Heading>
					</label>

					<Flex gap="0.5rem">
						<Input
							variant="dark-mode-border"
							style={{ flex: '1' }}
							value={formatDate(study.studyDate.toString())}
							disabled
						/>
					</Flex>
				</Box>
			</Flex>

			<label style={{ display: 'block' }}>
				<Heading as='h6' size='md'>Correspondence</Heading>
			</label>

			<AutoResizeTextarea
				border="2px solid #5686ce"
				value={correspondence}
				isDisabled={!isEditable()}
				marginBottom="1rem"
				// @ts-ignore
				minRows={5}
				onChange={e => setCorrespondence(e.target.value )}
			/>

			<label style={{ display: 'block' }}>
				<Heading as='h6' size='md'>Report</Heading>
			</label>

			<AutoResizeTextarea
				border="2px solid #5686ce"
				value={report}
				marginBottom="1rem"
				isDisabled={!isEditable()}
				// @ts-ignore
				minRows={12}
				onChange={e => setReport(e.target.value )}
			/>

			<Box style={{ flex: '1' }}>
				<label style={{ display: 'block' }}>
					<Heading as='h6' size='md'>Assigned Doctor</Heading>
				</label>

				<Select
					variant="dark-mode-border"
					value={assignedDoctor}
					disabled={!isEditable()}
					onChange={e => setAssignedDoctor(e.target.value )}
				>
					<option style={{'backgroundColor':'#373151','color':'#ffffff'}}  defaultValue="">N/A</option>
					{assignedDoctors.map(item => (
						<option style={{'backgroundColor':'#373151','color':'#ffffff'}}  key={item} value={item}>{item}</option>
					))}
				</Select>
			</Box>

			<SaveModal
				editable={isEditable()}
				onSave={(urgency, exit, confirm, changes) => saveObservations(urgency, exit, confirm, changes || false)}
				urgent={study.urgent}
				confirm={study.holterReport?.reportStatus === 'CONFIRMED_HOLTER'}
				holter
			/>
		</Box>
	)
}

export default HolterReportTile;