import { IAcl } from '../IAcl';

export class UserUserServiceEntity implements IAcl {
	public group?: string;
	
	public isVisitorAcl = false;
	
	public constructor() {
		this.group = 'User';
	}
	
	public canRead(): boolean {
		return true;
	}
	
	public canCreate(): boolean {
		return false;
	}
	
	public canUpdate(): boolean {
		return false;
	}
	
	public canDelete(): boolean {
		return false;
	}
}
