import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Select, FormControl, FormLabel, Stack, Button, ButtonGroup, Input, Textarea } from '@chakra-ui/react';
import { store } from 'Models/Store';
import {SessionEntity} from '../../../Models/Entities';

type FormValues = {
	date: Date | null;
	serviceOption: string;
	siteOption: string;
	sessionOption: string;
	startTime: string;
	endTime: string;
	additionalNotes: string;
};

interface NewSessionProps {
	drawerToggle(): void
}

export const NewSessionTile = (props: NewSessionProps) => {
	const { register, unregister, handleSubmit, watch, getValues } = useForm<FormValues>();
    
	const [siteOptions, setSiteOptions] = useState<Array<{value: string, label: string}>>([]);
	const [serviceType, setServiceType] = useState('');
    
	const serviceChosen = watch('serviceOption');
    
	useEffect(() => {
		const options: Array<{value: string, label: string}> = [];
        
		const siteOptions =  store.userSiteAccess
			.sort((usa1, usa2) => {
				const siteName1 = usa1.site.siteName.toLowerCase();
				const siteName2 = usa2.site.siteName.toLowerCase();
				if (siteName1 < siteName2) {
					return -1;
				} else if (siteName1 > siteName2) {
					return 1;
				} else {
					return 0;
				}
			});
        
		siteOptions.map(usa => {
			if (usa.siteId) {
				options.push({value: usa.siteId, label: usa.site.siteName});
			}
		})

		setSiteOptions(options);
	}, [])
    
	useEffect(() => {
		unregister('siteOption'); 
		unregister('sessionOption'); 
		unregister('startTime'); 
		unregister('endTime'); 
		unregister('additionalNotes'); 
        
		setServiceType(getValues('serviceOption'));
	}, [serviceChosen])
    
	const onSubmit = (data: FormValues) => {
        
		let sessionPeriod = 'am';
        
		if (data.sessionOption === 'afternoon') {
			sessionPeriod = 'pm'
		}
		if (data.sessionOption === 'full-day') {
			sessionPeriod = 'full_day';
		}
        
		let serviceType = data.serviceOption;
        
		if (data.serviceOption === 'daily-duties') {
			serviceType = 'daily_duties';
		}
        
		const session = new SessionEntity({
			sessionDate: data.date ?? new Date(),
			// @ts-ignore
			sessionPeriod: sessionPeriod,
			// @ts-ignore
			sessionType: serviceType,
			startTime: data.startTime,
			endTime: data.endTime,
			siteId: data.siteOption,
			userId: store.userId,
		})
        
		session.save().then(() => {
			props.drawerToggle();
			store.apolloClient.refetchQueries({
				include: 'active',
			});
		});
	}
    
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={5}>
				<FormControl variant="floating" id="date" isRequired>
					<Input {...register('date')}
						placeholder="Date"
						size="lg"
						type="date"
						isRequired
					/>
					<FormLabel>Date</FormLabel>
				</FormControl>
                
				<FormControl variant="floating" id="service-type" isRequired>
					<Select size='lg' placeholder='Service Type' {...register('serviceOption')}>
						<option value='scanning'>Scanning</option>
					</Select>
					<FormLabel>Service Type</FormLabel>
				</FormControl>
    
				{ serviceChosen && (
					<>
						{serviceType === 'scanning' && (
							<>
								<FormControl variant="floating" id="site" isRequired>
									<Select size='lg' placeholder='Site' {...register('siteOption')} >
										{siteOptions.map(s => (<option key={s.value} value={s.value} label={s.label}>{s.label}</option>))}
									</Select>
									<FormLabel>Site</FormLabel>
								</FormControl>

								<FormControl variant="floating" id="session" isRequired>
									<Select size='lg' placeholder='Session' {...register('sessionOption')} >
										<option key='morning' value='morning' label='Morning' >Morning</option>
										<option key='afternoon' value='afternoon' label='Afternoon'>Afternoon</option>
										<option key='full-day' value='full-day' label='Full Day'>Full Day</option>
									</Select>
									<FormLabel>Session</FormLabel>
								</FormControl>
							</>
						)}

						{serviceType !== 'scanning' && (
							<>
								<FormControl variant="floating" id="start-time" isRequired>
									<Input {...register('startTime')}
										placeholder="Start time"
										size="lg"
										type="time"
										isRequired
									/>
									<FormLabel>Start Time</FormLabel>
								</FormControl>
                                    
								<FormControl variant="floating" id="end-time" isRequired>
									<Input {...register('endTime')}
										placeholder="End time"
										size="lg"
										type="time"
										isRequired
									/>
									<FormLabel>End Time</FormLabel>
								</FormControl>
                                    
								<FormControl variant="floating" id="additional-notes">
									<Textarea 
										{...register('additionalNotes')}
										placeholder=''
										size='lg'
									/>
									<FormLabel>Additional Notes</FormLabel>
								</FormControl>
							</>
						)}
					</>
				)}
                
				<ButtonGroup>
					<Button onClick={props.drawerToggle} variant='outline'>Cancel</Button> 
                    
					<Button type='submit' colorScheme='blue'>Create Session</Button>
				</ButtonGroup>
			</Stack>
		</form>
	);
}