import * as React from 'react';
import { inject, observer } from 'mobx-react';

import SiteTile from './SiteTile';

const SitePageWrappingTileTile: React.FunctionComponent = () => {
	const contents = (
		<>
			<SiteTile />
		</>
	);

	return contents;
};

export default inject('store')(observer(SitePageWrappingTileTile));
