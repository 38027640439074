
import * as React from 'react';
import classNames from 'classnames';

/**
 * Elements that are defined on the body content context.
 */
export interface BodyContentContextElements {
	/** The body content div element ref */
	element: HTMLElement | null;
	
	
}

/**
 * The body content context. This is provided to allow children of the body content access the body content tag.
 */
export const BodyContentContext = React.createContext<BodyContentContextElements>({
	element: null,
	
	
});

/**
 * Props for the body content.
 */
export interface BodyContentProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	
	
}

/**
 * The body content component. This contains the content of every page.
 */
const BodyContent = (props: BodyContentProps) => {
	
	const [ el, setEl ] = React.useState<HTMLDivElement | null>(null)

	return (
		<div {...props} className={classNames("body-content", props.className)} ref={ref => setEl(ref)}>
			<BodyContentContext.Provider value={{element: el}}>
				{props.children}
			</BodyContentContext.Provider>
		</div>
	);
	
}


export default BodyContent;

