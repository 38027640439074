import * as React from 'react';
import { SERVER_URL } from 'Constants';
import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
	getModelName, getAttributes, 
} from 'Util/EntityUtils';
import { VisitorsSiteEntity } from 'Models/Security/Acl/VisitorsSiteEntity';
import { SuperAdminSiteEntity } from 'Models/Security/Acl/SuperAdminSiteEntity';
import { UserSiteEntity } from 'Models/Security/Acl/UserSiteEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { FileListPreview } from 'Views/Components/CRUD/Attributes/AttributeFile';
import { gql } from '@apollo/client';
import { lowerCaseFirst } from 'Util/StringUtils';
import { store } from 'Models/Store';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';
import { CRUD } from '../CRUDOptions';

export interface ISiteEntityAttributes extends IModelAttributes {
	siteName: string;
	siteKey: string;
	aETitle: string;
	accessKey: string;
	streetAddress: string;
	primaryContactName: string;
	primaryContactEmail: string;
	additionalNotes: string;
	reportHeader: string;
	reportLogoId: string;
	reportLogo: Blob;
	reportFilenameFormat: string;
	reportECGFilenameFormat: string;
	hl7MessagingEnabled: boolean;
	primaryContactNumber: string;
	messageCount: number;
	advancedReportBuilder: boolean;
	moSiteHD: string;
	moAPIURL: string;
	translationFileId: string;
	translationFile: Blob;
	hL7ProviderId?: string;
	hL7Provider?: Models.IHL7ProviderEntityAttributes;
	devicess: Array<Models.DeviceEntity | Models.IDeviceEntityAttributes>;
	patientss: Array<Models.PatientEntity | Models.IPatientEntityAttributes>;
	reportTemplates: Array<Models.ReportTemplateEntity | Models.IReportTemplateEntityAttributes>;
	requestedSites: Array<Models.UserSiteRequestEntity | Models.IUserSiteRequestEntityAttributes>;
	sessionSites: Array<Models.SessionEntity | Models.ISessionEntityAttributes>;
	userss: Array<Models.UserSiteAccessEntity | Models.IUserSiteAccessEntityAttributes>;
	siteServicess: Array<Models.SitesSiteServices | Models.ISitesSiteServicesAttributes>;
	rwmaPlotLabels?: string;
	scpIpAddress?: string;
	scpIpPort?: string;
	scpAETitle?: string;
	tickerAETitle?: string;
	healthImaging?: boolean;
	us2ViewerEnabled?: boolean;
	ahiDatastoreId?: string;
	ahiDatastore?: Models.IAhiDatastoreEntityAttributes;
	siteGroupId?: string;
	siteGroup?: Models.SiteGroupEntity | Models.ISiteGroupEntityAttributes
}

@entity('SiteEntity', 'Site')
export default class SiteEntity extends Model implements ISiteEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsSiteEntity(),
		new SuperAdminSiteEntity(),
		new UserSiteEntity(),
		new UserSiteEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
	];

	/**
	 * Site Name
	 */
	@Validators.Required()
	@Validators.Unique()
	@observable
	@attribute()
	@CRUD({
		name: 'Site Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public siteName: string;

	/**
	 * The short key used to reference the site
	 */
	@Validators.Unique()
	@observable
	@attribute()
	@CRUD({
		name: 'Site Key',
		displayType: 'textfield',
		order: 20,
		headerColumn: false,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public siteKey: string;

	/**
	 * The key used to submit a site access request
	 */
	@Validators.Required()
	@Validators.Unique()
	@observable
	@attribute()
	@CRUD({
		name: 'Access Key',
		displayType: 'textfield',
		order: 30,
		headerColumn: false,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public accessKey: string;
	
	/**
	 * The key used to aetitle
	 */
	@Validators.Unique()
	@observable
	@attribute()
	@CRUD({
		name: 'AE Title',
		displayType: 'textfield',
		order: 30,
		headerColumn: false,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public aETitle: string;

	/**
	 * Street Address
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Street Address',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public streetAddress: string;

	/**
	 * Primary Contact Name
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Primary Contact Name',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public primaryContactName: string;

	/**
	 * Primary Contact Email
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Primary Contact Email',
		displayType: 'textfield',
		order: 60,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public primaryContactEmail: string;

	/**
	 * Additional Notes
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Additional Notes',
		displayType: 'textfield',
		order: 70,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public additionalNotes: string;

	/**
	 * Report Header
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Report Header',
		displayType: 'textfield',
		order: 80,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public reportHeader: string;

	/**
	 * Report Logo
	 */
	@observable
	@attribute({ file: 'reportLogo' })
	@CRUD({
		name: 'Report Logo',
		displayType: 'file',
		order: 90,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'reportLogo',
		displayFunction: attr => (attr ? <img src={`${SERVER_URL}/api/files/${attr}`} style={{ maxWidth: '300px' }} /> : 'No File Attached'),
	})
	public reportLogoId: string;
	
	@observable
	public reportLogo: Blob;

	/**
	 * A string representing the format of the filename
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Report Filename Format',
		displayType: 'textfield',
		order: 100,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public reportFilenameFormat: string;

	/**
	 * A string representing the format of the ECG filename
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Report ECG Filename Format',
		displayType: 'textfield',
		order: 110,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public reportECGFilenameFormat: string;

	/**
	 * Can reporting doctors send private messages
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'HL7 Messaging Enabled',
		displayType: 'checkbox',
		order: 120,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public hl7MessagingEnabled: boolean;

	/**
	 * Primary Contact Number
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Primary Contact Number',
		displayType: 'textfield',
		order: 130,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public primaryContactNumber: string;

	/**
	 * The number of messages that have been sent regarding reports from this site.
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Message Count',
		displayType: 'textfield',
		order: 140,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public messageCount: number;

	/**
	 * Do they have the advancved report builder feature
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Advanced Report Builder',
		displayType: 'checkbox',
		order: 150,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public advancedReportBuilder: boolean;

	/**
	 * Medical Objects unique site identifier
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'MO Site HD',
		displayType: 'textfield',
		order: 160,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public moSiteHD: string;

	/**
	 * Site's unique Medical Objecst API Url
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'MO API URL',
		displayType: 'textfield',
		order: 170,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public moAPIURL: string;
	
	@observable
	@attribute()
	@CRUD({
		name: 'scpIpPort',
		displayType: 'textfield',
		order: 170,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public scpIpPort: string;
	
	@observable
	@attribute()
	@CRUD({
		name: 'scpIpAddress',
		displayType: 'textfield',
		order: 170,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public scpIpAddress: string;
	
	@observable
	@attribute()
	@CRUD({
		name: 'scpAETitle',
		displayType: 'textfield',
		order: 170,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public scpAETitle: string;
	
	@observable
	@attribute()
	@CRUD({
		name: 'tickerAETitle',
		displayType: 'textfield',
		order: 170,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public tickerAETitle: string;

	@observable
	@attribute({ file: 'translationFile' })
	@CRUD({
		name: 'Translation File',
		displayType: 'file',
		order: 180,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		fileAttribute: 'translationFile',
		displayFunction: attr => (attr
			? <FileListPreview url={attr} />
			: 'No File Attached'),
	})
	public translationFileId: string;

	@observable
	public translationFile: Blob;

	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Devicess',
		displayType: 'reference-multicombobox',
		order: 190,
		referenceTypeFunc: () => Models.DeviceEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'devicess',
			oppositeEntity: () => Models.DeviceEntity,
		}),
	})
	public devicess: Models.DeviceEntity[] = [];


	@observable
	@attribute()
	@CRUD({
		name: 'hL7Provider',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.HL7ProviderEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'hL7Provider',
			oppositeEntity: () => Models.HL7ProviderEntity,
		}),
	})
	public hL7ProviderId: string;
	
	public hL7Provider?: Models.HL7ProviderEntity;

	@observable
	@attribute()
	@CRUD({
		name: 'AHI Datastore',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.AhiDatastoreEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'ahiDatastore',
			oppositeEntity: () => Models.AhiDatastoreEntity,
		}),
	})
	public ahiDatastoreId: string;

	public ahiDatastore?: Models.AhiDatastoreEntity;

	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Patientss',
		displayType: 'hidden',
		order: 180,
		referenceTypeFunc: () => Models.PatientEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'patientss',
			oppositeEntity: () => Models.PatientEntity,
		}),
	})
	public patientss: Models.PatientEntity[] = [];

	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Report Templates',
		displayType: 'reference-multicombobox',
		order: 210,
		referenceTypeFunc: () => Models.ReportTemplateEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'reportTemplates',
			oppositeEntity: () => Models.ReportTemplateEntity,
		}),
	})
	public reportTemplates: Models.ReportTemplateEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		name: 'Site Group',
		displayType: 'reference-combobox',
		order: 140,
		referenceTypeFunc: () => Models.SiteGroupEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'siteGroup',
			oppositeEntity: () => Models.SiteGroupEntity,
		}),
	})
	public siteGroupId?: string;

	@observable
	@attribute({ isReference: true })
	public siteGroup: Models.SiteGroupEntity;

	/**
	 * Requested Site
	 */
	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Requested Sites',
		displayType: 'reference-multicombobox',
		order: 220,
		referenceTypeFunc: () => Models.UserSiteRequestEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'requestedSites',
			oppositeEntity: () => Models.UserSiteRequestEntity,
		}),
	})
	public requestedSites: Models.UserSiteRequestEntity[] = [];

	/**
	 * Session Site
	 */
	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Session Sites',
		displayType: 'reference-multicombobox',
		order: 230,
		referenceTypeFunc: () => Models.SessionEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'sessionSites',
			oppositeEntity: () => Models.SessionEntity,
		}),
	})
	public sessionSites: Models.SessionEntity[] = [];

	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Userss',
		displayType: 'reference-multicombobox',
		order: 240,
		referenceTypeFunc: () => Models.UserSiteAccessEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'userss',
			oppositeEntity: () => Models.UserSiteAccessEntity,
		}),
	})
	public userss: Models.UserSiteAccessEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		name: 'RWMA Plot Labels',
		displayType: 'textfield',
		order: 120,
		searchable: false,
		searchTransform: AttrUtils.standardiseBoolean,
	})
	public rwmaPlotLabels: string;
	
	@observable
	@attribute()
	@CRUD({
		name: 'Health Imaging',
		displayType: 'checkbox',
		order: 120,
		searchable: false,
		searchTransform: AttrUtils.standardiseBoolean,
	})
	public healthImaging: boolean;

	/**
	 * Site Services
	 */
	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Site Services',
		displayType: 'reference-multicombobox',
		order: 250,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.SitesSiteServices,
		optionEqualFunc: makeJoinEqualsFunc('siteServicesId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'siteEntity',
			oppositeEntityName: 'serviceEntity',
			relationName: 'sites',
			relationOppositeName: 'siteServices',
			entity: () => Models.SiteEntity,
			joinEntity: () => Models.SitesSiteServices,
			oppositeEntity: () => Models.ServiceEntity,
		}),
	})
	public siteServicess: Models.SitesSiteServices[] = [];

	@observable
	@attribute()
	@CRUD({
		name: 'US2 Viewer Enabled',
		displayType: 'checkbox',
		order: 51,
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public us2ViewerEnabled?: boolean;

	constructor(attributes?: Partial<ISiteEntityAttributes>) {
		super(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ISiteEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if(attributes.aETitle !== undefined) {
				this.aETitle = attributes.aETitle;
			}
			if (attributes.siteName !== undefined) {
				this.siteName = attributes.siteName;
			}
			if (attributes.siteKey !== undefined) {
				this.siteKey = attributes.siteKey;
			}
			if (attributes.accessKey !== undefined) {
				this.accessKey = attributes.accessKey;
			}
			if (attributes.streetAddress !== undefined) {
				this.streetAddress = attributes.streetAddress;
			}
			if (attributes.primaryContactName !== undefined) {
				this.primaryContactName = attributes.primaryContactName;
			}
			if (attributes.primaryContactEmail !== undefined) {
				this.primaryContactEmail = attributes.primaryContactEmail;
			}
			if (attributes.additionalNotes !== undefined) {
				this.additionalNotes = attributes.additionalNotes;
			}
			if (attributes.reportHeader !== undefined) {
				this.reportHeader = attributes.reportHeader;
			}
			if (attributes.reportLogo !== undefined) {
				this.reportLogo = attributes.reportLogo;
			}
			if (attributes.reportLogoId !== undefined) {
				this.reportLogoId = attributes.reportLogoId;
			}
			if (attributes.reportFilenameFormat !== undefined) {
				this.reportFilenameFormat = attributes.reportFilenameFormat;
			}
			if (attributes.reportECGFilenameFormat !== undefined) {
				this.reportECGFilenameFormat = attributes.reportECGFilenameFormat;
			}
			if (attributes.hl7MessagingEnabled !== undefined) {
				this.hl7MessagingEnabled = attributes.hl7MessagingEnabled;
			}
			if (attributes.primaryContactNumber !== undefined) {
				this.primaryContactNumber = attributes.primaryContactNumber;
			}
			if (attributes.messageCount !== undefined) {
				this.messageCount = attributes.messageCount;
			}
			if (attributes.advancedReportBuilder !== undefined) {
				this.advancedReportBuilder = attributes.advancedReportBuilder;
			}
			if (attributes.moSiteHD !== undefined) {
				this.moSiteHD = attributes.moSiteHD;
			}
			if (attributes.moAPIURL !== undefined) {
				this.moAPIURL = attributes.moAPIURL;
			}
			if (attributes.scpIpPort !== undefined) {
				this.scpIpPort = attributes.scpIpPort;
			}
			if (attributes.scpIpAddress !== undefined) {
				this.scpIpAddress = attributes.scpIpAddress;
			}
			if (attributes.scpAETitle !== undefined) {
				this.scpAETitle = attributes.scpAETitle;
			}
			if (attributes.tickerAETitle !== undefined) {
				this.tickerAETitle = attributes.tickerAETitle;
			}
			if (attributes.translationFile !== undefined) {
				this.translationFile = attributes.translationFile;
			}
			if (attributes.translationFileId !== undefined) {
				this.translationFileId = attributes.translationFileId;
			}
			if (attributes.healthImaging !== undefined) {
				this.healthImaging = attributes.healthImaging;
			}
			if (attributes.us2ViewerEnabled !== undefined) {
				this.us2ViewerEnabled = attributes.us2ViewerEnabled;
			}
			if (attributes.hL7Provider !== undefined) {
				if (attributes.hL7Provider === null) {
					this.hL7Provider = attributes.hL7Provider;
				} else if (attributes.hL7Provider instanceof Models.HL7ProviderEntity) {
					this.hL7Provider = attributes.hL7Provider;
				} else {
					this.hL7Provider = new Models.HL7ProviderEntity(attributes.hL7Provider);
				}
			}
			if (attributes.hL7ProviderId !== undefined) {
				this.hL7ProviderId = attributes.hL7ProviderId;
			}
			if (attributes.ahiDatastore !== undefined) {
				if (attributes.ahiDatastore === null) {
					this.ahiDatastore = attributes.ahiDatastore;
				} else if (attributes.ahiDatastore instanceof Models.AhiDatastoreEntity) {
					this.ahiDatastore = attributes.ahiDatastore;
				} 
			}
			if (attributes.ahiDatastoreId !== undefined) {
				this.ahiDatastoreId = attributes.ahiDatastoreId;
			}
			if (attributes.devicess !== undefined && Array.isArray(attributes.devicess)) {
				for (const model of attributes.devicess) {
					if (model instanceof Models.DeviceEntity) {
						this.devicess.push(model);
					} else {
						this.devicess.push(new Models.DeviceEntity(model));
					}
				}
			}
			if (attributes.patientss !== undefined && Array.isArray(attributes.patientss)) {
				for (const model of attributes.patientss) {
					if (model instanceof Models.PatientEntity) {
						this.patientss.push(model);
					} else {
						this.patientss.push(new Models.PatientEntity(model));
					}
				}
			}
			if (attributes.reportTemplates !== undefined && Array.isArray(attributes.reportTemplates)) {
				for (const model of attributes.reportTemplates) {
					if (model instanceof Models.ReportTemplateEntity) {
						this.reportTemplates.push(model);
					} else {
						this.reportTemplates.push(new Models.ReportTemplateEntity(model));
					}
				}
			}
			if (attributes.requestedSites !== undefined && Array.isArray(attributes.requestedSites)) {
				for (const model of attributes.requestedSites) {
					if (model instanceof Models.UserSiteRequestEntity) {
						this.requestedSites.push(model);
					} else {
						this.requestedSites.push(new Models.UserSiteRequestEntity(model));
					}
				}
			}
			if (attributes.sessionSites !== undefined && Array.isArray(attributes.sessionSites)) {
				for (const model of attributes.sessionSites) {
					if (model instanceof Models.SessionEntity) {
						this.sessionSites.push(model);
					} else {
						this.sessionSites.push(new Models.SessionEntity(model));
					}
				}
			}
			if (attributes.userss !== undefined && Array.isArray(attributes.userss)) {
				for (const model of attributes.userss) {
					if (model instanceof Models.UserSiteAccessEntity) {
						this.userss.push(model);
					} else {
						this.userss.push(new Models.UserSiteAccessEntity(model));
					}
				}
			}
			if (attributes.siteServicess !== undefined && Array.isArray(attributes.siteServicess)) {
				for (const model of attributes.siteServicess) {
					if (model instanceof Models.SitesSiteServices) {
						this.siteServicess.push(model);
					} else {
						this.siteServicess.push(new Models.SitesSiteServices(model));
					}
				}
			}
			if (attributes.siteGroup !== undefined) {
				if (attributes.siteGroup === null) {
					this.siteGroup = attributes.siteGroup;
				} else if (attributes.siteGroup instanceof Models.SiteGroupEntity) {
					this.siteGroup = attributes.siteGroup;
				}
			}
			if (attributes.siteGroupId !== undefined) {
				this.siteGroupId = attributes.siteGroupId;
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		siteServicess {
			${Models.SitesSiteServices.getAttributes().join('\n')}
			siteServices {
				${Models.ServiceEntity.getAttributes().join('\n')}
			}
		}
		assetss {
			${Models.AssetEntity.getAttributes().join('\n')}
		}
		devicess {
			${Models.DeviceEntity.getAttributes().join('\n')}
		}
		patientss {
			${Models.PatientEntity.getAttributes().join('\n')}
		}
		reportTemplates {
			${Models.ReportTemplateEntity.getAttributes().join('\n')}
			${Models.ReportTemplateEntity.getFiles().map(f => f.name).join('\n')}
		}
		requestedSites {
			${Models.UserSiteRequestEntity.getAttributes().join('\n')}
		}
		sessionSites {
			${Models.SessionEntity.getAttributes().join('\n')}
			${Models.SessionEntity.getFiles().map(f => f.name).join('\n')}
		}
		userss {
			${Models.UserSiteAccessEntity.getAttributes().join('\n')}
		}
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			siteServicess: {},
			devicess: {},
			patientss: {},
			reportTemplates: {},
			requestedSites: {},
			sessionSites: {},
			userss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'devicess',
							'patientss',
							'reportTemplates',
							'requestedSites',
							'sessionSites',
							'userss',
							'siteServicess',
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		return this.siteName;
	}

	public static fetchSingleSite() {
		const model = new SiteEntity();
		const modelName = lowerCaseFirst(getModelName(SiteEntity));

		return gql`
			query ${modelName} ($args:[WhereExpressionGraph]) {
				${modelName} (where: $args) {
					${getAttributes(SiteEntity).join('\n')}
					${model.defaultExpands}
				}
			}`;
	}

	public static async fetchSiteFilter() {
		const results = await store.apolloClient
			.query({
				query: gql`
					{
						siteEntitys {
							id
							siteName
						}
					}`,
				fetchPolicy: 'network-only',
			});
		return results.data['siteEntitys'].map((r: any) => new this(r));
	}
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(SiteEntity.prototype, 'created');
CRUD(modifiedAttr)(SiteEntity.prototype, 'modified');
