import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity,
} from 'Models/Model';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { CRUD } from '../CRUDOptions';
import { SuperAdminDicomMappingEntity } from '../Security/Acl/SuperAdminDicomMappingEntity';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';

export interface IDicomMappingEntityAttributes extends IModelAttributes {
	path: string;
	parentPath: string;
	name: string;
	tickerName: string;
	parentContainer: string;
}

@entity('DicomMappingEntity', 'DicomMapping')
export default class DicomMappingEntity extends Model implements IDicomMappingEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new SuperAdminDicomMappingEntity(),
	];

	/**
     * Fields to exclude from the JSON serialization in create operations.
     */
	public static excludeFromCreate: string[] = [
	];

	/**
     * Fields to exclude from the JSON serialization in update operations.
     */
	public static excludeFromUpdate: string[] = [
	];
    
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Path',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public path: string;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Path',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public parentPath: string;
    
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
    
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'TickerName',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public tickerName: string;
	
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Parent Container',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public parentContainer: string;
	
	@observable
	@attribute()
	@CRUD({
		name: 'ImageMode',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public imageMode: string;
	
	@observable
	@attribute()
	@CRUD({
		name: 'MeasurementMethod',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public measurementMethod: string;
	
	@observable
	@attribute()
	@CRUD({
		name: 'ImageView',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public imageView: string;
	
	@observable
	@attribute()
	@CRUD({
		name: 'FindingSite',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public findingSite: string;
	
	@observable
	@attribute()
	@CRUD({
		name: 'CardiacCyclePoint',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public cardiacCyclePoint: string;
    
	/**
     * Assigns fields from a passed in JSON object to the fields in this model.
     * Any reference objects that are passed in are converted to models if they are not already.
     * This function is called from the constructor to assign the initial fields.
     */
	@action
	public assignAttributes(attributes?: Partial<IDicomMappingEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.path !== undefined) {
				this.path = attributes.path;
			}

			if(attributes.parentPath !== undefined) {
				this.parentPath = attributes.parentPath;
			}
            
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}

			if (attributes.tickerName !== undefined) {
				this.tickerName = attributes.tickerName;
			}
			
			if (attributes.parentContainer !== undefined) {
				this.parentContainer = attributes.parentContainer;
			}
		}
	}

	/**
     * Additional fields that are added to GraphQL queries when using the
     * the managed model APIs.
     */
	public defaultExpands = '';

	/**
     * The save method that is called from the admin CRUD components.
     */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}

	/**
     * Returns the string representation of this entity to display on the UI.
     */
	public getDisplayName() {
		return this.name;
	}

	public listExpands = '';
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(DicomMappingEntity.prototype, 'created');
CRUD(modifiedAttr)(DicomMappingEntity.prototype, 'modified');
