import { inject, observer, useLocalStore } from 'mobx-react';
import React from 'react';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { isEmail } from 'Validators/Functions/Email';
import * as Models from 'Models/Entities';
import { getEmailPattern } from 'Validators/Functions/Email';

import { runInAction } from 'mobx';
import moment from 'moment';
import { 
	Button, 
	Colors, 
	Display, 
	Sizes,
} from 'Views/Components/Button/Button';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { store } from 'Models/Store';
import alert from 'Util/ToastifyUtils';
import { SERVER_URL } from 'Constants';
import Axios from 'axios';
import {
	Flex,
	Input,
	FormControl,
	FormLabel,
	Button as ChakraButton,
	useToast,
	FormErrorMessage
} from '@chakra-ui/react';
import { useForm, useFieldArray } from 'react-hook-form';

type FormValues = {
	fieldArray: {
		expiry: string;
		email: string;
	}[]
};

interface IShareLinkModalProps {
	study: Models.StudyEntity;
}

interface IRecipient {
	index: number;
	email: string;
	expiry: Date;
}

interface IShareLinkFormState {
	recipients: IRecipient[];
	confidentialityAgreement: boolean;
	errors: {
		confidentialityAgreement: string;
		[index: number]: {[attr: string]: string};
	};
	revoke: number | null;
	confirmShare: boolean;
}

const defaultShareLinkFormState: IShareLinkFormState = {
	recipients: [{ email: '', expiry: new Date(new Date().getTime() + (86400000 * 60)), index: 0 }],
	confidentialityAgreement: false,
	errors: {
		confidentialityAgreement: '',
		0: {},
	},
	revoke: null,
	confirmShare: false,
};

let formSubmitted: boolean = false;
const fieldErrorMessages = {
	email: 'Email Address is required.',
	expiry: 'Expiry is required.',
};

const ShareLinkModal = inject('store')(observer((props: IShareLinkModalProps) => {
	const shareLinkState = useLocalStore(() => (defaultShareLinkFormState));
	const [futureShareLinks, setFutureShareLinks] = React.useState<Models.ShareLinkEntity[]>(props.study.shareLinkss.filter(sl => moment().isBefore(moment(sl.expiryDate))));
	const toast = useToast();

	const {
		register,
		control,
		handleSubmit,
		formState: { errors }	
	} = useForm<FormValues>({
		defaultValues: {
			fieldArray: [{ email:'', expiry: moment().add(3, 'months').format('YYYY-MM-DD') }]
		  },
	});
	const { fields, append } = useFieldArray({
		control,
		name: 'fieldArray'
	  });

	const onSubmit = (formData: FormValues) => {
		if (shareLinkState.confidentialityAgreement === true) {
			formData.fieldArray.forEach(recipient => {
				Axios.get(`${SERVER_URL}/api/entity/${store.userGroups.map(ug => ug.name).includes('SuperAdmin') ? 'SuperAdminEntity' : 'UserEntity'}/${store.userId}`).then(response => {
					runInAction(() => {
						const newShareLink = new Models.ShareLinkEntity();

						newShareLink.countVisited = 0;
						newShareLink.emailAddress = recipient.email;
						newShareLink.expiryDate = new Date(recipient.expiry);
						newShareLink.studyId = props.study.id;
						props.study.shareLinkss.push(newShareLink);
						setFutureShareLinks(props.study.shareLinkss.filter(sl => moment().isBefore(moment(sl.expiryDate))));
						const auditEntity = new Models.AuditEntity();
						auditEntity.eventType = 'Share link created';
						auditEntity.eventDetail = `User with id: ${store.userId} sent share link for study with id: ${props.study.id} to ${recipient.email} with expiry date: ${recipient.expiry}`;
						if (store.userGroups.map(ug => ug.name).includes('SuperAdmin')) {
							auditEntity.superAdminId = store.userId;
							auditEntity.superAdmin = new Models.SuperAdminEntity(response.data);
						} else {
							auditEntity.userId = store.userId;
							auditEntity.user = new Models.UserEntity(response.data);
						}

						shareLinkState.confirmShare = false;
						
						newShareLink.save({ study: {} }).then(() => {
							alert(
								<div>
									<h6>Share Link Sent</h6>
								</div>,
								'success',
							);
							auditEntity.save();
						}).catch(error => {
							alert(
								<div>
									<h6>Share Link Not Sent</h6>
									<p>{error.message}</p>
								</div>,
								'error',
							);
						});
					});
				});
			});
		}
	};

	const handleExpiryDateChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
		runInAction(() => {
			const value = event.target.value;
			futureShareLinks[index].expiryDate = new Date(value);
		});
	}

	const updateShareLink = (shareLink: Models.ShareLinkEntity) => {
		if (shareLink.countVisited === null || shareLink.countVisited === undefined) {
			shareLink.countVisited = 0;
		}
		shareLink.save().then(()=>{
			toast({
				title: 'Share Link Updated',
				position: 'bottom-right',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
		});
	}

	const revokeShareLink = (shareLink: Models.ShareLinkEntity) => {
		runInAction(() => {
			shareLink.expiryDate = new Date(new Date().getTime() - (1000 * 60 * 60 * 24));
			if (shareLink.countVisited === null || shareLink.countVisited === undefined) {
				shareLink.countVisited = 0;
			}
			shareLink.revoked = true;
			shareLink.save().then(() => {
				setFutureShareLinks(futureShareLinks.filter(sl => sl.id !== shareLink.id));
				runInAction(() => {
					shareLinkState.revoke = null;
				});
				Axios.post(`${SERVER_URL}/api/entity/ShareLinkEntity/RevokeShareLink/${shareLink.id}`).then(() => {
					toast({
						title: 'Share Link Revoked',
						position: 'bottom-right',
						status: 'success',
						duration: 5000,
						isClosable: true,
					});
				});
			});
		});
	}

	return (
		<div className="share-study__modal">
			<h4>
				{`Share Study: ${props.study.patient.name.toLowerCase()} - ${props.study.studyType.toLowerCase()} - ${moment(props.study.studyDate).format('DD/MM/YYYY')}`}
			</h4>
			<p>Send unique study link.</p>
			<form onSubmit={handleSubmit(onSubmit)}>
				{fields.map((field, index) => (
					<div className="email-expiry" key={Math.random()}>
						<Flex width="100%" mb="24px">
							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors?.fieldArray?.[index]?.email}
								width="65%"
							>
								<Input
									type="text"
									size="lg"
									{...register(`fieldArray.${index}.email`, {
										required: true,
										pattern: getEmailPattern(),
									})}
								/>
								<FormLabel>Email</FormLabel>
								{formSubmitted && errors?.fieldArray?.[index]?.email?.type === 'required' ? (
									<FormErrorMessage>Email address is required</FormErrorMessage>
								) : (
									''
								)}
								{formSubmitted && errors?.fieldArray?.[index]?.email?.type === 'pattern' ? (
									<FormErrorMessage>Email format invalid</FormErrorMessage>
								) : (
									''
								)}
							</FormControl>
							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors?.fieldArray?.[index]?.expiry}
								id="expiry"
								width="35%"
							>

								<Input
									width="100%"
									m="0 6px"
									type="date"
									size="lg"
									{...register(`fieldArray.${index}.expiry`, {
										required: true,
										validate:v => moment(v).isAfter(moment.now())
									})}
								/>
								{formSubmitted && errors?.fieldArray?.[index]?.expiry?.type === 'required' ? (
									<FormErrorMessage>Expiry date is required</FormErrorMessage>
								) : (
									''
								)}
								{formSubmitted && errors?.fieldArray?.[index]?.expiry?.type === 'validate' ? (
									<FormErrorMessage>Expiry must be a future date</FormErrorMessage>
								) : (
									''
								)}
								<FormLabel margin="-4px 0 0 0">Expiry</FormLabel>
							</FormControl>
						</Flex>
					</div>
				))}
				<div className="add-recipient">
					<Button 
						colors={Colors.PrimaryLight} 
						display={Display.Solid} 
						sizes={Sizes.Medium} 
						buttonProps={{ id: 'add' }} 
						icon={{ icon: 'plus', iconPos: 'icon-left' }}
						onClick={() =>
							append({
							  email: '',
							  expiry: moment().add(3, 'months').format('YYYY-MM-DD')
							})
						  }
					>
						Add recipient
					</Button>
				</div>
				<input name="confidentialityAgreement" className="confidentialityAgreement" type="checkbox" value="Enable" checked={!!shareLinkState.confidentialityAgreement} onChange={e => runInAction(() => shareLinkState.confidentialityAgreement = !shareLinkState.confidentialityAgreement)} />
				<label className="confidentialityAgreementLabel" htmlFor="confidentialityAgreement">I have read the <a href={process.env.PUBLIC_URL + '/ConfidentialityAgreement.pdf'} target="_blank">confidentiality agreement</a></label>
				{formSubmitted && !shareLinkState.confidentialityAgreement  && (
					<div className="input-group__error-text">
						<p>Confidentiality agreement is required</p>
					</div>
				)}
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
					<Button
						colors={Colors.Secondary}
						display={Display.Outline}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'cancel' }}
						onClick={() => store.modal.hide()}
					>
						Close
					</Button>
					{shareLinkState.confirmShare ? (
						<Button
							type="submit"
							colors={Colors.Error} 
							display={Display.Solid} 
							sizes={Sizes.Medium} 
							buttonProps={{ id: 'submit' }}
							onClick={() => (formSubmitted = true)}
						>
							Are you sure?
						</Button>
					) : (
						<Button
							colors={Colors.Primary} 
							display={Display.Solid} 
							sizes={Sizes.Medium}
							buttonProps={{ id: 'confirm' }}
							onClick={e => runInAction(() => {
								e.preventDefault();
								shareLinkState.confirmShare = true;
							})}
						>
							Share Study
						</Button>
					)}
				</ButtonGroup>
			</form>
			{futureShareLinks.length > 0 && (
				<>
					<h4>Already Shared</h4>
					{futureShareLinks.map((shareLink, idx) => (
						<Flex mb="18px" width="100%">
							<Flex width="100%">
								<FormControl
									display="flex"
									flexDir="column"
									variant="floating"
									width="60%"
								>
									<Input
										type="text"
										size="lg"
										value={shareLink.emailAddress}
										disabled
									/>
									<FormLabel>Email</FormLabel>
								</FormControl>
								<FormControl
									display="flex"
									flexDir="column"
									variant="floating"
									id="expiry"
									width="35%"
								>

									<Input
										width="100%"
										m="0 6px"
										type="date"
										size="lg"
										value={moment(shareLink.expiryDate).format('YYYY-MM-DD')}
										onChange={e => handleExpiryDateChange(idx, e)}
									/>

									<FormLabel margin="-4px 0 0 0">Expiry</FormLabel>
								</FormControl>
							</Flex>
							<ChakraButton
								m="0 4px"
								colorScheme='blue'
								onClick={() => updateShareLink(shareLink)}>
											Update
							</ChakraButton>
							{shareLinkState.revoke === idx ? (
								<ChakraButton
									m="0 4px"
									colorScheme='red'
									minWidth="72px"
									onClick={() => revokeShareLink(shareLink)} 
								>
											Confirm
								</ChakraButton>
							) : (
								<ChakraButton
									m="0 4px"
									minWidth="72px"
									colorScheme='orange'
									onClick={() => {
										runInAction(() => {
											shareLinkState.revoke = idx;
										});
									}}>
											Revoke
								</ChakraButton>
							)}
						</Flex>
					))}
				</>
			)}
		</div>
	);
}));

export default ShareLinkModal;
