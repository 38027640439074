
import * as React from 'react';

interface PageWrapperProps {
	children?: React.ReactNode;
}

export class PageWrapper extends React.Component<PageWrapperProps> {
	public render() {
		return (
			<>
				{this.props.children}
			</>
		);
		
	}
}
