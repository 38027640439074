import React, { useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import axios from 'axios';
import { runInAction } from 'mobx';
import { useTranslation } from 'react-i18next';
import {useNavigate} from "react-router-dom";
import { Combobox } from 'Views/Components/Combobox/Combobox';
import {
	DoctorEntity, HolterReportEntity, StudyEntity, StudyFileEntity, 
} from '../../../Models/Entities';
import {withRouter, WithRouterProps}  from "../../../Util/withRouter";
import {
	Button, Colors, Display, Sizes, 
} from '../../Components/Button/Button';
import { SERVER_URL } from '../../../Constants';
import alert from '../../../Util/ToastifyUtils';
import { TextArea } from '../../Components/TextArea/TextArea';
import * as Enums from '../../../Models/Enums';
import { RadioButtonGroup } from '../../Components/RadioButton/RadioButtonGroup';
import { Alignment, ButtonGroup } from '../../Components/Button/ButtonGroup';
import { store } from '../../../Models/Store';
import ShareLinkModal from '../Modals/ShareLinkModal';

interface HolterStudyViewerProps extends WithRouterProps {
	study: StudyEntity;
}

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const HolterStudyViewer = observer((props: HolterStudyViewerProps) => {
	const { study } = props;
	
	const pdfs = useLocalStore(() => ({
		testData: '',
		leadECG: '',
		referral: '',
		diary: '',
		current: 'testData',
		loaded: false,
		pages: 0,
	}));
	
	const { t } = useTranslation();
	const navigate = useNavigate();

	const holter = useLocalStore(() => ({
		report: new HolterReportEntity(),
		changes: {},
		reportingDoctor: '',
		assignedDoctor: '',
	}));

	const doctorsState = useLocalStore(() => ({
		reportingDoctors: [] as DoctorEntity[],
	}));
	
	/**
	 * Checks for any reporting doctors already assigned to the study
	 * @returns The initial reportingDoctor to use
	 */
	const getInitialReportingDoctor = () => {
		let reportingDoctor = '';
		if (study.doctorss.length <= 0) return;
		const studyReportingDoctor = study.doctorss.filter(d => d.doctorType === 'REPORTING')[0];
		if (!studyReportingDoctor) {
			holter.reportingDoctor = reportingDoctor;
			return;
		}
		const reportingDoctorFound = studyReportingDoctor !== undefined && doctorsState.reportingDoctors.filter(
			d => `${d.prefix} ${d.firstName} ${d.lastName}`
			=== `${studyReportingDoctor.prefix} ${studyReportingDoctor.firstName} ${studyReportingDoctor.lastName}`,
		)[0];

		if (reportingDoctorFound) {
			reportingDoctor = `${reportingDoctorFound.prefix} ${reportingDoctorFound.firstName} ${reportingDoctorFound.lastName}`;
		}

		holter.reportingDoctor = reportingDoctor;
	};

	let canChangeUrgency = false;
	canChangeUrgency = canChangeUrgency
		|| (
			(store.userGroups.map(ug => ug.name).includes('SuperAdmin')
			|| (store.userSiteAccess.filter(usa => usa.siteId === study.patient.siteId).length > 0
			&& store.userSiteAccess.filter(usa => usa.siteId === study.patient.siteId)[0].accessLevel === 'FULL_ACCESS'))
		);

	let canEditStudy = false;
	canEditStudy = canEditStudy
		|| (
			(store.userType === 'CARDIAC_SCIENTIST' || store.userType === 'REPORTING_DOCTOR')
			&& store.userSiteAccess.filter(usa => usa.siteId === study.patient.siteId).length > 0
			&& store.userSiteAccess.filter(usa => usa.siteId === study.patient.siteId)[0].accessLevel === 'FULL_ACCESS'
		);

	let canShareStudy = false;
	canShareStudy = canShareStudy
		|| (
			(store.userGroups.map(ug => ug.name).includes('SuperAdmin')
				|| store.userType === 'SITE_ADMIN'
				|| store.userType === 'CARDIAC_SCIENTIST'
				|| store.userType === 'REPORTING_DOCTOR')
			&& store.userSiteAccess.filter(usa => usa.siteId === study.patient.siteId).length > 0
			&& store.userSiteAccess.filter(usa => usa.siteId === study.patient.siteId)[0].accessLevel === 'FULL_ACCESS'
		);
	
	let canAlterStatus = false;
	canAlterStatus = canAlterStatus
		|| (
			store.userType === 'REPORTING_DOCTOR'
			&& store.userSiteAccess.filter(usa => usa.siteId === study.patient.siteId).length > 0 
			&& store.userSiteAccess.filter(usa => usa.siteId === study.patient.siteId)[0].accessLevel === 'FULL_ACCESS'
		);
	
	let canSaveStudy = false;
	canSaveStudy = canSaveStudy
		|| (
			(store.userType === 'REPORTING_DOCTOR' || store.userType === 'CARDIAC_SCIENTIST') 
			&& store.userSiteAccess.filter(usa => usa.siteId === study.patient.siteId).length > 0 
			&& store.userSiteAccess.filter(usa => usa.siteId === study.patient.siteId)[0].accessLevel === 'FULL_ACCESS'
		);
	
	useEffect(() => {
		if (study.assignedDoctor) {
			runInAction(() => {
				holter.assignedDoctor = study.assignedDoctor;
			})
		}
		
		// Get all the associated PDFs
		axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/StudyFileByStudy/${study.id}`)
			.then(res => {
				res.data.forEach((file: StudyFileEntity) => {
					if (file.fileType === 'HOLTER_DIARY') {
						runInAction(() => {
							pdfs.diary = `${SERVER_URL}/api/files/${file.studyFileId}`;
						});
					}
					
					if (file.fileType === 'HOLTER_ECG') {
						runInAction(() => {
							pdfs.leadECG = `${SERVER_URL}/api/files/${file.studyFileId}`;
						});
					}

					if (file.fileType === 'HOLTER_REFERRAL') {
						runInAction(() => {
							pdfs.referral = `${SERVER_URL}/api/files/${file.studyFileId}`;
						});
					}

					if (file.fileType === 'HOLTER_REPORT') {
						runInAction(() => {
							pdfs.testData = `${SERVER_URL}/api/files/${file.studyFileId}`;
						});
					}
				});
			});
		
		axios.get(`${SERVER_URL}/api/entity/HolterReportEntity/ByStudy/${study.id}`)
			.then(res => {
				runInAction(() => {
					holter.report = new HolterReportEntity(res.data);
				});
			}).catch(() => {
				alert('Holter Report doesn\'t exist', 'error');
			}).finally(() => {
				runInAction(() => {
					pdfs.loaded = true;
				});
			});

		axios.get(`${SERVER_URL}/api/entity/SiteEntity/GetReportingDoctors/${study.patient.siteId}`)
			.then(res => {
				if (res.data !== '') {
					runInAction(() => {
						doctorsState.reportingDoctors = res.data;

						getInitialReportingDoctor();
					});
				}
			}).catch(err => console.log(err));
		
		// MAY COME BACK IF WE DECIDE TO ADD TEXT TO HOLTER TEST DATA
		// axios.get(`${SERVER_URL}/api/entity/HolterReportEntity/HTMLConvert/${study.id}`)
		// 	.then(res => {
		// 		runInAction(() => {
		// 			pdfs.testData = `data:application/pdf;base64,${res.data}`;
		// 		});
		// 	}).catch(() => {
		// 		alert('Failed to fetch test data', 'error');
		// 	}).finally(() => {
		// 		runInAction(() => {
		// 			pdfs.loaded = true;
		// 		});
		// 	});
	}, []);

	const saveReport = () => {
		if (holter.changes['changes'] === 'true') {
			runInAction(() => {
				holter.report.reportStatus = 'CARDIOLOGIST_CHANGES_PENDING';
			});
		} else if (canAlterStatus) {
			runInAction(() => {
				holter.report.reportStatus = 'CONFIRMED_HOLTER';	
			});
		}
		
		const reportingDoctor = study.doctorss.filter(d => d.doctorType === 'REPORTING');
		if (reportingDoctor.length === 0 && holter.reportingDoctor) {
			const reportingDoctorFound = doctorsState.reportingDoctors.filter(d => `${d.prefix} ${d.firstName} ${d.lastName}` === holter.reportingDoctor);
			
			if (reportingDoctorFound.length > 0) {
				study.doctorss.push(reportingDoctorFound[0]);
			}
		} else if (reportingDoctor.length > 0 && holter.reportingDoctor) {
			const reportingDoctorFound = doctorsState.reportingDoctors.filter(d => `${d.prefix} ${d.firstName} ${d.lastName}` === holter.reportingDoctor);
			if (reportingDoctorFound.length > 0) {
				const oldDoctor = study.doctorss.filter(d => d.doctorType === 'REPORTING' && d.id !== reportingDoctorFound[0].id);
				oldDoctor.forEach(d => {
					d.delete();
				});
				study.doctorss = [...study.doctorss.filter(d => !oldDoctor.map(od => od.id).includes(d.id))];
				study.doctorss.push(reportingDoctorFound[0]);
			}
		}
		
		study.assignedDoctor = holter.assignedDoctor;
		study.save({ doctorss: {} });

		holter.report.save(undefined, {
			contentType: 'multipart/form-data',
		}).then(() => {
			if (holter.report.reportStatus === 'CONFIRMED_HOLTER') {
				// Send PDF to Device Agent Endpoint
				axios.get(`${SERVER_URL}/api/entity/StudyEntity/HolterPdf/${study.id}`).then(() => {
					alert('Holter report updated successfully', 'success');		
				})
			}
		}).catch(e => {
			alert('Holter report update failed', 'error');
		}).finally(() => {
			navigate('/patientdashboard');
		});
	};
	
	const updateReport = (e: any) => {
		e.preventDefault();
		
		// Check radio checked
		if (canAlterStatus && Object.keys(holter.changes).length === 0) {
			alert('Select one option from selections', 'error');
			return;
		}
		
		if (holter.report.correspondence !== '' && holter.report.correspondence !== undefined && holter.report.correspondence !== null) {
			store.modal.show('Confirmation',
				<div className="confirmation__modal-holter">
					<h4>Correspondence has been added to this report</h4>
					<span>Have you read the correspondence added to this report?</span>
					<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
						<Button
							colors={Colors.Primary}
							display={Display.Solid}
							sizes={Sizes.Medium}
							buttonProps={{ id: 'confirm' }}
							onClick={() => {
								saveReport();
								store.modal.hide();
							}}
						>
							Yes, I have
						</Button>
						<Button 
							colors={Colors.Secondary} 
							display={Display.Outline} 
							sizes={Sizes.Medium} 
							buttonProps={{ id: 'cancel' }} 
							onClick={() => store.modal.hide()}
						>
							No, I have not
						</Button>
					</ButtonGroup>
				</div>);
		} else {
			saveReport();
		}
	};
	
	const transfromUrgency = () => {
		if (study.urgent) {
			return (
				<Button 
					disabled={!store.loggedIn || !canChangeUrgency}
					colors={Colors.Error} 
					display={Display.Solid} 
					sizes={Sizes.Small} 
					icon={{ icon: 'chevron-down', iconPos: 'icon-right' }} 
					onClick={() => runInAction(() => {
						study.toggleUrgent();
						study.save();
					})}
				>
					<span className="icon-left icon-clock">
						Urgent
					</span>
				</Button>
			);
		}
		return (
			<div className="urgent-group">
				<p>Urgency</p>
				<Button 
					colors={Colors.Grey} 
					disabled={!store.loggedIn || !canChangeUrgency}
					display={Display.Solid} 
					sizes={Sizes.Small} 
					icon={{ icon: 'chevron-down', iconPos: 'icon-right' }} 
					buttonProps={{ id: 'routine' }} 
					onClick={() => runInAction(() => {
						study.toggleUrgent();
						study.save();
					})}
				>
					Routine
				</Button>
			</div>
		);
	};

	const renderReportingDoctor = () => (
		<div className="urgent-group">
			<p>Reporting Doctor</p>
			<Combobox
				model={holter}
				modelProperty="reportingDoctor"
				label="Reporting Doctor"
				labelVisible={false}
				className="reportingDoctor"
				isDisabled={!(store.loggedIn && canSaveStudy)}
				options={[...doctorsState.reportingDoctors.map(d => ({ display: `${d.prefix} ${d.firstName} ${d.lastName}`, value: `${d.prefix} ${d.firstName} ${d.lastName}` }))]}
				onAfterChange={(event): void => {
					runInAction(() => {
						holter.reportingDoctor = event.currentTarget.textContent || '';
					});
				}}
			/>
		</div>
	);
	const renderAssignedDoctor = () => (
		<div className="urgent-group">
			<Combobox
				model={holter}
				modelProperty="assignedDoctor"
				label="Assigned Doctor"
				className="reportingDoctor"
				options={[...doctorsState.reportingDoctors.map(d => ({ display: `${d.prefix} ${d.firstName} ${d.lastName}`, value: `${d.prefix} ${d.firstName} ${d.lastName}` }))]}
				onAfterChange={(event): void => {
					runInAction(() => {
						holter.assignedDoctor = event.currentTarget.textContent || '';
					});
				}}
			/>
		</div>
	);

	return (
		<div className={`holter-viewer-container ${store.hasBackendAccess && 'super'}`}>
			{pdfs.loaded && (
				<>
					<div className="holter-viewer-left">
						<div className="holter-viewer-left-info">
							<Button 
								display={Display.Text} 
								icon={{ icon: 'chevron-left', iconPos: 'icon-left' }} 
								className="back-button" 
								onClick={() => navigate(-1)}
							>
								{t('holterReport.closeButton')}
							</Button>
							<div className="holter-viewer-study-info">
								<span>
									{t('holterReport.patientLabel')}
									{ study.patient.name}
								</span>
								<span>
									{t('holterReport.dobLabel')}
									{ new Date(study.patient.dob).toDateString()}
								</span>
								<span>
									{t('holterReport.examDateLabel')}
									{ new Date(study.studyDate).toDateString()}
								</span>
							</div>
							<div className="holter-viewer-tabs">
								<div className="tabs-button-group">
									<Button 
										onClick={() => {
											runInAction(() => {
												pdfs.current = 'testData';
											});
										}}
										className={pdfs.current === 'testData' ? 'active' : ''}
									>
										{t('holterReport.testDataLabel')}
									</Button>
								
									<Button
										onClick={() => {
											runInAction(() => {
												pdfs.current = 'leadECG';
											});
										}}
										disabled={pdfs.leadECG === ''}
										className={pdfs.current === 'leadECG' ? 'active' : ''}
									>
										{t('holterReport.12LeadLabel')}
									</Button>
			
									<Button
										onClick={() => {
											runInAction(() => {
												pdfs.current = 'referral';
											});
										}}
										disabled={pdfs.referral === ''}
										className={pdfs.current === 'referral' ? 'active' : ''}
									>
										{t('holterReport.referralLabel')}
									</Button>
									<Button
										onClick={() => {
											runInAction(() => {
												pdfs.current = 'diary';
											});
										}}
										disabled={pdfs.diary === ''}
										className={pdfs.current === 'diary' ? 'active' : ''}
									>
										{t('holterReport.diaryLabel')}
									</Button>
								</div>
								<Button 
									className="report-status-button"
								>
									{Enums.holterReportStatusOptions[holter.report.reportStatus]}
								</Button>
							</div>
						</div>
						
						<div className="holter-viewer-display">
							{pdfs[`${pdfs.current}`] && (
								<embed src={`${pdfs[`${pdfs.current}`]}#view=Fit&toolbar=1`} type="application/pdf" width="100%" height="100%" />
							)}
						</div>
					</div>
					<div className="holter-viewer-right">
						<form onSubmit={updateReport}>
							<TextArea
								model={holter.report} 
								modelProperty="correspondence"
								label="Enter Correspondence"
								isDisabled={!store.loggedIn || !canEditStudy}
							/>
							<TextArea
								className="report-data-field"
								model={holter.report}
								modelProperty="reportData" 
								isDisabled={!store.loggedIn || !canEditStudy}
							/>

							{store.loggedIn && canAlterStatus && (
								<RadioButtonGroup
									isRequired
									label="Select one"
									model={holter.changes}
									modelProperty="changes"
									options={[
										{ value: 'false', display: 'No changes to report by cardiologist, CONFIRMED' },
										{ value: 'true', display: 'Changes to report by cardiologist. See text box' },
									]}
								/>
							)}

							<div className="holter-control-buttons">
								{transfromUrgency()}

								{store.loggedIn && canAlterStatus && (
									renderReportingDoctor()
								)}
								
								{store.loggedIn && canSaveStudy && (
									renderAssignedDoctor()
								)}
								
							</div>

							<div className="holter-control-buttons">
								{store.loggedIn && canShareStudy && (
									<Button
										colors={Colors.Primary}
										display={Display.Solid}
										sizes={Sizes.Medium}
										buttonProps={{ id: 'confirm' }}
										onClick={() => {
											store.modal.show('Share Study', <ShareLinkModal {...props} study={study} />, { className: 'shareLink-wrapper' });
										}}
									>
										Share Report
									</Button>
								)}
	
								{store.loggedIn && canSaveStudy && (
									<Button
										className="save-button"
										colors={Colors.Primary}
										display={Display.Solid}
										sizes={Sizes.Medium}
										buttonProps={{ id: 'confirm' }}
										type="submit"
									>
										Save
									</Button>
								)}
							</div>
						</form>
					</div>
				</>
			)}
		</div>
	);
});

export default withRouter(HolterStudyViewer);
