import { gql } from '@apollo/client';
import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity,
} from 'Models/Model';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getAttributes,
	getCreatedModifiedCrudOptions, getModelName,
} from 'Util/EntityUtils';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import * as React from 'react';
import { lowerCaseFirst } from '../../Util/StringUtils';
import { SuperAdminAssetEntity } from '../Security/Acl/SuperAdminAssetEntity';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';
import { CRUD } from '../CRUDOptions';
import { UserAssetEntity } from '../Security/Acl/UserAssetEntity';
import { VisitorsAssetEntity } from '../Security/Acl/VisitorsAssetEntity';

export interface IAhiDatastoreEntityAttributes extends IModelAttributes {
	name: string;
	datastoreId: string;
}

@entity('AhiDatastoreEntity', 'AhiDatastore')
export default class AhiDatastoreEntity extends Model implements IAhiDatastoreEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new SuperAdminAssetEntity(),
	];

	/**
     * Fields to exclude from the JSON serialization in create operations.
     */
	public static excludeFromCreate: string[] = [
	];

	/**
     * Fields to exclude from the JSON serialization in update operations.
     */
	public static excludeFromUpdate: string[] = [
	];
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Datastore Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'HealthImaging Datastore ID',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public datastoreId: string;

	/**
     * Assigns fields from a passed in JSON object to the fields in this model.
     * Any reference objects that are passed in are converted to models if they are not already.
     * This function is called from the constructor to assign the initial fields.
     */
	@action
	public assignAttributes(attributes?: Partial<IAhiDatastoreEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.datastoreId !== undefined) {
				this.datastoreId = attributes.datastoreId;
			}
		}
	}

	/**
     * Additional fields that are added to GraphQL queries when using the
     * the managed model APIs.
     */
	public defaultExpands = '';

	/**
     * The save method that is called from the admin CRUD components.
     */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}

	/**
     * Returns the string representation of this entity to display on the UI.
     */
	public getDisplayName() {
		return this.name;
	}
	public static getFetchAhiDatastoreEntity() {
		const model = new AhiDatastoreEntity();
		const modelName = lowerCaseFirst(getModelName(AhiDatastoreEntity));

		return gql`
			query ${modelName} ($args:[WhereExpressionGraph]) {
				${modelName} (where: $args) {
					${getAttributes(AhiDatastoreEntity).join('\n')}
					${model.defaultExpands}
				}
			}`;
	}

	public listExpands = ''
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AhiDatastoreEntity.prototype, 'created');
CRUD(modifiedAttr)(AhiDatastoreEntity.prototype, 'modified');
