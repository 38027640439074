import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { StudyEntity } from '../../../Models/Entities';
import { SERVER_URL } from 'Constants';
import { Flex, Spinner, useToast, Box, Switch, Textarea } from '@chakra-ui/react';
import { AutoResizeTextarea } from '../../Components/AutoExpandTextArea/AutoExpandTextArea';

interface NewReportPreviewProps {
	study?: StudyEntity;
	enableText?: boolean;
	refetch?: () => void;
}

const NewReportPreview = (props: NewReportPreviewProps) => {
	const { study, refetch, enableText } = props;
	const [previewData, setPreviewData] = useState<string | null>(null);
	const [loading, setLoading] = useState(true);
	const [PDFenabled, updatePDFEnabled] = useState(true);

	const [reportText, setReportText] = useState('');

	const toast = useToast();

	useEffect(() => {
		if (
			study?.patient.site.advancedReportBuilder &&
			study.report?.reportDataGenerated &&
			study.report.reportDataGenerated.trim() !== ''
		) {	
			getReport();
		} else {
			if (refetch) {
				refetch();
			}
		}
	}, [])
	
	if (!study) {
		return <>Study is not set</>
	}

	const getReport = async () => {
		await axios.get(`${SERVER_URL}/api/entity/StudyEntity/EchoReportTXTFromTemplate/${study.id}/${study.selectedReportTemplate}`).then(res => ( setReportText(res.data)))

		await axios.get(`${SERVER_URL}/api/entity/StudyEntity/EchoReportPDFFromTemplate/${study.id}/${study.selectedReportTemplate}`)
			.then(res => {
				setPreviewData(`data:application/pdf;base64,${res.data}`);
			})
			.catch(err => {
				toast({
					title: 'Issue loading report',
					description: err,
					position: 'bottom-right',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			})
			.finally(() => {
				setLoading(false); 
			});
	}

	let reportData;
	let numberOfKeys = 0;

	try {
		let reportDataString = study.report?.reportDataGenerated ?? '';
		if (reportDataString.trim() === '') {
			reportDataString = '{}';
		}

		reportData = JSON.parse(reportDataString);
		numberOfKeys = Object.keys(reportData?.observation || {}).length;
	} catch (error) {
		console.error('Error parsing reportDataGenerated:', error);
	}


	if (!study.patient.site.advancedReportBuilder || numberOfKeys < 2) {
		return (
			<AutoResizeTextarea
				value={study.report?.reportData}
				isReadOnly
			/>
		)
	}

	return (
		<Flex
			justifyContent="center" 
			alignItems="center"
			height="90%"
		>
			{loading ? (
				<Spinner size="xl" />
			) : (
				previewData && (
					<Box h="100%" w="100%">
						{enableText && 
						<Box mb="10px">
							PDF Preview: <Switch id='Preview Mode' onChange={e => updatePDFEnabled(e.target.checked)} isChecked={PDFenabled}/>
						</Box>
						}
						{PDFenabled ?
							<embed src={`${previewData}#view=Fit&toolbar=1`} type="application/pdf" width="100%" height="100%" /> : 							
							<Textarea
								value={reportText}
								isDisabled
								h="calc(100% - 25px)"
								opacity="1 !important"
								cursor="default !important"
								color="rgb(86, 134, 206)"
							/>
						}

					</Box>
				)
			)}
		</Flex>
	);
}

export default NewReportPreview;
