import 'graphiql/graphiql.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-contexify/dist/ReactContexify.min.css';
import 'semantic-ui-css/semantic.min.css';
import './scss/main.scss';
import {ChakraProvider, extendTheme} from '@chakra-ui/react';

import { StyleFunctionProps } from '@chakra-ui/styled-system'
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import {BrowserRouter as Router, HashRouter} from 'react-router-dom';
import App from './App';
import { store } from './Models/Store';
import * as Models from 'Models/Entities'
import {  MultiSelectTheme } from 'chakra-multiselect'
import { theme as defaultTheme } from '@chakra-ui/theme'

import './i18n';

// Add the store to the global scope for easy debugging from the console
window['store'] = store;
window['Models'] = Models;

const activeLabelStyles = {
	transform: 'scale(0.85) translateY(-24px)'
};
import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const darkModeBorder = defineStyle({
	border: '2px solid #5686ce',
	backgroundColor: '#373151',
	color: '#eeeeee'
})

const echoStudyInputBorder = defineStyleConfig({
	variants: { darkModeBorder },
})


export const theme = extendTheme({
	components: {
		MultiSelect: MultiSelectTheme,
		Input: {
			variants: {
			  'dark-mode-border': (props: StyleFunctionProps) => ({
					...defaultTheme?.components?.Input?.variants?.outline(props),
					field: {
				  ...defaultTheme?.components?.Input?.variants?.outline(props).field,
				  border: '2px solid',
				  borderColor: '#5686ce',
				  backgroundColor: '#373151',
				  color: 'lightgrey'
					},
			  }),
			},
		  },
		Textarea : echoStudyInputBorder,
		Select : {
			variants: {
			  'dark-mode-border': (props: StyleFunctionProps) => ({
					...defaultTheme?.components?.Select?.variants?.outline(props),
					field: {
				  ...defaultTheme?.components?.Select?.variants?.outline(props).field,
				  border: '2px solid',
				  borderColor: '#5686ce',
				  backgroundColor: '#373151',
				  color: 'lightgrey'
					},
			  }),
			},
		  },
		Form: {
			variants: {
				floating: {
					container: {
						_focusWithin: {
							label: {
								...activeLabelStyles
							}
						},
						'input:not(:placeholder-shown) + label, .chakra-numberinput + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label': {
							...activeLabelStyles
						},
						label: {
							top: 0,
							left: 0,
							zIndex: 2,
							position: 'absolute',
							backgroundColor: 'white',
							pointerEvents: 'none',
							mx: 3,
							px: 1,
							my: 2,
							transformOrigin: 'left top'
						}
					}
				}
			}
		}
	},
	colors: {
		plumPurple:{
		  50: '#f0eefc',
		  100: '#d3cfe4',
		  200: '#b5b0cf',
		  300: '#9890bb',
		  400: '#7b70a6',
		  500: '#373151',
		  600: '#373151',
		  700: '#373151',	
		  800: '#201d32',
		  900: '#0b0916',
		},
		trueBlack:{
			50: '#000000',
			100: '#000000',
			200: '#000000',
			300: '#000000',
			400: '#000000',
			500: '#000000',
			600: '#000000',
			700: '#000000',	
			800: '#000000',
			900: '#000000',
		  }
	  }
});

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(<Router><ChakraProvider theme={theme} portalZIndex={40}><React.Suspense fallback={null}><App /></React.Suspense></ChakraProvider></Router>);


