import { gql, useQuery } from '@apollo/client';
import { Box, Button, CircularProgress, Flex, Image } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { StudyEntity } from '../../../Models/Entities';
import CTReportBuilder from './CTReportBuilder';
import ImageViewer from './ImageViewerComponents/ImageViewer';
import { NewAdvancedReportBuilderMethods } from './NewAdvancedReportBuilder';
import NewReportPreview from './NewReportPreview';

interface NewCTStudyViewerProps {
	studyId: string;
}

const NewCTStudyViewer = (props: NewCTStudyViewerProps) => {
	const { studyId } = props;

	const { loading, data, error, refetch } = useQuery(StudyEntity.getMinStudy(), {
		variables: {
			args: [{
				path: 'id',
				comparison: 'equal',
				value: studyId,
			}]
		},
		fetchPolicy: 'no-cache',
	});
	
	const [isImageViewerFullScreen, setIsImageViewerFullScreen] = useState(false);
	const [activePanel, setActivePanel] = useState('report');
	
	const reportButtonRef = useRef<HTMLButtonElement>(null);
	const previewButtonRef = useRef<HTMLButtonElement>(null);

	
	const [changes, setChanges] = useState(false)
	
	const CTReporterRef = useRef<NewAdvancedReportBuilderMethods | null>(null);
	
	const detectChanges = () => {
		setChanges(true);
	}

	
	if (loading) {
		return <CircularProgress isIndeterminate color='green.300' />
	}
	
	const { studyEntity: study } = data;


	const togglePanels = (newPanel: string) => {
		if (newPanel === activePanel) {
			toggleFullScreen();
		} else {
			setActivePanel(newPanel);
			setIsImageViewerFullScreen(false);
		}
	};
	
	const toggleFullScreen = () => {
		setIsImageViewerFullScreen(prev => !prev);
	};
	
	const renderPanel = () => {
		if (isImageViewerFullScreen) {
			return <></>
		}
		
		if (activePanel === 'report') {
			return <CTReportBuilder study={study} refetch={refetch} detectChanges={detectChanges} ref={CTReporterRef} ></CTReportBuilder>
		}
		if (activePanel === 'preview') {
			return <NewReportPreview study={study} refetch={refetch} />
		}
		else {
			return <>Loading...</>
		}
	}
	
	return (
		<Flex gridColumn="1 / -1" width='100vw' height='100vh' position='relative'>
			<Box flex='1' transition="width 0.3s" >
				<Image src={'/CT_placeholder_image.png'} width="100%" height="auto"></Image>
			</Box>
			<Box flex='1' display={isImageViewerFullScreen ? 'none' : ''} padding="20px" overflowY='auto' backgroundColor="#373151" color="#eeeeee">
				{renderPanel()}
			</Box>

			<Box
				transition="width 0.3s"
				position="absolute"
				left={isImageViewerFullScreen ? `calc(100% - (${reportButtonRef?.current?.offsetHeight}px /2))` : 'calc(50% - 20px)'}
				transform="translate(-50%, -50%) rotate(90deg)"
				top="5%"
			>
				<Box
					display="flex"
					alignItems="center"
				>

					<Button
						ref={reportButtonRef}
						onClick={() => togglePanels('report')}
						colorScheme={activePanel === 'report' ? 'plumPurple' : 'trueBlack'}
						marginRight="10px"
						left="50%"
					>
							Report
					</Button>

					<Button
						ref={previewButtonRef}
						onClick={() => togglePanels('preview')}
						colorScheme={activePanel === 'preview' ? 'plumPurple' : 'trueBlack'}
						marginRight="10px"
						left="50%"
					>
							Preview
					</Button>

				
				</Box>
			</Box>
		</Flex>
	);
}

export default NewCTStudyViewer;