import Axios from 'axios';
import { genderTypeOptions, studyTypeOptions, genderType, studyType } from 'Models/Enums';
import React from 'react';
import { makeEnumFetchFunction } from 'Util/EntityUtils';
import { store } from 'Models/Store';
import { SERVER_URL } from 'Constants';
import { DoctorEntity, HolterReportEntity, PatientEntity, ReportEntity, StudyEntity } from 'Models/Entities';
import {useNavigate} from 'react-router';
import {
	Select,
	Flex,
	Input,
	FormControl,
	FormLabel,
	Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, ModalCloseButton,
	Button,
	Textarea,
	Heading,
	useToast
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

interface newPatienModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
}

type FormValues = {
	firstName: string;
	lastName: string;
	patientId: string;
	gender: genderType;
	studyType: studyType;
	dob: string;
	siteId: string;
	referringDoctorPrefix: string;
	referringDoctorFirstName: string;
	referringDoctorLastName: string;
	urgency: string;
	clinicalDetails: string;
};


export const NewPatientModal = (props: newPatienModalProps) => {
	const { isOpen, onClose, onConfirm } = props;
	const siteOptions = store.userGroups.map(ug => ug.name).includes('SuperAdmin') ? store.sites.map(site => ({ display: site.siteName, value: site.id })) : store.userSiteAccess.map(usa => ({ display: usa.site.siteName, value: usa.siteId }));
	const toast = useToast();
	const navigate = useNavigate();

	const{	register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm<FormValues>();


	const createPatientWithStudyAndReport = (patientEntity: PatientEntity) => {
		const holterTypes = [studyTypeOptions.HOLTERTWENTYFOURHR, studyTypeOptions.HOLTERFORTYEIGHTHR, studyTypeOptions.HOLTEREXTENDED, studyTypeOptions.AMBULATORY_BP, studyTypeOptions.EVENT_MONITOR, studyTypeOptions.EXERCISE_STRESS_ECG];

		if (holterTypes.includes(studyTypeOptions[patientEntity.studiess[0].studyType])) {
			patientEntity.save({ studiess: { holterReport: {}, doctorss: {} } }).then(() => {
				toast({
					title: 'Patient Created',
					position: 'bottom-right',
					status: 'success',
					duration: 5000,
					isClosable: true,
				});
				reset({
					firstName: '',
					lastName: '',
					patientId: '',
					gender: undefined,
					studyType: undefined,
					dob: '',
					siteId: '',
					referringDoctorPrefix: '',
					referringDoctorFirstName: '',
					referringDoctorLastName: '',
					urgency: '',
					clinicalDetails: '',
				});
				onConfirm();

			});
		} else {
			patientEntity.save({ studiess: { report: {}, doctorss: {} } }).then(() => {
				toast({
					title: 'Patient Created',
					position: 'bottom-right',
					status: 'success',
					duration: 5000,
					isClosable: true,
				});
				onConfirm();

			});
		}
	};

	const savePatient = (formData: FormValues) => {
		Axios.get(`${SERVER_URL}/api/entity/PatientEntity/ValidatePatientId/${encodeURIComponent(formData.patientId)}/${encodeURIComponent(formData.siteId)}`).then(res => {
			if (res.data.siteId) {
				toast({
					title: 'Patient already exists',
					position: 'bottom-right',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
				navigate(`${SERVER_URL}/patientprofile/${res.data.id}`)
				return;
			}

			if (formData.dob 
				&& formData.gender
				&& formData.siteId
				&& formData.studyType) {
				const patientEntity = new PatientEntity({
					name: `${formData.firstName} ${formData.lastName}`,
					patientId: formData.patientId,
					dob: new Date(formData.dob),
					gender: formData.gender,
					site: res.data,
					siteId: res.data.id,
					studiess: [new StudyEntity({
						doctorss: [new DoctorEntity({
							prefix: formData.referringDoctorPrefix,
							firstName: formData.referringDoctorFirstName,
							lastName: formData.referringDoctorLastName,
							doctorType: 'REFERRING',
						})],
						clinicalDetails: formData.clinicalDetails,
						studyType: formData.studyType,
						studyDate: new Date(),
						urgent: formData.urgency === 'true',
					})],
				});
				const holterTypes = [studyTypeOptions.HOLTERTWENTYFOURHR, studyTypeOptions.HOLTERFORTYEIGHTHR, studyTypeOptions.HOLTEREXTENDED, studyTypeOptions.AMBULATORY_BP, studyTypeOptions.EVENT_MONITOR, studyTypeOptions.EXERCISE_STRESS_ECG];
				if (holterTypes.includes(studyTypeOptions[formData.studyType])) {
					patientEntity.studiess[0].holterReport = new HolterReportEntity({
						reportStatus: 'NOT_REPORTED_HOLTER',
					})

					createPatientWithStudyAndReport(patientEntity);
				} else {
					patientEntity.studiess[0].report = new ReportEntity({
						reportStatus: 'NOT_REPORTED',
					})

					createPatientWithStudyAndReport(patientEntity);
				}
			}
		}).catch(e => {
			alert(
				<div>
					<h6>Patient could not be created</h6>
				</div>,
			);
		});
	}
	

	return (
		<Modal isOpen={isOpen} onClose={()=> onClose()} isCentered>
			<form className="request" onSubmit={handleSubmit(savePatient)} >

				<ModalOverlay />
				<ModalContent  maxH="100%" maxW="80%" className="newPatientModal" paddingTop="14px">
					<ModalCloseButton />
					<ModalBody  maxHeight="80vh" overflowY="auto" w="100%">
						<Heading as='h4' size='md' noOfLines={1}>New Patient</Heading>	
						<Flex direction="column">
							<Flex alignItems="center" justifyContent="space-between">
								<FormControl
									display="flex"
									flexDir="column"
									variant="floating"
									isInvalid={!!errors.firstName}
									margin="8px 8px 8px 0"
								>
									<Input
										type="text"
										size="lg"
										{...register('firstName', {
											required: true,
										})}
									/>
									<FormLabel>First Name</FormLabel>
								</FormControl>
							
								<FormControl
									display="flex"
									flexDir="column"
									variant="floating"
									isInvalid={!!errors.lastName}
									margin="8px 0 8px 8px"
								>
									<Input
										type="text"
										size="lg"
										{...register('lastName', {
											required: true,
										})}
									/>
									<FormLabel>Last Name</FormLabel>
								</FormControl>
							</Flex>

							<Flex alignItems="center" justifyContent="space-between">
								<FormControl
									display="flex"
									flexDir="column"
									variant="floating"
									isInvalid={!!errors.patientId}
									margin="8px 8px 8px 0"
								>
									<Input
										type="text"
										size="lg"
										{...register('patientId', {
											required: true,
										})}
									/>
									<FormLabel>Paitent ID</FormLabel>
								</FormControl>
								<FormControl
									display="flex"
									flexDir="column"
									variant="floating"
									isInvalid={!!errors.dob}
									id="dob"
									width="100%"
									margin="8px 0 8px 8px"
								>

									<Input
										width="100%"
										m="0 6px"
										type="date"
										size="lg"
										{...register('dob', {
											required: true,
										})}
									/>

									<FormLabel margin="-4px 0 0 0">Date of Birth</FormLabel>
								</FormControl>
							</Flex>

							<Flex alignItems="center" justifyContent="space-between">
								<FormControl
									display="flex"
									flexDir="column"
									variant="floating"
									isInvalid={!!errors.studyType}
									id="studyType"
									width="100%"
									margin="8px 8px 8px 0"
								>
									<Select 
										width="100%"
										height="42px"
										{...register('studyType', {
											required: true,
										})}>
										{makeEnumFetchFunction(studyTypeOptions).filter(option => ['HOLTERTWENTYFOURHR','HOLTERFORTYEIGHTHR','HOLTEREXTENDED','AMBULATORY_BP','EVENT_MONITOR'].includes(option.value)).map(option => (<option key={option.value} value={option.value}>{option.display}</option>))}
									</Select>

									<FormLabel margin="-4px 0 0 0">Study Type</FormLabel>
								</FormControl>
								<FormControl
									display="flex"
									flexDir="column"
									variant="floating"
									isInvalid={!!errors.gender}
									id="gender"
									width="100%"
									margin="8px 0 8px 8px"
								>
									<Select 
										width="100%"
										height="42px"
										{...register('gender', {
											required: true,
										})}>
										{makeEnumFetchFunction(genderTypeOptions).map(option => (<option key={option.value} value={option.value}>{option.display}</option>))}
									</Select>

									<FormLabel margin="-4px 0 0 0">Gender</FormLabel>
								</FormControl>
							</Flex>

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.siteId}
								id="siteId"
								width="100%"
								margin="8px 0 8px 0"
							>
								<Select 
									width="100%"
									height="42px"
									{...register('siteId', {
										required: true,
									})}>
									{siteOptions.map(option => (<option key={option.value} value={option.value}>{option.display}</option>))}
								</Select>

								<FormLabel margin="-4px 0 0 0">Study Type</FormLabel>
							</FormControl>
						</Flex>
						<Heading as='h4' size='md' noOfLines={1}>Referring Doctor</Heading>	
						<Flex alignItems="center" justifyContent="space-between">

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.referringDoctorPrefix}
								width="80px"
								margin="8px 8px 8px 0"
							>
								<Input
									type="text"
									size="lg"
									{...register('referringDoctorPrefix', {
										required: true,
									})}
								/>
								<FormLabel>Prefix</FormLabel>
							</FormControl>	
							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.referringDoctorFirstName}
								width="50%"
								margin="8px"
							>
								<Input
									type="text"
									size="lg"
									{...register('referringDoctorFirstName', {
										required: true,
									})}
								/>
								<FormLabel>First Name</FormLabel>
							</FormControl>	

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.referringDoctorLastName}
								width="50%"
								margin="8px 0px 8px 8px"
							>
								<Input
									type="text"
									size="lg"
									{...register('referringDoctorLastName', {
										required: true,
									})}
								/>
								<FormLabel>Last Name</FormLabel>
							</FormControl>	
						</Flex>

						<FormControl
							display="flex"
							flexDir="column"
							variant="floating"
							isInvalid={!!errors.urgency}
							id="urgency"
							width="100%"
							margin="8px 0 8px 0"
						>
							<Select 
								width="100%"
								height="42px"
								{...register('urgency', {
									required: true,
								})}>
								{[{ display: 'Urgent', value: 'true' }, { display: 'Routine', value: 'false' }].map(option => (<option key={option.value} value={option.value}>{option.display}</option>))}
							</Select>

							<FormLabel margin="-4px 0 0 0">Urgency</FormLabel>
						</FormControl>

						<FormControl
							display="flex"
							flexDir="column"
							variant="floating"
							isInvalid={!!errors.clinicalDetails}
							margin="16px 0 8px 0"

						>
							<Textarea
								size="lg"
								{...register('clinicalDetails', {
									required: true,
								})}
							/>
							<FormLabel>Clinical Details</FormLabel>
						</FormControl>
							
								
					</ModalBody>

					<ModalFooter display="Flex" justifyContent="space-between">
						<Button variant='outline' onClick={()=> onClose()}>Cancel</Button>
						<Button colorScheme='blue' mr={3} type="submit">Confirm</Button>
		
					</ModalFooter>
				</ModalContent>
			</form>

		</Modal>
	);
};
