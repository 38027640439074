import React, { useRef, useState } from 'react';

import { observable, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { store } from 'Models/Store';
import { ModelCollection } from 'Views/Components/ModelCollection/ModelCollection';
import * as Models from 'Models/Entities';
import moment from 'moment';
import { ApiQueryParams, IOrderByCondition } from 'Views/Components/ModelCollection/ModelAPIQuery';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import { PaginationQueryOptions } from 'Models/PaginationData';
import { IFilter } from 'Views/Components/Collection/CollectionFilterPanel';
import { makeEnumFetchFunction } from 'Util/EntityUtils';
import { debounce } from 'lodash';
import {ICollectionBulkActionProps, ICollectionItemActionProps} from 'Views/Components/Collection/Collection';
import { Button, Colors, Display, Sizes } from 'Views/Components/Button/Button';
import alert from 'Util/ToastifyUtils';
import { Link } from 'react-router-dom';
import { SERVER_URL } from 'Constants';
import {useQuery} from "@apollo/client";
import {SiteGroupEntity} from "Models/Entities";
import SiteGroupOptionsModal from "../../Components/Modal/SiteGroupOptionsModal";

type transformFn<T> = (item: T, name: string) => (string | React.ReactNode);
interface ISiteCrudTileProps {
	orderBy?: IOrderByCondition<Models.SiteEntity>;
	perPage?: number;
}

const SiteCrudTile = (props: ISiteCrudTileProps) => {
	const [search, setSearch] = useState({ searchTerm: '' });
	const collectionRef = useRef<ModelCollection<Models.SiteEntity> | null>(null);
	const defaultOrderBy: IOrderByCondition<Models.SiteEntity> = { path: 'created', descending: true };
	const orderBy: IOrderByCondition<Models.SiteEntity> | undefined = observable(defaultOrderBy || props.orderBy);
	const paginationQueryOptions: PaginationQueryOptions = observable(new PaginationQueryOptions());
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [selectedModels, setSelectedModels] = useState<Models.SiteEntity[]>([]);

	const {loading, data} = useQuery(SiteGroupEntity.fetchSiteGroupFilter())
	if(loading){
		return(<></>)
	}

	const transformSiteName: transformFn<Models.SiteEntity> = (site: Models.SiteEntity) => site?.siteName;
	const transformSiteStreetAddress: transformFn<Models.SiteEntity> = (site: Models.SiteEntity) => site?.streetAddress;
	const transformSiteContactName: transformFn<Models.SiteEntity> = (site: Models.SiteEntity) => site?.primaryContactName;
	const transformSiteContactEmail: transformFn<Models.SiteEntity> = (site: Models.SiteEntity) => site?.primaryContactEmail;
	const transformSiteContactNumber: transformFn<Models.SiteEntity> = (site: Models.SiteEntity) => site?.primaryContactNumber;
	const transformSiteGroup: transformFn<Models.SiteEntity> = (site: Models.SiteEntity) => {
		const matchingSiteGroup = data.siteGroupEntitys.find((group: { id: string | undefined; }) => group.id === site.siteGroupId);

		if (matchingSiteGroup) {
			return matchingSiteGroup.name;
		}

		return site.siteGroupId;
	}
	
	const getHeaders = (): Array<ICollectionHeaderProps<Models.SiteEntity>> => {
		return [
			{
				name: 'siteName',
				displayName: 'Site Name',
				sortable: true,
				transformItem: transformSiteName,
			},
			{
				name: 'streetAddress',
				displayName: 'Street Address',
				sortable: true,
				transformItem: transformSiteStreetAddress,
			},
			{
				name: 'primaryContactName',
				displayName: 'Primary Contact Name',
				sortable: true,
				transformItem: transformSiteContactName,
			},
			{
				name: 'primaryContactEmail',
				displayName: 'Primary Contact Email',
				sortable: true,
				transformItem: transformSiteContactEmail,
			},
			{
				name: 'primaryContactNumber',
				displayName: 'Primary Contact Number',
				sortable: true,
				transformItem: transformSiteContactNumber,
			},
			{
				name: 'siteGroup',
				displayName: 'Site Group',
				sortable: true,
				transformItem: transformSiteGroup
			}
		];
	};

	const getActions = () => {
		const tableActions: Array<ICollectionItemActionProps<Models.SiteEntity>> = [];

		if (store.userGroups.map(ug => ug.name).includes('SuperAdmin')) {
			tableActions.push({
				action: item => {},
				label: 'Edit Site',
				customButton: (site: Models.SiteEntity) => (
					<Link to={{ pathname: `${SERVER_URL}/admin/siteentity/edit/${site.id}` }}>
						<Button
							colors={Colors.Primary}
							display={Display.Text}
							sizes={Sizes.Small}
							buttonProps={{ id: 'edit' }}
						>
							Edit Site
						</Button>
					</Link>
				),
			});
		}

		return tableActions;
	};

	const getBulkActions = (): ICollectionBulkActionProps<Models.SiteEntity>[] => {
		const createBulkAccess: ICollectionBulkActionProps<Models.SiteEntity> = {
			label: `Add To SiteGroup`,
			showIcon: true,
			icon: 'location-map',
			iconPos: 'icon-left',
			bulkAction: (models, event) => {
				setSelectedModels(models);
				setIsModalOpen(true);
			},
		};

		return [createBulkAccess];
	};

	const getMoreParamsFromFilters = (filters: Array<IFilter<Models.SiteEntity>>): ApiQueryParams => {
		const params = {};

		const siteGroupFilter = filters.find(f => f.path === 'siteGroup' && !!f.value1 && (f.value1 as []).length !== 0);
		if (siteGroupFilter) {
			params['siteGroup'] = JSON.stringify(siteGroupFilter.value1);
		}

		return params;
	};

	const onSearchTriggered = (searchTerm: string) => {
		setSearch({ searchTerm });
	};

	const getMoreParams = (filters?: Array<IFilter<Models.SiteEntity>>, filterApplied?: boolean): ApiQueryParams => {
		const filterParams = (filters && filterApplied) ? getMoreParamsFromFilters(filters) : {};

		if (search.searchTerm) {
			filterParams['searchStr'] = search.searchTerm;
		}

		return filterParams;
	};

	const additionalActions = (): React.ReactNode[] => [];

	const getFilters = (): Array<IFilter<Models.SiteEntity>> => {
		let siteGroupOptions = {};

		data.siteGroupEntitys.forEach((siteGroup: { name: any; id: string | number; }) => {
			if (siteGroup.name) {
				siteGroupOptions[siteGroup.id] = siteGroup.name;
			}
		});

		const siteGroupFilter: IFilter<Models.SiteEntity> = {
			path: 'siteGroup',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Site Group',
			enumResolveFunction: makeEnumFetchFunction(siteGroupOptions),
		};

		return [siteGroupFilter];
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
		setSelectedModels([]);
	}

	const handleModalConfirm = async (selectedGroup: string) => {
		if(selectedGroup.trim() !== '') {
			alert(`SiteGroup not selected`, 'error');
		}
		try {
			selectedModels.forEach(site => {
				runInAction(()=>{
					site.siteGroupId = selectedGroup;
					site.save();
				})
			})
			alert(`Successfully added to SiteGroup`, 'success');
		} catch (error) {
			console.error('Error creating entity:', error);
			alert(`Could not add to SiteGroup`, 'error');
		}
		setIsModalOpen(false);
		setSelectedModels([]);
		window.location.reload();
	}

	return (
		<div>
			<SiteGroupOptionsModal
				isOpen={isModalOpen}
				onClose={handleCloseModal}
				onConfirm={handleModalConfirm}
				models={selectedModels}
				siteGroups={data.siteGroupEntitys}
			/>
			<ModelCollection
				ref={collectionRef}
				url="/api/entity/SiteEntity/AddSites"
				searchStr={search.searchTerm}
				isApiQuery
				orderBy={orderBy}
				model={Models.SiteEntity}
				headers={getHeaders()}
				actions={getActions()}
				selectableItems={true}
				onSearchTriggered={debounce(onSearchTriggered, 200)}
				perPage={paginationQueryOptions.perPage}
				idColumn="id"
				dataFields={row => ({
					created: moment(row.created).format('YYYY-MM-DD'),
					modified: moment(row.modified).format('YYYY-MM-DD'),
				})}
				getMoreParams={getMoreParams}
				filters={getFilters()}
				additionalActions={additionalActions()}
				selectedBulkActions={getBulkActions()}
				dateFormat="d/m/Y"
				altFormat="d/m/Y"
			/>
		</div>
	);
};

export default inject('store')(observer(SiteCrudTile));
