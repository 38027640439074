import axios from "axios";
import React, {useEffect, useState} from "react";
import { Navigate } from "react-router-dom";
import { SERVER_URL } from "../../../Constants";
import { IUserResult, store } from "../../../Models/Store";

interface ProtectedRouteProps {
    children: React.ReactNode
}

const onSuccess = (userResult?: IUserResult) => {
    if (userResult) {
        store.setLoggedInUser(userResult);
    }
};

const onError = () => {
    store.clearLoggedInUser();
    return false;
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
    const [authorized, setAuthorized] = useState<boolean>();
    
    useEffect(() => {
        if (store.loggedIn) {
            setAuthorized(true);
        } else {
            axios.get(`${SERVER_URL}/api/account/me`)
                .then(({data}) => {
                    onSuccess(data);
                    setAuthorized(true);
                })
                .catch(() => {
                    onError();
                    setAuthorized(false);
                });
        }
    }, [])

    if (authorized !== undefined) {
        if (!authorized) {
            return <Navigate to="/login"/>;
        }

        return <> {props.children} </>;
    }
    
    return <></>
};