import React, { useRef, useState } from 'react';

import { observable, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { store } from 'Models/Store';
import { ModelCollection } from 'Views/Components/ModelCollection/ModelCollection';
import * as Models from 'Models/Entities';
import moment from 'moment';
import { ApiQueryParams, IOrderByCondition } from 'Views/Components/ModelCollection/ModelAPIQuery';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import { PaginationQueryOptions } from 'Models/PaginationData';
import { IFilter } from 'Views/Components/Collection/CollectionFilterPanel';
import { userTypeOptions } from 'Models/Enums';
import { makeEnumFetchFunction } from 'Util/EntityUtils';
import { debounce } from 'lodash';
import {ICollectionBulkActionProps, ICollectionItemActionProps} from 'Views/Components/Collection/Collection';
import { Button, Colors, Display, Sizes } from 'Views/Components/Button/Button';
import { Link } from 'react-router-dom';
import { SERVER_URL } from 'Constants';
import {useNavigate} from "react-router";

type transformFn<T> = (item: T, name: string) => (string | React.ReactNode);
interface IUserSiteAccessCrudTileProps {
	orderBy?: IOrderByCondition<Models.UserEntity>;
	perPage?: number;
}

interface ISearch {
	searchTerm: string;
}

const UserSiteAccessCrudTile = (props: IUserSiteAccessCrudTileProps) => {
	const [search, setSearch] = useState({ searchTerm: '' });
	const collectionRef = useRef<ModelCollection<Models.UserEntity> | null>(null);
	const defaultOrderBy: IOrderByCondition<Models.UserEntity> = { path: 'created', descending: true };
	const orderBy: IOrderByCondition<Models.UserEntity> | undefined = observable(defaultOrderBy || props.orderBy);
	const paginationQueryOptions: PaginationQueryOptions = observable(new PaginationQueryOptions());
	const navigate = useNavigate()

	const transformUserName: transformFn<Models.UserEntity> = (user: Models.UserEntity) => user?.name;
	const transformUserEmail: transformFn<Models.UserEntity> = (user: Models.UserEntity) => user?.email;

	const transformUserType: transformFn<Models.UserEntity> = (user: Models.UserEntity) => user?.userType;


	const getHeaders = (): Array<ICollectionHeaderProps<Models.UserEntity>> => {
		return [
			{
				name: 'name',
				displayName: 'Name',
				sortable: true,
				transformItem: transformUserName,
			},
			{
				name: 'email',
				displayName: 'Email',
				sortable: true,
				transformItem: transformUserEmail,
			},
			{
				name: 'userType',
				displayName: 'User Type',
				sortable: true,
				transformItem: transformUserType,
			},
		];
	};

	const getActions = () => {
		const tableActions: Array<ICollectionItemActionProps<Models.UserEntity>> = [];

		if (store.userGroups.map(ug => ug.name).includes('SuperAdmin')) {
			tableActions.push({
				action: item => {},
				label: 'Edit Site Access',
				customButton: (user: Models.UserEntity) => (
					<Link to={{ pathname: `${SERVER_URL}/userdashboard/editsiteaccess/${user.id}` }}>
						<Button
							colors={Colors.Primary}
							display={Display.Text}
							sizes={Sizes.Small}
							buttonProps={{ id: 'edit' }}
							icon={{ icon: 'chevron-right', iconPos: 'icon-right' }}
						>
							Edit Site Access
						</Button>
					</Link>
				),
			});
		}

		return tableActions;
	};

	const getBulkActions = (): ICollectionBulkActionProps<Models.UserEntity>[] => {
		const createBulkAccess: ICollectionBulkActionProps<Models.UserEntity> = {
			label: `Bulk Add User Site Access`,
			showIcon: true,
			icon: 'man',
			iconPos: 'icon-left',
			bulkAction: (models, event) => {
				runInAction(() => {
					const ids = models.map(model => model.id).join(',');
					navigate(`${SERVER_URL}/userdashboard/editsiteaccess/${ids}/create`);
				});
			},
		};

		return [createBulkAccess];
	};

	const getMoreParamsFromFilters = (filters: Array<IFilter<Models.UserEntity>>): ApiQueryParams => {
		const params = {};

		const userTypeFilter = filters.find(f => f.path === 'userType' && !!f.value1);
		if (userTypeFilter) {
			if ((userTypeFilter.value1 as string[]).includes('CLIENT_DEVICE')) {
				params['clientDevice'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('SITE_ADMIN')) {
				params['siteAdmin'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('SITE_USER')) {
				params['siteUser'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('REPORTING_DOCTOR')) {
				params['reportingDoctor'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('CARDIAC_SCIENTIST')) {
				params['cardiacScientist'] = true;
			}
		}

		return params;
	};

	const onSearchTriggered = (searchTerm: string) => {
		setSearch({ searchTerm });
	};

	const getMoreParams = (filters?: Array<IFilter<Models.UserEntity>>, filterApplied?: boolean): ApiQueryParams => {
		const filterParams = (filters && filterApplied) ? getMoreParamsFromFilters(filters) : {};

		if (search.searchTerm) {
			filterParams['searchStr'] = search.searchTerm;
		}

		return filterParams;
	};

	const additionalActions = (): React.ReactNode[] => [];

	const getFilters = (): Array<IFilter<Models.UserEntity>> => {
		const userTypeFilter: IFilter<Models.UserEntity> = {
			path: 'userType',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'User Type',
			enumResolveFunction: makeEnumFetchFunction(userTypeOptions),
		};


		return [userTypeFilter];
	};

	return (
		<div>
			<ModelCollection
				ref={collectionRef}
				url="/api/entity/UserEntity/user_accounts_list"
				searchStr={search.searchTerm}
				isApiQuery
				orderBy={orderBy}
				model={Models.UserEntity}
				headers={getHeaders()}
				actions={getActions()}
				selectableItems={true}
				onSearchTriggered={debounce(onSearchTriggered, 200)}
				perPage={paginationQueryOptions.perPage}
				idColumn="id"
				dataFields={row => ({
					created: moment(row.created).format('YYYY-MM-DD'),
					modified: moment(row.modified).format('YYYY-MM-DD'),
				})}
				getMoreParams={getMoreParams}
				filters={getFilters()}
				additionalActions={additionalActions()}
				selectedBulkActions={getBulkActions()}
				dateFormat="d/m/Y"
				altFormat="d/m/Y"
			/>
		</div>
	);
};

export default inject('store')(observer(UserSiteAccessCrudTile));
