import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import { inject, observer } from 'mobx-react';
import { DistributeReportTile } from 'Views/Tiles/HL7Messaging/DistributeReportTile';

const DistributeReportPage: React.FunctionComponent = () => {
	const navigationLinks = getFrontendNavLinks();

	const contents = (
		<SecuredPage groups={['SuperAdmin', 'User']}>
			<Navigation
				linkGroups={navigationLinks}
				orientation={Orientation.VERTICAL}
			/>

			<div className="body-content">
				<DistributeReportTile />
			</div>
		</SecuredPage>
	);

	return contents;
};

export default inject('store')(observer(DistributeReportPage)); 
