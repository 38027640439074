import * as React from 'react';

interface CustomSpinnerProps {
	echo?: boolean;
}

export default function CustomSpinner(props: CustomSpinnerProps) {
	return (
		<div className="custom-spinner">
			<div className={props.echo ? 'custom-loader-echo' : 'custom-loader'} />
		</div>
	);
}
