import {inject, observer} from 'mobx-react';
import * as React from 'react';
import Navigation, {Orientation} from 'Views/Components/Navigation/Navigation';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import {getFrontendNavLinks} from 'Views/FrontendNavLinks';
import SiteDashboardWrappingTileTile from 'Views/Tiles/SiteDashboardWrappingTileTile';

const SiteDashboardPage = () => {
	const navigationLinks = getFrontendNavLinks();

	return (
		<SecuredPage groups={['SuperAdmin', 'User']}>
			<Navigation
				linkGroups={navigationLinks}
				orientation={Orientation.VERTICAL}
			/>

			<div className="body-content">
				<SiteDashboardWrappingTileTile/>
			</div>
		</SecuredPage>
	);
}

export default inject('store')(observer(SiteDashboardPage));
