import React from 'react';
import { Tr, Td } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

interface SelectableTableRowProps {
    rowData: (string | number)[]; 
    isSelected: boolean;
    onClick: () => void;
    isSelectable: boolean;
}

const SelectableTableRow: React.FC<SelectableTableRowProps> = ({ rowData, isSelected, onClick, isSelectable }) => {
    return (
        <Tr
            onClick={isSelectable ? onClick : undefined} 
            bg={isSelected ? 'blue.100' : 'white'}
            _hover={!isSelected && isSelectable ? { bg: 'gray.100' } : {}} 
            cursor={isSelectable ? 'pointer' : 'default'}
        >
            {rowData.map((cell, index) => (
                <Td key={index}>
                    {isSelected && index === 0 && <CheckIcon color="green.500" style={{ marginRight: '8px' }} />}
                    {cell}
                </Td>
            ))}
        </Tr>
    );
};

export default SelectableTableRow;
