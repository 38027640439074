
import { StudyEntity, StudyFileEntity } from 'Models/Entities';
import axios from 'axios';
import {mapLimit} from 'async';

import { IoGrid, IoPause, IoPlay } from 'react-icons/io5';
import {
	Flex,
	Box,
	Button,
	Spinner,
	Select,
	FormControl,
	FormLabel,
	IconButton,
	Grid
} from '@chakra-ui/react';
import { SERVER_URL } from 'Constants';
import { useEffect, useMemo, useState, useRef } from 'react';
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import StressViewerViewport from './StressViewerViewport';
import { utilities } from '@cornerstonejs/tools';
import { RenderingEngine, Enums, getRenderingEngine } from '@cornerstonejs/core';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import _ from 'lodash';


interface StressImageViewerProps {
	study: StudyEntity;
	isImageViewerFullScreen: boolean;
}


const StressImageViewer = (props: StressImageViewerProps) => {

	const { study } = props;
	const [visibleViewports, setVisibleViewports] = useState<any[]>([]);
	const viewportRef= useRef<any[]>([]);
	viewportRef.current = visibleViewports;
	const [viewportCurrentImages, setViewportCurrentImages] = useState<object>({});
	const [viewportCachedImages, setViewportCachedImages] = useState<string[]>([]);
	const [studyViews, setStudyViews] = useState<any>({})
	const [studyStages, setStudyStages] = useState<any>({})
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [stressEchoGroupMode, setStressEchoGroupMode] = useState<string>('view');
	const [selectedStressView, setSelectedStressView] = useState<string>('1');
	const [selectedStressStage, setSelectedStressStage] = useState<string>('1');
	const [isPlaying, setIsPlaying] = useState<boolean>(false);
	let loadedViewports: string[] = [];
	const parentContainer = useRef<HTMLDivElement | null>(null);
	const [framerateMultipier, setFramerateMultiplier] = useState<number>(1);
	const [stressViewportWidth, setViewportWidth] = useState<string>('');
	const [viewportHeight, setViewportHeight] = useState<string>('');
	const [gridTemplateRows, setGridTemplateRows] = useState<string[]>(['']);
	const [gridTemplateColumns, setGridTemplateColumns] = useState<string[]>(['']);

	const startUp = () => {
		axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/StudyDicomFileByStudy/${study.id}`)
			.then(async res => {
				const studyFiles: StudyFileEntity[] = [];
				res.data.forEach((obj: StudyFileEntity) => {
					studyFiles.push(obj);
				});
				setIsLoading(true);
				const promiseMap = mapLimit(studyFiles, 20, prepareImageData);
				promiseMap
					.then((result: any) => {
						setSelectedStressView(Object.keys(studyViews)[0]);

						Object.keys(studyViews).forEach(viewKey => {
							let longestDuration = 0;
							studyViews[viewKey].files.forEach((viewFile: any) => {
								const duration = viewFile.numFrames / viewFile.frameRate;
								if (duration > longestDuration) {
									longestDuration = duration;
								}
							});

							studyViews[viewKey].files.map((viewFile: any) => {
								viewFile.dynamicFramerate = viewFile.numFrames / (longestDuration + .1);
								return viewFile;
							});

							studyViews[viewKey].files.sort((a: { stageNumber: string; }, b: { stageNumber: string; }) => {
								const nameA = parseInt(a.stageNumber);
								const nameB = parseInt(b.stageNumber);
								return nameA - nameB;
							});
						});

						Object.keys(studyStages).forEach(stageKey => {
							let longestDuration = 0;
							studyStages[stageKey].files.forEach((viewFile: any) => {
								const duration = viewFile.numFrames / viewFile.frameRate;
								longestDuration = duration > longestDuration ? duration : longestDuration;
							});

							studyStages[stageKey].files.map((viewFile: any) => {
								viewFile.dynamicFramerate =  viewFile.numFrames / longestDuration;
								return viewFile;
							});

						});
						setStudyStages(studyStages);
						setStudyViews(studyViews);
						setVisibleViewports(studyViews[Object.keys(studyViews)[0]].files);
						setIsLoading(false);

					})

			})
	}

	const prepareImageData = (studyFile: StudyFileEntity, callback: (arg0: null, arg1: StudyFileEntity) => void) => {
		
		cornerstoneDICOMImageLoader.wadouri.dataSetCacheManager.load(`${SERVER_URL}/api/files/${studyFile.studyFileId}`, cornerstoneDICOMImageLoader.internal.xhrRequest)
			.then( async (dataSet: any) => {
				const stageName = dataSet.string('x00082120');
				const stageNumber = dataSet.intString('x00082122');
				const numberOfStages = dataSet.intString('x00082124');
				const viewName = dataSet.string('x00082127');
				const viewNumber = dataSet.intString('x00082128');
				const numberOfViews = dataSet.intString('x0008212a');
				const numFrames = dataSet.intString('x00280008');
				const studyFramerate = dataSet.intString('x00082144');

				if (viewNumber !== undefined) {
					if (!studyViews[viewNumber]) {
						studyViews[viewNumber] = {
							files : [{
								file: studyFile,
								numFrames: numFrames,
								frameRate: studyFramerate,
								stageNumber: stageNumber
							}],
							numberOfViews : numberOfViews,
							name: viewName || `View ${viewNumber}`
						}
					} else {
						studyViews[viewNumber].files.push({
							file: studyFile,
							numFrames: numFrames,
							frameRate: studyFramerate,
							stageNumber: stageNumber
						});
					}
					setStudyViews(studyViews);
				}

				if (stageNumber !== undefined) {
					if (!studyStages[stageNumber]) {
						studyStages[stageNumber] = {
							files : [{
								file: studyFile,
								numFrames: numFrames,
								frameRate: studyFramerate
							}],
							numberOfStages : numberOfStages,
							name: stageName || `Stage ${stageNumber}`
						}
					} else {
						studyStages[stageNumber].files.push({
							file: studyFile,
							numFrames: numFrames,
							frameRate: studyFramerate
						});
					}
					setStudyStages(studyStages);
				}
				callback(null, studyFile)

			});
	}



	useEffect(() => {
		startUp();
	}, []);


	const currentImagesCallback = (fileId: string, viewport:string) => {
		viewportCurrentImages[viewport] = fileId;
		setViewportCurrentImages(Object.assign({},viewportCurrentImages));
	}

	const deselectImagesCallback = (viewport:string) => {
		delete viewportCurrentImages[viewport];
		setViewportCurrentImages(Object.assign({},viewportCurrentImages));
	}

	const cachedImagesCallback = (fileId: string) => {
		if(viewportCachedImages.indexOf(fileId) === -1) {
			viewportCachedImages.push(fileId);
			setViewportCachedImages(viewportCachedImages);
		}

	}

	const playbackReadyCallback = (fileId: string) => {
		loadedViewports.push(fileId);
		if (loadedViewports.length === visibleViewports.length) {
			loadedViewports = [];
			togglePlayback();
		}
	}

	useEffect(()=> {
		if (studyViews[selectedStressView]) {
			setVisibleViewports(studyViews[selectedStressView].files);
			togglePlayback();
		}
	},[selectedStressView]);

	useEffect(()=> {
		if (studyStages[selectedStressStage]) {
			setVisibleViewports(studyStages[selectedStressStage].files);
			togglePlayback();
		}
	},[selectedStressStage]);

	
	useEffect(()=> {
		if (stressEchoGroupMode === 'view' && Object.keys(studyViews).length) {
			const groupKey = (Object.keys(studyViews)[0]);
			setSelectedStressView(groupKey);
			setVisibleViewports(studyViews[groupKey].files);
		} else if (Object.keys(studyStages).length) {
			const groupKey = (Object.keys(studyStages)[0]);
			setSelectedStressStage(groupKey);
			setVisibleViewports(studyStages[groupKey].files);
		}
	},[stressEchoGroupMode]);


	const updateGroupMode = (groupMode: string) => {
		setStressEchoGroupMode(groupMode);
	}


	const togglePlayback = () => {
		const viewportElements = document.querySelectorAll('.viewport_element');
		if (!isPlaying) {
			viewportElements.forEach(element => {
				const studyFramerate = element.getAttribute('data-framerate');
				const numFrames = element.getAttribute('data-numframes');
				if (studyFramerate && numFrames) {
					const frameRate = parseInt(studyFramerate) * framerateMultipier;
					utilities.cine.playClip(element as HTMLDivElement, {loop:false, framesPerSecond: frameRate});
				}

			});
			setIsPlaying(true);
			viewportElements.forEach(element => {
				element.addEventListener('CORNERSTONE_CINE_TOOL_STOPPED', cornerstoneCineToolStopped);
			});
		} else {
			document.querySelectorAll('.viewport_element').forEach(element => {
				element.removeEventListener('CORNERSTONE_CINE_TOOL_STOPPED', cornerstoneCineToolStopped);
				utilities.cine.stopClip(element as HTMLDivElement);
			});
			setIsPlaying(false);
		}

	}

	const cornerstoneCineToolStopped = (e: any) => {
		const viewportElements = document.querySelectorAll('.viewport_element');

		viewportElements.forEach(element => {
			const index = element.getAttribute('data-index');
			const renderingEngineId = `myRenderingEngine_${index}`;
			const renderingEngine = getRenderingEngine(renderingEngineId);
			const studyFramerate = element.getAttribute('data-framerate');
			const numFrames = element.getAttribute('data-numframes');
			const viewportId = `CT_STACK_${index}`;
			if (renderingEngine && studyFramerate && numFrames) {
				const viewport = renderingEngine.getViewport(viewportId);
				utilities.scroll(viewport, {delta: 0 - parseFloat(numFrames), loop: false});
				const frameRate = parseInt(studyFramerate) * framerateMultipier;
				utilities.cine.playClip(element as HTMLDivElement, {framesPerSecond: frameRate,loop:false});
			}
		});
	}

	function hasFocus(selector: string) {
		return Array
			.from(document.querySelectorAll(selector))
			.some(function(el){
				return el === document.activeElement
			});
	}

	const nextPage = () => {
		if (stressEchoGroupMode === 'view') {
			const newViewNumber = (parseInt(selectedStressView) % Object.keys(studyViews).length) + 1;
			
			setSelectedStressView(newViewNumber.toString());
		} else {
			const newStageNumber = (parseInt(selectedStressStage) % Object.keys(studyStages).length) + 1;
			setSelectedStressStage(newStageNumber.toString());
		}
	}

	const prevPage = () => {
		if (stressEchoGroupMode === 'view') {
			if (selectedStressView === '1') {
				setSelectedStressView(Object.keys(studyViews).length.toString());
			} else {
				setSelectedStressView((parseInt(selectedStressView) - 1).toString());
			}
		} else {
			if (selectedStressStage === '1') {
				setSelectedStressStage(Object.keys(studyStages).length.toString());
			} else {
				setSelectedStressStage((parseInt(selectedStressStage) - 1).toString());
			}
		}
	}

	useEffect(() => {
		// @ts-ignore
		const handleKeyDown = e => {
			const key = e.keyCode;
			if (key == 32 && !hasFocus('input, select, textarea')) {
				togglePlayback();
			}
			if (key == 40) {
				nextPage();
			}
			if (key == 38) {
				prevPage();
			}				
		};
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};

	}, [togglePlayback]);

	const updatePlaybackSpeed = (multiplier: number) => {
		if (framerateMultipier + multiplier > 0) {
			setFramerateMultiplier(framerateMultipier + multiplier);
		}
	}

	useEffect(() => {
		const viewportElements = document.querySelectorAll('.viewport_element');
		viewportElements.forEach(element => {
			element.removeEventListener('CORNERSTONE_CINE_TOOL_STOPPED', cornerstoneCineToolStopped);
			utilities.cine.stopClip(element as HTMLDivElement);
		});
		viewportElements.forEach(element => {
			const studyFramerate = element.getAttribute('data-framerate');
			const numFrames = element.getAttribute('data-numframes');
			if (studyFramerate && numFrames) {
				const frameRate = parseInt(studyFramerate) * framerateMultipier;
				utilities.cine.playClip(element as HTMLDivElement, {loop:false, framesPerSecond: frameRate});
			}

		});
		// setIsPlaying(true);
		viewportElements.forEach(element => {
			element.addEventListener('CORNERSTONE_CINE_TOOL_STOPPED', cornerstoneCineToolStopped);
		});
	}, [framerateMultipier]);


	const calculateViewportWidth = () => {
		if (parentContainer.current && viewportRef.current) {
			const containerWidth: number = parentContainer.current.offsetWidth;
			const containerHeight: number = parentContainer.current.offsetHeight;
			let rowCount = 1;
			let colCount = 1;
			let elemWidth = containerWidth;
			if (viewportRef.current.length === 1) {
				setViewportWidth('100%');
			} else if (viewportRef.current.length === 2) {
				if (containerWidth > 900) {
					setViewportWidth('50%');
					elemWidth = containerWidth * .5;
					rowCount = Math.round(viewportRef.current.length/2);
					colCount = 2;
				} else {
					setViewportWidth('100%');
					rowCount = viewportRef.current.length;
				}
			} else if (viewportRef.current.length === 3) {
				if (containerWidth > 1200) {
					setViewportWidth('33%');
					rowCount = Math.round(viewportRef.current.length/3);
					elemWidth = containerWidth * .33;
					colCount = 3;
				}else if (containerWidth > 300) {
					elemWidth = containerWidth * .5;

					rowCount = Math.round(viewportRef.current.length/2);
					colCount = 2;
				} else {
					rowCount = viewportRef.current.length;
				}
			} else if (viewportRef.current.length === 4) {
				if (containerWidth > 350) {
					elemWidth = containerWidth * .5;

					rowCount = Math.round(viewportRef.current.length/2);
					colCount = 2;
				} else {
					rowCount = viewportRef.current.length;
				}
			} else {
				if (containerWidth > 900) {
					rowCount = Math.round(viewportRef.current.length/3);
					elemWidth = containerWidth * .33;

					colCount = 3;
				} else if (containerWidth > 400) {
					elemWidth = containerWidth * .5;
					rowCount = Math.round(viewportRef.current.length/2);
					colCount = 2;
				} else {
					rowCount = viewportRef.current.length;
				}
			}
			if ((containerHeight / containerWidth) > .7) {
				const viewportWidthInt = (containerWidth / colCount) - 25;
				const viewportWidthPx = viewportWidthInt + 'px';
				const viewportHeightPx = (viewportWidthInt * .7) + 'px'

				setViewportWidth(viewportWidthPx);
				setViewportHeight(viewportHeightPx);
				const gridRows = Array(rowCount).fill(viewportHeightPx);
				const gridCols = Array(colCount).fill(viewportWidthPx);
				setGridTemplateRows(gridRows);
				setGridTemplateColumns(gridCols);
			} else {
				const viewportHeightInt = (containerHeight / rowCount) - 25;
				const viewportHeightPx = viewportHeightInt + 'px'
				const viewportWidthPx = (viewportHeightInt * 1.42) + 'px'
				setViewportHeight(viewportHeightPx);
				setViewportWidth(viewportWidthPx);
				const gridRows = Array(rowCount).fill(viewportHeightPx);
				const gridCols = Array(colCount).fill(viewportWidthPx);
				setGridTemplateRows(gridRows);
				setGridTemplateColumns(gridCols);
			}

			// if container width:height is wider than .7, calculate max that can fit with column count wide and set height as .7 of that
			// if container width:height is less than .7, do the inverse
			//fuck width percentages

		}
	}

	useEffect(() => {
		const resizeObserver = new ResizeObserver(calculateViewportWidth);

		if (parentContainer.current) {
			resizeObserver.observe(parentContainer.current);
		}
		return () => {
			resizeObserver.disconnect();
		  };
	  }, []);

	useEffect(() => {
		calculateViewportWidth();
	}, [visibleViewports, selectedStressView, selectedStressStage, stressEchoGroupMode]);
	
	return (
		<Flex height='100%' ref={parentContainer}>
			{isLoading ? <Flex direction='column' width='100%' position='relative'><Box position='absolute' left='50%' top='50%' transform='translate(-50%,-50%) scale(3)'><Spinner size='xl' color='blue.500'></Spinner></Box></Flex> : 
				<>

					<Flex direction='column' width='100%' position='relative'>
						<Flex mb='5px' width='calc(100% - 180px)'>
							<FormControl variant="floating">
								<Select color='#fff' value={stressEchoGroupMode} onChange={e=>updateGroupMode(e.target.value)}>
									<option value='view' style={{color:'#444'}}>View</option>
									<option value='stage' style={{color:'#444'}}>Stage</option>
								</Select>
								<FormLabel style={{color:'#fff', background: '#100f19'}}>Review Stress by</FormLabel>
							</FormControl>

							{stressEchoGroupMode === 'view' ? 
								<FormControl variant="floating">
									<Select color='#fff' value={selectedStressView} onChange={e=>setSelectedStressView(e.target.value)}>
										{Object.keys(studyViews).map(key => <option value={key} style={{color:'#444'}}>{studyViews[key].name}</option>)}
									</Select>
									<FormLabel style={{color:'#fff', background: '#100f19'}}>Select View</FormLabel>
								</FormControl>
								:
								<FormControl variant="floating">
									<Select color='#fff' value={selectedStressStage} onChange={e=>setSelectedStressStage(e.target.value)}>
										{Object.keys(studyStages).map(key => <option value={key} style={{color:'#444'}}>{studyStages[key].name}</option>)}
									</Select>
									<FormLabel style={{color:'#fff', background: '#100f19'}}>Select Stage</FormLabel>
								</FormControl>
							}

							<Flex>
								<Flex color='#fff' width='60px' justifyContent='center' alignItems='center'>
									{stressEchoGroupMode === 'view' ? 
										`${selectedStressView} / ${Object.keys(studyViews).length}`
										:
										`${selectedStressStage} / ${Object.keys(studyStages).length}`
									}
								</Flex>
								<IconButton
									colorScheme='blue'
									aria-label="Previous page"
									icon={<ChevronLeftIcon />}
									onClick={prevPage}
								/>
								<IconButton
									colorScheme='blue'
									aria-label="Next page"
									icon={<ChevronRightIcon />}
									onClick={nextPage}								
								/>
							</Flex>

						</Flex>



						<Flex
							position='absolute'
							bottom='20px'
							left='50%'
							transform='translateX(-50%)'
							background='#373151'
							borderRadius='5px'
							width='170px'
							padding='8px'
							alignItems='center'
							justifyContent='space-between'
							zIndex='100'
						>
							<Box>
								{isPlaying ?
									<IoPause fontSize='20px' color='#fff' onClick={()=>togglePlayback()} cursor='pointer' />
									:
									<IoPlay fontSize='20px' color='#fff' onClick={()=>togglePlayback()} cursor='pointer'  />
								}


							</Box>
							<Flex
								border='1px solid #5686ce'
								background='#373151'
								borderRadius='5px'
								padding='2px 8px'
								margin='0px 8px'
								alignItems='center'
								width='120px'
								justifyContent='space-between'
								zIndex='100'
							>
								<ChevronLeftIcon
									color='#5686ce'
									onClick={()=>updatePlaybackSpeed(-0.2)}
									cursor='pointer'
								></ChevronLeftIcon>
								<Box color='#5686ce' cursor='pointer'>{framerateMultipier.toFixed(1)}x speed</Box>
								<ChevronRightIcon
									color='#5686ce'
									onClick={()=>updatePlaybackSpeed(0.2)}
									cursor='pointer'
								></ChevronRightIcon>
							</Flex>


						</Flex>

						<Grid width='100%' height='100%' paddingBottom='50px'  maxHeight='90vh' gridTemplateRows={gridTemplateRows.join(' ')} gridTemplateColumns={gridTemplateColumns.join(' ')} justifyItems='center' alignItems='center' justifyContent='center'>
							{visibleViewports.map((studyFile, index) => 
								<StressViewerViewport
									studyFileId={studyFile.file.studyFileId}
									index={index} 
									frameRate={studyFile.dynamicFramerate}
									viewportHeight={viewportHeight}
									viewportWidth={stressViewportWidth}
									key={studyFile.file.studyFileId} 
									currentFileCallback={currentImagesCallback}
									deselectFileCallback={deselectImagesCallback}
									cachedFileCallback={cachedImagesCallback}
									playbackReadyCallback={playbackReadyCallback}
									amPlaying={isPlaying}
									playbackMultiplier={framerateMultipier}></StressViewerViewport>
							)}
						</Grid>

					</Flex>
				</>
			}

		</Flex>
	);
}

export default StressImageViewer;
