import { gql } from "@apollo/client";
import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getAttributes,
	getCreatedModifiedCrudOptions, getModelName,
} from 'Util/EntityUtils';
import { VisitorsMeasurementEntity } from 'Models/Security/Acl/VisitorsMeasurementEntity';
import { SuperAdminMeasurementEntity } from 'Models/Security/Acl/SuperAdminMeasurementEntity';
import { UserMeasurementEntity } from 'Models/Security/Acl/UserMeasurementEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { lowerCaseFirst } from "../../Util/StringUtils";
import { CRUD } from '../CRUDOptions';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';

export interface IMeasurementEntityAttributes extends IModelAttributes {
	name: string;
	unit: string;
	value: number;

	studyId?: string;
	study?: Models.StudyEntity | Models.IStudyEntityAttributes;
	isPrimary?: boolean;
}

@entity('MeasurementEntity', 'Measurement')
export default class MeasurementEntity extends Model implements IMeasurementEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsMeasurementEntity(),
		new SuperAdminMeasurementEntity(),
		new UserMeasurementEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
	];

	/**
	 * Name of measurement
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;

	/**
	 * Unit of measurement
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Unit',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public unit: string;

	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Value',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public value: number;

	@observable
	@attribute()
	@CRUD({
		name: 'Study',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.StudyEntity,
	})
	public studyId?: string;

	/**
	 * is Primary Measurement
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'IsPrimary',
		displayType: 'checkbox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseString,
	})
	public isPrimary?: boolean;

	@observable
	@attribute({ isReference: true })
	public study: Models.StudyEntity;

	constructor(attributes?: Partial<IMeasurementEntityAttributes>) {
		super(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IMeasurementEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.unit !== undefined) {
				this.unit = attributes.unit;
			}
			if (attributes.value !== undefined) {
				this.value = attributes.value;
			}
			if (attributes.studyId !== undefined) {
				this.studyId = attributes.studyId;
			}
			if (attributes.isPrimary !== undefined) {
				this.isPrimary = attributes.isPrimary;
			}
			if (attributes.study !== undefined) {
				if (attributes.study === null) {
					this.study = attributes.study;
				} else if (attributes.study instanceof Models.StudyEntity) {
					this.study = attributes.study;
					this.studyId = attributes.study.id;
				} else {
					this.study = new Models.StudyEntity(attributes.study);
					this.studyId = this.study.id;
				}
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		study {
			${Models.StudyEntity.getAttributes().join('\n')}
		}
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}

	public static fetchMeasurements() {
		const modelName = lowerCaseFirst(getModelName(MeasurementEntity));

		return gql`
			query ${modelName}s ($args:[WhereExpressionGraph]) {
				${modelName}s (where: $args) {
					id
					isPrimary
					name
					studyId
					unit
					value
				}
			}`;
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		return this.id;
	}
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(MeasurementEntity.prototype, 'created');
CRUD(modifiedAttr)(MeasurementEntity.prototype, 'modified');
