import React, { useEffect, useRef, useState } from 'react';

import { 
	observable, 
	action, 
	toJS, 
	runInAction,
} from 'mobx';
import { inject, observer } from 'mobx-react';
import { store } from 'Models/Store';
import { ModelCollection } from 'Views/Components/ModelCollection/ModelCollection';
import * as Models from 'Models/Entities';
import moment from 'moment';
import { ApiQueryParams, IOrderByCondition } from 'Views/Components/ModelCollection/ModelAPIQuery';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import { PaginationQueryOptions } from 'Models/PaginationData';
import { IFilter } from 'Views/Components/Collection/CollectionFilterPanel';
import { accessLevelOptions, genderTypeOptions, userTypeOptions } from 'Models/Enums';
import { makeEnumFetchFunction } from 'Util/EntityUtils';
import { debounce } from 'lodash';
import { ICollectionItemActionProps } from 'Views/Components/Collection/Collection';
import { Button, Colors, Display, Sizes } from 'Views/Components/Button/Button';
import alert from 'Util/ToastifyUtils';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { Link } from 'react-router-dom';
import { SERVER_URL } from 'Constants';

type transformFn<T> = (item: T, name: string) => (string | React.ReactNode);
interface IUserSiteAccessCrudTileProps {
	orderBy?: IOrderByCondition<Models.UserEntity>;
	perPage?: number;
}

interface ISearch {
	searchTerm: string;
}

const UserAccountRequestCrudTile = (props: IUserSiteAccessCrudTileProps) => {
	const [search, setSearch] = useState({ searchTerm: '' });
	const collectionRef = useRef<ModelCollection<Models.UserEntity> | null>(null);
	const defaultOrderBy: IOrderByCondition<Models.UserEntity> = { path: 'created', descending: true };
	const orderBy: IOrderByCondition<Models.UserEntity> | undefined = observable(defaultOrderBy || props.orderBy);
	const paginationQueryOptions: PaginationQueryOptions = observable(new PaginationQueryOptions());

	const approveAccount = (u: Models.UserEntity) => {
		store.modal.show('Confirmation', 
			<div className="confirmation__modal">
				<h4>Do you want to activate this user account?</h4>
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
					<Button 
						colors={Colors.Primary} 
						display={Display.Solid} 
						sizes={Sizes.Medium} 
						buttonProps={{ id: 'confirm' }} 
						onClick={() => runInAction(() => {
							u.status = 'ACTIVE';
							u.save();
							store.modal.hide();
						})}
					>
						Confirm
					</Button>
					<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={() => store.modal.hide()}>Cancel</Button>
				</ButtonGroup>
			</div>);
	};

	const tranformUserName: transformFn<Models.UserEntity> = (user: Models.UserEntity) => user?.name;
	const tranformUserEmail: transformFn<Models.UserEntity> = (user: Models.UserEntity) => user?.email;
	const transformUserType: transformFn<Models.UserEntity> = (user: Models.UserEntity) => userTypeOptions[user?.userType];

	const getHeaders = (): Array<ICollectionHeaderProps<Models.UserEntity>> => {
		return [
			{
				name: 'name',
				displayName: 'Name',
				sortable: true,
				transformItem: tranformUserName,
			},
			{
				name: 'email',
				displayName: 'Email',
				sortable: true,
				transformItem: tranformUserEmail,
			},
			{
				name: 'userType',
				displayName: 'User Type',
				sortable: true,
				transformItem: transformUserType,
			},
		];
	};

	const getActions = () => {
		const tableActions: Array<ICollectionItemActionProps<Models.UserEntity>> = [];

		tableActions.push({
			action: item => {},
			label: 'Revoke Access',
			customButton: (u: Models.UserEntity) => (
				<Button 
					colors={Colors.Success}
					display={Display.Text} 
					sizes={Sizes.Small} 
					buttonProps={{ id: 'approve' }} 
					onClick={() => approveAccount(u)}
					icon={{ icon: 'check', iconPos: 'icon-left' }}
				>
					Approve Account
				</Button>
			),
		});

		if (store.userGroups.map(ug => ug.name).includes('SuperAdmin')) {
			tableActions.push({
				action: item => {},
				label: 'Edit',
				customButton: (u: Models.UserEntity) => (
					<Link to={{ pathname: `${SERVER_URL}/admin/userentity/edit/${u.id}` }}>
						<Button 
							colors={Colors.Primary}
							display={Display.Text} 
							sizes={Sizes.Small} 
							buttonProps={{ id: 'edit' }}
							icon={{ icon: 'chevron-right', iconPos: 'icon-right' }}
						>
							Edit
						</Button>
					</Link>
				),
			});
		}
		
		return tableActions;
	};

	const getMoreParamsFromFilters = (filters: Array<IFilter<Models.UserEntity>>): ApiQueryParams => {
		const params = {};

		const userTypeFilter = filters.find(f => f.path === 'userType' && !!f.value1);
		if (userTypeFilter) {
			if ((userTypeFilter.value1 as string[]).includes('CLIENT_DEVICE')) {
				params['clientDevice'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('SITE_ADMIN')) {
				params['siteAdmin'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('SITE_USER')) {
				params['siteUser'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('REPORTING_DOCTOR')) {
				params['reportingDoctor'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('CARDIAC_SCIENTIST')) {
				params['cardiacScientist'] = true;
			}
		}

		const siteFilter = filters.find(f => f.path === 'site' && !!f.value1 && (f.value1 as []).length !== 0);
		if (siteFilter) {
			params['siteFilter'] = JSON.stringify(siteFilter.value1);
		}

		return params;
	};

	const onSearchTriggered = (searchTerm: string) => {
		setSearch({ searchTerm });
	};

	const getMoreParams = (filters?: Array<IFilter<Models.UserEntity>>, filterApplied?: boolean): ApiQueryParams => {
		const filterParams = (filters && filterApplied) ? getMoreParamsFromFilters(filters) : {};

		if (!store.userGroups.map(ug => ug.name).includes('SuperAdmin') && !store.userGroups.map(ug => ug.name).includes('Super Administrators')) {
			filterParams['siteIds'] = JSON.stringify(store.userSiteAccess.map(usa => usa.siteId));
		}

		if (search.searchTerm) {
			filterParams['searchStr'] = search.searchTerm;
		}

		filterParams['status'] = 'PENDING';

		return filterParams;
	};

	const additionalActions = (): React.ReactNode[] => [];

	const getFilters = (): Array<IFilter<Models.UserEntity>> => {
		const userTypeFilter: IFilter<Models.UserEntity> = {
			path: 'userType',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'User Type',
			enumResolveFunction: makeEnumFetchFunction(userTypeOptions),
		};

		const siteOptions = {};
		if (store.userGroups.map(ug => ug.name).includes('SuperAdmin')) {
			store.sites.forEach(site => {
				if (site.siteName) {
					siteOptions[site.id] = site.siteName;
				}
			});
		} else {
			store.userSiteAccess.forEach(usa => {
				const jsUsa = toJS(usa);
				if (jsUsa.siteId && jsUsa.site) {
					if (jsUsa.site.siteName) {
						siteOptions[jsUsa.siteId] = jsUsa.site.siteName;
					}
				}
			});
		}

		const siteFilter: IFilter<Models.UserEntity> = {
			path: 'site',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Site',
			enumResolveFunction: makeEnumFetchFunction(siteOptions),
		};

		return [siteFilter, userTypeFilter];
	};

	return (
		<div>
			<ModelCollection
				ref={collectionRef}
				url="/api/entity/UserEntity/account_request_list"
				searchStr={search.searchTerm}
				isApiQuery
				orderBy={orderBy}
				model={Models.UserEntity}
				headers={getHeaders()}
				actions={getActions()}
				selectableItems={false}
				onSearchTriggered={debounce(onSearchTriggered, 200)}
				perPage={paginationQueryOptions.perPage}
				idColumn="id"
				dataFields={row => ({
					created: moment(row.created).format('YYYY-MM-DD'),
					modified: moment(row.modified).format('YYYY-MM-DD'),
				})}
				getMoreParams={getMoreParams}
				filters={getFilters()}
				additionalActions={additionalActions()}
				dateFormat="d/m/Y"
				altFormat="d/m/Y"
			/>
		</div>
	);
};

export default inject('store')(observer(UserAccountRequestCrudTile));
