import Axios from 'axios';
import { SERVER_URL } from 'Constants';
import { runInAction, toJS } from 'mobx';
import {  useLocalStore } from 'mobx-react';
import { PatientEntity, StudyEntity, UserEntity, SiteEntity, UserSiteAccessEntity } from 'Models/Entities';
import { genderType, genderTypeOptions, studyTypeOptions } from 'Models/Enums';
import { store } from 'Models/Store';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { makeEnumFetchFunction } from 'Util/EntityUtils';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import alert from 'Util/ToastifyUtils';
import moment from 'moment';

import AddressingModal from '../Modals/AddressingModal';
import PreviewModal from '../Modals/PreviewModal';
import TemplatePreviewModal from '../Modals/TemplatePreviewModal';
import { useForm } from 'react-hook-form';
import {
	Input,
	Flex,
	Box,
	FormControl,
	Checkbox,
	FormLabel,
	Select,
} from '@chakra-ui/react';


interface IProvider {
	firstName: string;
	lastName: string;
	jobTitle: string;
	practice: string;
	providerId: string;
	city: string;
}

type FormValues = {
	surname: string;
	firstName: string;
	middleName: string;
	gender: genderType;
	dob: string;
	patientClass: string | null;
	to: IProvider | null;
	toStringField: string;
	cc: IProvider[];
	requestDate: string;
	observationDate: string;
	fromProviderNo: string;
	fromPrefix: string;
	fromFirstName: string;
	fromLastName: string;
	fromSuffix: string;
	documentTitle: string;
	clinicalArea: string | null;
	correction: boolean;
};

const defaultFormState: FormValues = {
	surname: '',
	firstName: '',
	middleName: '',
	gender: genderTypeOptions[2],
	dob: '',
	patientClass: '',
	to: null,
	toStringField: '',
	cc: [],
	requestDate: '',
	observationDate: '',
	fromProviderNo: '',
	fromPrefix: '',
	fromFirstName: '',
	fromLastName: '',
	fromSuffix: '',
	documentTitle: '',
	clinicalArea: '',
	correction: false,
};

export const DistributeReportTile = () => {
	const [loading, setLoading] = React.useState<boolean>(true);
	const [HL7MessageSent, setHL7MessageSent] = React.useState<boolean>(true);

	const {
		register,
		setValue,
		getValues,
		formState: { errors },
		handleSubmit,
	} = useForm<FormValues>()

	const pdfPagesState = useLocalStore(() => ({
		echoReportPDF: 1,
		echoReportTXT: 1,
		holterReportPDF: 1,
		holterReportTXT: 1,
	}));

	const distributeReportState = useLocalStore(() => ({
		patient: new PatientEntity(),
		study: new StudyEntity(),
		distributeReport: defaultFormState,
		loading: true,
		errors: {},
	}));

	const files = useLocalStore(() => ({
		holterReportPDF: '',
		holterReportTXT: '',
		echoReportPDF: '',
		echoReportTXT: '',
		previewType: 'PDF',
		preview: '',
	}));

	const { id } = useParams();
	const navigate = useNavigate();
	
	const [displayCC, setDisplayCC] = useState<IProvider[]>([]);

	const fillFormValues = (): void => {
		runInAction(() => {
			const { name } = distributeReportState.patient;
			setValue('surname', name.split(' ')[name.split(' ').length - 1]);
			setValue('firstName', name.split(' ')[0]);
			setValue('middleName', name.split(' ').filter((n, i) => i !== 0 && i !== name.split(' ').length - 1).join());
			setValue('gender', distributeReportState.patient.gender);
			setValue('dob', moment(distributeReportState.study.patient.dob).format('YYYY-MM-DD'));
			setValue('patientClass', 'O');
			setValue('requestDate', moment(distributeReportState.study.studyDate).format('YYYY-MM-DD'));
			setValue('observationDate', moment(distributeReportState.study.studyDate).format('YYYY-MM-DD'));
			setValue('documentTitle', distributeReportState.study.studyType);
			let reportingDoctorId: string = '';
			if (['HOLTERTWENTYFOURHR','HOLTERFORTYEIGHTHR','HOLTEREXTENDED'].includes(distributeReportState.study.studyType)) {
				reportingDoctorId = distributeReportState.study?.holterReport?.reportingDoctor || '';
			} else {
				reportingDoctorId = distributeReportState.study?.report?.reportingDoctor || '';
			}
			if (reportingDoctorId) {
				retrieveDoctorData(reportingDoctorId);
			} else {
				const existingReportingDoctor = distributeReportState.study.doctorss.filter(d => d.doctorType === 'REPORTING');
				if (existingReportingDoctor.length > 0) {
					setValue('fromPrefix', distributeReportState.study.doctorss.filter(d => d.doctorType === 'REPORTING')[0].prefix)
					setValue('fromFirstName', distributeReportState.study.doctorss.filter(d => d.doctorType === 'REPORTING')[0].firstName)
					setValue('fromLastName', distributeReportState.study.doctorss.filter(d => d.doctorType === 'REPORTING')[0].lastName)
					setValue('fromSuffix', distributeReportState.study.doctorss.filter(d => d.doctorType === 'REPORTING')[0].suffix)
				}
			}
		});
	};

	const retrieveStudyData = async () => {
		await store.apolloClient.query({
			query: StudyEntity.getMinStudy(),
			fetchPolicy: 'network-only',
			variables: {
				args: [{
					path: 'id',
					comparison: 'equal',
					value: id,
				}],
			},
		}).then(res => {
			runInAction(() => {
				distributeReportState.study = new StudyEntity(res.data.studyEntity);
				
				if (distributeReportState.study.hl7messagesent === null) {
					setHL7MessageSent(false);
				} else {
					setHL7MessageSent(distributeReportState.study.hl7messagesent);	
				}
				distributeReportState.patient = new PatientEntity(res.data.studyEntity.patient);
				setLoading(false);
			});
		}).catch(error => console.error(error));
	};

	const retrieveDoctorData = async (doctorId: string) => {
		await store.apolloClient.query({
			query: UserEntity.getFetchSingleQueryProfilePage(),
			fetchPolicy: 'network-only',
			variables: {
				args: [{
					path: 'id',
					comparison: 'equal',
					value: doctorId,
				}],
			},
		}).then(res => {
			setValue('fromPrefix','Dr');
			if (res.data.userEntity.firstName) {
				setValue('fromFirstName', res.data.userEntity.firstName);
			} else {
				setValue('fromFirstName', res.data?.userEntity?.name ? res.data.userEntity.name.split(' ')[0] : '');
			}
			if (res.data.userEntity.lastName) {
				setValue('fromLastName', res.data.userEntity.lastName);
			} else {
				setValue('fromLastName', res.data?.userEntity?.name ? res.data.userEntity.name.split(' ')[1] : '');
			}
			setValue('fromSuffix',res.data?.userEntity?.suffix);
			if (res.data?.userEntity?.sitess?.length) {
				const siteData = res.data?.userEntity.sitess.find((site: UserSiteAccessEntity) =>site.siteId === distributeReportState.study.patient.siteId);
				setValue('fromProviderNo',siteData.providerNumber);
			}
		}).catch(error => console.error(error));
	};

	const retrieveProviderNo = async () => {
		await Axios.get(`${SERVER_URL}/api/entity/StudyEntity/ReportingDoctorProviderNo/${distributeReportState.study.id}`)
			.then(res => runInAction(() => {
				setValue('fromProviderNo', res.data);
			}))
			.catch(err => alert('Could not retrieve provider no', 'error'));
	};

	const retrieveReportingDoctor = async () => {
		runInAction(() => {
			const existingReportingDoctor = distributeReportState.study.doctorss.filter(d => d.doctorType === 'REPORTING');

			if (existingReportingDoctor.length > 0) {
				setValue('fromPrefix', existingReportingDoctor[0].prefix);
				setValue('fromFirstName', existingReportingDoctor[0].firstName);
				setValue('fromLastName', existingReportingDoctor[0].lastName);
				setValue('fromSuffix', existingReportingDoctor[0].suffix);
				setValue('fromProviderNo', '');
			}
		});
	};

	const toggleHL7MessageSent = () => {
		distributeReportState.study.toggleHL7MessageSent();
		setHL7MessageSent(!HL7MessageSent);
	}

	useEffect(() => {
		async function fetchData() {
			await retrieveStudyData();
			let reportingDoctorId = ''
			if (['HOLTERTWENTYFOURHR','HOLTERFORTYEIGHTHR','HOLTEREXTENDED'].includes(distributeReportState.study.studyType)) {
				reportingDoctorId = distributeReportState.study?.holterReport?.reportingDoctor || '';
			} else {
				reportingDoctorId = distributeReportState.study?.report?.reportingDoctor || '';
			}
			if (reportingDoctorId) {
				await retrieveDoctorData(reportingDoctorId);
			} else {
				await retrieveReportingDoctor();
				await retrieveProviderNo();
			}
			fillFormValues();
		}

		fetchData();
	}, []);

	const previewModal = async (view: string) => {
		await runInAction(() => {
			files.previewType = view;
		});
		if (distributeReportState.study.studyType !== 'ECHO' && distributeReportState.study.studyType !== 'STRESS_ECHO') {
			await Axios.get(`${SERVER_URL}/api/entity/StudyEntity/TestDataPDF/${distributeReportState.study.id}`)
				.then(async res => await runInAction(() => files.holterReportPDF = `data:application/pdf;base64,${res.data}`))
				.then(async () => {
					await Axios.get(`${SERVER_URL}/api/entity/StudyEntity/TestDataTXT/${distributeReportState.study.id}`)
						.then(async res => await runInAction(() => files.holterReportTXT = res.data))
						.then(() => store.modal.show('Preview', <PreviewModal study={distributeReportState.study} files={files} pages={pdfPagesState} reportReference="holterReport" />, { className: 'preview-wrapper' }))
						.catch(err => alert('Could not retrieve holter report txt', 'error'));
				})
				.catch(() => alert('Failed to fetch test data', 'error'));
		} else if (distributeReportState.study.patient.site.advancedReportBuilder && (distributeReportState.study.report?.advancedreportstring !== null && distributeReportState.study.report?.advancedreportstring !== '')) {
			files.echoReportPDF = '';
			files.echoReportTXT = '';
			store.modal.show('Preview', <TemplatePreviewModal files={files} pages={pdfPagesState} reportReference="echoReport" study={distributeReportState.study} />, { className: 'preview-wrapper' })
		} else {
			await Axios.get(`${SERVER_URL}/api/entity/StudyEntity/EchoReportPDFBasic/${distributeReportState.study.id}`)
				.then(async res => await runInAction(() => files.echoReportPDF = `data:application/pdf;base64,${res.data}`))
				.then(async () => {
					await Axios.get(`${SERVER_URL}/api/entity/StudyEntity/EchoReportTXTBasic/${distributeReportState.study.id}`)
						.then(async res => await runInAction(() => files.echoReportTXT = res.data))
						.then(() => store.modal.show('Preview', <PreviewModal study={distributeReportState.study} files={files} pages={pdfPagesState} reportReference="echoReport" />, { className: 'preview-wrapper' }))
						.catch(err => alert('Could not retrieve echo report txt', 'error'));
				})
				.catch(() => alert('Failed to fetch echo report', 'error'));
		}
	};

	const fillBlankFields = (provider: IProvider): IProvider => {
		provider.city = provider.city || '';
		provider.practice = provider.practice || '';
		provider.jobTitle = provider.jobTitle || '';
		return provider;
	};

	const selectTo = (provider: IProvider) => {
		setValue('to', fillBlankFields(provider))
		setValue('toStringField', `${provider.jobTitle} ${provider.firstName} ${provider.lastName} (${provider.providerId})`);
	};

	const selectCc = (provider: IProvider): void => {
		if (getValues('cc')) {
			setDisplayCC([...getValues('cc'), fillBlankFields(provider)]);
			setValue('cc',  [...getValues('cc'), fillBlankFields(provider)]);
		} else {
			setValue('cc',  [fillBlankFields(provider)]);
			setDisplayCC( [fillBlankFields(provider)]);
		}
	};

	const removeTo = () => {
		setValue('to', null);
		setValue('toStringField', '');
	};

	const removeCc = (provider: IProvider) => {
		setValue('cc', getValues('cc').filter(cd => cd.providerId !== provider.providerId));
		setDisplayCC(getValues('cc').filter(cd => cd.providerId !== provider.providerId));
	};

	const clearCc = (): void => {
		setValue('cc', []);
		setDisplayCC([]);
	}

	const renderAddressingModal = (type: string) => store.modal.show(
		'Provider Lookup',
		<AddressingModal
			select={(provider: IProvider) => {
				if (type === 'to') {
					selectTo(provider);
				} else {
					selectCc(provider);
				}
			}}
			remove={(provider: IProvider) => {
				if (type === 'to') {
					removeTo();
				} else {
					removeCc(provider);
				}
			}}
			ccList={getValues('cc')}
			to={getValues('to')}
			type={type}
			siteId={distributeReportState.patient.siteId || ''}
		/>,
		{ className: 'addressingModal-wrapper' },
	);
	
	const onSubmit = (formData: FormValues): void => {
		Axios.post(
			`${SERVER_URL}/api/entity/StudyEntity/hl7message`,
			{
				studyId: distributeReportState.study.id,
				to: formData.to,
				cc: formData.cc ? formData.cc : [],
				documentTitle: formData.documentTitle,
				correction: HL7MessageSent,
				reportDetails: {
					...formData,
					dob: new Date(formData.dob),
					requestDate: new Date(formData.requestDate),
					observationDate: new Date(formData.observationDate),
				},
			},
		).then(res => {
			alert('HL7 Message Sent', 'success');
			navigate('/patientdashboard');
		}).catch(error => alert(`HL7 Message Failed\n${error}`, 'error'));
	};

	if (loading) { return <></>; }

	return (
		<div className="distribute-report__tile">
			<h4>Distribute Report via HL7 Messaging</h4>
			<form className="report-content" onSubmit={handleSubmit(onSubmit)}>
				<Flex className="left-column">
					<p>Patient Details</p>

					<Box border="1px solid #ccc" padding="8px" marginBottom="8px">
						<Flex flexWrap="wrap" flexDirection="row" justifyContent="flex-start" alignItems="flex-start" >
							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.surname}
								id="surname"
								width="32%"
								margin="8px 6px 8px 0px"
							>
								<Input
									width="100%"
									m="0 6px"
									type="text"
									size="lg"
									{...register('surname', {
										required: true,
									})}
								/>
								<FormLabel margin="-4px 0 0 0">Surname</FormLabel>
							</FormControl>

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.firstName}
								id="firstName"
								width="32%"
								margin="8px 6px 8px 0px"
							>
								<Input
									width="100%"
									m="0 6px"
									type="text"
									size="lg"
									{...register('firstName', {
										required: true,
									})}
								/>
								<FormLabel margin="-4px 0 0 0">First Name</FormLabel>
							</FormControl>

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.middleName}
								id="middleName"
								width="32%"
								margin="8px 6px 8px 0px"
							>
								<Input
									width="100%"
									m="0 6px"
									type="text"
									size="lg"
									{...register('middleName')}
								/>
								<FormLabel margin="-4px 0 0 0">Middle Name</FormLabel>
							</FormControl>

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.gender}
								id="gender"
								width="32%"
								margin="8px 6px 8px 0px"
							>
								<Select
									className='exclude-styles'
									width="100%"
									height="42px"
									{...register('gender', {
										required: true,
									})}>
									{makeEnumFetchFunction(genderTypeOptions).map(option => (<option key={option.value} value={option.value}>{option.display}</option>))}
								</Select>

								<FormLabel margin="-4px 0 0 0">Gender</FormLabel>
							</FormControl>

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.dob}
								id="dob"
								width="32%"
								margin="8px 6px 8px 0px"
							>

								<Input
									width="100%"
									m="0 6px"
									type="date"
									size="lg"
									{...register('dob', {
										required: true,
									})}
								/>

								<FormLabel margin="-4px 0 0 0">Date of Birth</FormLabel>
							</FormControl>

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.patientClass}
								id="patientClass"
								width="32%"
								margin="8px 6px 8px 0px"
							>


								<Select
									width="100%"
									height="42px"
									{...register('patientClass', {
										required: true,
									})}>
									<option value='O'>O</option>
								</Select>

								<FormLabel margin="-4px 0 0 0">Patient Class</FormLabel>
							</FormControl>
						</Flex>
					</Box>
					<p>Document Details</p>

					<Box border="1px solid #ccc" padding="8px" marginBottom="8px">
						<Flex flexWrap="wrap" flexDirection="row" justifyContent="flex-start" alignItems="flex-start" >
							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.requestDate}
								id="requestDate"
								width="32%"
								margin="8px 6px 8px 0px"
							>
								<Input
									width="100%"
									m="0 6px"
									type="date"
									size="lg"
									{...register('requestDate', {
										required: true,
									})}
								/>

								<FormLabel margin="-4px 0 0 0">Request Date</FormLabel>
							</FormControl>
							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.observationDate}
								id="observationDate"
								width="32%"
								margin="8px 6px 8px 0px"
							>

								<Input
									width="100%"
									m="0 6px"
									type="date"
									size="lg"
									{...register('observationDate', {
										required: true,
									})}
								/>

								<FormLabel margin="-4px 0 0 0">Observation Date</FormLabel>
							</FormControl>

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.documentTitle}
								id="documentTitle"
								width="32%"
								margin="8px 6px 8px 0px"
							>

								<Select
									width="100%"
									height="42px"
									{...register('documentTitle', {
										required: true,
									})}>
									{makeEnumFetchFunction(studyTypeOptions).map(option => (<option key={option.value} value={option.value}>{option.display}</option>))}
								</Select>

								<FormLabel margin="-4px 0 0 0">Document Title</FormLabel>
							</FormControl>

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.clinicalArea}
								id="clinicalArea"
								width="32%"
								margin="8px 6px 8px 0px"
							>
								<Input
									width="100%"
									m="0 6px"
									type="text"
									size="lg"
									{...register('clinicalArea')}
								/>
								<FormLabel margin="-4px 0 0 0">Clinical Area</FormLabel>
							</FormControl>
						</Flex>
					</Box>
					<p>Reporting Doctor</p>

					<Box border="1px solid #ccc" padding="8px">
						<Flex flexWrap="wrap" flexDirection="row" justifyContent="flex-start" alignItems="flex-start" >
							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.fromProviderNo}
								id="fromProviderNo"
								width="32%"
								margin="8px 6px 8px 0px"
							>
								<Input
									width="100%"
									m="0 6px"
									type="text"
									size="lg"
									{...register('fromProviderNo', {
										required: true,
									})}
								/>
								<FormLabel margin="-4px 0 0 0">Provider No</FormLabel>
							</FormControl>

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.fromPrefix}
								id="fromPrefix"
								width="32%"
								margin="8px 6px 8px 0px"
							>
								<Input
									width="100%"
									m="0 6px"
									type="text"
									size="lg"
									{...register('fromPrefix', {
										required: true,
									})}
								/>
								<FormLabel margin="-4px 0 0 0">Prefix</FormLabel>
							</FormControl>

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.fromFirstName}
								id="fromFirstName"
								width="32%"
								margin="8px 6px 8px 0px"
							>
								<Input
									width="100%"
									m="0 6px"
									type="text"
									size="lg"
									{...register('fromFirstName', {
										required: true,
									})}
								/>
								<FormLabel margin="-4px 0 0 0">First Name</FormLabel>
							</FormControl>

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.fromLastName}
								id="fromLastName"
								width="32%"
								margin="8px 6px 8px 0px"
							>
								<Input
									width="100%"
									m="0 6px"
									type="text"
									size="lg"
									{...register('fromLastName', {
										required: true,
									})}
								/>
								<FormLabel margin="-4px 0 0 0">Last Name</FormLabel>
							</FormControl>

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.fromSuffix}
								id="fromSuffix"
								width="32%"
								margin="8px 6px 8px 0px"
							>
								<Input
									width="100%"
									m="0 6px"
									type="text"
									size="lg"
									{...register('fromSuffix')}
								/>
								<FormLabel margin="-4px 0 0 0">Suffix</FormLabel>
							</FormControl>
						</Flex>
					</Box>
				</Flex>

				<div className="right-column">
					<p className="addressing-label">Addressing Details</p>

					<div className="addressing-details">
						<div className="information__container">
							<div className="to-container">
								<ButtonGroup alignment={Alignment.HORIZONTAL} className="find-buttons">
									<Button colors={Colors.Primary} display={Display.Text} sizes={Sizes.Medium} onClick={removeTo}>Clear</Button>
									<Button colors={Colors.Primary} display={Display.Solid} sizes={Sizes.Medium} icon={{ icon: 'search', iconPos: 'icon-left' }} onClick={() => renderAddressingModal('to')}>Find</Button>
								</ButtonGroup>
								<FormControl
									display="flex"
									flexDir="column"
									variant="floating"
									isInvalid={!!errors.toStringField}
									id="toStringField"
									width="95%"
									margin="8px 6px 8px 0px"
								>
									<Input
										className="to-text-field"
										width="100%"
										m="0 6px"
										type="text"
										size="lg"
										isDisabled
										{...register('toStringField', {
											required: true,
										})}
									/>

									<FormLabel margin="-4px 0 0 0" className="skip-styles">To:</FormLabel>
								</FormControl>
							</div>
							<div className="copy-container">
								<ButtonGroup alignment={Alignment.HORIZONTAL} className="add-buttons">
									<Button colors={Colors.Primary} display={Display.Text} sizes={Sizes.Medium} onClick={clearCc}>Clear</Button>
									<Button colors={Colors.Primary} display={Display.Solid} sizes={Sizes.Medium} icon={{ icon: 'plus', iconPos: 'icon-left' }} onClick={() => renderAddressingModal('cc')}>Add</Button>
								</ButtonGroup>
								<p className="copyDoctorLabel">Cc:</p>
								<div className="copyDoctorList">
									{displayCC.map(copyDoctor => (
										<div className="copyDoctor" key={copyDoctor.providerId}>
											{`${copyDoctor.jobTitle} ${copyDoctor.firstName} ${copyDoctor.lastName} (${copyDoctor.providerId})`}
											<i
												role="button"
												aria-label="removeCopyDoctor"
												tabIndex={0}
												className="delete icon copyDoctorRemove"
												onClick={e => {
													setValue(
														'cc',
														getValues('cc').filter(cd => cd.providerId !== copyDoctor.providerId)
													)
													setDisplayCC(getValues('cc').filter(cd => cd.providerId !== copyDoctor.providerId))
												}}
											/>
										</div>
									))
									}
								</div>
							</div>
						</div>
					</div>
					<div className="attached-report">
						<p>Attached Report</p>
						<div className="information__container"> 
							<span className="attached-report-pdf"> 
								<p>PDF format</p> 
								<Button colors={Colors.Primary} display={Display.Text} sizes={Sizes.Medium} onClick={() => previewModal('PDF')}> 
									{`${distributeReportState.study.patient.patientId}_${distributeReportState.patient.name.split(' ')[0]}_${distributeReportState.patient.name.split(' ')[distributeReportState.patient.name.split(' ').length - 1]}_${moment(distributeReportState.study.patient.dob).format('DDMMyyyy')}_${moment(distributeReportState.study.studyDate).format('DDMMyyyy')}.pdf`} 
								</Button> 
							</span>
							<span className="attached-report-text"> 
								<p>Text file format</p> 
								<Button colors={Colors.Primary} display={Display.Text} sizes={Sizes.Medium} onClick={() => previewModal('Text')}> 
									{`${distributeReportState.study.patient.patientId}_${distributeReportState.patient.name.split(' ')[0]}_${distributeReportState.patient.name.split(' ')[distributeReportState.patient.name.split(' ').length - 1]}_${moment(distributeReportState.study.patient.dob).format('DDMMyyyy')}_${moment(distributeReportState.study.studyDate).format('DDMMyyyy')}.txt`} 
								</Button> 
							</span>
						</div>
					</div>
					<Checkbox
						isChecked={HL7MessageSent}
						onChange={toggleHL7MessageSent}>Is message a correction of a previous report?
					</Checkbox>
					<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
						<Button colors={Colors.Primary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={() => navigate('/patientdashboard/')}>
							Cancel
						</Button>
						<Button type="submit" colors={Colors.Primary} display={Display.Solid} sizes={Sizes.Medium} buttonProps={{ id: 'submit' }}>
							Send
						</Button>
					</ButtonGroup>
				</div>
			</form>
		</div>
	);
};