import {gql} from '@apollo/client';
import {StudyEntity} from 'Models/Entities';
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
	getModelName, getAttributes, 
} from 'Util/EntityUtils';
import { VisitorsSessionEntity } from 'Models/Security/Acl/VisitorsSessionEntity';
import { SuperAdminSessionEntity } from 'Models/Security/Acl/SuperAdminSessionEntity';
import { UserSessionEntity } from 'Models/Security/Acl/UserSessionEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import * as Enums from '../Enums';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';
import { lowerCaseFirst } from '../../Util/StringUtils';
import { CRUD } from '../CRUDOptions';
import {store} from '../Store';

export interface ISessionEntityAttributes extends IModelAttributes {
	sessionType: Enums.sessionType;
	sessionDate: Date;
	sessionPeriod: Enums.sessionPeriod;
	sessionStarted: Date;
	sessionEnded: Date;
	countEchos: number;
	countStressEchos: number;
	countExerciseStress: number;
	countIpEchos: number;
	countIpStressEchos: number;
	countIpExerciseStress: number;
	countOpPaediatricEcho: number;
	countIpPaediatricEcho: number;
	countOpDobutamineStressEcho: number;
	countIpDobutamineStressEcho: number;
	countOp12LeadECG: number;
	countIp12LeadECG: number;
	startTime: string;
	endTime: string;
	imageWorklistId: string;
	imageWorklist: Blob;
	notes: string;
	siteId?: string;
	site?: Models.SiteEntity | Models.ISiteEntityAttributes;
	userId?: string;
	user?: Models.UserEntity | Models.IUserEntityAttributes;
	
	supervisorId?: string;
	allComplete: boolean;
	
	open: boolean;
}

@entity('SessionEntity', 'Session')
export default class SessionEntity extends Model implements ISessionEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsSessionEntity(),
		new SuperAdminSessionEntity(),
		new UserSessionEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
	];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Session Type',
		displayType: 'enum-combobox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => AttrUtils.standardiseEnum(attr, Enums.sessionTypeOptions),
		enumResolveFunction: makeEnumFetchFunction(Enums.sessionTypeOptions),
		displayFunction: (attribute: Enums.sessionType) => Enums.sessionTypeOptions[attribute],
	})
	public sessionType: Enums.sessionType;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Session Date',
		displayType: 'datepicker',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public sessionDate: Date;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Session Period',
		displayType: 'enum-combobox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => AttrUtils.standardiseEnum(attr, Enums.sessionPeriodOptions),
		enumResolveFunction: makeEnumFetchFunction(Enums.sessionPeriodOptions),
		displayFunction: (attribute: Enums.sessionPeriod) => Enums.sessionPeriodOptions[attribute],
	})
	public sessionPeriod: Enums.sessionPeriod;

	/**
	 * The datetime the session was started
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Session Started',
		displayType: 'datetimepicker',
		order: 40,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public sessionStarted: Date;

	/**
	 * The datetime the session was completed
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Session Ended',
		displayType: 'datetimepicker',
		order: 50,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public sessionEnded: Date;

	/**
	 * The number of echos completed
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Count OP Echos',
		displayType: 'textfield',
		order: 60,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public countEchos: number;

	/**
	 * The number of stress echos completed
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Count OP Stress Echos',
		displayType: 'textfield',
		headerColumn: true,
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public countStressEchos: number;

	/**
	 * The number of exercise stresses completed
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Count OP Exercise Stress',
		displayType: 'textfield',
		headerColumn: true,
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public countExerciseStress: number;
	
	/**
	 * The number of echos completed
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Count IP Echos',
		displayType: 'textfield',
		order: 60,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public countIpEchos: number;

	/**
	 * The number of stress echos completed
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Count IP Stress Echos',
		displayType: 'textfield',
		headerColumn: true,
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public countIpStressEchos: number;

	/**
	 * The number of exercise stresses completed
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Count IP Exercise Stress',
		displayType: 'textfield',
		headerColumn: true,
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public countIpExerciseStress: number;

	/**
	 * The number of Out Patient Paediatric echos completed
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Count OP Paediatric Echo',
		displayType: 'textfield',
		headerColumn: true,
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public countOpPaediatricEcho: number;
	/**
	 * The number in Out Patient Paediatric echos completed
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Count IP Paediatric Echo',
		displayType: 'textfield',
		headerColumn: true,
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public countIpPaediatricEcho: number;
	/* 
		The number of out patient dobutamine stress echos completed
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name:'Count OP Dobutamine Stress Echos',
		displayType: 'textfield',
		headerColumn: true,
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public countOpDobutamineStressEcho: number;
	/**
	 * The number of in patient dobutamine stress echos completed
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Count IP Dobutamine Stress Echos',
		displayType: 'textfield',
		headerColumn: true,
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public countIpDobutamineStressEcho: number;
	/**
	 * The number of out patient 12 lead ECGS completed
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Count OP Exercise Stress',
		displayType: 'textfield',
		headerColumn: true,
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public countOp12LeadECG: number;
	/**
	 * The number of in patient 12 lead ECGS completed
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Count IP 12 Lead ECG',
		displayType: 'textfield',
		headerColumn: true,
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public countIp12LeadECG: number;


	/**
	 * The time started work
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Start Time',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public startTime: string;

	/**
	 * The time ended the work
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'End Time',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public endTime: string;

	/**
	 * An image of their worklist
	 */
	@observable
	@attribute({ file: 'imageWorklist' })
	@CRUD({
		name: 'Image Worklist',
		displayType: 'file',
		order: 110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		fileAttribute: 'imageWorklist',
		displayFunction: attr => (attr 
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="An image of their worklist" style={{ maxWidth: '300px' }} />
			: 'No File Attached'),
	})
	public imageWorklistId: string;
	
	@observable
	public imageWorklist: Blob;

	/**
	 * Notes
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Notes',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public notes: string;

	/**
	 * Session Site
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Site',
		displayType: 'reference-combobox',
		headerColumn: true,
		order: 10,
		referenceTypeFunc: () => Models.SiteEntity,
		displayFunction: (attr: any, that: Model) => <p>{that['site']?.siteName ? that['site']?.siteName : 'Office'}</p>,
	})
	public siteId?: string;

	@observable
	@attribute({ isReference: true })
	public site: Models.SiteEntity;

	/**
	 * Session User
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'User',
		displayType: 'reference-combobox',
		order: 140,
		referenceTypeFunc: () => Models.UserEntity,
	})
	public userId?: string;

	@observable
	@attribute({ isReference: true })
	public user: Models.UserEntity;
	
	/**
	 * Session Supervisor
	 */
	@observable
	@attribute()
	public supervisorId?: string;

	@observable
	@attribute()
	public allComplete: boolean;
	
	@observable
	@attribute()
	public open: boolean;

	constructor(attributes?: Partial<ISessionEntityAttributes>) {
		super(attributes);
	}
	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ISessionEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.sessionType !== undefined) {
				this.sessionType = attributes.sessionType;
			}
			if (attributes.sessionDate !== undefined) {
				if (attributes.sessionDate === null) {
					this.sessionDate = attributes.sessionDate;
				} else {
					this.sessionDate = moment(attributes.sessionDate).toDate();
				}
			}
			if (attributes.sessionPeriod !== undefined) {
				this.sessionPeriod = attributes.sessionPeriod;
			}
			if (attributes.sessionStarted !== undefined) {
				if (attributes.sessionStarted === null) {
					this.sessionStarted = attributes.sessionStarted;
				} else {
					this.sessionStarted = moment(attributes.sessionStarted).toDate();
				}
			}
			if (attributes.sessionEnded !== undefined) {
				if (attributes.sessionEnded === null) {
					this.sessionEnded = attributes.sessionEnded;
				} else {
					this.sessionEnded = moment(attributes.sessionEnded).toDate();
				}
			}
			if (attributes.countEchos !== undefined) {
				this.countEchos = attributes.countEchos;
			}
			if (attributes.countStressEchos !== undefined) {
				this.countStressEchos = attributes.countStressEchos;
			}
			if (attributes.countExerciseStress !== undefined) {
				this.countExerciseStress = attributes.countExerciseStress;
			}
			if (attributes.countIpEchos !== undefined) {
				this.countIpEchos = attributes.countIpEchos;
			}
			if (attributes.countIpStressEchos !== undefined) {
				this.countIpStressEchos = attributes.countIpStressEchos;
			}
			if (attributes.countIpExerciseStress !== undefined) {
				this.countIpExerciseStress = attributes.countIpExerciseStress;
			}
			if (attributes.countOpPaediatricEcho !== undefined) {
				this.countOpPaediatricEcho = attributes.countOpPaediatricEcho;
			}
			if (attributes.countIpPaediatricEcho !== undefined) {
				this.countIpPaediatricEcho = attributes.countIpPaediatricEcho;
			}
			if (attributes.countOpDobutamineStressEcho !== undefined) {
				this.countOpDobutamineStressEcho = attributes.countOpDobutamineStressEcho;
			}
			if (attributes.countIpDobutamineStressEcho !== undefined) {
				this.countIpDobutamineStressEcho = attributes.countIpDobutamineStressEcho;
			}
			if (attributes.countOp12LeadECG !== undefined) {
				this.countOp12LeadECG = attributes.countOp12LeadECG;
			}
			if (attributes.countIp12LeadECG !== undefined) {
				this.countIp12LeadECG = attributes.countIp12LeadECG;
			}
			if (attributes.startTime !== undefined) {
				this.startTime = attributes.startTime;
			}
			if (attributes.endTime !== undefined) {
				this.endTime = attributes.endTime;
			}
			if (attributes.imageWorklist !== undefined) {
				this.imageWorklist = attributes.imageWorklist;
			}
			if (attributes.imageWorklistId !== undefined) {
				this.imageWorklistId = attributes.imageWorklistId;
			}
			if (attributes.notes !== undefined) {
				this.notes = attributes.notes;
			}
			if (attributes.user !== undefined) {
				if (attributes.user === null) {
					this.user = attributes.user;
				} else if (attributes.user instanceof Models.UserEntity) {
					this.user = attributes.user;
					this.userId = attributes.user.id;
				} else {
					this.user = new Models.UserEntity(attributes.user);
					this.userId = this.user.id;
				}
			} else if (attributes.userId !== undefined) {
				this.userId = attributes.userId;
			}
			
			if(attributes.supervisorId !== undefined) {
				this.supervisorId = attributes.supervisorId;
			}
			
			if (attributes.site !== undefined) {
				if (attributes.site === null) {
					this.site = attributes.site;
				} else if (attributes.site instanceof Models.SiteEntity) {
					this.site = attributes.site;
					this.siteId = attributes.site.id;
				} else {
					this.site = new Models.SiteEntity(attributes.site);
					this.siteId = this.site.id;
				}
			} else if (attributes.siteId !== undefined) {
				this.siteId = attributes.siteId;
			}

			if (attributes.allComplete) {
				this.allComplete = attributes.allComplete;
			}
			if (attributes.open) {
				this.open = attributes.open;
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		site {
			${Models.SiteEntity.getAttributes().join('\n')}
			${Models.SiteEntity.getFiles().map(f => f.name).join('\n')}
		}
		user {
			${Models.UserEntity.getAttributes().join('\n')}
		}
	`;
	
	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}

	public async saveSession() {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		return this.id;
	}

	public listExpands = `
		site {
			${Models.SiteEntity.getAttributes().join('\n')}
		}
	`;

	public static getFetchSession() {
		const model = new SessionEntity();
		const modelName = lowerCaseFirst(getModelName(SessionEntity));

		return gql`
			query ${modelName} ($args:[WhereExpressionGraph]) {
				${modelName} (where: $args) {
					${getAttributes(SessionEntity).join('\n')}
					${model.defaultExpands}
				}
			}`;
	}
	
	public static getCompletedSessions() {
		const model = new SessionEntity();
		const modelName = lowerCaseFirst(getModelName(SessionEntity));
		
		return gql`
			query session($userId: [String]) {
				sessionEntitysConditional(where: [
					{comparison: equal, path: "userId", value: $userId}, 
					{comparison: equal, path: "allComplete", value: "true"}
				]) {
					${getAttributes(SessionEntity).join('\n')}
					${model.defaultExpands}
				}
			}
		`;
	}
	
	public static getUpcomingSessions() {
		return gql`
			query session($userId: [String]) {
				sessionEntitysConditional(where: [
					{comparison: equal, path: "userId", value: $userId}, 
					{comparison: equal, path: "allComplete", value: "false"}
				]) {
					id
					userId
					sessionDate
					sessionType
					sessionPeriod
					open
					siteId
					countEchos
				    countStressEchos
				    countExerciseStress
				    countIpEchos
				    countIpStressEchos
				    notes
				    countIpExerciseStress
					countOpPaediatricEcho
					countIpPaediatricEcho
					countOpDobutamineStressEcho
					countIpDobutamineStressEcho
					countOp12LeadECG
					countIp12LeadECG
					site {
						siteName			
					}
				}
			}
		`;
	}
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(SessionEntity.prototype, 'created');
CRUD(modifiedAttr)(SessionEntity.prototype, 'modified');
