import React, { useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import {runInAction, toJS} from 'mobx';
import { TextArea } from 'Views/Components/TextArea/TextArea';
import {
	DoctorEntity, SiteEntity, StudyEntity, UserEntity, 
} from 'Models/Entities';
import { store } from 'Models/Store';
import {
	Button, Colors, Display, Sizes, 
} from 'Views/Components/Button/Button';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import alert from 'Util/ToastifyUtils';
import { SERVER_URL } from 'Constants';
import axios from 'axios';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { TextField } from '../../Components/TextBox/TextBox';
import {useNavigate} from "react-router-dom";

interface BasicReportState {
	reportData: string;
	advancedReportString: string;
}

interface BasicReportBuilderProps {
	study: StudyEntity;
	site: SiteEntity;
	basicReportState: BasicReportState;
	reportingDoctors: DoctorEntity[];
}

const BasicReportBuilder = observer((props: BasicReportBuilderProps) => {
	const {
		study,
		site,
		basicReportState,
		reportingDoctors,
	} = props;
	
	const navigate = useNavigate();
	
	/**
	 * Checks for any reporting doctors already assigned to the study
	 * @returns The initial reportingDoctor to use
	 */
	const getInitialReportingDoctor = () => {
		if (study.doctorss.length <= 0) return '';

		const reportingDoctor = study.doctorss.filter(d => d.doctorType === 'REPORTING')[0];
		if (!reportingDoctor) {
			return '';
		}
		
		const reportingDoctorFound = reportingDoctor !== undefined && reportingDoctors.filter(
			d => `${d.prefix} + ${d.firstName} ${d.lastName}`
			=== `${reportingDoctor.prefix} + ${reportingDoctor.firstName} ${reportingDoctor.lastName}`,
		)[0];

		if (reportingDoctorFound) {
			return `${reportingDoctorFound.prefix} ${reportingDoctorFound.firstName} ${reportingDoctorFound.lastName}`;
		}

		// Can't find any relevant reporting doctor to use
		return '';
	};
	
	const reportState = useLocalStore(() => ({
		reportData: study.report?.reportData,
		advancedReportString: study.report?.advancedreportstring,
		txt: '',
		reportingDoctor: getInitialReportingDoctor(),
	}));

	const confirmationState = useLocalStore(() => ({
		confirmed: study.report?.reportStatus === 'CONFIRMED',
		userDetails: new UserEntity(),
		modalCheck: '',
	}));

	const retrieveUser = () => {
		store.apolloClient.query({
			query: UserEntity.getFetchSingleQueryProfilePage(),
			fetchPolicy: 'network-only',
			variables: {
				args: [{
					path: 'id',
					comparison: 'equal',
					value: store.userId,
				}],
			},
		}).then(res => {
			runInAction(() => {
				confirmationState.userDetails = new UserEntity(res.data.userEntity);
			});
		}).catch(error => {
			console.error(error);
		});
	};

	let hasEditAccess = false;

	if (store.userSiteAccess.filter(usa => usa.siteId === site.id).length > 0) {
		hasEditAccess = ((store.userType === 'CARDIAC_SCIENTIST' && study.report?.reportStatus !== 'CONFIRMED') || store.userType === 'REPORTING_DOCTOR')
			&& (store.userSiteAccess.filter(usa => usa.siteId === site.id)[0].accessLevel === 'FULL_ACCESS');
	}

	let canConfirmReport = false;
	if (!canConfirmReport && store.userSiteAccess.filter(usa => usa.siteId === site.id).length > 0) {
		canConfirmReport = (store.userType === 'REPORTING_DOCTOR')
		&& (store.userSiteAccess.filter(usa => usa.siteId === site.id)[0].accessLevel === 'FULL_ACCESS');
	}
	
	const toggleStudyUrgency = (): void => {
		store.modal.show('Confirmation', 
			<div className="confirmation__modal">
				<h4>{`Do you want to set the study urgency to ${study.urgent ? 'Routine' : 'Urgent'}?`}</h4>
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
					<Button 
						colors={Colors.Primary} 
						display={Display.Solid} 
						sizes={Sizes.Medium} 
						buttonProps={{ id: 'confirm' }} 
						onClick={(): void => runInAction(() => {
							study.toggleUrgent();
							study.save();
							store.modal.hide();
						})}
					>
						Confirm
					</Button>
					<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={(): void => store.modal.hide()}>Cancel</Button>
				</ButtonGroup>
			</div>);
	};

	const modelProperty = site.advancedReportBuilder && (basicReportState.advancedReportString !== null && basicReportState.advancedReportString !== '') ? 'advancedReportString' : 'reportData';

	const getReportAsTXT = (): void => {
		if (study.selectedReportTemplate !== undefined) {
			axios.get(`${SERVER_URL}/api/entity/StudyEntity/EchoReportTXT/${study.id}`)
				.then(res => runInAction(() => {
					reportState.reportData = res.data;
				}))
				.catch(() => runInAction(() => {
					reportState.reportData = 'Unable to load report txt data for the selected template.';
					alert('Unable to load report txt data for the selected template', 'error');
				}));
		}
	};

	const saveLogic = async (exit: boolean) => {
		runInAction(() => {
			if (study.report) { study.report.reportData = basicReportState.reportData || ''; }

			if (study.report) {
				if (confirmationState.confirmed) {
					if (!reportState.reportingDoctor) {
						alert('Must set a reporting doctor', 'error');
						return;
					}

					study.report.reportStatus = 'CONFIRMED';
				} else if (exit) {
					study.report.reportStatus = 'PROVISIONAL';
				}
			}

			// [CNSD-35] Poached from AdvancedReportBuilder
			const reportingDoctor = study.doctorss.filter(d => d.doctorType === 'REPORTING');
			if (reportingDoctor.length === 0 && reportState.reportingDoctor) {
				const reportingDoctorFound = reportingDoctors.filter(d => `${d.prefix} ${d.firstName} ${d.lastName}` === reportState.reportingDoctor);
				if (reportingDoctorFound.length > 0) {
					study.doctorss.push(reportingDoctorFound[0]);
				}
			} else if (reportingDoctor.length > 0 && reportState.reportingDoctor) {
				const reportingDoctorFound = reportingDoctors.filter(d => `${d.prefix} ${d.firstName} ${d.lastName}` === reportState.reportingDoctor);
				if (reportingDoctorFound.length > 0) {
					const oldDoctor = study.doctorss.filter(d => d.doctorType === 'REPORTING' && d.id !== reportingDoctorFound[0].id);
					oldDoctor.forEach(d => {
						d.delete();
					});
					study.doctorss = [...study.doctorss.filter(d => !oldDoctor.map(od => od.id).includes(d.id))];
					study.doctorss.push(reportingDoctorFound[0]);
				}
			}

			study.save({ report: {}, doctorss: {} }).then(() => {
				alert(<p>Report Saved</p>, 'success');
				if (exit) {
					navigate('/patientdashboard/');
				}
			});
		});
	};
	
	const saveReportLogic = (exit: boolean) => {
		if (study.report?.reportStatus === 'CONFIRMED') {
			store.modal.show('Confirmation',
				<div className="confirmation__modal">
					<h4>This study is already confirmed</h4>
					<h5>Type CONFIRM to make changes to the report</h5>
					<TextField model={confirmationState} modelProperty="modalCheck" placeholder="Type CONFIRM" />
					<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
						<Button
							colors={Colors.Primary}
							display={Display.Solid}
							sizes={Sizes.Medium}
							buttonProps={{ id: 'confirm' }}
							onClick={(): void => runInAction(() => {
								const reportingDoctor = study.doctorss.filter(d => d.doctorType === 'REPORTING');

								if (confirmationState.modalCheck === 'CONFIRM') {
									// Only have name =/ 
									if (`${reportingDoctor[0].firstName} ${reportingDoctor[0].lastName}` === confirmationState.userDetails.name) {
										saveLogic(exit).then(() => {
											runInAction(() => {
												confirmationState.modalCheck = '';
											});

											store.modal.hide();
										});
									} else {
										alert('You are not this studies reporting doctor. Only the assigned reporting doctor can make edits to a confirmed report.', 'error');
									}
								} else {
									alert('Type CONFIRM to continue', 'error');
								}
							})}
						>
							Confirm
						</Button>
						<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={(): void => store.modal.hide()}>Cancel</Button>
					</ButtonGroup>
				</div>);
		} else {
			saveLogic(exit);
		}
	};
	
	useEffect(() => {
		getReportAsTXT();
		retrieveUser();
	}, []);
	
	return (
		<div className="basic-report-builder">
			{site.advancedReportBuilder && !reportState[modelProperty] && (
				<p>Please select a report template using the advanced panel.</p>
			)}
			
			{(reportState[modelProperty] != null) && (
				<TextArea
					model={basicReportState}
					modelProperty={modelProperty}
					isDisabled={site.advancedReportBuilder || !hasEditAccess}
				/>
			)}
			
			{!site.advancedReportBuilder && (
				<div className="update-controls">
				<div className="urgent-group">
					<p>Urgency</p>
					{study.urgent ? (
						<Button disabled={!hasEditAccess} colors={Colors.Error} display={Display.Solid} sizes={Sizes.Small} icon={{ icon: 'chevron-down', iconPos: 'icon-right' }} onClick={(): void => runInAction(() => toggleStudyUrgency())}><span className="icon-left icon-clock">Urgent</span></Button>
					) : (
						<Button disabled={!hasEditAccess} colors={Colors.Grey} display={Display.Solid} sizes={Sizes.Small} icon={{ icon: 'chevron-down', iconPos: 'icon-right' }} buttonProps={{ id: 'routine' }} onClick={() => runInAction((): void => toggleStudyUrgency())}>Routine</Button>
					)}
				</div>
				{canConfirmReport && (
					<>
						{study.report?.reportStatus !== 'CONFIRMED' && (
							<>
								<div className="urgent-group">
									<p>Reporting Doctor</p>
									<Combobox
										model={reportState}
										modelProperty="reportingDoctor"
										label="Reporting Doctor"
										labelVisible={false}
										className="reportingDoctor"
										isDisabled={!hasEditAccess}
										options={[...reportingDoctors.map(d => ({ display: `${d.prefix} ${d.firstName} ${d.lastName}`, value: `${d.prefix} ${d.firstName} ${d.lastName}` }))]}
										onAfterChange={(event): void => {
											runInAction(() => {
												reportState.reportingDoctor = event.currentTarget.textContent || '';
											});
										}}
									/>
								</div>
								<div className="confirm-report">
									<input 
										type="checkbox"
										name="confirm" 
										onChange={(): void => runInAction(() => { 
											confirmationState.confirmed = !confirmationState.confirmed; 
										})} 
										checked={confirmationState.confirmed}
									/>                            
									<label htmlFor="confirm">Confirm Report</label>
								</div>
							</>
						)}
					</>
				)}
				{hasEditAccess && (
					<>
						<Button
							display={Display.Solid}
							colors={Colors.Primary}
							className="submit-button"
							onClick={() => saveReportLogic(false)}
						>
							Save
						</Button>
						<Button
							display={Display.Solid}
							colors={Colors.Primary}
							className="submit-button"
							onClick={() => saveReportLogic(true)}
						>
							Save & Exit
						</Button>
					</>
				)}
			</div>
			)}
		</div>
	);
});

export default BasicReportBuilder;
