import { IAcl } from '../IAcl';

export class VisitorsStructuredReportEntity implements IAcl {
	public group?: string;

	public isVisitorAcl = true;

	public constructor() {
		this.group = 'Visitors';
	}

	public canRead(): boolean {
		return false;
	}

	public canCreate(): boolean {
		return false;
	}

	public canUpdate(): boolean {
		return false;
	}

	public canDelete(): boolean {
		return false;
	}
}
