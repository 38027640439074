import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import initialMeasurementStructure from '../../../Assets/data/measurementStructure.json';
import { AiFillSave } from 'react-icons/ai';
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Flex, Select, Input, Button, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Tab,
	TabList,
	Tabs,
	InputGroup,
	InputRightElement
} from '@chakra-ui/react';
import { CheckCircleIcon, DeleteIcon, TriangleDownIcon, TriangleUpIcon, AddIcon } from '@chakra-ui/icons';
import { getErrorMessages } from 'Util/GraphQLUtils';
import { store } from '../../../Models/Store';
import {
	useReactTable,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	ExpandedState,
	getPaginationRowModel,
	getFilteredRowModel,
	getExpandedRowModel,
	Row
} from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { MeasurementEntity, StudyEntity, } from 'Models/Entities';
import { 
	PrecisionMap, DefaultUnitMap,
} from 'Util/measurements_template';

import { TableDataItem } from './StudyModels';
import * as computedMeasurements from './computedMeasurements';

interface MeasurementTableProps {
	study: StudyEntity,
	refetch?: () => void
}

const colorTheme = {
	background : '#333333',
	text : '#cccccc'
}

const formatValuePrecision = (value: number, significantFigures: number) => (value ? value.toFixed(significantFigures ) : '0');

const computeDependantValues = (measurementKey: string, MeasurementEntities: MeasurementEntity[]) => {
	const measurementStructure = initialMeasurementStructure[measurementKey];

	let childRowsLength = 0;
	if (measurementStructure.dependants && measurementStructure.dependants.length > 0) {
		measurementStructure.dependants.forEach((dependantKey: string)=> {
			const length = MeasurementEntities.filter(element => element.name === dependantKey).length;
			if (childRowsLength === 0) {
				childRowsLength = length;
			} else if (length < childRowsLength && !['BSA', 'Height','Weight','CW-RAP'].includes(dependantKey)) {	
				childRowsLength = length;
			}
		});
	}
	const subRowData = [];
	if (childRowsLength > 0) {
		for (let i = 0; i < childRowsLength; i++) {
			const fakeTableData = measurementStructure.dependants.map(function(dependantKey: string){
				let dummyValue = 0				
				if (MeasurementEntities.filter(element => element.name === dependantKey).length > 1 && !['BSA', 'Height','Weight','CW-RAP'].includes(dependantKey)) {
					dummyValue = MeasurementEntities.filter(element => element.name === dependantKey)[i].value;
				} else {
					dummyValue = MeasurementEntities.filter(element => element.name === dependantKey)[0].value;
				}
				return {
					'id' : dependantKey,
					'name' :  dependantKey, 
					'key':  dependantKey, 
					'label' :  initialMeasurementStructure[dependantKey].name,
					'unit' : DefaultUnitMap[initialMeasurementStructure[dependantKey].key],
					'type' : initialMeasurementStructure[dependantKey].defaultValue,
					'group' : initialMeasurementStructure[dependantKey].group,
					'value' : dummyValue ? dummyValue.toString() : '0',
					'rawValue' : dummyValue,
					'isChild' : false,
				}});
			const computedMeasurementValue = computedMeasurements.computedValuesMap[measurementStructure.key](fakeTableData);
			subRowData.push({
				'id' : measurementKey + '_' + 'M' + (i + 1),
				'name' : 'M' + (i + 1),
				'key': measurementKey,
				'label' : 'M' + (i + 1),
				'unit' : DefaultUnitMap[measurementStructure.key],
				'type' : measurementStructure.defaultValue,
				'group' : measurementStructure.group,
				'value' : formatValuePrecision(computedMeasurementValue, (PrecisionMap[measurementStructure.key] || 0)),
				'rawValue' : computedMeasurementValue,
				'isChild' : true,
				'isTempRow' : false,
				'isPrimary' :false,
			})
		}
	}
	return subRowData;
}

const formatStudyData = (MeasurementEntitys: MeasurementEntity[], defaultValues: object, newMeasurementData?: MeasurementEntity) => {
	const MeasurementEntitysOrganised: TableDataItem[] = [];
	Object.keys(initialMeasurementStructure).forEach(function(measurementKey: string){
		const measurementDetails = initialMeasurementStructure[measurementKey];
		const measurementMatches = MeasurementEntitys.filter(element => element.name === measurementKey);
		if (measurementMatches.length && !measurementDetails.computed) {
			let measurmentMetadata = defaultValues[measurementMatches[0].name];
			if (!measurmentMetadata) {
				measurmentMetadata = initialMeasurementStructure[measurementMatches[0].name];
			}
			MeasurementEntitysOrganised.push({
				'id' : measurementMatches[0].id,
				'key': measurementMatches[0].name,
				'name' : measurementMatches[0].name,
				'label' : measurmentMetadata.name,
				'unit' : measurementMatches[0].unit,
				'type' : measurmentMetadata.defaultValue,
				'group' : measurmentMetadata.group,
				'value' : formatValuePrecision(measurementMatches[0].value, (PrecisionMap[measurmentMetadata.key] || 0)),
				'rawValue' : measurementMatches[0].value,
				'isChild' : false,
				'isTempRow' : false,
				'subRows' : measurementMatches.map((measurement, index)=> ({
					'id' : measurement.id,
					'name' : 'M' + (index + 1),
					'key': measurement.name,
					'label' : 'M' + (index + 1),
					'unit' : measurement.unit,
					'type' : measurmentMetadata.defaultValue,
					'group' : measurmentMetadata.group,
					'value' : formatValuePrecision(measurement.value, (PrecisionMap[measurmentMetadata.key] || 0)),
					'rawValue' : measurement.value,
					'isChild' : true,
					'isTempRow' : newMeasurementData ? measurement.id === newMeasurementData.id : false,
					'isPrimary' : measurement.isPrimary,
				}))
			});
		} else if (measurementDetails.computed) {
			let dependantsAvailable = true;
			if (measurementDetails.dependants) {
				measurementDetails.dependants.forEach(function(key: string){
					const measurement = MeasurementEntitys.find(element => element.name === key);
					if (!measurement) {
						dependantsAvailable = false;
					}
				});
			}
			if (dependantsAvailable) {
				const computedMeasurementData: TableDataItem = {
					'id' : measurementKey,
					'name' :  measurementDetails.key,
					'key':  measurementKey, 
					'label' :  measurementDetails.name,
					'unit' : DefaultUnitMap[measurementDetails.key],
					'type' : measurementDetails.defaultValue,
					'group' : measurementDetails.group,
					'value' : '0',
					'rawValue' : 0,
					'isChild' : false,
				};

				computedMeasurementData.subRows = computeDependantValues(measurementKey, MeasurementEntitys);
				MeasurementEntitysOrganised.push(computedMeasurementData);
			}
		}

	});

	return MeasurementEntitysOrganised.map(function(measurement){
		if (!initialMeasurementStructure[measurement.id] || !initialMeasurementStructure[measurement.id].computed) {
			if (measurement.type === 'Average' && measurement.subRows && measurement.subRows.length) {
				measurement.value = (measurement.subRows.reduce((a: number, b: TableDataItem) => (a + Number(b.value)), 0) / measurement.subRows.length).toString();
			} else if (measurement.type === 'Max' && measurement.subRows && measurement.subRows.length) {
				measurement.value = (Math.max(...measurement.subRows.map((measurement: TableDataItem) => Number(measurement.value)))).toString();
			} else if (measurement.type === 'Min' && measurement.subRows && measurement.subRows.length) {
				measurement.value = (Math.min(...measurement.subRows.map((measurement: TableDataItem) => Number(measurement.value)))).toString();
			} else if (measurement.type === 'Single' && measurement.subRows && measurement.subRows.length) {
				measurement.value = measurement.subRows.find(item => item.isPrimary)?.value || measurement.subRows[0].value;
			}
			measurement.value = formatValuePrecision(Number(measurement.value), (PrecisionMap[initialMeasurementStructure[measurement.name].key] || 0));
			measurement.rawValue = Number(measurement.value);
		}
		return measurement;
	});

}

export const MeasurementTable = (props: MeasurementTableProps) => {
	const { loading: measurementsLoading, data: measurements, error: measurementError, refetch: measurementFetch} = useQuery(MeasurementEntity.fetchMeasurements(), {
		fetchPolicy: 'network-only',
		variables: {
			args: [{
				path: 'studyId',
				comparison: 'equal',
				value: props.study.id,
			}],
		}
	})
	
	if (!props.study.measurementStructure || Object.keys(props.study.measurementStructure).length === 0) {
		props.study.measurementStructure = JSON.stringify(initialMeasurementStructure);
	}
	
	let hasEditAccess = true;	
	const toast = useToast();	

	const groupLabels: string[] = Object.keys(initialMeasurementStructure).map(item => initialMeasurementStructure[item].group).filter((value, index, array) => array.indexOf(value) === index && value);
	const [currentTab, setCurrentTab] = React.useState<string>(groupLabels[0]);

	const [newMeasurementModelOpen, setNewMeasurementModalOpen] = React.useState<boolean>(false);
	const [createMeasurementModelOpen, setCreateMeasurementModalOpen] = React.useState<boolean>(false);
	const [createMeasurementModelType, setCreateMeasurementModalType] = React.useState<string>('');
	const [createMeasurementSelection, setCreateMeasurementSelection] = React.useState<string>('');
	const [createMeasurementValue, setCreateMeasurementValue] = React.useState<string>('');
	const [deleteMeasurementModelOpen, setDeleteMeasurementModalOpen] = React.useState<boolean>(false);
	const [measurementStructure, setMeasurementStructure] = React.useState<object>(JSON.parse(props.study.measurementStructure));
	const [expanded, setExpanded] = React.useState<ExpandedState>({});
	const [measurementsData, setMeasurementsData] = React.useState<MeasurementEntity[]>(props.study.measurementss);
	const [newMeasurementData, setNewMeasurementsData] = React.useState<MeasurementEntity>();
	const [deletedMeasurementData, setDeletedMeasurementsData] = React.useState<MeasurementEntity>();
	const [deletedMeasurementTableData, setDeletedMeasurementTableData] = React.useState<TableDataItem>();
	const [tableData, setTableData] = React.useState<TableDataItem[]>();
	const [createMeasurementOptions, setCreateMeasurementOptions] = React.useState<{ label: string; key: string; }[]>([]);

	if (store.userSiteAccess.filter(usa => usa.siteId === props.study.patient.site.id).length > 0) {
		hasEditAccess = (store.userType === 'CARDIAC_SCIENTIST' || store.userType === 'REPORTING_DOCTOR')
		&& (store.userSiteAccess.filter(usa => usa.siteId === props.study.patient.site.id)[0].accessLevel === 'FULL_ACCESS')
		&& props.study?.report?.reportStatus !== 'CONFIRMED';
	}

	useEffect(() => {
		if (!measurementsLoading) {
			setMeasurementsData(JSON.parse(JSON.stringify(measurements.measurementEntitys)))
			setTableData((formatStudyData(JSON.parse(JSON.stringify(measurements.measurementEntitys)), JSON.parse(props.study.measurementStructure))))		
		}
		
	}, [measurementsLoading]);

	useEffect(() => {
		if (props.refetch) {
			props.refetch();
		}
	}, []);

	const columnHelper = createColumnHelper<TableDataItem>();

	const columns = [
		columnHelper.accessor('label', {
			cell: info => info.getValue(),
			header: 'Name'
		}),
		columnHelper.accessor(info => renderMeasurementInput(info), {
			id: 'Measurement',
			header: 'Value'
		}),
		columnHelper.accessor(info => formatUnit(info), {
			header: 'Unit',
		}),
		columnHelper.accessor(info => renderTypeSelect(info), {
			id: 'Type',
		}),
		columnHelper.accessor(info => renderActions(info), {
			id: 'actions',
		})
	];

	const table = useReactTable({
		columns,
		data : tableData || [],
		state: {
			expanded,
		},
		getSubRows: row => row.subRows,
		onExpandedChange: setExpanded,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		debugTable: true,
		manualPagination: true
	});
	
	if (measurementsLoading) {
		return <></>;
	}

	const renderActions = (info : TableDataItem) => {
		if (initialMeasurementStructure[info.key].computed) {
			return <></>
		} else if (info.isChild) {
			return (
				<Flex color="#444" width="100%" justifyContent="start" alignItems="center">
					{!info.isTempRow ? <CheckCircleIcon m="0 2px" cursor="pointer" onClick={() => selectSingleMeasurement(info)} transition="all .5s" _hover={{ color: '#0a9421' }} color={info.isPrimary ? '#0a9421' : colorTheme.text}></CheckCircleIcon> : 
						<Box m="0 2px">
							<AiFillSave
								color={colorTheme.text}
								aria-label="confirm add measurement icon"
								cursor="pointer" 
								onClick={() => setNewMeasurementModalOpen(true)}
							/>
						</Box>
					}
					{!initialMeasurementStructure[info.key].computed ? <DeleteIcon m="0 2px" color={colorTheme.text} cursor="pointer" onClick={() => !info.isTempRow ? openDeleteMeasurementDialog(info) : removeTempMeasurement()} transition="all .5s" _hover={{ color: '#d10d0d' }}></DeleteIcon> : ''}
				</Flex>
			)
		} else {
			return (
				<Flex color="#444" width="100%" justifyContent="start" alignItems="center" cursor="pointer">
					<AddIcon color={colorTheme.text} m="0 6px 0 2px" onClick={event => addMeasurement(info, event)}></AddIcon>
					{!initialMeasurementStructure[info.key].computed && (!info.subRows || info.subRows.length === 1) ? <DeleteIcon m="0 2px" color={colorTheme.text} cursor="pointer" onClick={() => openDeleteMeasurementDialog(info)} transition="all .5s" _hover={{ color: '#d10d0d' }}></DeleteIcon> : ''}
				</Flex>
			)
		}
	}
	
	const renderTypeSelect = (info: TableDataItem) => {
		const typeOptioons: string[] = ['Average','Max','Min'].filter(type => type !== info.type);
		if (!initialMeasurementStructure[info.key].computed && !info.isChild && info.subRows && info.subRows.length > 1) {
			return (
				<Select size='sm' variant="outline" placeholder={info.type} onChange={e => updateMeasurmentType(info, e.target.value)} className="ignore-styles" onClick={event=> event.stopPropagation()}>
					{typeOptioons.map(option => <option style={{ color: 'black' }}  value={option} key={option}>{option}</option>)}
				</Select>
			);
		} else {
			return <Box p="2px 8px">Single</Box>;
		}
	}

	const RenderChevron = (row: Row<TableDataItem>) => {
		if (row.original && row.original.subRows && row.original.subRows.length > 1) { 
			return row.getIsExpanded() ? <TriangleDownIcon aria-label="sorted descending" w="3" h="3" /> : <TriangleUpIcon aria-label="sorted descending" w="3" h="3" />;
		}
		return;
	}

	const renderMeasurementInput = function(info: TableDataItem) {
		if (!initialMeasurementStructure[info.key].computed && (!info.subRows || info.subRows.length === 1 || info.isChild)) {
			return (
				<>
					<Input
						textAlign="right"
						borderColor="#ddd"
						m="0"
						type="number"
						size="sm"
						className="ignore-styles"
						variant="outline"
						defaultValue={info.value || 0}
						onBlur={event=> changeMeasurement(event, info)}
					/>
				</>

			);
		} else if (initialMeasurementStructure[info.key].computed && !info.isChild) {
			const computedValue = computedMeasurements.computedValuesMap[initialMeasurementStructure[info.key].key](tableData);
			return (<Box p="4px 8px" textAlign="right">
				{computedValue ? formatValuePrecision(computedValue,PrecisionMap[initialMeasurementStructure[info.key].key] || 0) : '-'}
			</Box>
			);
		} else {
			return (
				<Box p="4px 8px" textAlign="right">
					{info.value}
				</Box>
			)
		}
	}

	const changeMeasurement = (event: React.FocusEvent<HTMLInputElement, Element>, info: TableDataItem) => {
		const inputValue = event.target.value;
		const targetMeasurementEntity = measurementsData.find(item => item.id === info.id);	
		if (targetMeasurementEntity) {
			targetMeasurementEntity.value = parseFloat(inputValue);
			setMeasurementsData(measurementsData);
			setTableData(() => formatStudyData(measurementsData, measurementStructure, newMeasurementData));
		}
	}
	
	const blurNewMeasurementinput = (event: React.FocusEvent<HTMLInputElement, Element>) => {
		if (newMeasurementData) {
			newMeasurementData.value = parseFloat(event.target.value);
			setNewMeasurementsData(newMeasurementData);
		}
	}

	const updateMeasurmentType = function (measurement: TableDataItem, type: string) {
		if (['Max','Min','Average'].includes(type)) {
			const updatedMeasurementData = measurementsData.map(dataItem => {
				if (dataItem.name === measurement.name) {
					if (!measurementStructure[measurement.name]) {
						measurementStructure[measurement.name] = initialMeasurementStructure[measurement.name];
					}
					measurementStructure[measurement.name].defaultValue = type;

					dataItem.isPrimary = false;
				}
				return dataItem;
			});
			setMeasurementsData(updatedMeasurementData);
			setMeasurementStructure(measurementStructure);
			setTableData(() => formatStudyData(measurementsData, measurementStructure, newMeasurementData));
		}

	}

	const formatUnit = (info: TableDataItem) =>{
		if (info.unit) {
			return <Box>{info.unit.replace('m2', 'm²')}</Box>
		} else {
			return null;
		}
	}

	const openDeleteMeasurementDialog = (tableRowData: TableDataItem) => {
		const selectedMeasurement = measurementsData.find((measurement: MeasurementEntity) => measurement.id === tableRowData.id);
		if (selectedMeasurement) {
			setDeletedMeasurementsData(selectedMeasurement);
			setDeletedMeasurementTableData(tableRowData);
			setDeleteMeasurementModalOpen(true);
		}
	}

	const selectSingleMeasurement = (measurement: TableDataItem) => {
		const updatedMeasurementData = measurementsData.map(dataItem => {
			if (dataItem.name === measurement.key) {
				if (!measurementStructure[measurement.name]) {
					measurementStructure[measurement.name] = initialMeasurementStructure[measurement.name];
				}
				measurementStructure[measurement.key].defaultValue = 'Single';
				dataItem.isPrimary = dataItem.id === measurement.id;
			}
			return dataItem;
		});
		setMeasurementsData(updatedMeasurementData);
		setMeasurementStructure(measurementStructure);
		setTableData(() => formatStudyData(measurementsData, measurementStructure, newMeasurementData));
	}

	const addMeasurement = (measurement: TableDataItem, event: React.MouseEvent<SVGElement, MouseEvent>) => {
		event.preventDefault();
		event.stopPropagation();

		setCreateMeasurementModalType(measurement.name);

		setCreateMeasurementSelection(measurement.key);
		setCreateMeasurementValue('');
		setCreateMeasurementModalOpen(true);
	}

	const removeTempMeasurement = () => {
		if (newMeasurementData) {
			const newMeasurmentIndex = measurementsData.findIndex(measurement => newMeasurementData.id === measurement.id);
			measurementsData.splice(newMeasurmentIndex, 1);
			setMeasurementsData(measurementsData);
			setTableData(formatStudyData(measurementsData, measurementStructure));
		}
	}
	
	const saveNewMeasurement = () => {
		setNewMeasurementModalOpen(false);
		
		const measurementToUpdate = new MeasurementEntity(newMeasurementData);

		measurementToUpdate?.save().then(res => {
			setTableData(formatStudyData(measurementsData, measurementStructure));
		});
	}

	const deleteMeasurement = () => {
		setDeleteMeasurementModalOpen(false);
		
		const removeMeasurement = new MeasurementEntity(deletedMeasurementData);

		removeMeasurement?.delete().then(res => {
			table.toggleAllRowsExpanded(false);
			const newData = measurementsData.filter(item=>item.id !== removeMeasurement.id);
			setMeasurementsData([...newData]);
			setTableData(() => formatStudyData([...newData], measurementStructure));
			toast({
				title: 'Delete Successful',
				description: 'Measurement Deleted',
				position: 'bottom-right',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});

			measurementFetch();
		})
	}

	const saveChanges = () => {
		if (props.study) {
			props.study.measurementss = measurementsData.map(data => new MeasurementEntity(data));
			Object.keys(initialMeasurementStructure).forEach(measurementKey => {
				const measurementMetadata = initialMeasurementStructure[measurementKey];
				if (measurementMetadata.computed) {
					let dependantsAvailable = true;
					if (measurementMetadata.dependants) {
						measurementMetadata.dependants.forEach(function(key: string){
							const measurement = props.study.measurementss.find(element => element.name === key);
							if (!measurement) {
								dependantsAvailable = false;
							}
						});
					}
					if (dependantsAvailable) {
						const value = computedMeasurements.computedValuesMap[measurementMetadata.key](tableData);
						const existingMeasurement = props.study.measurementss.find(measurement => measurement.name === measurementKey);
						if (!existingMeasurement && (value || value === 0)) {
							const newMeasurmentEntity = new MeasurementEntity({
								unit: DefaultUnitMap[measurementMetadata.key],
								name: measurementKey,
								studyId: props.study.id,
								study: props.study,
								value: parseFloat(value),
							});
							newMeasurmentEntity.save().then(()=>{
								props.study.measurementss.push(newMeasurmentEntity);
							});
						} else if (existingMeasurement) {
							existingMeasurement.value = value;
						}
					}
				}
			});
			props.study.measurementStructure = JSON.stringify(measurementStructure);
			props.study.save({measurementss:props.study.measurementss, measurementStructure:JSON.stringify(measurementStructure)}).then(res => {
				toast({
					title: 'Update Successful',
					description: 'Measurements Updated',
					position: 'bottom-right',
					status: 'success',
					duration: 5000,
					isClosable: true,
				});
				setMeasurementsData(props.study.measurementss);
				measurementFetch();
				if (props.refetch) {
					props.refetch();
				}
			}).catch(response => {
				const errorMessages = getErrorMessages(response).map((error: any) => (
					<p>{error.message}</p>
				));
				toast({
					title: 'Update failed',
					description: errorMessages,
					position: 'bottom-right',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			});
		}
	}

	const openCreateMeasurmentDialog = () => {
		setCreateMeasurementSelection('');
		setCreateMeasurementValue('');
		setCreateMeasurementModalType('');
		const optionsArray = Object.keys(initialMeasurementStructure).filter(key => initialMeasurementStructure[key].group === currentTab && !initialMeasurementStructure[key].computed).map(key => ({
			'label' : initialMeasurementStructure[key].name,
			'key' : key,
		}));
		setCreateMeasurementOptions(optionsArray);
		setCreateMeasurementSelection(optionsArray[0].key);
		setCreateMeasurementModalOpen(true);
	}

	const createNewMeasurement = () => {
		if (initialMeasurementStructure[createMeasurementSelection] !== undefined) {
			const newMeasurement = new MeasurementEntity({
				unit: DefaultUnitMap[initialMeasurementStructure[createMeasurementSelection].key],
				name: createMeasurementSelection,
				studyId: props.study.id,
				value: parseFloat(createMeasurementValue)
			});
			newMeasurement.save().then(res => {
				measurementsData.push(newMeasurement)
				setMeasurementsData(measurementsData);
				setTableData(formatStudyData(measurementsData, measurementStructure));
				setCreateMeasurementModalOpen(false);
				measurementFetch();
				toast({
					title: 'Update Successful',
					description: 'New Measurement Created',
					position: 'bottom-right',
					status: 'success',
					duration: 5000,
					isClosable: true,
				});
			})
		}
 		
	}

	return (<>
		<Tabs  bgColor={colorTheme.background} color={colorTheme.text} margin="16px 16px 0 16px" width="100%">
			<TabList flexWrap="wrap">
				{groupLabels.map(label => <Tab 
				 _selected={{
						borderBottom: '2px solid',
						color: 'blue.400',
						borderBottomColor: 'blue.400'
				  }}
				  _active={{
						background: 'transparent'
				  }}
				  whiteSpace="nowrap" key={label} onClick={() => setCurrentTab(label)}>{label}</Tab>)}
			</TabList>
		</Tabs>
		<Table bgColor={colorTheme.background} color={colorTheme.text} colorScheme="blackAlpha" margin="0 16px 0 16px" size="sm" variant="striped">
			<Thead>
				{table.getHeaderGroups().map(headerGroup => (
					<Tr key={headerGroup.id}>
						{headerGroup.headers.map(header => (
							<Th
								key={header.id}
								color="#eee"
								p="6px 14px"
								width={['measurement','type'].includes(header.id.toLowerCase()) ? '120px' : 'unset'}
							>
								{flexRender(
									header.column.columnDef.header,
									header.getContext()
								)}
								<chakra.span pl="4">
									{header.column.getIsSorted() ? (
										header.column.getIsSorted() === 'desc' ? (
											<TriangleDownIcon aria-label="sorted descending" />
										) : (
											<TriangleUpIcon aria-label="sorted ascending" />
										)
									) : null}
								</chakra.span>
							</Th>
						))}
					</Tr>
				))}
			</Thead>
			<Tbody>
				{table.getRowModel().rows.map(row => {
					if (row.original.isTempRow) {
						return( 
							<Tr key={row.original.id + (row.original.isChild ? '-isChild' : '')} display={currentTab === row.original.group ? 'table-row' : 'none'}>
								<Td>
									<Box display="inline-block" width="30px"></Box>
											
									<Input
										m="0"
										type="text"
										size="sm"
										display="inline-block"
										width="calc(100% - 30px)"
										className="ignore-styles"
										variant="outline"
										defaultValue={row.original.value || 0}
										onBlur={event => blurNewMeasurementinput(event)}
									/>
								</Td>
								<Td></Td>
								<Td></Td>
								<Td></Td>
								<Td>{renderActions(row.original)}</Td>
							</Tr>
						)
					} else {
						return (
							<Tr  key={row.original.id + (row.original.isChild ? '-isChild' : '')}  display={currentTab === row.original.group ? 'table-row' : 'none'} onClick={()=>{row.original.subRows && row.original.subRows.length > 1 ? row.toggleExpanded() : ''}}>
								{row.getAllCells().map(cell =>  (
									<Td key={cell.id}>
										<Box display={cell.column.columnDef.header == 'Name' ? 'inline-block' : 'none'} width="30px" cursor="pointer">
											{RenderChevron(row)}
										</Box>
										{row.getValue(cell.column.id)}
									</Td>
								))}
							</Tr>
						);
					}
				})}
			</Tbody>
		</Table>
		<Flex width="100%" justifyContent="space-between" alignItems="center" p="8px" margin="0 16px 16px 16px"  bgColor={colorTheme.background} color={colorTheme.text}>
			<Button colorScheme="blue" isDisabled={!hasEditAccess} onClick={openCreateMeasurmentDialog}>Add Measurement</Button>
			<Button colorScheme="blue"  onClick={saveChanges}>Save</Button>
		</Flex>
		
		<Modal isOpen={newMeasurementModelOpen} onClose={()=> setNewMeasurementModalOpen(false)}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Confirm New Measurement</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
						Are you sure you want to add a new {newMeasurementData ? newMeasurementData.name: ''} measurement to this study?
				</ModalBody>

				<ModalFooter>
					<Button variant='ghost' onClick={()=> setNewMeasurementModalOpen(false)}>Cancel</Button>
					<Button colorScheme='blue' mr={3} onClick={saveNewMeasurement}>
						Save
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>

		<Modal isOpen={createMeasurementModelOpen} onClose={()=> setCreateMeasurementModalOpen(false)}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Add New Measurement</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{createMeasurementModelType === '' ?
						<Box> Measurment Type:
							<Select size='sm' variant="outline" className="ignore-styles" onChange={e => setCreateMeasurementSelection(e.target.value)}>
								{createMeasurementOptions.map(option => <option value={option.key} key={option.key}>{option.label}</option>)}
							</Select>
						</Box>
						: 
						<Flex justifyContent="space-between">
							<Box>Measurment Type:</Box>
							<Box fontWeight="600" mr="8px">{createMeasurementModelType}</Box>
						</Flex>
					}

						Value: 
					<InputGroup>
						<Input
							m="0"
							type="text"
							size="sm"
							className="ignore-styles"
							variant="outline"
							defaultValue=""
							onBlur={event=> setCreateMeasurementValue(event.target.value)}
						/>
						<InputRightElement 
							height="100%"
							background="#ebebeb"
							color="#353535"
							fontWeight="600"
							fontSize="14px"
							width="auto"
							minWidth="30px"
							padding="0 4px 3px 4px"
						>
							{createMeasurementSelection ? DefaultUnitMap[initialMeasurementStructure[createMeasurementSelection].key] : ''}
						</InputRightElement>
					</InputGroup>
				</ModalBody>
				<ModalFooter>
					<Button variant='ghost' onClick={()=> setCreateMeasurementModalOpen(false)}>Cancel</Button>
					<Button colorScheme='blue' mr={3} onClick={createNewMeasurement}>
							Create Measurement
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>

		<Modal isOpen={deleteMeasurementModelOpen} onClose={()=> setDeleteMeasurementModalOpen(false)}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Confirm Delete Measurement</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
						Are you sure you want to delete {deletedMeasurementTableData ? deletedMeasurementTableData.label + ' from ' + deletedMeasurementTableData.key : 'a measurement'} from this study?
				</ModalBody>

				<ModalFooter>
					<Button variant='ghost' onClick={()=> setDeleteMeasurementModalOpen(false)}>Cancel</Button>
					<Button colorScheme='blue' mr={3} onClick={deleteMeasurement}>
						Confirm
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	</>
	);
}