import React, { useRef, useState } from 'react';
import { observable, toJS, runInAction } from 'mobx';
import { inject, observer, useLocalStore } from 'mobx-react';
import { store } from 'Models/Store';
import { ModelCollection } from 'Views/Components/ModelCollection/ModelCollection';
import * as Models from 'Models/Entities';
import moment from 'moment';
import { ApiQueryParams, IOrderByCondition } from 'Views/Components/ModelCollection/ModelAPIQuery';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import { PaginationQueryOptions } from 'Models/PaginationData';
import { IFilter } from 'Views/Components/Collection/CollectionFilterPanel';
import { accessLevel, accessLevelOptions, userTypeOptions } from 'Models/Enums';
import { makeEnumFetchFunction } from 'Util/EntityUtils';
import { debounce } from 'lodash';
import { ICollectionItemActionProps } from 'Views/Components/Collection/Collection';
import { Button, Colors, Display, Sizes } from 'Views/Components/Button/Button';
import alert from 'Util/ToastifyUtils';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { SERVER_URL } from 'Constants';
import Axios from 'axios';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { getEmailPattern, isEmail } from 'Validators/Functions/Email';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	FormControl,
	Input,
	FormLabel,
	FormErrorMessage,
	Select,
	Button as ChakraButton,
	ButtonGroup as ChakraButtonGroup,
	useToast,

} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

type transformFn<T> = (item: T, name: string) => (string | React.ReactNode);
interface IUserSiteRequestCrudTileProps {
	orderBy?: IOrderByCondition<Models.UserSiteRequestEntity>;
	perPage?: number;
	
	children?: React.ReactNode;
}

const defaultSendSiteAccessState = {
	email: '',
	site: null,
	accessLevel: 0,
	includeAccessLevel: false,
	errors: {
		email: '',
		site: '',
		accessLevel: '',
	},
};

type SendSiteAccessFormValues = {
	email: string;
	site: string;
	accessLevel : string;
};

const fieldErrorMessages = {
	site: 'Site is required.',
	email: 'Email address is required.',
	accessLevel: 'Access Level is required',
};

const ModalContents = observer(() => {
	const sendSiteAccessState = useLocalStore(() => (
		{ 
			formState: defaultSendSiteAccessState, 
			userDetails: {
				name: '',
				type: '',
			},
			confirmSubmission: false,
		}
	));

	const siteOptions = {};

	if (store.userGroups.map(ug => ug.name).includes('SuperAdmin')) {
		store.sites.forEach(site => {
			if (site.id && site.siteName) {
				siteOptions[site.id] = site.siteName;
			}
		});
	} else {
		store.userSiteAccess.forEach(usa => {
			const jsUsa = toJS(usa);
			if (jsUsa.siteId && jsUsa.site) {
				if (jsUsa.site.siteName) {
					siteOptions[jsUsa.siteId] = jsUsa.site.siteName;
				}
			}
		});
	}

	const validateField = (field: string) => {
		if (sendSiteAccessState.formState[field] === '' || sendSiteAccessState.formState[field] === null) {
			if (field === 'accessLevel' && !sendSiteAccessState.formState.includeAccessLevel) {
				// @ts-ignore
				runInAction(() => delete sendSiteAccessState.formState.errors[field]);
			} else {
				runInAction(() => sendSiteAccessState.formState.errors[field] = fieldErrorMessages[field]);
			}
		} else if (field === 'email' && !isEmail(sendSiteAccessState.formState.email)) {
			runInAction(() => sendSiteAccessState.formState.errors[field] = 'Not a valid email.');
		} else {
			runInAction(() => delete sendSiteAccessState.formState.errors[field]);
		}
	};

	const onSubmit = (event: any) => {
		event.preventDefault();
		validateField('email');
		validateField('site');
		validateField('accessLevel');

		if (Object.keys(sendSiteAccessState.formState.errors).length === 0) {
			Axios.post(
				`${SERVER_URL}/api/entity/UserSiteRequestEntity/SendSiteAccess`,
				{
					siteId: sendSiteAccessState.formState.site,
					email: sendSiteAccessState.formState.email,
					accessLevel: sendSiteAccessState.formState.accessLevel,
				},
			).then(data => {
				alert(
					<div className="send-success">
						<p className="success">{`Sent site invite to ${sendSiteAccessState.formState.email}`}</p>
					</div>, 
					'success',
				);
				store.modal.hide();
			}).catch(error => {
				alert(
					<div className="send-failure">
						<p className="error">{`Unable to send access key to ${sendSiteAccessState.formState.email}`}</p>
						<p className="message">{error.message}</p>
					</div>, 
					'error',
				);
			});
		}
	};

	return (
		<div className="reqest__modal">
			<h4>Send Site Access</h4>
			<p>Send unique site ID to a user.</p>
			<form className="request" onSubmit={onSubmit}>
				<Combobox
					model={sendSiteAccessState.formState}
					className="request-site"
					modelProperty="site"
					label="Select Site"
					isRequired
					errors={sendSiteAccessState.formState.errors.site}
					options={makeEnumFetchFunction(siteOptions)}
				/>
				<TextField 
					model={sendSiteAccessState.formState}
					id="request_email"
					className="request-email"
					modelProperty="email"
					label="Email address"
					onAfterChange={e => runInAction(() => {
						Axios.get(`${SERVER_URL}/api/entity/UserEntity/CheckUserEmail/${e.target.value}`)
							.then(data => {
								runInAction(() => {
									if (data.data !== '') {
										sendSiteAccessState.formState.includeAccessLevel = true;
										sendSiteAccessState.userDetails.name = data.data.name;
										sendSiteAccessState.userDetails.type = data.data.userType;
									} else {
										sendSiteAccessState.formState.includeAccessLevel = false;
									}
								});
							});
					})}
					isRequired
					errors={sendSiteAccessState.formState.errors.email} 
				/>
				{sendSiteAccessState.formState.includeAccessLevel && (
					<>
						<p className="user-exists">
							<span className="user-label">User already exists:</span>
							<span className="user-name">{`${sendSiteAccessState.userDetails.name}`}</span>
							<span className="user-type">{` - ${sendSiteAccessState.userDetails.type}`}</span>
						</p>
						<Combobox
							model={sendSiteAccessState.formState}
							className="request-accessLevel"
							modelProperty="accessLevel"
							label="Select Access Level"
							isRequired
							errors={sendSiteAccessState.formState.errors.accessLevel}
							options={makeEnumFetchFunction(accessLevelOptions)}
						/>
					</>
				)}
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
					<Button 
						colors={Colors.Primary} 
						display={Display.Outline} 
						sizes={Sizes.Medium} 
						buttonProps={{ id: 'cancel' }} 
						onClick={() => store.modal.hide()}
					>
						Cancel
					</Button>
					{!sendSiteAccessState.confirmSubmission ? (
						<Button 
							colors={Colors.Primary} 
							display={Display.Solid} 
							sizes={Sizes.Medium}
							onClick={e => {
								e.preventDefault();
								runInAction(() => sendSiteAccessState.confirmSubmission = true);
							}}
						>
							Send access ID
						</Button>
					) : (
						<Button 
							type="submit"
							colors={Colors.Primary} 
							display={Display.Solid} 
							sizes={Sizes.Medium} 
							buttonProps={{ id: 'submit' }} 
						>
							Are you sure?
						</Button>
					)}
				</ButtonGroup>
			</form>
		</div>
	);
});

const UserSiteRequestCrudTile = (props: IUserSiteRequestCrudTileProps) => {
	const [search, setSearch] = useState({ searchTerm: '' });
	const [isModalOpen, setIsModalOpen] = useState(false);
	const toast = useToast();
	
	const collectionRef = useRef<ModelCollection<Models.UserSiteRequestEntity> | null>(null);
	const defaultOrderBy: IOrderByCondition<Models.UserSiteRequestEntity> = { path: 'created', descending: true };
	const orderBy: IOrderByCondition<Models.UserSiteRequestEntity> | undefined = observable(defaultOrderBy || props.orderBy);
	const paginationQueryOptions: PaginationQueryOptions = observable(new PaginationQueryOptions());

	const tranformUserName: transformFn<Models.UserSiteRequestEntity> = (userSiteRequest: Models.UserSiteRequestEntity) => userSiteRequest?.userRequestingSite?.name;
	const tranformSiteName: transformFn<Models.UserSiteRequestEntity> = (userSiteRequest: Models.UserSiteRequestEntity) => userSiteRequest?.siteRequested?.siteName;
	const transformUserType: transformFn<Models.UserSiteRequestEntity> = (userSiteRequest: Models.UserSiteRequestEntity) => userTypeOptions[userSiteRequest?.userRequestingSite?.userType];
	const transformAccessLevel: transformFn<Models.UserSiteRequestEntity> = (userSiteRequest: Models.UserSiteRequestEntity) => accessLevelOptions[userSiteRequest?.accessLevel];

	const {
		register: registerAccessForm,
		formState: { errors: accessFormErrors },
		handleSubmit: handleAccessFormSubmit,
	} = useForm<SendSiteAccessFormValues>();

	const sendSiteAccessState = useLocalStore(() => (
		{ 
			formState: defaultSendSiteAccessState, 
			userDetails: {
				name: '',
				type: '',
			},
			confirmSubmission: false,
		}
	));
	
	const approve = (usr: Models.UserSiteRequestEntity) => {
		const usa = new Models.UserSiteAccessEntity();
		usa.site = usr.siteRequested;
		usa.siteId = usr.siteRequestedId;
		usa.user = usr.userRequestingSite;
		usa.userId = usr.userRequestingSiteId;
		usa.accessLevel = usr.accessLevel;

		usa.save().then(() => {
			alert(
				<div className="approve-success">
					<p className="success">{`${usr.userRequestingSite.name} granted ${usr.accessLevel} to ${usr.siteRequested.siteName}`}</p>
				</div>, 
				'success',
			);
			Axios.post(
				`${SERVER_URL}/api/entity/UserSiteRequestEntity/ApproveSiteRequest`,
				{
					id: usr.siteRequestedId,
					email: usr.userRequestingSite.email,
					accessLevel: usr.accessLevel,
					approved: true,
				},
			);
			usr.delete().then(() => {
				collectionRef.current?.refetch();
			});
		}).catch(error => {
			alert(
				<div className="approve-error">
					<p className="error">Failed to grant access</p>
					<p className="internal-error-title">Message:</p>
					<p className="internal-error">{error}</p>
				</div>, 
				'error',
			);
		});
	};

	const reject = (usr: Models.UserSiteRequestEntity) => {
		Axios.post(
			`${SERVER_URL}/api/entity/UserSiteRequestEntity/ApproveSiteRequest`,
			{
				id: usr.siteRequestedId,
				email: usr.userRequestingSite.email,
				accessLevel: usr.accessLevel,
				approved: false,
			},
		);

		usr.delete().then(() => {
			collectionRef.current?.refetch();
			alert(
				<div className="reject-success">
					<p className="success">Request rejected</p>
				</div>, 
				'success',
			);	
		}).catch((error: any) => {
			alert(
				<div className="approve-error">
					<p className="error">Failed to reject request</p>
					<p className="internal-error-title">Message:</p>
					<p className="internal-error">{error}</p>
				</div>, 
				'error',
			);
		});
	};

	const getHeaders = (): Array<ICollectionHeaderProps<Models.UserSiteRequestEntity>> => [
		{
			name: 'name',
			displayName: 'Name',
			sortable: true,
			transformItem: tranformUserName,
		},
		{
			name: 'site',
			displayName: 'Site',
			sortable: true,
			transformItem: tranformSiteName,
		},
		{
			name: 'userType',
			displayName: 'User Type',
			sortable: true,
			transformItem: transformUserType,
		},
		{
			name: 'accessLevel',
			displayName: 'Access Level',
			sortable: true,
			transformItem: transformAccessLevel,
		},
	];

	const getActions = () => {
		const tableActions: Array<ICollectionItemActionProps<Models.UserSiteRequestEntity>> = [];
		
		tableActions.push({
			action: item => {},
			label: 'Approve',
			customButton: (usr: Models.UserSiteRequestEntity) => (
				<Button 
					colors={Colors.Success}
					display={Display.Text} 
					sizes={Sizes.Small} 
					buttonProps={{ id: 'approve' }} 
					onClick={() => approve(usr)}
					icon={{ icon: 'check', iconPos: 'icon-left' }}
				>
					Approve
				</Button>
			),
		});

		tableActions.push({
			action: item => {},
			label: 'Reject',
			customButton: (usr: Models.UserSiteRequestEntity) => (
				<Button 
					colors={Colors.Error}
					display={Display.Text} 
					sizes={Sizes.Small} 
					buttonProps={{ id: 'reject' }} 
					onClick={() => reject(usr)}
					icon={{ icon: 'cross', iconPos: 'icon-left' }}
				>
					Reject
				</Button>
			),
		});

		return tableActions;
	};

	const getMoreParamsFromFilters = (filters: Array<IFilter<Models.UserSiteRequestEntity>>): ApiQueryParams => {
		const params = {};

		const accessFilter = filters.find(f => f.path === 'accessLevel' && !!f.value1);
		if (accessFilter) {
			if ((accessFilter.value1 as string[]).includes('VIEW_ONLY')) {
				params['viewOnly'] = true;
			}
			if ((accessFilter.value1 as string[]).includes('FULL_ACCESS')) {
				params['fullAccess'] = true;
			}
		}

		const userTypeFilter = filters.find(f => f.path === 'userType' && !!f.value1);
		if (userTypeFilter) {
			if ((userTypeFilter.value1 as string[]).includes('CLIENT_DEVICE')) {
				params['clientDevice'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('SITE_ADMIN')) {
				params['siteAdmin'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('SITE_USER')) {
				params['siteUser'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('REPORTING_DOCTOR')) {
				params['reportingDoctor'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('CARDIAC_SCIENTIST')) {
				params['cardiacScientist'] = true;
			}
		}

		const siteFilter = filters.find(f => f.path === 'site' && !!f.value1 && (f.value1 as []).length !== 0);
		if (siteFilter) {
			params['siteFilter'] = JSON.stringify(siteFilter.value1);
		}

		return params;
	};

	const onSearchTriggered = (searchTerm: string) => {
		setSearch({ searchTerm });
	};

	const getMoreParams = (filters?: Array<IFilter<Models.UserSiteRequestEntity>>, filterApplied?: boolean): ApiQueryParams => {
		const filterParams = (filters && filterApplied) ? getMoreParamsFromFilters(filters) : {};

		if (!store.userGroups.map(ug => ug.name).includes('SuperAdmin') && !store.userGroups.map(ug => ug.name).includes('Super Administrators')) {
			filterParams['siteIds'] = JSON.stringify(store.userSiteAccess.map(usa => usa.siteId));
		}

		if (search.searchTerm) {
			filterParams['searchStr'] = search.searchTerm;
		}

		return filterParams;
	};

	const additionalActions = (): React.ReactNode[] => [
		<Button
			key="send-site-access"
			className={Display.Solid}
			icon={{ icon: 'create', iconPos: 'icon-left' }}
			buttonProps={{ onClick: () => setIsModalOpen(true) }}
		>
				Send site access
		</Button>,
	];

	const getFilters = (): Array<IFilter<Models.UserSiteRequestEntity>> => {
		const accessFilter: IFilter<Models.UserSiteRequestEntity> = {
			path: 'accessLevel',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Access Level',
			enumResolveFunction: makeEnumFetchFunction(accessLevelOptions),
		};

		const userTypeFilter: IFilter<Models.UserSiteRequestEntity> = {
			path: 'userType',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'User Type',
			enumResolveFunction: makeEnumFetchFunction(userTypeOptions),
		};

		const siteOptions = {};
		if (store.userGroups.map(ug => ug.name).includes('SuperAdmin')) {
			store.sites.forEach(site => {
				if (site.siteName) {
					siteOptions[site.id] = site.siteName;
				}
			});
		} else {
			store.userSiteAccess.forEach(usa => {
				const jsUsa = toJS(usa);
				if (jsUsa.siteId && jsUsa.site) {
					if (jsUsa.site.siteName) {
						siteOptions[jsUsa.siteId] = jsUsa.site.siteName;
					}
				}
			});
		}

		const siteFilter: IFilter<Models.UserSiteRequestEntity> = {
			path: 'site',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Site',
			enumResolveFunction: makeEnumFetchFunction(siteOptions)
		};

		return [accessFilter, siteFilter, userTypeFilter];
	};

	const onAccessFormSubmit = (formData: SendSiteAccessFormValues) => {

		Axios.post(
			`${SERVER_URL}/api/entity/UserSiteRequestEntity/SendSiteAccess`,
			{
				siteId: formData.site,
				email: formData.email,
				accessLevel: formData.accessLevel,
			},
		).then(data => {
			toast({
				title: 'Success',
				description: `Sent site invite to ${formData.email}`,
				position: 'bottom-right',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
			setIsModalOpen(false)
		}).catch(error => {
			toast({
				title: 'Send failed',
				description: error.message,
				position: 'bottom-right',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		});
	};

	const siteOptions = {};

	if (store.userGroups.map(ug => ug.name).includes('SuperAdmin')) {
		store.sites.forEach(site => {
			if (site.id && site.siteName) {
				siteOptions[site.id] = site.siteName;
			}
		});
	} else {
		store.userSiteAccess.forEach(usa => {
			const jsUsa = toJS(usa);
			if (jsUsa.siteId && jsUsa.site) {
				if (jsUsa.site.siteName) {
					siteOptions[jsUsa.siteId] = jsUsa.site.siteName;
				}
			}
		});
	}
	
	return (
		<div>
			<ModelCollection
				ref={collectionRef}
				url="/api/entity/UserSiteRequestEntity/userSiteRequest_list"
				searchStr={search.searchTerm}
				isApiQuery
				orderBy={orderBy}
				model={Models.UserSiteRequestEntity}
				headers={getHeaders()}
				actions={getActions()}
				selectableItems={false}
				onSearchTriggered={debounce(onSearchTriggered, 200)}
				perPage={paginationQueryOptions.perPage}
				idColumn="id"
				dataFields={row => ({
					created: moment(row.created).format('YYYY-MM-DD'),
					modified: moment(row.modified).format('YYYY-MM-DD'),
				})}
				getMoreParams={getMoreParams}
				filters={getFilters()}
				additionalActions={additionalActions()}
				dateFormat="d/m/Y"
				altFormat="d/m/Y"
			/>
			<Modal isOpen={isModalOpen} onClose={()=>setIsModalOpen(false)} size="xl" isCentered>
				<ModalOverlay />
				<ModalContent maxH="100%" maxW="50%">
					<form className="request" onSubmit={handleAccessFormSubmit(onAccessFormSubmit)}>
						<ModalHeader>Send Site Access</ModalHeader>
						<ModalCloseButton />
						<ModalBody maxHeight="80vh" w="50vw">
							<p>Send unique site ID to a user.</p>
							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!accessFormErrors.site}
								id="studyType"
								width="100%"
								margin="8px 8px 8px 0"
							>
								<Select 
									width="100%"
									height="42px"
									{...registerAccessForm('site', {
										required: true,
									})}>
									{makeEnumFetchFunction(siteOptions).map(option => (<option key={option.value} value={option.value}>{option.display}</option>))}
								</Select>

								<FormLabel margin="-4px 0 0 0">Site</FormLabel>
							</FormControl>
							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!accessFormErrors.email}
								id="login_username"
							>
								<Input
									type="text"
									size="lg"
									{...registerAccessForm('email', {
										required: true,
										pattern: getEmailPattern(),
									})}
									onChange={e => runInAction(() => {
										Axios.get(`${SERVER_URL}/api/entity/UserEntity/CheckUserEmail/${e.target.value}`)
											.then(data => {
												runInAction(() => {
													if (data.data !== '') {
														sendSiteAccessState.formState.includeAccessLevel = true;
														sendSiteAccessState.userDetails.name = data.data.name;
														sendSiteAccessState.userDetails.type = data.data.userType;
													} else {
														sendSiteAccessState.formState.includeAccessLevel = false;
													}
												});
											});
									})}
								/>
								<FormLabel>Email Address</FormLabel>
								{accessFormErrors.email?.type === 'required' ? (
									<FormErrorMessage>Email address is required</FormErrorMessage>
								) : (
									''
								)}
								{accessFormErrors.email?.type === 'pattern' ? (
									<FormErrorMessage>Email format invalid</FormErrorMessage>
								) : (
									''
								)}
							</FormControl>

							{sendSiteAccessState.formState.includeAccessLevel && (
								<>
									<p className="user-exists">
										<span className="user-label">User already exists:</span>
										<span className="user-name">{`${sendSiteAccessState.userDetails.name}`}</span>
										<span className="user-type">{` - ${sendSiteAccessState.userDetails.type}`}</span>
									</p>
									<Select 
										width="100%"
										height="42px"
										{...registerAccessForm('accessLevel', {
											required: sendSiteAccessState.formState.includeAccessLevel,
										})}>
										{makeEnumFetchFunction(accessLevelOptions).map(option => (<option key={option.value} value={option.value}>{option.display}</option>))}
									</Select>
								</>
							)}
						</ModalBody>
						<ModalFooter>
							<ChakraButtonGroup mb="2" width="100%" justifyContent="space-between">
								<ChakraButton 
									variant='ghost'
									colorScheme="outline"
									onClick={()=>{setIsModalOpen(false)}}>
							Close
								</ChakraButton>
								{!sendSiteAccessState.confirmSubmission ? (
									<ChakraButton 
										colorScheme='blue'
										onClick={e => {
											e.preventDefault();
											runInAction(() => sendSiteAccessState.confirmSubmission = true);
										}}
									>
							Send access ID
									</ChakraButton>
								) : (
									<ChakraButton 
										type="submit"
										colorScheme='blue'
									>
							Are you sure?
									</ChakraButton>
								)}
							</ChakraButtonGroup>
						</ModalFooter>
					</form>

				</ModalContent>
			</Modal>
		</div>
	);
};

export default inject('store')(observer(UserSiteRequestCrudTile));
