import { Box, Button, Heading, Flex, Modal, useToast,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter } from '@chakra-ui/react';
import { StudyEntity } from 'Models/Entities';
import React, { useEffect, useState } from 'react';
import { getErrorMessages } from 'Util/GraphQLUtils';

interface RwmaPlotViewerProps {
	study: StudyEntity;
	refetch: () => void;
}

const RwmaPlotViewer = (props: RwmaPlotViewerProps) => {
	const { study, refetch } = props;
	const toast = useToast();	
	let initialPlotData = {};
	const defaultSegmentLabels = {'ECHO':['Rest'],'STRESS_ECHO':['Rest','Post'],'DOBUTAMINE_STRESS_ECHO':['Rest','Low Dose','Peak','Recovery']}
	
	let segmentLabels = defaultSegmentLabels[study.studyType];
	if (study.patient.site.rwmaPlotLabels && JSON.parse(study.patient.site.rwmaPlotLabels)[study.studyType]) {
		segmentLabels = JSON.parse(study.patient.site.rwmaPlotLabels)[study.studyType];
	}
	
	if (!study.rwmaPlotData) {
		segmentLabels.forEach((label: string) => {
			initialPlotData[label] = {
				1 : '-',
				2 : '-',
				3 : '-',
				4 : '-',
				5 : '-',
				6 : '-',
				7 : '-',
				8 : '-',
				9 : '-',
				10 : '-',
				11 : '-',
				12 : '-',
				13 : '-',
				14 : '-',
				15 : '-',
				16 : '-',
			}
		});
	} else {
		initialPlotData = JSON.parse(study.rwmaPlotData);
	}

	const [segmentScoreModalOpen, setSegmentScoreModalOpen] = useState(false);
	const [selectedSegment, setSelectedSegment] = useState(1);
	const [selectedPlot, setSelectedPlot] = useState('');
	const [segmentScores, setSegmentScores] =  useState(initialPlotData);

	const scoreMap = {
		'1' : {
			'label' : 'Normal',
			'color' : '#3ac72d',
			'textColor' : '#000'
		},
		'2' : {
			'label' : 'Hypokinetic',
			'color' : '#ffff43',
			'textColor' : '#000'
		},
		'3' : {
			'label' : 'Akinetic',
			'color' : '#c31b14',
			'textColor' : '#fff'
		},
		'4' : {
			'label' : 'Dyskinetic',
			'color' : '#3b64f9',
			'textColor' : '#000'
		},
		'5' : {
			'label' : 'Aneurysmal',
			'color' : '#5a5a5a',
			'textColor' : '#fff'
		},
		'6' : {
			'label' : 'Akinetic with scar',
			'color' : '#f7252c',
			'textColor' : '#fff'
		},
		'7' : {
			'label' : 'Dyskinetic with scar',
			'color' : '#7aa1fb',
			'textColor' : '#000'
		},
		'x' : {
			'label' : 'x',
			'color' : '#ababab',
			'textColor' : '#000'
		},
		'-' : {
			'label' : 'Unset',
			'color' : '#ffffff',
			'textColor' : '#000'
		},
	}
	
	const openSegmentModal = (segment: number, plotLabel:string) => {
		setSelectedSegment(segment);
		setSelectedPlot(plotLabel);
		setSegmentScoreModalOpen(true);
	};

	const updateSegmentScore = (score: string) => {
		segmentScores[selectedPlot][selectedSegment] = score;
		setSegmentScores(segmentScores);
		setSegmentScoreModalOpen(false);
	}

	const resetBullseye = (plotLabel: string, score: string) => {

		Object.keys(segmentScores[plotLabel]).forEach(key => {
			segmentScores[plotLabel][key] = score
		});
		setSegmentScores({...segmentScores});
	}

	const saveStudy = () => {
		const studyObject = new StudyEntity(study);
		studyObject.rwmaPlotData = JSON.stringify(segmentScores);
		studyObject.save({rwmaPlotData:JSON.stringify(segmentScores)}).then(res => {
			toast({
				title: 'Update Successful',
				description: 'Segments Updated',
				position: 'bottom-right',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
			if (refetch) {
				refetch();
			}
		}).catch(response => {
			const errorMessages = getErrorMessages(response).map((error: any) => (
				<p>{error.message}</p>
			));
			toast({
				title: 'Update failed',
				description: errorMessages,
				position: 'bottom-right',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		});
	}
	
	
	return (
		<>
			<Box overflow="hidden" p="0 10px">
				<Flex justifyContent="space-around" alignItems="flex-start" flexWrap="wrap" maxWidth="100%" pb="100px">
					{Object.keys(segmentScores).map((segmentScoreKey: string) => {
						const segmentData = segmentScores[segmentScoreKey];

						return (
							<Flex flexDirection="column" key={segmentScoreKey} mb="10px">
								<Heading as="h4" mb="0">{segmentScoreKey}</Heading>

								<div className={'bullseyeDiagram ' + (Object.keys(segmentScores).length === 1 ? 'singleDiagram' : '')}>
									<div className="segmentLabels">
										<span className={'segmentLabel-5 segment-' + segmentData[5]}>{segmentData[5]}</span>
										<span className={'segmentLabel-6 segment-' + segmentData[6]}>{segmentData[6]}</span>
										<span className={'segmentLabel-7 segment-' + segmentData[7]}>{segmentData[7]}</span>
										<span className={'segmentLabel-8 segment-' + segmentData[8]}>{segmentData[8]}</span>
										<span className={'segmentLabel-9 segment-' + segmentData[9]}>{segmentData[9]}</span>
										<span className={'segmentLabel-10 segment-' + segmentData[10]}>{segmentData[10]}</span>
										<span className={'segmentLabel-11 segment-' + segmentData[11]}>{segmentData[11]}</span>
										<span className={'segmentLabel-12 segment-' + segmentData[12]}>{segmentData[12]}</span>
										<span className={'segmentLabel-13 segment-' + segmentData[13]}>{segmentData[13]}</span>
										<span className={'segmentLabel-14 segment-' + segmentData[14]}>{segmentData[14]}</span>
										<span className={'segmentLabel-15 segment-' + segmentData[15]}>{segmentData[15]}</span>
										<span className={'segmentLabel-16 segment-' + segmentData[16]}>{segmentData[16]}</span>
									</div>
									<div className='outerCircle'>
										<div className='outerCircleBorder'></div>
										<div className="ring"></div>
										<div onClick={()=>{openSegmentModal(11,segmentScoreKey)}} className={'arc arc-7 segment-' + segmentData[11]}></div>
										<div onClick={()=>{openSegmentModal(12,segmentScoreKey)}} className={'arc arc-8 segment-' + segmentData[12]}></div>
										<div onClick={()=>{openSegmentModal(13,segmentScoreKey)}} className={'arc arc-9 segment-' + segmentData[13]}></div>
										<div onClick={()=>{openSegmentModal(14,segmentScoreKey)}} className={'arc arc-10 segment-' + segmentData[14]}></div>
										<div onClick={()=>{openSegmentModal(15,segmentScoreKey)}} className={'arc arc-11 segment-' + segmentData[15]}></div>
										<div onClick={()=>{openSegmentModal(16,segmentScoreKey)}} className={'arc arc-12 segment-' + segmentData[16]}></div>
									</div>
									<div className='middleCircle'>
										<div onClick={()=>{openSegmentModal(5,segmentScoreKey)}} className={'arc arc-1 segment-' + segmentData[5]}></div>
										<div onClick={()=>{openSegmentModal(6,segmentScoreKey)}} className={'arc arc-2 segment-' + segmentData[6]}></div>
										<div onClick={()=>{openSegmentModal(7,segmentScoreKey)}} className={'arc arc-3 segment-' + segmentData[7]}></div>
										<div onClick={()=>{openSegmentModal(8,segmentScoreKey)}} className={'arc arc-4 segment-' + segmentData[8]}></div>
										<div onClick={()=>{openSegmentModal(9,segmentScoreKey)}} className={'arc arc-5 segment-' + segmentData[9]}></div>
										<div onClick={()=>{openSegmentModal(10,segmentScoreKey)}} className={'arc arc-6 segment-' + segmentData[10]}></div>
									</div>
									<div className="innerCircle">
										<div onClick={()=>{openSegmentModal(1,segmentScoreKey)}} className="quarter quarter1" style={{backgroundColor: scoreMap[segmentData[1]]?.color, color: scoreMap[segmentData[1]]?.textColor}}><span>{segmentData[1]}</span></div>
										<div onClick={()=>{openSegmentModal(2,segmentScoreKey)}} className="quarter quarter2" style={{backgroundColor: scoreMap[segmentData[2]]?.color, color: scoreMap[segmentData[2]]?.textColor}}><span>{segmentData[2]}</span></div>
										<div onClick={()=>{openSegmentModal(3,segmentScoreKey)}} className="quarter quarter3" style={{backgroundColor: scoreMap[segmentData[3]]?.color, color: scoreMap[segmentData[3]]?.textColor}}><span>{segmentData[3]}</span></div>
										<div onClick={()=>{openSegmentModal(4,segmentScoreKey)}} className="quarter quarter4" style={{backgroundColor: scoreMap[segmentData[4]]?.color, color: scoreMap[segmentData[4]]?.textColor}}><span>{segmentData[4]}</span></div>
										<div className="cutout"></div>
									</div>
								</div>
								<Flex width="100%" justifyContent="space-between" alignItems="center" p="0 5px">
									<Button colorScheme='blue' onClick={()=> resetBullseye(segmentScoreKey,'1')}>All Normal</Button>
									<Button colorScheme='blue' onClick={()=> resetBullseye(segmentScoreKey,'-')}>Clear All</Button>
								</Flex>
							</Flex>);
					})}

				</Flex>


				<Modal isOpen={segmentScoreModalOpen} onClose={()=> setSegmentScoreModalOpen(false)}>
					<ModalOverlay />
					<ModalContent maxWidth="600px">
						<ModalHeader>Update Segment</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Flex alignItems="center" justifyContent="center" flexWrap="wrap">
								{Object.keys(scoreMap).map(scoreMapKey => <Button margin="0 10px 8px 10px" width="150px" onClick={()=> {updateSegmentScore(scoreMapKey)}} backgroundColor={scoreMap[scoreMapKey].color} color={scoreMap[scoreMapKey].textColor} key={scoreMap[scoreMapKey].label}>{scoreMap[scoreMapKey].label}</Button>)}
							</Flex>
						</ModalBody>
						
						<ModalFooter>
							<Button variant='ghost' onClick={()=> setSegmentScoreModalOpen(false)}>Cancel</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</Box>
			<Box backgroundColor="#5c5c5c" borderRadius="5px" position="absolute" bottom="50px" right="15px" padding="12px">
				<Button float="right" colorScheme='blue' onClick={()=> saveStudy()}>Save</Button>
			</Box>
		</>
	);
}

export default RwmaPlotViewer;
