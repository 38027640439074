import {inject, observer} from 'mobx-react';
import React from 'react';
import Tabs from 'Views/Components/Tabs/Tabs';
import {withRouter, WithRouterProps} from "../../Util/withRouter";
import {Button, Colors, Display, Sizes} from "../Components/Button/Button";
import {runInAction} from "mobx";
import {SERVER_URL} from "../../Constants";
import {useNavigate} from "react-router";
import UserSiteAccessEditCrudTile from "./UserSiteAccess/UserSiteAccessEditCrudTile";
import {useParams} from "react-router-dom";
import UserSiteAccessCreateCrudTile from "./UserSiteAccess/UserSiteAccessCreateCrudTile";

const UserAccessCreateWrappingTileTile = (props: WithRouterProps) => {
	const navigate = useNavigate();
	const {ids} = useParams();
	
	const backButton = () => {
		const idArray = ids ? ids.split(',') : [];
		if(idArray.length === 0 || idArray.length > 1) {
			return(
				<Button
					colors={Colors.Primary}
					display={Display.Solid}
					sizes={Sizes.Medium}
					buttonProps={{id: 'confirm'}}
					onClick={() => runInAction(() => {
						navigate(`${SERVER_URL}/userdashboard`);
					})}
				>
					Back
				</Button>
			)
		}
		return(
			<Button
				colors={Colors.Primary}
				display={Display.Solid}
				sizes={Sizes.Medium}
				buttonProps={{id: 'confirm'}}
				onClick={() => runInAction(() => {
					navigate(`${SERVER_URL}/userdashboard/editsiteaccess/${ids}`);
				})}
			>
				Back
			</Button>
		)
	}
	
	return (
			<div className="user-dashboard__container">
				<div style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: '20px'
				}}>
					{backButton()}
				</div>
				<div>
					<UserSiteAccessCreateCrudTile/>
				</div>
			</div>
	);};
export default inject('store')(observer(withRouter(UserAccessCreateWrappingTileTile)));
