import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import {Navigate, useParams} from 'react-router';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import { useEffect } from 'react';
import { runInAction } from 'mobx';
import { store } from '../../Models/Store';
import EndSessionTile from '../Tiles/Sessions/EndSessionTile';
import { SessionEntity } from '../../Models/Entities';

const SessionPagePage = observer(() => {
	const sessionStore = useLocalStore(() => ({
		session: new SessionEntity(),
		loading: true,
	}));
	
	const params = useParams();

	useEffect(() => {
		store.apolloClient.query({
			query: SessionEntity.getFetchSession(),
			fetchPolicy: 'network-only',
			variables: {
				args: [{
					path: 'id',
					comparison: 'equal',
					value: params.id,
				}],
			},
		}).then(res => {
			runInAction(() => {
				sessionStore.session = new SessionEntity(res.data.sessionEntity);
				sessionStore.loading = false;
			});
		}).catch(err => console.log(err));
	}, []);

	if (!store.hasSessions) {
		return <Navigate to="/profile" />;
	}

	const navigationLinks = getFrontendNavLinks();
	
	if (!sessionStore.loading) {
		return (
			<SecuredPage groups={['SuperAdmin', 'User']}>
				<Navigation
					linkGroups={navigationLinks}
					orientation={Orientation.VERTICAL}
				/>

				<div className="body-content">
					{/*<EndSessionTile session={sessionStore.session} />*/}
				</div>
			</SecuredPage>
		);
	} 
	return <span>loading...</span>;
});

export default SessionPagePage;
