import { IAcl } from '../IAcl';

export class UserAssetEntity implements IAcl {
    public group?: string;

    public isVisitorAcl = false;

    public constructor() {
        this.group = 'User';
    }

    public canRead(): boolean {
        return false;
    }

    public canCreate(): boolean {
        return true;
    }

    public canUpdate(): boolean {
        return false;
    }

    public canDelete(): boolean {
        return false;
    }
}
