import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
	getModelName, getAttributes,
} from 'Util/EntityUtils';
import { VisitorsUserEntity } from 'Models/Security/Acl/VisitorsUserEntity';
import { SuperAdminUserEntity } from 'Models/Security/Acl/SuperAdminUserEntity';
import { UserUserEntity } from 'Models/Security/Acl/UserUserEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { lowerCaseFirst } from 'Util/StringUtils';
import { gql } from '@apollo/client';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';
import * as Enums from '../Enums';

import { CRUD } from '../CRUDOptions';

export interface IUserEntityAttributes extends IModelAttributes {
	email: string;
	userType: Enums.userType;
	name: string;
	initials: string;
	asarNumber: string;
	trainee: boolean;
	supervisor: boolean;
	status: Enums.statusType;
	address: string;
	suffix: string;
	prefix?: string;
	specialty?: string;
	middleName?: string;
	firstname?: string;
	lastname?: string;
	auditss: Array<Models.AuditEntity | Models.IAuditEntityAttributes>;
	sessionUsers: Array<Models.SessionEntity | Models.ISessionEntityAttributes>;
	sitess: Array<Models.UserSiteAccessEntity | Models.IUserSiteAccessEntityAttributes>;
	userSiteRequestss: Array<Models.UserSiteRequestEntity | Models.IUserSiteRequestEntityAttributes>;
	userServicess: Array<Models.UsersUserServices | Models.IUsersUserServicesAttributes>;
	eSignature?: string;
}

@entity('UserEntity', 'User')
export default class UserEntity extends Model implements IUserEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsUserEntity(),
		new SuperAdminUserEntity(),
		new UserUserEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		'email',
	];

	@Validators.Email()
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Email',
		displayType: 'displayfield',
		order: 10,
		createFieldType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public email: string;

	@Validators.Length(6)
	@observable
	@CRUD({
		name: 'Password',
		displayType: 'hidden',
		order: 20,
		createFieldType: 'password',
	})
	public password: string;

	@Validators.Custom('Password Match', (e: string, target: UserEntity) => new Promise(resolve => resolve(target.password !== e ? 'Password fields do not match' : null)))
	@observable
	@CRUD({
		name: 'Confirm Password',
		displayType: 'hidden',
		order: 30,
		createFieldType: 'password',
	})
	public _confirmPassword: string;

	/**
	 * User Type
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'User Type',
		displayType: 'enum-combobox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => AttrUtils.standardiseEnum(attr, Enums.userTypeOptions),
		enumResolveFunction: makeEnumFetchFunction(Enums.userTypeOptions),
		displayFunction: (attribute: Enums.userType) => Enums.userTypeOptions[attribute],
	})
	public userType: Enums.userType;

	/**
	 * Name
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;

	@observable
	@attribute()
	@CRUD({
		name: 'ESignature',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public eSignature?: string;

	/**
	 * Initials
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Initials',
		displayType: 'textfield',
		order: 60,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public initials: string;

	/**
	 * ASAR Number
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'ASAR Number',
		displayType: 'textfield',
		order: 70,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public asarNumber: string;

	/**
	 * Trainee
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Trainee',
		displayType: 'checkbox',
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public trainee: boolean;

	/**
	 * Supervisor
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Supervisor',
		displayType: 'checkbox',
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public supervisor: boolean;

	/**
	 * Users account status
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Status',
		displayType: 'enum-combobox',
		order: 100,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => AttrUtils.standardiseEnum(attr, Enums.statusTypeOptions),
		enumResolveFunction: makeEnumFetchFunction(Enums.statusTypeOptions),
		displayFunction: (attribute: Enums.statusType) => Enums.statusTypeOptions[attribute],
	})
	public status: Enums.statusType;

	/**
	 * Address of the user
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Address',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public address: string;

	/**
	 * Any suffix to the doctor that might be required e.g. M.D, FRCPath
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Suffix',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public suffix: string;

	/**
	 * Any prefix to the doctor that might be required e.g. M.D, FRCPath
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Prefix',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public prefix?: string;

	@observable
	@attribute()
	@CRUD({
		name: 'Specialty',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public specialty?: string;

	@observable
	@attribute()
	@CRUD({
		name: 'MiddleName',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public middleName?: string;

	@observable
	@attribute()
	@CRUD({
		name: 'FirstName',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public firstname?: string;

	@observable
	@attribute()
	@CRUD({
		name: 'LastName',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public lastname?: string;

	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Auditss',
		displayType: 'reference-multicombobox',
		order: 110,
		referenceTypeFunc: () => Models.AuditEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'auditss',
			oppositeEntity: () => Models.AuditEntity,
		}),
	})
	public auditss: Models.AuditEntity[] = [];

	/**
	 * Session User
	 */
	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Session Users',
		displayType: 'reference-multicombobox',
		order: 120,
		referenceTypeFunc: () => Models.SessionEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'sessionUsers',
			oppositeEntity: () => Models.SessionEntity,
		}),
	})
	public sessionUsers: Models.SessionEntity[] = [];

	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Sitess',
		displayType: 'reference-multicombobox',
		order: 130,
		referenceTypeFunc: () => Models.UserSiteAccessEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'sitess',
			oppositeEntity: () => Models.UserSiteAccessEntity,
		}),
	})
	public sitess: Models.UserSiteAccessEntity[] = [];

	/**
	 * User Site Requests
	 */
	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'User Site Requestss',
		displayType: 'reference-multicombobox',
		order: 140,
		referenceTypeFunc: () => Models.UserSiteRequestEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'userSiteRequestss',
			oppositeEntity: () => Models.UserSiteRequestEntity,
		}),
	})
	public userSiteRequestss: Models.UserSiteRequestEntity[] = [];

	/**
	 * User Services
	 */
	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'User Services',
		displayType: 'reference-multicombobox',
		order: 150,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.UsersUserServices,
		optionEqualFunc: makeJoinEqualsFunc('userServicesId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'userEntity',
			oppositeEntityName: 'serviceEntity',
			relationName: 'users',
			relationOppositeName: 'userServices',
			entity: () => Models.UserEntity,
			joinEntity: () => Models.UsersUserServices,
			oppositeEntity: () => Models.ServiceEntity,
		}),
	})
	public userServicess: Models.UsersUserServices[] = [];

	constructor(attributes?: Partial<IUserEntityAttributes>) {
		super(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IUserEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.userType !== undefined) {
				this.userType = attributes.userType;
			}
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.eSignature !== undefined) {
				this.eSignature = attributes.eSignature;
			}
			if (attributes.initials !== undefined) {
				this.initials = attributes.initials;
			}
			if (attributes.asarNumber !== undefined) {
				this.asarNumber = attributes.asarNumber;
			}
			if (attributes.trainee !== undefined) {
				this.trainee = attributes.trainee;
			}
			if (attributes.supervisor !== undefined) {
				this.supervisor = attributes.supervisor;
			}
			if (attributes.status !== undefined) {
				this.status = attributes.status;
			}
			if (attributes.address !== undefined) {
				this.address = attributes.address;
			}
			if (attributes.suffix !== undefined) {
				this.suffix = attributes.suffix;
			}
			if (attributes.prefix !== undefined) {
				this.prefix = attributes.prefix;
			}
			if (attributes.specialty !== undefined) {
				this.specialty = attributes.specialty;
			}
			if (attributes.middleName !== undefined) {
				this.middleName = attributes.middleName;
			}
			if (attributes.lastname !== undefined) {
				this.lastname = attributes.lastname;
			}
			if (attributes.firstname !== undefined) {
				this.firstname = attributes.firstname;
			}
			if (attributes.auditss !== undefined && Array.isArray(attributes.auditss)) {
				for (const model of attributes.auditss) {
					if (model instanceof Models.AuditEntity) {
						this.auditss.push(model);
					} else {
						this.auditss.push(new Models.AuditEntity(model));
					}
				}
			}
			if (attributes.sessionUsers !== undefined && Array.isArray(attributes.sessionUsers)) {
				for (const model of attributes.sessionUsers) {
					if (model instanceof Models.SessionEntity) {
						this.sessionUsers.push(model);
					} else {
						this.sessionUsers.push(new Models.SessionEntity(model));
					}
				}
			}
			if (attributes.sitess !== undefined && Array.isArray(attributes.sitess)) {
				for (const model of attributes.sitess) {
					if (model instanceof Models.UserSiteAccessEntity) {
						this.sitess.push(model);
					} else {
						this.sitess.push(new Models.UserSiteAccessEntity(model));
					}
				}
			}
			if (attributes.userSiteRequestss !== undefined && Array.isArray(attributes.userSiteRequestss)) {
				for (const model of attributes.userSiteRequestss) {
					if (model instanceof Models.UserSiteRequestEntity) {
						this.userSiteRequestss.push(model);
					} else {
						this.userSiteRequestss.push(new Models.UserSiteRequestEntity(model));
					}
				}
			}
			if (attributes.userServicess !== undefined && Array.isArray(attributes.userServicess)) {
				for (const model of attributes.userServicess) {
					if (model instanceof Models.UsersUserServices) {
						this.userServicess.push(model);
					} else {
						this.userServicess.push(new Models.UsersUserServices(model));
					}
				}
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = ` 
		userServicess { 
			${Models.UsersUserServices.getAttributes().join('\n')} 
			userServices { 
				${Models.ServiceEntity.getAttributes().join('\n')} 
			} 
		} 
		sessionUsers { 
			${Models.SessionEntity.getAttributes().join('\n')} 
			${Models.SessionEntity.getFiles().map(f => f.name).join('\n')} 
		} 
		sitess { 
			${Models.UserSiteAccessEntity.getAttributes().join('\n')} 
			site { 
				${Models.SiteEntity.getAttributes().join('\n')} 
			} 
		} 
		auditss { 
			${Models.AuditEntity.getAttributes().join('\n')} 
		} 
		userSiteRequestss { 
			${Models.UserSiteRequestEntity.getAttributes().join('\n')} 
		} 
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			userServicess: {},
			auditss: {},
			sessionUsers: {},
			sitess: {},
			userSiteRequestss: {},
		};

		if (formMode === 'create') {
			relationPath['password'] = {};

			if (this.password !== this._confirmPassword) {
				throw Error('Password fields do not match');
			}
		}
		return this.save(
			relationPath,
			{
				graphQlInputType: formMode === 'create'
					? `[${this.getModelName()}CreateInput]`
					: `[${this.getModelName()}Input]`,
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'auditss',
							'sessionUsers',
							'sitess',
							'userSiteRequestss',
							'userServicess',
						],
					},
				],
			},
		);
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		return this.email;
	}

	public singleQueryProfileExpands = ` 
		userServicess { 
			${Models.UsersUserServices.getAttributes().join('\n')} 
			userServices { 
				${Models.ServiceEntity.getAttributes().join('\n')} 
			} 
		} 
		sessionUsers { 
			${Models.SessionEntity.getAttributes().join('\n')} 
			${Models.SessionEntity.getFiles().map(f => f.name).join('\n')} 
		} 
		sitess { 
			${Models.UserSiteAccessEntity.getAttributes().join('\n')} 
			site { 
				${Models.SiteEntity.getAttributes().join('\n')} 
			} 
		} 
		userSiteRequestss { 
			${Models.UserSiteRequestEntity.getAttributes().join('\n')} 
		} 
	`;

	public static getFetchSingleQueryProfilePage() {
		const model = new UserEntity();
		const modelName = lowerCaseFirst(getModelName(UserEntity));

		return gql` 
			query ${modelName} ($args:[WhereExpressionGraph]) { 
				${modelName} (where: $args) { 
					${getAttributes(UserEntity).join('\n')} 
					${model.singleQueryProfileExpands} 
				} 
			}`;
	}
}
/* 
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them. 
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(UserEntity.prototype, 'created');
CRUD(modifiedAttr)(UserEntity.prototype, 'modified'); 
