import React from 'react';
import { TextArea } from 'Views/Components/TextArea/TextArea';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { StudyEntity } from '../../../Models/Entities';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import { store } from 'Models/Store';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { SERVER_URL } from 'Constants';
import NewReportPreview from '../Study/NewReportPreview';
import TestDataPreview from '../Study/HolterComponents/TestDataPreview';
import {
	Select,
	Box,
	Flex,
	Textarea
} from '@chakra-ui/react';
import axios from 'axios';

interface PreviewModalProps {
	study?: StudyEntity;
	files: any;
	pages: any;
	reportReference: string;
	basic?: boolean;
}

const PreviewModal = observer((props: PreviewModalProps) => {
	const { files, pages, reportReference, basic } = props;
	const [reportData, setReportData] = React.useState('');
	const [isLoading, setIsLoading] = React.useState(false);

	
	const getReport = () => {
		setIsLoading(true);
		axios.get(`${SERVER_URL}/api/entity/StudyEntity/EchoReportTXT/${props?.study?.id}`)
			.then(res => {
				setIsLoading(false);
				setReportData(res.data);
			})
			.catch(() => {
				setIsLoading(false);
				setReportData('Unable to load report txt data for the selected template.');
			}
			);
	}


	if (basic) {
		files.previewType = 'Text';
	}

	if (props.study && props.study.id) {
		React.useEffect(() => {
			getReport();
		},[]);
	}
	
	return (
		<div className="file-preview-modal">
			{!basic && (
				<Flex marginBottom="12px" alignItems="center">
					<Box marginRight="10px">Select a file type to preview report</Box>
					<Select
						size="lg"
						width="200px"
						display="flex"
						flexDir="column"
						onChange={event => {
							runInAction(() => files.previewType = event.target.value ? event.target.value : '')
						}
						}

					>
						<option value="PDF">PDF</option>
						<option value="Text">Text</option>

					</Select>
				</Flex>			)}
			{files.previewType === 'PDF' && (
				<>
					{!!props.study ? (
						<> {
							['HOLTERTWENTYFOURHR', 'HOLTERFORTYEIGHTHR', 'HOLTEREXTENDED'].includes(props.study.studyType) ? 
								<TestDataPreview study={props.study} /> : 
								<NewReportPreview study={props.study} /> 
						} </>
					)  :
						<div className="file-pdf-preview">
							<embed src={files[`${reportReference}PDF`] + '#view=Fit&toolbar=1'} type="application/pdf" width="100%" height="100%" />
						</div>
					}</>
			)}
			{files.previewType === 'Text' && (
				<>
					{!!props.study && !['HOLTERTWENTYFOURHR', 'HOLTERFORTYEIGHTHR', 'HOLTEREXTENDED'].includes(props.study.studyType) ? (
						<>
							{isLoading ? '' : 		
								<Textarea
									isDisabled={true}
									value={reportData}
									size="sm"
									style={{
										width: '100%',
										height: '100%',
										resize: 'none',
										margin: '0',
										overflow: 'auto',
										opacity: 1
									}}
								/>}
						</>
					) : (
						<> {
							<div className="report-txt-preview">
								<TextArea
									model={files}
									modelProperty={`${reportReference}TXT`}
									isDisabled
								/>
							</div>
						}</>)  } </>
			)}
			{!basic && (
				<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={() => store.modal.hide()}>
					Close
				</Button>
			)}
		</div>
	);
});

export default PreviewModal;