import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, ButtonGroup, Checkbox, Select } from '@chakra-ui/react';
import { store } from '../../../Models/Store';

interface SaveModalProps {
	onSave: (urgency: boolean, exit: boolean, confirm: boolean, changes?: boolean) => void;
	urgent: boolean;
	confirm: boolean;
	editable: boolean;
	holter?: boolean;
}

const SaveModal = (props: SaveModalProps) => {
	const [urgency, setUrgency] = useState<boolean>(false);
	const [selectedOption, setSelectedOption] = useState('');
	const [confirmed, setConfirmed] = useState(false);
	const {onSave, urgent, confirm, editable, holter} = props;
	
	useEffect(() => {
		setUrgency(urgent);
		if (confirm) {
			setConfirmed(confirm);
			setSelectedOption('confirmed');
		}
	}, [])

	useEffect(() => {
		if (confirmed) {
			setSelectedOption('confirmed')
		} else {
			setSelectedOption('');
		}
	}, [confirmed]);

	const handleOptionChange = (option: string) => {
		if (editable) {
			if (option === 'confirmed') {
				setConfirmed(!confirmed);	
			} else {
				setSelectedOption(option);
			}
		}
	};

	const modalStyle: CSSProperties = {
		position: 'fixed',
		bottom: '20px',
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		borderRadius: '4px',
		backgroundColor: '#808080',
		right: '30px',
		padding: '20px',
		display: 'flex',
		alignItems: 'center',
	};
	
	return(
		<div style={modalStyle}>
			{(store.userType.toLowerCase() === 'reporting_doctor') && (
				<>
					<Checkbox 
						disabled={!editable}
						isChecked={selectedOption === 'confirmed'}
						onChange={() => handleOptionChange('confirmed')}
						marginRight="20px" 
						fontWeight='bold' 
						whiteSpace='nowrap'
					>
						Confirm report
					</Checkbox>
					
					{(holter && store.userType.toLowerCase() === 'reporting_doctor') && (
						<Checkbox
							disabled={!editable}
							isChecked={selectedOption === 'changesRequested'}
							onChange={() => handleOptionChange('changesRequested')}
							marginRight="20px"
							fontWeight='bold'
							whiteSpace='nowrap'
						>
							Changes Requested
						</Checkbox>
					)}
				</>
			)}
			<Select
				backgroundColor="#c5c5c5"
				fontWeight="600"
				color="#222222"
				borderColor="transparent"
				disabled={!editable}
				value={String(urgency)}
				onChange={e => setUrgency(e.target.value === 'true')}
				marginRight="20px"
			>
				<option value="false">Routine</option>
				<option value="true">Urgent</option>
			</Select>
			<ButtonGroup>
				<Button colorScheme="blue" isDisabled={!editable} onClick={() => onSave(urgency, true, selectedOption === 'confirmed', selectedOption === 'changesRequested')}>Save & Exit</Button>
				<Button colorScheme="blue" isDisabled={!editable} onClick={() => onSave(urgency, false, selectedOption === 'confirmed', selectedOption === 'changesRequested')}>Save</Button>
			</ButtonGroup>
		</div>
	);
}

export default SaveModal;