import * as React from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { SERVER_URL } from '../../../../Constants';
import { Model } from '../../../../Models/Model';
import { StudyFileEntity } from '../../../../Models/Entities';

export interface AttributeStudyFileListProps {
	model: StudyFileEntity;
}

interface FileMetadata {
	id: string;
	created: string;
	modified: string;
	fileName: string;
	contentType: string;
	length: number;
}

@observer
export default class AttributeStudyFileList<T extends Model> extends React.Component<AttributeStudyFileListProps> {
	protected readonly initialFileId?: string;

	@observable
	protected fileMetadata?: FileMetadata;

	@action
	protected onFetchSucceeded = (metadata: FileMetadata) => {
		this.fileMetadata = metadata;
	};

	constructor(props: AttributeStudyFileListProps) {
		super(props);

		this.loadFile();
	}

	protected loadFile = () => {
		const { model } = this.props;

		if (model) {
			fetch(`${SERVER_URL}/api/files/metadata/${model.studyFileId}`)
				.then(x => x.json())
				.then(metadata => this.onFetchSucceeded(metadata));
		}
	};

	render() {
		const { model } = this.props;

		return (
			<a
				href={`${SERVER_URL}/api/files/${model.studyFileId}?download=true`}
				target="_blank"
				rel="noopener noreferrer"
				className="btn btn--icon icon-download icon-right"
			>
				{this.fileMetadata?.fileName ?? 'Download'}
			</a>
		);
	}
}
