import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsUserSiteRequestEntity } from 'Models/Security/Acl/VisitorsUserSiteRequestEntity';
import { SuperAdminUserSiteRequestEntity } from 'Models/Security/Acl/SuperAdminUserSiteRequestEntity';
import { UserUserSiteRequestEntity } from 'Models/Security/Acl/UserUserSiteRequestEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import * as Enums from '../Enums';
import { CRUD } from '../CRUDOptions';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';

export interface IUserSiteRequestEntityAttributes extends IModelAttributes {
	accessLevel: Enums.accessLevel;

	siteRequestedId?: string;
	siteRequested?: Models.SiteEntity | Models.ISiteEntityAttributes;
	userRequestingSiteId?: string;
	userRequestingSite?: Models.UserEntity | Models.IUserEntityAttributes;
}

@entity('UserSiteRequestEntity', 'User Site Request')
export default class UserSiteRequestEntity extends Model implements IUserSiteRequestEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsUserSiteRequestEntity(),
		new SuperAdminUserSiteRequestEntity(),
		new UserUserSiteRequestEntity(),
		new UserUserSiteRequestEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
	];

	/**
	 * Access Level requested
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Access Level',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => AttrUtils.standardiseEnum(attr, Enums.accessLevelOptions),
		enumResolveFunction: makeEnumFetchFunction(Enums.accessLevelOptions),
		displayFunction: (attribute: Enums.accessLevel) => Enums.accessLevelOptions[attribute],
	})
	public accessLevel: Enums.accessLevel;

	/**
	 * Requested Site
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Site Requested',
		displayType: 'reference-combobox',
		order: 20,
		referenceTypeFunc: () => Models.SiteEntity,
	})
	public siteRequestedId?: string;

	@observable
	@attribute({ isReference: true })
	public siteRequested: Models.SiteEntity;

	/**
	 * User Site Requests
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'User Requesting Site',
		displayType: 'reference-combobox',
		order: 30,
		referenceTypeFunc: () => Models.UserEntity,
	})
	public userRequestingSiteId?: string;

	@observable
	@attribute({ isReference: true })
	public userRequestingSite: Models.UserEntity;

	constructor(attributes?: Partial<IUserSiteRequestEntityAttributes>) {
		super(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IUserSiteRequestEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.accessLevel !== undefined) {
				this.accessLevel = attributes.accessLevel;
			}
			if (attributes.siteRequestedId !== undefined) {
				this.siteRequestedId = attributes.siteRequestedId;
			}
			if (attributes.siteRequested !== undefined) {
				if (attributes.siteRequested === null) {
					this.siteRequested = attributes.siteRequested;
				} else if (attributes.siteRequested instanceof Models.SiteEntity) {
					this.siteRequested = attributes.siteRequested;
					this.siteRequestedId = attributes.siteRequested.id;
				} else {
					this.siteRequested = new Models.SiteEntity(attributes.siteRequested);
					this.siteRequestedId = this.siteRequested.id;
				}
			}
			if (attributes.userRequestingSiteId !== undefined) {
				this.userRequestingSiteId = attributes.userRequestingSiteId;
			}
			if (attributes.userRequestingSite !== undefined) {
				if (attributes.userRequestingSite === null) {
					this.userRequestingSite = attributes.userRequestingSite;
				} else if (attributes.userRequestingSite instanceof Models.UserEntity) {
					this.userRequestingSite = attributes.userRequestingSite;
					this.userRequestingSiteId = attributes.userRequestingSite.id;
				} else {
					this.userRequestingSite = new Models.UserEntity(attributes.userRequestingSite);
					this.userRequestingSiteId = this.userRequestingSite.id;
				}
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		siteRequested {
			${Models.SiteEntity.getAttributes().join('\n')}
			${Models.SiteEntity.getFiles().map(f => f.name).join('\n')}
		}
		userRequestingSite {
			${Models.UserEntity.getAttributes().join('\n')}
		}
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		return this.id;
	}
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(UserSiteRequestEntity.prototype, 'created');
CRUD(modifiedAttr)(UserSiteRequestEntity.prototype, 'modified');
