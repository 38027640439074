import {
	Avatar,
	Box,
	Button,
	ButtonGroup,
	Card,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	IconButton,
	Modal, 
	ModalBody,
	ModalCloseButton,
	ModalContent, 
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Radio,
	RadioGroup, 
	Select,
	Stack,
	Text,
	Textarea, 
	useBreakpointValue, 
	useDisclosure, 
	useMediaQuery,
	Input
} from '@chakra-ui/react';
import { AttachmentIcon, DeleteIcon } from '@chakra-ui/icons';
import React, {useEffect, useState} from 'react';
import { Controller, useForm } from 'react-hook-form';
import FileUploader, { fileType } from 'Views/Components/FileUploadChakra/FileUploader';
import {SessionEntity, StudyFileEntity, UserSiteAccessEntity} from '../../../Models/Entities';
import { store } from '../../../Models/Store';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';

interface EndSessionProps {
	drawerToggle(): void;
	currentSession?: SessionEntity;
}

type FormValues = {
	date: string;
	reportsComplete: string;
	echo: number;
	stressTest: number;
	stressEcho: number;
	echoIp: number;
	opPaediatricEcho: number;
	ipPaediatricEcho: number;
	opDobutamineStressEcho: number;
	ipDobutamineStressEcho: number;
	op12LeadECG: number;
	ip12LeadECG: number;
	stressIpTest: number;
	stressIpEcho: number;
	additionalNotes: string;
	upload: any;
	supervisor: string;
	site: string;
};

const GET_SUPERVISORS = gql`
	query GetUserSiteAccessEntitiesBySiteId($siteId: String!) {
		userSiteAccessEntitys(where: [ 
			{ path: "siteId", comparison: equal, value: [$siteId] },
			{ path: "user.supervisor", comparison: equal, value: "true" }
		]) {
			user { 
				id 
				name
			} 
		} 
	}
`;

const GET_USER = gql`
  query GetUser($id: ID!) {
    userEntity(id: $id) {
      id
      trainee
    }
  }
`;

const EndSessionTile = (props: EndSessionProps) => {
	const { currentSession } = props;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { register, unregister, handleSubmit, control, getValues, formState, watch, trigger } = useForm<FormValues>({
		defaultValues: {
			date: currentSession?.sessionDate ? moment(currentSession?.sessionDate).format('YYYY-MM-DD') : '',
			reportsComplete: currentSession?.allComplete ? 'true' : 'false',
			echo: currentSession?.countEchos ?? 0,
			stressTest: currentSession?.countExerciseStress ?? 0,
			stressEcho: currentSession?.countStressEchos ?? 0,
			echoIp: currentSession?.countIpEchos ?? 0,
			stressIpTest: currentSession?.countIpExerciseStress ?? 0,
			stressIpEcho: currentSession?.countIpStressEchos ?? 0,
			opPaediatricEcho: currentSession?.countOpPaediatricEcho ?? 0,
			ipPaediatricEcho: currentSession?.countIpPaediatricEcho ?? 0,
			opDobutamineStressEcho: currentSession?.countOpDobutamineStressEcho ?? 0,
			ipDobutamineStressEcho: currentSession?.countIpDobutamineStressEcho ?? 0,
			op12LeadECG: currentSession?.countOp12LeadECG ?? 0,
			ip12LeadECG: currentSession?.countIp12LeadECG ?? 0,
			additionalNotes: currentSession?.notes ?? '',
			supervisor: currentSession?.supervisorId ?? '',
			site: currentSession?.siteId ?? '',
		},
	});
	
	const [fileUploaded, setFileUploaded] = useState<boolean>(false);
	const [file, setFile] = useState<fileType[]>([]);
	const [siteOptions, setSiteOptions] = useState<Array<{value: string, label: string}>>([]);

	const isFormValid = formState.isValid;

	const { data: user } = useQuery(GET_USER, {
		variables: { id: store.userId },
	});
	
	const { loading: supervisorsLoading, error: supervisorsError, data: supervisors } = useQuery(GET_SUPERVISORS, {
		variables: { siteId: props.currentSession?.siteId },
	});

	const isComplete = watch('reportsComplete');

	useEffect(() => {
		const options: Array<{value: string, label: string}> = [];

		const siteOptions =  store.userSiteAccess
			.sort((usa1, usa2) => {
				const siteName1 = usa1.site.siteName.toLowerCase();
				const siteName2 = usa2.site.siteName.toLowerCase();
				if (siteName1 < siteName2) {
					return -1;
				} else if (siteName1 > siteName2) {
					return 1;
				} else {
					return 0;
				}
			});

		siteOptions.map(usa => {
			if (usa.siteId) {
				options.push({value: usa.siteId, label: usa.site.siteName});
			}
		})

		setSiteOptions(options);
	}, [])
	
	const supervisorOptions = supervisors?.userSiteAccessEntitys.map((userSiteAccessEntity: UserSiteAccessEntity) => ({
		value: userSiteAccessEntity.user.id,
		label: userSiteAccessEntity.user.name,
	}));
	
	const onSubmit = async (data: FormValues) => {
		const { drawerToggle } = props;

		if (!user?.userEntity.trainee) {
			unregister('supervisor')
		}
		
		const session = new SessionEntity(currentSession);
		
		// @ts-ignore
		session.site = currentSession.site;
		session.siteId = data?.site;
		session.allComplete = data.reportsComplete === 'true';
		session.countEchos = data.echo;
		session.countStressEchos = data.stressEcho;
		session.countExerciseStress = data.stressTest;
		session.countIpEchos = data.echoIp;
		session.countIpStressEchos = data.stressIpEcho;
		session.countIpExerciseStress = data.stressIpTest;
		session.countOpPaediatricEcho = data.opPaediatricEcho;
		session.countIpPaediatricEcho = data.ipPaediatricEcho;
		session.countOpDobutamineStressEcho = data.opDobutamineStressEcho;
		session.countIpDobutamineStressEcho = data.ipDobutamineStressEcho;
		session.countOp12LeadECG = data.op12LeadECG;
		session.countIp12LeadECG = data.ip12LeadECG;
		session.sessionDate = new Date(data.date);
		session.notes = data.additionalNotes;
		session.open = false;
		session.supervisorId = data.supervisor !== '' ? data.supervisor : undefined;
		session.userId = store.userId;
		for (const f of file) {
			const sf = new StudyFileEntity();
			sf.fileType = 'WORKLIST';

			const base64Response = await fetch(f.data);
			const blobData = await base64Response.blob();
			const contentType = blobData.type || 'application/octet-stream';
			sf.studyFile = new File([blobData], f.name, { type: contentType });
			sf.status = 'AVAILABLE';
			sf.sessionId = session.id;
			
			sf.save(undefined, {
				contentType: 'multipart/form-data',
			});
		}
		
		session.save().then(() => {
			store.apolloClient.refetchQueries({
				include: 'active',
			});
			drawerToggle();
		});
	};

	const maxLabelWidth = useBreakpointValue({ base: '100%', md: 'sm' });
	const [isMobile] = useMediaQuery('(max-width: 767px)');
	
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={5}>
				<FormControl variant="floating" id="date" isRequired>
					<Input {...register('date')}
						placeholder="Date"
						size="lg"
						type="date"
						isRequired
					/>
					<FormLabel>Date</FormLabel>
				</FormControl>
				<FormLabel>All reports complete?</FormLabel>
				<Controller
					defaultValue='true'
					name="reportsComplete"
					control={control}
					render={({ field: { onChange, value } }) => (
						<RadioGroup onChange={onChange} value={value}  >
							<Stack direction="row">
								<Radio value="true">Yes</Radio>
								<Radio value="false">No</Radio>
							</Stack>
						</RadioGroup>
					)}
				/>

				<FormLabel>Site</FormLabel>
				<Controller
					name='site'
					control={control}
					render={({ field: { onChange, value } }) => (
						<Select placeholder="Site" value={value} onChange={onChange}>
							{siteOptions.map((option: any) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</Select>
					)}
				/>

				<FormLabel>Number of tests completed</FormLabel>

				<Flex
					direction={isMobile ? 'column' : 'row'}
					flexWrap='wrap'
					maxWidth={isMobile ? '100%' : 'none'}
				>
					<Flex flexWrap='wrap' flex={isMobile ? '100%' : '48%'} mr='5px'>
						<Flex padding='12px 5px 5px 5px' backgroundColor='#f7f7f7' borderRadius='5px' border='1px solid #ccc' mb='10px' width="100%">
							<Box flex={isMobile ? '1 1 50%' : '1'} p={2}>
								<FormControl variant="floating" id="echo">
									<NumberInput min={0} defaultValue={0}>
										<NumberInputField {...register('echo', {
											validate: () => (getValues('echo') + getValues('stressEcho') + getValues('stressTest') + getValues('echoIp') + getValues('stressIpEcho') + getValues('stressIpTest') + 
											getValues('opPaediatricEcho') + getValues('ipPaediatricEcho') + getValues('opDobutamineStressEcho') + getValues('ipDobutamineStressEcho') + getValues('op12LeadECG') + getValues('ip12LeadECG')) > 0,
										})} />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
									<FormLabel maxW={maxLabelWidth} isTruncated backgroundColor='#f7f7f7 !important'>OP Echo</FormLabel>
									{formState.errors.echo && formState.errors.echo.type === 'validate' && (
										<Text color='red'>
										Atleast 1 test must be completed.
										</Text>
									)}
								</FormControl>
							</Box>
							<Box flex={isMobile ? '1 1 50%' : '1'} p={2}>
								<FormControl variant="floating" id="echo-ip">
									<NumberInput min={0} defaultValue={0}>
										<NumberInputField {...register('echoIp')} />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
									<FormLabel maxW={maxLabelWidth} isTruncated backgroundColor='#f7f7f7 !important'>IP Echo</FormLabel>
								</FormControl>
							</Box>
						</Flex>
						<Flex padding='12px 5px 5px 5px' backgroundColor='#f7f7f7' borderRadius='5px' border='1px solid #ccc' mb='10px' width="100%">
							<Box flex={isMobile ? '1 1 50%' : '1'} p={2}>
								<FormControl variant="floating" id="stress-echo">
									<NumberInput min={0} defaultValue={0}>
										<NumberInputField {...register('stressEcho', { min: 0 })}/>
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
									<FormLabel maxW={maxLabelWidth} isTruncated backgroundColor='#f7f7f7 !important'>OP Stress Echo</FormLabel>
								</FormControl>
							</Box>

							<Box flex={isMobile ? '1 1 50%' : '1'} p={2}>
								<FormControl variant="floating" id="stress-ip-echo">
									<NumberInput min={0} defaultValue={0}>
										<NumberInputField {...register('stressIpEcho', { min: 0 })}/>
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
									<FormLabel maxW={maxLabelWidth} isTruncated backgroundColor='#f7f7f7 !important'>IP Stress Echo</FormLabel>
								</FormControl>
							</Box>
						</Flex>
						<Flex padding='12px 5px 5px 5px' backgroundColor='#f7f7f7' borderRadius='5px' border='1px solid #ccc' mb='10px' width="100%">
							<Box flex={isMobile ? '1 1 50%' : '1'} p={2}>
								<FormControl variant="floating" id="stress-test">
									<NumberInput min={0} defaultValue={0}>
										<NumberInputField {...register('stressTest', { min: 0 })} />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
									<FormLabel maxW={maxLabelWidth} isTruncated backgroundColor='#f7f7f7 !important'>OP Stress Test</FormLabel>
								</FormControl>
							</Box>

							<Box flex={isMobile ? '1 1 50%' : '1'} p={2}>
								<FormControl variant="floating" id="stress-ip-test">
									<NumberInput min={0} defaultValue={0}>
										<NumberInputField {...register('stressIpTest', { min: 0 })} />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
									<FormLabel maxW={maxLabelWidth} isTruncated backgroundColor='#f7f7f7 !important'>IP Stress Test</FormLabel>
								</FormControl>
							</Box>
						</Flex>
					</Flex>
					<Flex flexWrap='wrap' flex={isMobile ? '100%' : '48%'} ml='5px'>
						<Flex padding='12px 5px 5px 5px' backgroundColor='#f7f7f7' borderRadius='5px' border='1px solid #ccc' mb='10px' width="100%">
							<Box flex={isMobile ? '1 1 50%' : '1'} p={2}>
								<FormControl variant="floating" id="stress-ip-test">
									<NumberInput min={0} defaultValue={0}>
										<NumberInputField {...register('opPaediatricEcho', { min: 0 })} />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
									<FormLabel maxW={maxLabelWidth} isTruncated backgroundColor='#f7f7f7 !important'>OP Paediatric Echo</FormLabel>
								</FormControl>
							</Box>

							<Box flex={isMobile ? '1 1 50%' : '1'} p={2}>
								<FormControl variant="floating" id="echo">
									<NumberInput min={0} defaultValue={0}>
										<NumberInputField {...register('ipPaediatricEcho', { min: 0 })} />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
									<FormLabel maxW={maxLabelWidth} isTruncated backgroundColor='#f7f7f7 !important'>IP Paedeatric Echo</FormLabel>
								</FormControl>
							</Box>
						</Flex>
						<Flex padding='12px 5px 5px 5px' backgroundColor='#f7f7f7' borderRadius='5px' border='1px solid #ccc' mb='10px' width="100%">
							<Box flex={isMobile ? '1 1 50%' : '1'} p={2}>
								<FormControl variant="floating" id="echo-ip">
									<NumberInput min={0} defaultValue={0}>
										<NumberInputField {...register('opDobutamineStressEcho', { min: 0 })} />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
									<FormLabel maxW={maxLabelWidth} isTruncated backgroundColor='#f7f7f7 !important'>OP Dobutamine Stress Echo</FormLabel>
								</FormControl>
							</Box>

							<Box flex={isMobile ? '1 1 50%' : '1'} p={2}>
								<FormControl variant="floating" id="stress-echo">
									<NumberInput min={0} defaultValue={0}>
										<NumberInputField {...register('ipDobutamineStressEcho', { min: 0 })}/>
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
									<FormLabel maxW={maxLabelWidth} isTruncated backgroundColor='#f7f7f7 !important'>IP Dobutamine Stress Echo</FormLabel>
								</FormControl>
							</Box>
						</Flex>
						<Flex padding='12px 5px 5px 5px' backgroundColor='#f7f7f7' borderRadius='5px' border='1px solid #ccc' mb='10px' width="100%">
							<Box flex={isMobile ? '1 1 50%' : '1'} p={2}>
								<FormControl variant="floating" id="stress-ip-echo">
									<NumberInput min={0} defaultValue={0}>
										<NumberInputField {...register('op12LeadECG', { min: 0 })}/>
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
									<FormLabel maxW={maxLabelWidth} isTruncated backgroundColor='#f7f7f7 !important'>OP 12 Lead ECG</FormLabel>
								</FormControl>
							</Box>

							<Box flex={isMobile ? '1 1 50%' : '1'} p={2}>
								<FormControl variant="floating" id="stress-test">
									<NumberInput min={0} defaultValue={0}>
										<NumberInputField {...register('ip12LeadECG', { min: 0 })} />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
									<FormLabel maxW={maxLabelWidth} isTruncated backgroundColor='#f7f7f7 !important'>IP 12 Lead ECG</FormLabel>
								</FormControl>
							</Box>
						</Flex>
					</Flex>
				</Flex>

				{user?.userEntity.trainee && user.userEntity.trainee !== null && (
					<>
						<FormLabel>Supervisor</FormLabel>
						{!supervisorsLoading && (
							<Controller
								{...register('supervisor', {
									validate: () => getValues('supervisor') != '',
								})}
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select placeholder="Supervisor" value={value} onChange={onChange}>
										{supervisorOptions.map((option: any) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</Select>
								)}
							/>
						)}
						{formState.errors.supervisor && formState.errors.supervisor.type === 'validate' && (
							<Text color='red'>
								Set a supervisor
							</Text>
						)}
					</>
				)}

				<FormLabel>{currentSession?.open ? (<>Upload patient list</>) : (<>Upload additional patient lists</>)}</FormLabel>
				<FormControl variant="floating" id="upload">
					{!fileUploaded ? (
						<FileUploader  {...register('upload', {
							validate: () => fileUploaded || !currentSession?.open,
						})}
						showOver
						onUploadEnd={(f: fileType[]) => {
							setFile(f);
							setFileUploaded(true);
							unregister('upload');
						}
								
						} 
						onUploadStart={() => console.log(file)}
						/>
					) : (
						<>
							{file.map(f => (
								<Card>
									<Flex flex='1' gap='4' alignItems='center' flexDirection='row' p={4}>
										<Avatar icon={<AttachmentIcon />} />
										<Box>
											<Heading size='sm'>{f.name}</Heading>
											<Text>{f.type}</Text>
										</Box>
										<IconButton aria-label='Delete file' ml='auto' onClick={
											() => {
												const arr = file.filter(a => a !== f);
												setFile(arr);
												setFileUploaded(arr.length > 0);
												if (arr.length === 0) {
													register('upload');
												}
											}
										} icon={<DeleteIcon />} />
									</Flex>
								</Card>
							))}
						</>
					)}
					{fileUploaded}
					{formState.errors.upload && formState.errors.upload.type === 'validate' && (
						<Text color='red'>
							Atleast 1 file must be uploaded
						</Text>
					)}
				</FormControl>
				
				<FormLabel>Additional Notes</FormLabel>
				
				<Textarea
					{...register('additionalNotes')}
					placeholder=''
					size='lg'
				/>
				
				<ButtonGroup>
					<Button onClick={props.drawerToggle} variant='outline'>Cancel</Button>
					{isComplete === 'false' ? (
						<Button onClick={isFormValid ? onOpen : handleSubmit(onSubmit)} colorScheme='blue' >
							{currentSession?.open ? <>End Session</> : <>Update Session</>}
						</Button>
					) : (
						<Button type='submit' colorScheme='blue' >
							{currentSession?.open ? <>End Session</> : <>Update Session</>}
						</Button>
					)}
				</ButtonGroup>
			</Stack>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Confirmation</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						Are you sure you want to submit without all reports complete?
					</ModalBody>
					<ModalFooter>
						<Button variant="ghost" mr={3} onClick={onClose}>Cancel</Button>
						<Button colorScheme="red" onClick={() => {
							handleSubmit(onSubmit)();
						}}>Submit Anyway</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</form>
	);
};

export default EndSessionTile;
