import React from 'react';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { UseFormRegister } from 'react-hook-form';
import {StudyFormValues} from "../../Tiles/Patients/StudyCrudTile";
import * as Models from "../../../Models/Entities";

interface ReferringDoctorDisplayProps {
    selectedStudy: Models.StudyEntity | undefined; 
    registerStudyForm: UseFormRegister<StudyFormValues>;
}

const ReferringDoctorDisplay: React.FC<ReferringDoctorDisplayProps> = ({ selectedStudy, registerStudyForm }) => {
    const defaultReturn = <div style={{margin: "4px 0 8px 0"}}></div>
    if (selectedStudy && selectedStudy.doctorss) {
        const referringDoctor = selectedStudy.doctorss.find(doctor => doctor.doctorType === "REFERRING");
        if (referringDoctor) {
            return (
                <>
                    {selectedStudy && (
                        <FormControl
                            display="flex"
                            flexDir="column"
                            width="100%"
                            margin="4px 0 8px 0"
                        >
                            <FormLabel
                                margin={"0px 4px -1px 12px"}
                                backgroundColor={'white'}
                                fontSize="sm"
                                position="relative">Referring Doctor
                            </FormLabel>
                            <div style={{ display: 'flex', marginBottom: '8px', alignItems: 'flex-start' }}>
                                <div style={{ flex: '1' }}>
                                    <Input
                                        width="100%"
                                        m="0"
                                        size="lg"
                                        {...registerStudyForm('referringDoctorPrefix', {
                                            required: false,
                                        })}
                                    />
                                </div>

                                <div style={{ flex: '2', marginLeft: '8px' }}>
                                    <Input
                                        width="100%"
                                        m="0"
                                        size="lg"
                                        {...registerStudyForm('referringDoctorFirstName', {
                                            required: false,
                                        })}
                                    />
                                </div>

                                <div style={{ flex: '2', marginLeft: '8px' }}>
                                    <Input
                                        width="100%"
                                        m="0"
                                        size="lg"
                                        {...registerStudyForm('referringDoctorLastName', {
                                            required: false,
                                        })}
                                    />
                                </div>
                            </div>
                        </FormControl>
                    )}
                </>
            );
        } else {
            return defaultReturn
        }
    } else {
        return defaultReturn
    }
};

export default ReferringDoctorDisplay;
