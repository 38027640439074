import { useQuery } from '@apollo/client';
import { Textarea, useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, {
	ChangeEvent,
	forwardRef,
	ForwardRefRenderFunction,
	useEffect,
	useImperativeHandle,
	useState
} from 'react';
import { useNavigate } from 'react-router';
import { ReportEntity, StudyEntity, UserEntity } from '../../../Models/Entities';
import { SERVER_URL } from 'Constants';
import { reportStatus } from '../../../Models/Enums';
import { store } from '../../../Models/Store';
import ConfirmedAlreadyModal from '../Modals/ConfirmedAlreadyModal';
import SaveModal from '../Modals/SaveModal';
import UrgencyConfirmationModal from '../Modals/UrgencyConfirmationModal';

interface NewBasicReportBuilderProps {
	study: StudyEntity;
	refetch: () => void;
	detectChanges?: () => void;
}

export interface NewBasicReportBuilderMethods {
	// Define your methods here
	quietSave: () => Promise<boolean>;
}
const NewBasicReportBuilder: ForwardRefRenderFunction<NewBasicReportBuilderMethods, NewBasicReportBuilderProps> = (props, ref) => {
	const { study, refetch, detectChanges } = props;
	const [reportData, setReportData] = useState('');
	const [urgent, setUrgent] = useState<boolean>(false);
	const [exiting, setExiting] = useState<boolean>(false);
	const [confirming, setConfirming] = useState<boolean>(false);
	const [isUrgencyModalOpen, setIsUrgencyModalOpen] = useState<boolean>(false);
	const [confirmedAlreadyModalOpen, setConfirmedAlreadyModalOpen] = useState<boolean>(false);

	const navigate = useNavigate();
	const toast = useToast();

	useImperativeHandle(ref, () => ({
		quietSave,
	}))

	const { loading: userLoading, data: user, error: userError} = useQuery(UserEntity.getFetchSingleQueryProfilePage(), {
		variables: {
			args: [{
				path: 'id',
				comparison: 'equal',
				value: store.userId,
			}]
		}
	})

	const { userEntity: loggedInUser } = user || {};

	const getReport = () => {
		axios.get(`${SERVER_URL}/api/entity/StudyEntity/EchoReportTXT/${study.id}`)
			.then(res => {
				setReportData(res.data);
			})
			.catch(() => 
				setReportData('Unable to load report txt data for the selected template.')
			);
	}

	const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		const inputValue = e.target.value
		
		if (detectChanges) {
			detectChanges();
		}
		setReportData(inputValue)
	}

	const checkEditable = () => !(study.report?.reportStatus === 'CONFIRMED' && store.userType === 'CARDIAC_SCIENTIST')

	const updateStudyStatus = (confirm: boolean, exit: boolean): reportStatus => {
		// Reporting Doctor

		if (store.userType.toLowerCase() === 'reporting_doctor') {
			if (confirm) {
				return 'CONFIRMED';
			} else if (exit) {
				return 'PROVISIONAL';
			}
		}

		if (store.userType.toLowerCase() === 'cardiac_scientist') {
			if (exit) {
				return 'PROVISIONAL'
			}
		}

		if (study.report?.reportStatus) {
			return study.report?.reportStatus;
		}

		return 'NOT_REPORTED';
	}

	const quietSave = async (): Promise<boolean> => {
		try {
			const report = new ReportEntity(study.report);
			report.reportData = reportData;

			await report.save();
			return true;
		} catch (error) {
			console.error('Error saving report:', error);

			toast({
				title: 'Error saving study',
				description: 'Error saving study',
				position: 'bottom-right',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});

			return false;
		}
	};
	
	const saveStudy = (urgency: boolean, exit: boolean, confirm: boolean) => {
		const report = new ReportEntity(study.report);
		report.reportData = reportData;
		report.reportStatus = updateStudyStatus(confirm, exit);
		
		if (report.reportStatus === 'CONFIRMED') {
			report.reportingDoctor = store.userId || '';
		}

		report.save().then(() => {
			toast({
				title: 'Report updated',
				description: 'Report Updated',
				position: 'bottom-right',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
		}).catch(() => {
			toast({
				title: 'Error saving report data',
				description: 'Error saving report data',
				position: 'bottom-right',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		})

		const updatedStudy = new StudyEntity(study);
		updatedStudy.urgent = urgency;

		updatedStudy.save().then(() => {
			toast({
				title: 'Study Saved',
				description: 'Study Updated',
				position: 'bottom-right',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
		});
		

		if (exit) {
			navigate('/');
		}
	}
	
	const saveReport = (urgency: boolean, exit: boolean, confirm: boolean) => {
		setUrgent(urgency);
		setExiting(exit);
		setConfirming(confirm);

		if (!study.urgent && urgency) {
			setIsUrgencyModalOpen(true)
			return;
		}

		if (study.report?.reportStatus === 'CONFIRMED') {
			setConfirmedAlreadyModalOpen(true);
			return;
		}
		
		saveStudy(urgency, exit, confirm)
	}

	const saveUrgentStudy = (urgency: boolean, exit: boolean, confirm: boolean) => {
		if (study.report?.reportStatus === 'CONFIRMED') {
			setConfirmedAlreadyModalOpen(true);
			return;
		}

		saveStudy(urgency, exit, confirm);
	}

	const getReadOnly = (): boolean => {
		// If study is confirmed and user is not the reporting doctor
		if (study.report?.reportStatus === 'CONFIRMED' && store.userId !== study.report?.reportingDoctor) {
			return true;
		}

		if (store.userId === study.report?.reportingDoctor) {
			return false;
		}

		if ((store.userType === 'SITE_ADMIN' || store.userType === 'SITE_USER') && store.userSiteAccess[0].accessLevel === 'FULL_ACCESS') {
			return true;
		}

		const patientSiteId = study.patient.siteId;

		for (const siteAccess of loggedInUser?.sitess || []) {
			if (siteAccess.siteId === patientSiteId) {
				// Found a match for the siteId, extract the access level
				return siteAccess.accessLevel === 'VIEW_ONLY';
			}
		}

		return true;
	}
	
	useEffect(() => {
		refetch();
		getReport()
		setUrgent(study.urgent);
	}, [])
	
	return <>
		<Textarea
			isDisabled={getReadOnly()}
			value={reportData}
			onChange={handleInputChange}
			size="sm"
			style={{
				border:'2px solid #5686ce',
				width: '100%',
				height: getReadOnly() ? 'calc(100% - 33px)' : 'calc(100% - 80px)',
				resize: 'none',
				margin: '0',
				overflow: 'auto',
			}}
		/>

		<UrgencyConfirmationModal
			isOpen={isUrgencyModalOpen}
			onClose={() => setIsUrgencyModalOpen(false)}
			onConfirm={() => {
				setIsUrgencyModalOpen(false)
				saveUrgentStudy(urgent, exiting, confirming)
			}}
		/>

		<ConfirmedAlreadyModal
			isOpen={confirmedAlreadyModalOpen}
			onClose={() => setConfirmedAlreadyModalOpen(false)}
			onConfirm={() => {
				setConfirmedAlreadyModalOpen(false)
				saveStudy(urgent, exiting, confirming)
			}}
		/>
		
		{!getReadOnly() && (
			<SaveModal editable={checkEditable()} onSave={saveReport} urgent={study.urgent} confirm={study.report?.reportStatus === 'CONFIRMED'} />
		)}
	</>
}

export default forwardRef(NewBasicReportBuilder);