import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsShareLinkEntity } from 'Models/Security/Acl/VisitorsShareLinkEntity';
import { SuperAdminShareLinkEntity } from 'Models/Security/Acl/SuperAdminShareLinkEntity';
import { UserShareLinkEntity } from 'Models/Security/Acl/UserShareLinkEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { CRUD } from '../CRUDOptions';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';

export interface IShareLinkEntityAttributes extends IModelAttributes {
	emailAddress: string;
	expiryDate: Date;
	lastVisited: Date;
	countVisited: number;
	revoked: boolean;
	expirynotificationsent: boolean;
	studyId?: string;
	study?: Models.StudyEntity | Models.IStudyEntityAttributes;
}

@entity('ShareLinkEntity', 'Share Link')
export default class ShareLinkEntity extends Model implements IShareLinkEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsShareLinkEntity(),
		new SuperAdminShareLinkEntity(),
		new UserShareLinkEntity(),
		new UserShareLinkEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
	];

	/**
	 * The email address this link belongs to
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Email Address',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public emailAddress: string;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Expiry Date',
		displayType: 'datetimepicker',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public expiryDate: Date;

	/**
	 * The datetime the link was last visited
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Last Visited',
		displayType: 'datetimepicker',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public lastVisited: Date;

	/**
	 * The number of times this link has been visited
	 */
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Count Visited',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public countVisited: number;

	/**
	 * Has the share link been manually revoked
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Revoked',
		displayType: 'checkbox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public revoked: boolean;

	/**
	 * Has an expiry email already been sent
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'ExpiryNotificationSent',
		displayType: 'checkbox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public expirynotificationsent: boolean;

	@observable
	@attribute()
	@CRUD({
		name: 'Study',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.StudyEntity,
	})
	public studyId?: string;

	@observable
	@attribute({ isReference: true })
	public study: Models.StudyEntity;

	constructor(attributes?: Partial<IShareLinkEntityAttributes>) {
		super(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IShareLinkEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.emailAddress !== undefined) {
				this.emailAddress = attributes.emailAddress;
			}
			if (attributes.expiryDate !== undefined) {
				if (attributes.expiryDate === null) {
					this.expiryDate = attributes.expiryDate;
				} else {
					this.expiryDate = moment(attributes.expiryDate).toDate();
				}
			}
			if (attributes.lastVisited !== undefined) {
				if (attributes.lastVisited === null) {
					this.lastVisited = attributes.lastVisited;
				} else {
					this.lastVisited = moment(attributes.lastVisited).toDate();
				}
			}
			if (attributes.countVisited !== undefined) {
				this.countVisited = attributes.countVisited;
			}
			if (attributes.revoked !== undefined) {
				this.revoked = attributes.revoked;
			}
			if (attributes.expirynotificationsent !== undefined) {
				this.expirynotificationsent = attributes.expirynotificationsent;
			}
			if (attributes.study !== undefined) {
				if (attributes.study === null) {
					this.study = attributes.study;
				} else if (attributes.study instanceof Models.StudyEntity) {
					this.study = attributes.study;
					this.studyId = attributes.study.id;
				} else {
					this.study = new Models.StudyEntity(attributes.study);
					this.studyId = this.study.id;
				}
			} else if (attributes.studyId !== undefined) {
				this.studyId = attributes.studyId;
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		study {
			${Models.StudyEntity.getAttributes().join('\n')}
		}
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		return this.id;
	}
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ShareLinkEntity.prototype, 'created');
CRUD(modifiedAttr)(ShareLinkEntity.prototype, 'modified');
