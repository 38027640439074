import { useEffect, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { observable, runInAction } from 'mobx';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Creatable from 'react-select/creatable';

import { 
	Button, 
	Colors, 
	Display, 
} from 'Views/Components/Button/Button';
import alert from 'Util/ToastifyUtils';
import { 
	DefaultUnitMap, 
	getSRPathLabel, 
	getVariableLabel, 
	PrecisionMap,
} from 'Util/measurements_template';
import { cloneDeep } from 'lodash';
import { MeasurementEntity, SiteEntity, StudyEntity } from '../../../Models/Entities';
import { Form } from '../../Components/Form/Form';
import { store } from '../../../Models/Store';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import { MeasurementTable } from './MeasurementTable';

interface MeasurementTileProps {
	study: StudyEntity;
	site: SiteEntity;
	updateMeasurement: (measurementLabel: string, measurement: MeasurementEntity) => void;
	updateTranslations: () => void;
	calculatedMeasurements: any;
	reportMeasurementsState: any;
	updateReportMeasurementsState: (newState: any) => void;
	refetchStudyMeasurements: () => void;
	measurementsLabelMap: string;
}

const useForceUpdate = () => {
	const [value, setValue] = useState(0);
	return () => setValue(value => value + 1);
};

const MeasurementTile = observer((props: MeasurementTileProps) => {
	const { 
		study, 
		site, 
		updateMeasurement, 
		updateTranslations, 
		calculatedMeasurements, 
		reportMeasurementsState,
		updateReportMeasurementsState,
		refetchStudyMeasurements,
		measurementsLabelMap,
	} = props;

	const forceUpdate = useForceUpdate();
	
	useEffect(() => {
		if (!formState.isLoading) {
			runInAction(() => {
				contentState.contents = getContent();
				forceUpdate();
			});
		}
	}, [calculatedMeasurements]);

	let hasEditAccess = false;
	if (store.userSiteAccess.filter(usa => usa.siteId === site.id).length > 0) {
		hasEditAccess = (store.userType === 'CARDIAC_SCIENTIST' || store.userType === 'REPORTING_DOCTOR')
		&& (store.userSiteAccess.filter(usa => usa.siteId === site.id)[0].accessLevel === 'FULL_ACCESS')
		&& study.report?.reportStatus !== 'CONFIRMED';
	}

	const measurementUpdates = useLocalStore(() => ({
		newMeasurements: [] as MeasurementEntity[],
		updatedMeasurements: [] as MeasurementEntity[],
	}));

	const formState = useLocalStore(() => ({
		isLoading: true,
	}));
	
	const measurementObject = useLocalStore(() => ({
		MMLVIDD: new Array<MeasurementEntity>(),
		MMLVIDDIndex: new Array<MeasurementEntity>(),
		MMLVIDS: new Array<MeasurementEntity>(),
		MMLVIVSD: new Array<MeasurementEntity>(),
		MMLVPWTD: new Array<MeasurementEntity>(),
		MMLVMASScubed: new Array<MeasurementEntity>(),
		MMLVMI: new Array<MeasurementEntity>(),
		MMLVFSteich: new Array<MeasurementEntity>(),
		MMLVEFteich: new Array<MeasurementEntity>(),
		MMAOROOT: new Array<MeasurementEntity>(),
		MMLAAPSD: new Array<MeasurementEntity>(),
		MMRVIDD: new Array<MeasurementEntity>(),
		//
		$2DLVIDD: new Array<MeasurementEntity>(),
		$2DLVIDDIndex: new Array<MeasurementEntity>(),
		$2DLVIDS: new Array<MeasurementEntity>(),
		$2DLVIVSD: new Array<MeasurementEntity>(),
		$2DLVPWTD: new Array<MeasurementEntity>(),
		$2DLVMASScubed: new Array<MeasurementEntity>(),
		$2DLVMI: new Array<MeasurementEntity>(),
		$2DLVFSteich: new Array<MeasurementEntity>(),
		$2DLVEFteich: new Array<MeasurementEntity>(),
		//
		$2DLVEDVMODBP: new Array<MeasurementEntity>(),
		$2DLVEDVMODBPIndex: new Array<MeasurementEntity>(),
		$2DLVESVMODBP: new Array<MeasurementEntity>(),
		$2DLVSVMODBP: new Array<MeasurementEntity>(),
		$2DLVEFMODBP: new Array<MeasurementEntity>(),
		$2DLVEDVMODA4C: new Array<MeasurementEntity>(),
		$2DLVESVMODA4C: new Array<MeasurementEntity>(),
		$2DSVMODA4C: new Array<MeasurementEntity>(),
		$2DEFMODA4C: new Array<MeasurementEntity>(),
		$2DLVEDVMODA2C: new Array<MeasurementEntity>(),
		$2DLVESVMODA2C: new Array<MeasurementEntity>(),
		$2DSVMODA2C: new Array<MeasurementEntity>(),
		$2DEFMODA2C: new Array<MeasurementEntity>(),
		$2DPGLSA2C: new Array<MeasurementEntity>(),
		$2DPGLSA4C: new Array<MeasurementEntity>(),
		$2DPGLSALAX: new Array<MeasurementEntity>(),
		$2DPGLS: new Array<MeasurementEntity>(),
		PWLVIVRT: new Array<MeasurementEntity>(),
		//
		$2DRVEDDBASE: new Array<MeasurementEntity>(),
		$2DRVEDDMID: new Array<MeasurementEntity>(),
		MMTAPSE: new Array<MeasurementEntity>(),
		PWRVSPRIME: new Array<MeasurementEntity>(),
		//
		$2DLAAREA: new Array<MeasurementEntity>(),
		$2DLAEDVMODBP: new Array<MeasurementEntity>(),
		$2DLAEDVMODBPIndex: new Array<MeasurementEntity>(),
		$2DLAEDVMODAL: new Array<MeasurementEntity>(),
		$2DLAEDVMODALIndex: new Array<MeasurementEntity>(),
		$2DRAAREA: new Array<MeasurementEntity>(),
		RAVOL: new Array<MeasurementEntity>(),
		//
		$2DAOROOT: new Array<MeasurementEntity>(),
		$2DAOROOTIndex: new Array<MeasurementEntity>(),
		$2DSTJ: new Array<MeasurementEntity>(),
		$2DASCAO: new Array<MeasurementEntity>(),
		$2DASCAOIndex: new Array<MeasurementEntity>(),
		$2DAOARCH: new Array<MeasurementEntity>(),
		$2DDESCAO: new Array<MeasurementEntity>(),
		//
		$2DLVOTD: new Array<MeasurementEntity>(),
		PWLVOTVTI: new Array<MeasurementEntity>(),
		PWLVOTVMAX: new Array<MeasurementEntity>(),
		PWLVOTVMEAN: new Array<MeasurementEntity>(),
		PWLVOTMGRAD: new Array<MeasurementEntity>(),
		PWLVOTPGRAD: new Array<MeasurementEntity>(),
		PWLVOTSV: new Array<MeasurementEntity>(),
		CWAVVTI: new Array<MeasurementEntity>(),
		CWAVVMAX: new Array<MeasurementEntity>(),
		CWAVVMEAN: new Array<MeasurementEntity>(),
		CWAVMGRAD: new Array<MeasurementEntity>(),
		CWAVPGRAD: new Array<MeasurementEntity>(),
		CAVAVTI: new Array<MeasurementEntity>(),
		CAVAVTIIndex: new Array<MeasurementEntity>(),
		CAVAVMAX: new Array<MeasurementEntity>(),
		CAVDI: new Array<MeasurementEntity>(),
		CWARPHT: new Array<MeasurementEntity>(),
		//
		PWMVEMAX: new Array<MeasurementEntity>(),
		PWMVAMAX: new Array<MeasurementEntity>(),
		PWMVEARATIO: new Array<MeasurementEntity>(),
		PWMVDECT: new Array<MeasurementEntity>(),
		PWMVADUR: new Array<MeasurementEntity>(),
		PWEPRIMESEP: new Array<MeasurementEntity>(),
		PWEPRIMELAT: new Array<MeasurementEntity>(),
		PWEEPRIMERATIOSEP: new Array<MeasurementEntity>(),
		PWEEPRIMERATIOLAT: new Array<MeasurementEntity>(),
		PWEEPRIMERATIOAVE: new Array<MeasurementEntity>(),
		PWAPRIMESEP: new Array<MeasurementEntity>(),
		PWAPRIMELAT: new Array<MeasurementEntity>(),
		CWMVMGRAD: new Array<MeasurementEntity>(),
		CWMVPGRAD: new Array<MeasurementEntity>(),
		CWMVVMAX: new Array<MeasurementEntity>(),
		CWMVPHT: new Array<MeasurementEntity>(),
		CWMVVTI: new Array<MeasurementEntity>(),
		CMVAPHT: new Array<MeasurementEntity>(),
		CMVAVTI: new Array<MeasurementEntity>(),
		//
		PWPVEINSVMAX: new Array<MeasurementEntity>(),
		PWPVEINDVMAX: new Array<MeasurementEntity>(),
		PWPVEINARVMAX: new Array<MeasurementEntity>(),
		PWPVSDRATIO: new Array<MeasurementEntity>(),
		PWPVEINARDUR: new Array<MeasurementEntity>(),
		PWMVADURPWPVEINARDUR: new Array<MeasurementEntity>(),
		//
		CWTRVMAX: new Array<MeasurementEntity>(),
		CWTRPGRAD: new Array<MeasurementEntity>(),
		CWRAP: new Array<MeasurementEntity>(),
		CWRVSP: new Array<MeasurementEntity>(),
		CWTVEMAX: new Array<MeasurementEntity>(),
		CWTVPHT: new Array<MeasurementEntity>(),
		CWTVMGRAD: new Array<MeasurementEntity>(),
		//
		CWPVVMAX: new Array<MeasurementEntity>(),
		CWPVPGRAD: new Array<MeasurementEntity>(),
		CWPVMGRAD: new Array<MeasurementEntity>(),
		CWPREPGRAD: new Array<MeasurementEntity>(),
		CWPAEDP: new Array<MeasurementEntity>(),
		//
		Height: new Array<MeasurementEntity>(),
		Weight: new Array<MeasurementEntity>(),
		BSA: new Array<MeasurementEntity>(),
	}));
	
	const formObject = useLocalStore(() => ({
		MMLVIDD: '',
		MMLVIDDIndex: '',
		MMLVIDS: '',
		MMLVIVSD: '',
		MMLVPWTD: '',
		MMLVMASScubed: '',
		MMLVMI: '',
		MMLVFSteich: '',
		MMLVEFteich: '',
		MMAOROOT: '',
		MMLAAPSD: '',
		MMRVIDD: '',
		//
		$2DLVIDD: '',
		$2DLVIDDIndex: '',
		$2DLVIDS: '',
		$2DLVIVSD: '',
		$2DLVPWTD: '',
		$2DLVMASScubed: '',
		$2DLVMI: '',
		$2DLVFSteich: '',
		$2DLVEFteich: '',
		//
		$2DLVEDVMODBP: '',
		$2DLVEDVMODBPIndex: '',
		$2DLVESVMODBP: '',
		$2DLVSVMODBP: '',
		$2DLVEFMODBP: '',
		$2DLVEDVMODA4C: '',
		$2DLVESVMODA4C: '',
		$2DSVMODA4C: '',
		$2DEFMODA4C: '',
		$2DLVEDVMODA2C: '',
		$2DLVESVMODA2C: '',
		$2DSVMODA2C: '',
		$2DEFMODA2C: '',
		$2DPGLSA2C: '',
		$2DPGLSA4C: '',
		$2DPGLSALAX: '',
		$2DPGLS: '',
		PWLVIVRT: '',
		//
		$2DRVEDDBASE: '',
		$2DRVEDDMID: '',
		MMTAPSE: '',
		PWRVSPRIME: '',
		//
		$2DLAAREA: '',
		$2DLAEDVMODBP: '',
		$2DLAEDVMODBPIndex: '',
		$2DLAEDVMODAL: '',
		$2DLAEDVMODALIndex: '',
		$2DRAAREA: '',
		RAVOL: '',
		//
		$2DAOROOT: '',
		$2DAOROOTIndex: '',
		$2DSTJ: '',
		$2DASCAO: '',
		$2DASCAOIndex: '',
		$2DAOARCH: '',
		$2DDESCAO: '',
		//
		$2DLVOTD: '',
		PWLVOTVTI: '',
		PWLVOTVMAX: '',
		PWLVOTVMEAN: '',
		PWLVOTMGRAD: '',
		PWLVOTPGRAD: '',
		PWLVOTSV: '',
		CWAVVTI: '',
		CWAVVMAX: '',
		CWAVVMEAN: '',
		CWAVMGRAD: '',
		CWAVPGRAD: '',
		CAVAVTI: '',
		CAVAVTIIndex: '',
		CAVAVMAX: '',
		CAVDI: '',
		CWARPHT: '',
		//
		PWMVEMAX: '',
		PWMVAMAX: '',
		PWMVEARATIO: '',
		PWMVDECT: '',
		PWMVADUR: '',
		PWEPRIMESEP: '',
		PWEPRIMELAT: '',
		PWEEPRIMERATIOSEP: '',
		PWEEPRIMERATIOLAT: '',
		PWEEPRIMERATIOAVE: '',
		PWAPRIMESEP: '',
		PWAPRIMELAT: '',
		CWMVMGRAD: '',
		CWMVPGRAD: '',
		CWMVVMAX: '',
		CWMVPHT: '',
		CWMVVTI: '',
		CMVAPHT: '',
		CMVAVTI: '',
		//
		PWPVEINSVMAX: '',
		PWPVEINDVMAX: '',
		PWPVEINARVMAX: '',
		PWPVSDRATIO: '',
		PWPVEINARDUR: '',
		PWMVADURPWPVEINARDUR: '',
		//
		CWTRVMAX: '',
		CWTRPGRAD: '',
		CWRAP: '',
		CWRVSP: '',
		CWTVEMAX: '',
		CWTVPHT: '',
		CWTVMGRAD: '',
		//
		CWPVVMAX: '',
		CWPVPGRAD: '',
		CWPVMGRAD: '',
		CWPREPGRAD: '',
		CWPAEDP: '',
		//
		Height: '',
		Weight: '',
		BSA: '',
	}));
	
	const { t, i18n } = useTranslation();

	const languageState = useLocalStore(() => ({
		namespace: '',
	}));

	const reOrderMeasurements = () => {
		Object.keys(reportMeasurementsState).forEach(key => {
			if (measurementObject[key] !== undefined) {
				runInAction(() => {
					const sortedMeasurements = cloneDeep(measurementObject[key]).sort((a: MeasurementEntity) => {
						if (a.id === reportMeasurementsState[key].id) {
							return -1; 
						}
						return 1;
					});
					measurementObject[key] = sortedMeasurements;
				});
			}
		});
	};

	const saveMeasurements = (): void => {
		updateReportMeasurementsState(reportMeasurementsState);
		measurementUpdates.newMeasurements.forEach(measurement => {
			runInAction(() => {
				study.measurementss.push(measurement);
				updateMeasurement(measurement.name, measurement);
			});
		});
		measurementUpdates.updatedMeasurements.forEach(measurement => {
			updateMeasurement(measurement.name, measurement);
		});
		runInAction(() => {
			study.reportMeasurements = JSON.stringify(reportMeasurementsState);
		});
		study.save({ measurementss: {} }).then(async () => {
			await store.apolloClient.query({
				query: StudyEntity.getFetchStudy(),
				fetchPolicy: 'network-only',
				variables: {
					args: [{
						path: 'id',
						comparison: 'equal',
						value: study.id,
					}],
				},
			}).then(res => {
				const latestMeasurements = res.data.studyEntity.measurementss;
				const latestReportMeasurements = JSON.parse(res.data.studyEntity.reportMeasurements);
				Object.keys(latestReportMeasurements).forEach(key => {
					if (latestReportMeasurements[key].id === undefined) {
						const measurementWithId = latestMeasurements.filter((m: MeasurementEntity) => getVariableLabel(m.name) === key 
						&& m.value === latestReportMeasurements[key].value 
						&& m.unit === latestReportMeasurements[key].unit);
						if (measurementWithId.length > 0) {
							[latestReportMeasurements[key]] = measurementWithId;
						}
					}
				});
				study.reportMeasurements = JSON.stringify(latestReportMeasurements);
				study.save();
			});
			updateTranslations();
			refetchStudyMeasurements();
			alert('Updated measurements', 'success');
		}).catch(error => alert('Measurements not updated', 'error'));

		runInAction(() => {
			measurementUpdates.newMeasurements = [];
		});
	};
	
	const getContent = () => (
		<div className={classNames('measurement-panel', store.hasBackendAccess && 'top-bar')}>
			<MeasurementTable study={study}></MeasurementTable>
		</div>
	);

	const contentState = useLocalStore(() => ({
		contents: getContent(),
	}));

	useEffect(() => {
		// Get existing Measurements
		study.measurementss.forEach(measure => {
			// Get var names to match
			let variableName = measure.name;

			variableName = getVariableLabel(variableName);

			runInAction(() => {
				if (measurementObject[variableName]) {
					measurementObject[variableName].push(measure);
				}
			});
		});

		runInAction(() => {
			reOrderMeasurements();
		});

		axios.get(`${SERVER_URL}/api/entity/SiteEntity/${study.patient.siteId}`)
			.then(siteRes => {
				if (siteRes.data !== '') {
					if (siteRes.data.translationFileId) {
						axios.get(`${SERVER_URL}/api/files/${siteRes.data.translationFileId}?download=true`).then(translationRes => {
							const translationFile = translationRes.data;
							// @ts-ignore
							i18n.addResourceBundle('en', siteRes.data.siteName, translationFile);

							runInAction(() => {
								languageState.namespace = `${siteRes.data.siteName}:`;
								formState.isLoading = false;	
								contentState.contents = getContent();
							});
						}).catch((error: Error) => {
							console.error(error);
						});
					} else {
						runInAction(() => {
							// @ts-ignore
							i18n.removeResourceBundle('en', siteRes.data.siteName);

							runInAction(() => {
								languageState.namespace = '';
								formState.isLoading = false;
								contentState.contents = getContent();
							});
						});
					}
				}
			}).catch(err => console.error(err));
	}, []);

	return contentState.contents;
});

export default MeasurementTile;

interface MeasurementSelectorProps {
	value: string;
}

const MeasurementSelector = observer((props: MeasurementSelectorProps) => {
	const { value } = props;

	return (
		<div className="calcMeasurement-wrapper">
			<div className="calcMeasurement-inner-bit">{value}</div>
		</div>
	);
});
