import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button, Colors } from 'Views/Components/Button/Button';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import {withRouter, WithRouterProps} from "../../Util/withRouter";

@observer
class PageAccessDenied extends React.Component<WithRouterProps> {
	public render() {
		return (
			<div className='error-info__page'>
				<div className="error-info__elements">
					<h1 className="error-info__heading">403</h1>
					<p className="error-info__info">Oops! Unfortunately you are not allowed to access this page!</p>
					<ButtonGroup alignment={Alignment.HORIZONTAL}>
						<Button colors={Colors.Error} onClick={() => this.props.navigate(-1)}>
							Return to Previous Page 
						</Button>
						<Link to="/logout" className="btn btn--solid btn--error">
							Logout
						</Link>
					</ButtonGroup>
				</div>
			</div>
		);
	}
}

export default withRouter(PageAccessDenied)

