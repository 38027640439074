import * as React from 'react';
import { observer } from 'mobx-react';
import { SERVER_URL } from '../../../Constants';
import {withRouter, WithRouterProps}  from "../../../Util/withRouter";
import { store } from "Models/Store";

@observer
class Logout extends React.Component<WithRouterProps> {
	public componentDidMount() {
		const location = new URLSearchParams(this.props.location.search.substring(1))['redirect'];
		let qry = `?redirect=${location ? `${location}` : '/'}`;
		window.location.href = `${SERVER_URL}/api/authorization/logout${qry}`;
		
		store.clearLoggedInUser();
	}
	
	

	public render() {
		return null;
	}
}

export default withRouter(Logout)
