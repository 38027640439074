import React, {Component} from 'react';
import classNames from 'classnames';

interface CardProps {
	rounded?: boolean;
	className?: string;
	icon?: string | React.ReactNode;
	iconOnly?: boolean;
	href?: string;
	children?: React.ReactNode;
}
export default class Card extends Component<CardProps> {
	
	private onClick = () => {
		if (this.props.href) {
			window.open(this.props.href);
		}
	};
	
	render() {
		return (
			<div className={classNames('card', this.props.rounded ? 'card--rounded' : '', this.props.className)}>
				<div className={'card--content'} onClick={this.onClick}>
					{
						this.props.icon 
							? typeof(this.props.icon) === 'string' 
							? <div className={classNames('icon', this.props.iconOnly ? 'icon-only' : '', this.props.icon)}/> 
							: <div className={classNames('icon', this.props.iconOnly ? 'icon-only' : '')}>{this.props.icon}</div>
							: null
					}
					{this.props.children}
				</div>
			</div>
		);
	}
	
}
