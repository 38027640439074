import React, {Component} from 'react';
import classNames from 'classnames';

interface CardGroupProps {
	layout?: 'horizontal' | 'vertical';
	className?: string;
	children?: React.ReactNode;
}

export default class CardGroup extends Component<CardGroupProps> {



	render() {
		return (
			<div className={classNames('cards-wrapper', this.props.layout ? this.props.layout : 'horizontal', this.props.className)}>
				{this.props.children}
			</div>
		);
	}

}
