import { SERVER_URL } from 'Constants';
import axios from 'axios';
import { getEmailPattern } from 'Validators/Functions/Email';
import { getErrorMessages } from 'Util/GraphQLUtils';
import {
	Input,
	Button,
	useToast,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Flex,
	Box,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

type FormValues = {
	email: string;
};

let formSubmitted: boolean = false;

export const ResetPasswordRequestPage = () => {
	const toast = useToast();
	const navigate = useNavigate();

	const onSubmit = (formData: FormValues) => {
		axios
			.post(`${SERVER_URL}/api/account/reset-password-request`, {
				username: formData.email,
			})
			.then(({ data }) => {
				onResetPasswordSent(formData);
			})
			.catch(response => {
				const errorMessages = getErrorMessages(response).map((error: any) => (
					<p>{error.message}</p>
				));
				toast({
					title: 'Sending request failed',
					description: errorMessages,
					position: 'bottom-right',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			});
	};

	const onResetPasswordSent = (formData: FormValues) => {
		toast({
			title: 'Success',
			description: `Reset Password Email has been sent to ${formData.email}`,
			position: 'bottom-right',
			status: 'success',
			duration: 5000,
			isClosable: true,
		});
		navigate('/login');
	};

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<FormValues>();

	return (
		<Flex justifyContent="center" alignItems="center" gridArea="body">
			<Box
				className="login-container"
				flex="1"
				mb={{ base: '35%', lg: '140px' }}
			>
				<form className="reset-password" onSubmit={handleSubmit(onSubmit)}>
					<Flex
						flexDirection="column"
						alignItems="center"
						width={{ base: '80%', lg: '60%' }}
						maxWidth="600px"
						margin="auto"
					>
						<Box width="100%">
							<h4>Reset Password</h4>
						</Box>
						<FormControl
							display="flex"
							flexDir="column"
							width="100%"
							variant="floating"
							isInvalid={!!errors.email}
							id="request_email"
							ml={{ base: '0', md: '5px' }}
						>
							<Input
								type="text"
								size="lg"
								width="100%"
								{...register('email', {
									required: true,
									pattern: getEmailPattern(),
								})}
							/>
							<FormLabel>Email Address</FormLabel>
							{formSubmitted && errors.email?.type === 'required' ? (
								<FormErrorMessage>Email is required</FormErrorMessage>
							) : (
								''
							)}
							{formSubmitted && errors.email?.type === 'pattern' ? (
								<FormErrorMessage>Email format invalid</FormErrorMessage>
							) : (
								''
							)}
						</FormControl>
						<Flex
							flexDirection={{ base: 'column', md: 'row' }}
							alignItems={{ base: 'center', md: 'flex-start' }}
							width="100%"
						>
							<Button
								type="submit"
								id="reset_password"
								colorScheme="blue"
								width="100%"
								mr={{ base: '0', md: '5px !important' }}
								mb={{ base: '10px !important', md: '0' }}
								onClick={() => (formSubmitted = true)}
							>
                Reset Password
							</Button>
							<Button
								id="cancel_reset_pwd"
								onClick={() => navigate('/login')}
								width="100%"
								variant="outline"
								colorScheme="gray"
								ml={{ base: '0', md: '5px !important' }}
							>
                Cancel
							</Button>
						</Flex>
					</Flex>
				</form>
			</Box>
		</Flex>
	);
};
