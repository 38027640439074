import { ApolloClient } from '@apollo/client';
import { History } from 'history';
import {action, computed, observable, toJS} from 'mobx';
import { IFilter } from 'Views/Components/Collection/CollectionFilterPanel';
import * as Models from 'Models/Entities';
import { IGlobalModal } from '../Views/Components/Modal/GlobalModal';
import {UserSiteAccessEntity, SiteEntity, SiteGroupEntity} from './Entities';

export interface IGroupResult {
	name: string;
	hasBackendAccess: boolean;
}

export interface IUserResult {
	id: string;
	email: string;
	groups: IGroupResult[];
	userSiteAccess: UserSiteAccessEntity[];
	sites: SiteEntity[];
	userType: string;
	
	trainee: boolean;
}

/**
 * A global singleton store that contains a global state of data
 */
export class Store {
	@observable
	private user?: IUserResult;

	/**
	 * The current location in the application
	 */
	@observable
	public appLocation: 'frontend' | 'admin' = 'frontend';

	/**
	 * The client for Apollo
	 */
	public apolloClient: ApolloClient<object>;

	/**
	 * The global modal that is stored in the app and can be called imperatively
	 */
	public modal: IGlobalModal;

	/**
	 * This signifies weather we are logged in or not
	 * Only ever set this value to true if there is a value set in this.token
	 */
	@computed
	public get loggedIn() {
		// return this.getUser !== undefined;
		return this.user !== undefined;
	}

	/**
	 * The user Id of the logged-in user
	 */
	@computed
	public get userId(): string | undefined {
		return this.user ? this.user.id : undefined;
	}

	/**
	 * The email of the current logged in user
	 */
	@computed
	public get email(): string | undefined {
		return this.user ? this.user.email : undefined;
	}

	/**
	 * The groups that the logged in user are a part of
	 */
	@computed
	public get userGroups(): IGroupResult[] {
		if (this.user) {
			return [...this.user.groups];
		}
		return [];
	}

	/**
	 * Does this user have access to the backend admin views
	 */
	@computed
	public get hasBackendAccess() {
		if (this.user) {
			return this.user.groups.some(ug => ug.hasBackendAccess);
		}
		return false;
	}

	/**
	 * Is the frontend in edit mode
	 */
	@observable
	public frontendEditMode = false;

	/**
	 * Sets the current logged in user in the store
	 * @param userResult
	 */
	@action
	public setLoggedInUser(userResult: IUserResult) {
		this.user = userResult;
	}

	/**
	 * Clears the logged in user data from the store
	 */
	@action clearLoggedInUser() {
		this.user = undefined;
		// localStorage.removeItem('user');
	}

	@computed
	public get userSiteAccess() {
		if (this.user) {
			return this.user.userSiteAccess;
		}
		return [];
	}
	
	@computed
	public get hasSessions(): boolean {
		// Super admin has access
		if (this.userGroups.map(ug => ug.name).includes('SuperAdmin')) {
			return true;
		}

		// If the user has at least one site with sessions
		const hasSessionAccess = this.user?.userSiteAccess.find(us => us.featureSessions === true);
		
		return hasSessionAccess !== undefined;
	}

	@computed
	public get sites() {
		if (this.user) {
			return this.user.sites;
		}
		return [];
	}

	@computed
	public get userType() {
		if (this.user) {
			return this.user.userType;
		}
		return '';
	}
	
	@computed
	public get getUser() {
		return this.user;
	}

	@observable
	public studyFilters: Array<IFilter<Models.StudyEntity>> = [];

	@action setStudyFilters = (filters: Array<IFilter<Models.StudyEntity>>) => {
		this.studyFilters = filters;
	};

	@observable
	public studyFilterApplied: boolean = false;

	@action setStudyFilterApplied = (applied: boolean) => {
		this.studyFilterApplied = applied;
	};

	@observable
	public USAFilters: Array<IFilter<Models.StudyEntity>> = [];

	@action setUSAFilters = (filters: Array<IFilter<Models.StudyEntity>>) => {
		this.USAFilters = filters;
	};

	@observable
	public USAFilterApplied: boolean = false;

	@action setUSAFilterApplied = (applied: boolean) => {
		this.USAFilterApplied = applied;
	};
	
	@observable
	public shortcutModal: boolean = false;

	@action setShortcutModal = (openState: boolean) => {
		this.shortcutModal = openState;
	};
	
	@observable
	public archiveModal: boolean = false;

	@action setArchiveModal = (openState: boolean) => {
		this.archiveModal = openState;
	};


	@observable
	public tempReportState: object = {};
	@action setTempReportState = (tempState: object) => {
		this.tempReportState = tempState;
	}
	
	@action clearTempReportState = () => {
		this.tempReportState = {};
	}

	@computed
	public get getTempReportState() {
		return this.tempReportState;
	}
	
	
}

export const store = new Store();
