import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsStructuredReportEntity } from 'Models/Security/Acl/VisitorsStructuredReportEntity';
import { SuperAdminStructuredReportEntity } from 'Models/Security/Acl/SuperAdminStructuredReportEntity';
import { UserStructuredReportEntity } from 'Models/Security/Acl/UserStructuredReportEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { CRUD } from '../CRUDOptions';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';

export interface IStructuredReportEntityAttributes extends IModelAttributes {
	originalData: string;
	currentData: string;
	countFilesReceived: number;
	countFilesTotal: number;

	studyId?: string;
	study?: Models.StudyEntity | Models.IStudyEntityAttributes;
}

@entity('StructuredReportEntity', 'Structured Report')
export default class StructuredReportEntity extends Model implements IStructuredReportEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsStructuredReportEntity(),
		new SuperAdminStructuredReportEntity(),
		new UserStructuredReportEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
	];

	/**
	 * The original parsed structured report data in JSON format
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Original Data',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public originalData: string;

	/**
	 * The parsed structured report data which can be updated in JSON format
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Current Data',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public currentData: string;

	/**
	 * A count of the number of files received
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Count Files Received',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public countFilesReceived: number;

	/**
	 * A count of the number of files queued to be uploaded
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Count Files Total',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public countFilesTotal: number;

	@observable
	@attribute()
	@CRUD({
		name: 'Study',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.StudyEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'studys',
			oppositeEntity: () => Models.StudyEntity,
		}),
	})
	public studyId?: string;

	@observable
	@attribute({ isReference: true })
	public study: Models.StudyEntity;

	constructor(attributes?: Partial<IStructuredReportEntityAttributes>) {
		super(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IStructuredReportEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.originalData !== undefined) {
				this.originalData = attributes.originalData;
			}
			if (attributes.currentData !== undefined) {
				this.currentData = attributes.currentData;
			}
			if (attributes.countFilesReceived !== undefined) {
				this.countFilesReceived = attributes.countFilesReceived;
			}
			if (attributes.countFilesTotal !== undefined) {
				this.countFilesTotal = attributes.countFilesTotal;
			}
			if (attributes.studyId !== undefined) {
				this.studyId = attributes.studyId;
			}
			if (attributes.study !== undefined) {
				if (attributes.study === null) {
					this.study = attributes.study;
				} else if (attributes.study instanceof Models.StudyEntity) {
					this.study = attributes.study;
					this.studyId = attributes.study.id;
				} else {
					this.study = new Models.StudyEntity(attributes.study);
					this.studyId = this.study.id;
				}
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		study {
			${Models.StudyEntity.getAttributes().join('\n')}
		}
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'study',
						],
					},
				],
			},
		);
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		return this.id;
	}
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(StructuredReportEntity.prototype, 'created');
CRUD(modifiedAttr)(StructuredReportEntity.prototype, 'modified');
