import * as React from 'react';
import { Comparators } from '../ModelCollection/ModelQuery';
import { displayType } from 'Models/CRUDOptions';
import { Button, Display } from '../Button/Button';
import FilterDateRange from './CollectionFilterAttributes/FilterDateRangePicker';
import { ButtonGroup, Alignment } from '../Button/ButtonGroup';
import { MultiSelect, useMultiSelect, ChangeActions } from 'chakra-multiselect';
import { Model } from 'Models/Model';

import {
	runInAction,
} from 'mobx';
import { Box } from '@chakra-ui/react';
import { observer } from 'mobx-react';


export type IFilter<T> = {
	/** The column name to filter on */
	path: string;
	/** The comparison operator */
	comparison: Comparators | 'range';
	/** The value to filter on */
	value1: string | string[] | Date | number | undefined;
	/** 
	 * The second value to filter
	 * Only valid for 'range' type comparison for now where this represents the end of the range 
	 */
	value2: string | Date | number | undefined;
	/** this is specifically for the model of date range */
	active: boolean;
	/** The display type of the filter */
	displayType: displayType;
	/** The display name of the filter */
	displayName: string;
	/** The function to resolve and return the options of the enum-combobox (for now only enum-combobox) */
	enumResolveFunction?: Array<{display: string, value: string}>;
}


export interface ICollectionFilterPanelProps<T> {
	filters: IFilter<T>[];
	onClearFilter: () => void;
	onApplyFilter: () => void;
	onFilterChanged?: () => void;
	removeApplyFilter?: boolean;
	removeClearFilter?: boolean;
	dateFormat?: string;
	altFormat?: string;
}

const CollectionFilterPanel = observer((
	props: ICollectionFilterPanelProps<Model>
) => {
	if (props.filters === undefined || !props.filters.length) {
		return null;
	}

	const filterValues = {};
	props.filters.forEach(filter => {
		if (filter.displayType === 'enum-combobox') {
			filterValues[filter.path] = {};
			let currentValue: string[] = [];
			if (Array.isArray(filter.value1)) {
				currentValue = filter.value1.map(value => {
					const matchingOption = filter?.enumResolveFunction?.find(opt => opt.value === value);
					if (matchingOption) {
						return matchingOption.display;
					} else {
						return '';
					}
				});
			}
			// looks like there is a bug with useMultiselectOptions where the label attribute isnt working, so I'm setting both to the display value and will map the value correctly later 
			const {value, options, onChange} = useMultiSelect({
				value: currentValue,
				options: filter && filter.enumResolveFunction ? filter.enumResolveFunction.map((option: { display: string; value: string; }) => ({
					'value' : option.display,
					'label' : option.display,
				})) : [],
			});
			filterValues[filter.path].value = value;
			filterValues[filter.path].options = options;
			filterValues[filter.path].onChange = onChange;
			filterValues[filter.path].filter = filter;
			
			React.useEffect(() => {
				runInAction(() => {
					Object.keys(filterValues).forEach(filterPath => {
						filterValues[filterPath].value = standardizeArray(filterValues[filterPath].value);
						if (filterValues[filterPath].filter.displayType === 'enum-combobox') {
							// for each enum combobox, massage the data from an array of label strings, into an array of value strings on the value1 attribute
							filterValues[filterPath].filter.value1 = filterValues[filterPath].value.map((label: any) => filterValues[filterPath].filter.enumResolveFunction.find((element: { display: string; value: string; }) => element.display === label.value).value);
						}
					});
					props.onApplyFilter();
					if (props.onFilterChanged) {
						props.onFilterChanged();
					}
				});
			}, [filterValues[filter.path].value]);
		}
	});

	type StringArrayElement = string;
	type ObjectArrayElement = { value: string; label: string };
	type MixedArrayElement = StringArrayElement | ObjectArrayElement;

	function isObjectArrayElement(element: MixedArrayElement): element is ObjectArrayElement {
		return typeof element === 'object' && element !== null && 'value' in element && 'label' in element;
	}

	function standardizeArray(value: string | string[] | undefined): ObjectArrayElement[] {
		if (value === undefined) {
			return [];
		}

		// If the value is a string, convert it into an array with one element
		if (typeof value === 'string') {
			return [{ value, label: value }];
		}

		// Now value is guaranteed to be an array of strings or objects
		return value.map(item => {
			if (isObjectArrayElement(item)) {
				// If the item is already in the correct format, return it as is
				return item;
			} else {
				// Otherwise, convert the string to the object format
				return { value: item, label: item };
			}
		});
	}


	const clearFilters = () => {
		props.onClearFilter();
		Object.keys(filterValues).forEach(filterPath => {
			filterValues[filterPath].onChange([], ChangeActions.MultiClear);		
		});
	}
	
	return (
		<>

			<div className="collection-filter-form__container">
				{
					props.filters.map((filter: IFilter<Model>) => {
						switch (filter.displayType) {
								
							case 'datepicker':
								if (filter.comparison === 'range') {
									return (
										<FilterDateRange
											filter={filter}
											className={'filter-' + filter.path}
											key={'filter-' + filter.path}
											dateFormat={props.dateFormat}
											altFormat={props.altFormat}
											onAfterChange={() => {
												if (props.onFilterChanged) {
													props.onFilterChanged();
												}
											}}
										/>
									);
								}
								return '';
								
								
							case 'enum-combobox':
								if (filter && filter.enumResolveFunction) {
									return (
										<Box 
											width="300px"
											margin="0 1.5rem"
											key={'filter-' + filter.path}
											className="filter-multiselect-chakra"
										>
											<MultiSelect
												value={filterValues[filter.path].value}
												options={filterValues[filter.path].options}
												label={filter.displayName}
												onChange={filterValues[filter.path].onChange}
												width="100px"
												className={'filter-' + filter.path}
											/>
										</Box>
									);
								} else {
									return '';
								}
						}
						return '';

					})
				}
			</div>
			<div className="collection-filter-form__actions">
				{
					
				}
				<ButtonGroup alignment={Alignment.HORIZONTAL}>
					{!props.removeClearFilter && (
						<Button
							className="clear-filters"
							display={Display.Outline}
							onClick={clearFilters}
						>
								Clear Filters
						</Button>
					)}
					{!props.removeApplyFilter && (
						<Button
							className="apply-filters"
							display={Display.Solid}
							onClick={props.onApplyFilter}
						>
								Apply Filters
						</Button>
					)}
				</ButtonGroup>
				{
					
				}
			</div>
			
		</>
	);
})

export default CollectionFilterPanel;