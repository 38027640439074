import moment from 'moment';
import { action, observable, computed } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
	getAttributes, getModelName, 
} from 'Util/EntityUtils';
import { VisitorsStudyEntity } from 'Models/Security/Acl/VisitorsStudyEntity';
import { SuperAdminStudyEntity } from 'Models/Security/Acl/SuperAdminStudyEntity';
import { UserStudyEntity } from 'Models/Security/Acl/UserStudyEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { gql } from '@apollo/client';
import * as Enums from '../Enums';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';

import { lowerCaseFirst } from '../../Util/StringUtils';
import { CRUD } from '../CRUDOptions';

export interface IStudyEntityAttributes extends IModelAttributes {
	studyInstanceUID: string;
	studyDate: Date;
	sonographer: string;
	trainee: string;
	clinicalDetails: string;
	urgent: boolean;
	archiveDate: Date;
	ecgId: string;
	procedureStatus: Enums.procedureStatusType;
	hl7messagesent: boolean;
	studyFilesCount: number;
	ecgExist: boolean;
	referralExist: boolean;
	diaryExist: boolean;
	reportMeasurements: string;
	studyType: Enums.studyType;
	selectedReportTemplate: string;
	doctorss: Array<Models.DoctorEntity | Models.IDoctorEntityAttributes>;
	measurementss: Array<Models.MeasurementEntity | Models.IMeasurementEntityAttributes>;
	shareLinkss: Array<Models.ShareLinkEntity | Models.IShareLinkEntityAttributes>;
	patientId?: string;
	patient?: Models.PatientEntity | Models.IPatientEntityAttributes;
	studyFiless: Array<Models.StudyFileEntity | Models.IStudyFileEntityAttributes>;
	holterReport?: Models.HolterReportEntity | Models.IHolterReportEntityAttributes;
	report?: Models.ReportEntity | Models.IReportEntityAttributes;
	structuredReport?: Models.StructuredReportEntity | Models.IStructuredReportEntityAttributes;
	totalStudyFiles: number;
	assignedDoctor: string;
	accessionNumber: string;
	measurementStructure?: string;
	rwmaPlotData?: string;
}

@entity('StudyEntity', 'Study')
export default class StudyEntity extends Model implements IStudyEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsStudyEntity(),
		new SuperAdminStudyEntity(),
		new UserStudyEntity(),
		new UserStudyEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
	];

	/**
	 * studyInstanceUID
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Study InstanceUID',
		displayType: 'textfield',
		order: 10,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public studyInstanceUID: string;

	/**
	 * The date of the study
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Study Date',
		displayType: 'datepicker',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public studyDate: Date;

	/**
	 * The Sonographer's name
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Sonographer',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public sonographer: string;
	
	@observable
	@attribute()
	@CRUD({
		name: 'Trainee',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public trainee: string;

	@observable
	@attribute()
	@CRUD({
		name: 'Assigned Doctor',
		displayType: 'textfield',
		order: 90,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
	})
	assignedDoctor: string;
	
	@observable
	@attribute()
	@CRUD({
		name: 'Accession Number',
		displayType: 'textfield',
		order: 90,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
	})
	accessionNumber: string;
	
	/**
	 * Clinical Details
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Clinical Details',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public clinicalDetails: string;

	@observable
	@attribute()
	ecgId: string;

	@observable
	@attribute()
	procedureStatus: Enums.procedureStatusType;

	/**
	 * Urgent
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Urgent',
		displayType: 'checkbox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public urgent: boolean;

	/**
	 * The DateTime the Study will be / was archived
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Archive Date',
		displayType: 'datetimepicker',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public archiveDate: Date;

	/**
	 * A boolean to check if a report has been sent via HL7
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'HL7MessageSent',
		displayType: 'checkbox',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public hl7messagesent: boolean;

	/**
	 * Source of truth for which measurements to use
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Report Measurements',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public reportMeasurements: string;

	@observable
	@attribute()
	@CRUD({
		name: 'Measurement Structure',
		displayType: 'textfield',
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public measurementStructure: string;

	@observable
	@attribute()
	@CRUD({
		name: 'RWMA Plot Data',
		displayType: 'textfield',
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public rwmaPlotData: string;


	/**
	 * Service Type
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Study Type',
		displayType: 'enum-combobox',
		order: 90,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => AttrUtils.standardiseEnum(attr, Enums.studyTypeOptions),
		enumResolveFunction: makeEnumFetchFunction(Enums.studyTypeOptions),
		displayFunction: (attribute: Enums.studyType) => Enums.studyTypeOptions[attribute],
	})
	public studyType: Enums.studyType;

	/**
	 * The template that is selected by the clinician
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Selected Report Template',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public selectedReportTemplate: string = 'Default';

	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Doctorss',
		displayType: 'reference-multicombobox',
		order: 110,
		referenceTypeFunc: () => Models.DoctorEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'doctorss',
			oppositeEntity: () => Models.DoctorEntity,
		}),
	})
	public doctorss: Models.DoctorEntity[] = [];

	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Measurementss',
		displayType: 'reference-multicombobox',
		order: 120,
		referenceTypeFunc: () => Models.MeasurementEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'measurementss',
			oppositeEntity: () => Models.MeasurementEntity,
		}),
	})
	public measurementss: Models.MeasurementEntity[] = [];

	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Share Linkss',
		displayType: 'reference-multicombobox',
		order: 130,
		referenceTypeFunc: () => Models.ShareLinkEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'shareLinkss',
			oppositeEntity: () => Models.ShareLinkEntity,
		}),
	})
	public shareLinkss: Models.ShareLinkEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		name: 'Patient',
		displayType: 'reference-combobox',
		order: 140,
		referenceTypeFunc: () => Models.PatientEntity,
	})
	public patientId?: string;
	
	@observable
	@attribute({ isReference: true })
	public patient: Models.PatientEntity;

	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Study Filess',
		displayType: 'file-list',
		order: 140,
		referenceTypeFunc: () => Models.StudyFileEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'studyFiless',
			oppositeEntity: () => Models.StudyFileEntity,
		}),
	})
	public studyFiless: Models.StudyFileEntity[] = [];
	
	@observable
	public studyFilesCount: number;

	@observable
	public ecgExist: boolean;
	

	@observable
	public referralExist: boolean;
	
	
	@observable
	public diaryExist: boolean;

	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Holter Report',
		displayType: 'reference-combobox',
		order: 160,
		referenceTypeFunc: () => Models.HolterReportEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'holterReports',
			oppositeEntity: () => Models.HolterReportEntity,
		}),
	})
	public holterReport?: Models.HolterReportEntity;

	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Report',
		displayType: 'reference-combobox',
		order: 170,
		referenceTypeFunc: () => Models.ReportEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'reports',
			oppositeEntity: () => Models.ReportEntity,
		}),
	})
	public report?: Models.ReportEntity;

	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Structured Report',
		displayType: 'reference-combobox',
		order: 180,
		referenceTypeFunc: () => Models.StructuredReportEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'structuredReports',
			oppositeEntity: () => Models.StructuredReportEntity,
		}),
	})
	public structuredReport?: Models.StructuredReportEntity;

	public totalStudyFiles: number = 50;

	@CRUD({
		name: 'Patient',
		displayType: 'hidden',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		customAttributeName: 'patient.name',
		displayFunction: (attr, that) => (that['patient'] ? that['patient']['name'] : ''),
	})
	@computed
	public get patientName(): string {
		return this.patient.name;
	}

	constructor(attributes?: Partial<IStudyEntityAttributes>) {
		super(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IStudyEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.studyInstanceUID !== undefined) {
				this.studyInstanceUID = attributes.studyInstanceUID;
			}
			if (attributes.studyDate !== undefined) {
				if (attributes.studyDate === null) {
					this.studyDate = attributes.studyDate;
				} else {
					let { studyDate } = attributes;
					
					if (typeof attributes.studyDate === 'string' && (attributes.studyDate as string).endsWith('Z')) {
						studyDate = (attributes.studyDate as any).slice(0, -1);
					}
				
					this.studyDate = moment(studyDate).toDate();
				}
			}
			if (attributes.sonographer !== undefined) {
				this.sonographer = attributes.sonographer;
			}
			if (attributes.trainee !== undefined) {
				this.trainee = attributes.trainee;
			}
			if (attributes.clinicalDetails !== undefined) {
				this.clinicalDetails = attributes.clinicalDetails;
			}
			if (attributes.urgent !== undefined) {
				this.urgent = attributes.urgent;
			}
			if (attributes.assignedDoctor !== undefined) {
				this.assignedDoctor = attributes.assignedDoctor;
			}
			if (attributes.accessionNumber !== undefined) {
				this.accessionNumber = attributes.accessionNumber;
			}
			if (attributes.ecgId !== undefined) {
				this.ecgId = attributes.ecgId;
			}
			if (attributes.procedureStatus !== undefined) {
				this.procedureStatus = attributes.procedureStatus;
			}
			if (attributes.archiveDate !== undefined) {
				if (attributes.archiveDate === null) {
					this.archiveDate = attributes.archiveDate;
				} else {
					let { archiveDate } = attributes;

					if (typeof attributes.archiveDate === 'string' && (attributes.archiveDate as string).endsWith('Z')) {
						archiveDate = (attributes.archiveDate as any).slice(0, -1);
					}
					
					this.archiveDate = moment(archiveDate).toDate();
				}
			}
			if (attributes.hl7messagesent !== undefined) {
				this.hl7messagesent = attributes.hl7messagesent;
			}
			if (attributes.studyFilesCount !== undefined) {
				this.studyFilesCount = attributes.studyFilesCount;
			}
			if (attributes.ecgExist !== undefined) {
				this.ecgExist = attributes.ecgExist;
			}
			if (attributes.referralExist !== undefined) {
				this.referralExist = attributes.referralExist;
			}
			if (attributes.diaryExist !== undefined) {
				this.diaryExist = attributes.diaryExist;
			}
			if(attributes.selectedReportTemplate !== undefined) {
				this.selectedReportTemplate = attributes.selectedReportTemplate;
			}
			if (attributes.reportMeasurements !== undefined) {
				this.reportMeasurements = attributes.reportMeasurements;
			}
			if (attributes.measurementStructure !== undefined) {
				this.measurementStructure = attributes.measurementStructure;
			}
			if (attributes.rwmaPlotData !== undefined) {
				this.rwmaPlotData = attributes.rwmaPlotData;
			}
			if (attributes.studyType !== undefined) {
				this.studyType = attributes.studyType;
			}
			if (attributes.doctorss !== undefined && Array.isArray(attributes.doctorss)) {
				for (const model of attributes.doctorss) {
					if (model instanceof Models.DoctorEntity) {
						this.doctorss.push(model);
					} else {
						this.doctorss.push(new Models.DoctorEntity(model));
					}
				}
			}
			if (attributes.measurementss !== undefined && Array.isArray(attributes.measurementss)) {
				for (const model of attributes.measurementss) {
					if (model instanceof Models.MeasurementEntity) {
						this.measurementss.push(model);
					} else {
						this.measurementss.push(new Models.MeasurementEntity(model));
					}
				}
			}
			if (attributes.shareLinkss !== undefined && Array.isArray(attributes.shareLinkss)) {
				for (const model of attributes.shareLinkss) {
					if (model instanceof Models.ShareLinkEntity) {
						this.shareLinkss.push(model);
					} else {
						this.shareLinkss.push(new Models.ShareLinkEntity(model));
					}
				}
			}
			if (attributes.patient !== undefined) {
				if (attributes.patient === null) {
					this.patient = attributes.patient;
				} else if (attributes.patient instanceof Models.PatientEntity) {
					this.patient = attributes.patient;
					this.patientId = attributes.patient.id;
				} else {
					this.patient = new Models.PatientEntity(attributes.patient);
					this.patientId = this.patient.id;
				}
			} else if (attributes.patientId !== undefined) {
				this.patientId = attributes.patientId;
			}
			if (attributes.studyFiless !== undefined && Array.isArray(attributes.studyFiless)) {
				for (const model of attributes.studyFiless) {
					if (model instanceof Models.StudyFileEntity) {
						this.studyFiless.push(model);
					} else {
						this.studyFiless.push(new Models.StudyFileEntity(model));
					}
				}
			}
			if (attributes.holterReport !== undefined) {
				if (attributes.holterReport === null) {
					this.holterReport = attributes.holterReport;
				} else if (attributes.holterReport instanceof Models.HolterReportEntity) {
					this.holterReport = attributes.holterReport;
				} else {
					this.holterReport = new Models.HolterReportEntity(attributes.holterReport);
				}
			}
			if (attributes.report !== undefined) {
				if (attributes.report === null) {
					this.report = attributes.report;
				} else if (attributes.report instanceof Models.ReportEntity) {
					this.report = attributes.report;
				} else {
					this.report = new Models.ReportEntity(attributes.report);
				}
			}
			if (attributes.structuredReport !== undefined) {
				if (attributes.structuredReport === null) {
					this.structuredReport = attributes.structuredReport;
				} else if (attributes.structuredReport instanceof Models.StructuredReportEntity) {
					this.structuredReport = attributes.structuredReport;
				} else {
					this.structuredReport = new Models.StructuredReportEntity(attributes.structuredReport);
				}
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		doctorss {
			${Models.DoctorEntity.getAttributes().join('\n')}
		}
		measurementss {
			${Models.MeasurementEntity.getAttributes().join('\n')}
		}
		shareLinkss {
			${Models.ShareLinkEntity.getAttributes().join('\n')}
		}
		patient {
			${Models.PatientEntity.getAttributes().join('\n')}
			site {
				${Models.SiteEntity.getAttributes().join('\n')}
				translationFileId
				reportTemplates {
					${Models.ReportTemplateEntity.getAttributes().join('\n')}
				}
			}
		}
		holterReport {
			${Models.HolterReportEntity.getAttributes().join('\n')}
		}
		report {
			${Models.ReportEntity.getAttributes().join('\n')}
		}
		structuredReport {
			${Models.StructuredReportEntity.getAttributes().join('\n')}
		}
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			doctorss: {},
			measurementss: {},
			shareLinkss: {},
			studyFiless: {},
			holterReport: {},
			report: {},
			structuredReport: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'doctorss',
							'measurementss',
							'shareLinkss',
							'studyFiless',
							'holterReport',
							'report',
							'structuredReport',
						],
					},
				],
			},
		);
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		return this.id;
	}

	public toggleUrgent() {
		this.urgent = !this.urgent;
	}

	public setSelectedReportTemplate(template: string) {
		this.selectedReportTemplate = template;
	}

	public getSelectedReportTemplate(): string {
		return this.selectedReportTemplate;
	}

	@action
	public toggleHL7MessageSent() {
		this.hl7messagesent = !this.hl7messagesent;
	}

	public static getFetchStudy() {
		const model = new StudyEntity();
		const modelName = lowerCaseFirst(getModelName(StudyEntity));

		return gql`
			query ${modelName} ($args:[WhereExpressionGraph]) {
				${modelName} (where: $args) {
					${getAttributes(StudyEntity).join('\n')}
					${model.defaultExpands}
					studyFiless {
						fileType
						studyFileId
					}
				}
			}`;
	}
	
	public static getMinStudy() {
		
		const modelName = lowerCaseFirst(getModelName(StudyEntity));

		return gql`
			query ${modelName} ($args:[WhereExpressionGraph]) {
				${modelName} (where: $args) {
					id
					studyType
					sonographer
					clinicalDetails
					assignedDoctor
					trainee
					selectedReportTemplate
					procedureStatus
					urgent
					studyDate
					studyInstanceUID
					measurementStructure
					accessionNumber
					hl7messagesent
					rwmaPlotData
					studyInstanceUID
					patient {
						${Models.PatientEntity.getAttributes().join('\n')}
						site {
							${Models.SiteEntity.getAttributes().join('\n')}
							translationFileId
							rwmaPlotLabels
							hL7ProviderId
							healthImaging
							reportTemplates {
								${Models.ReportTemplateEntity.getAttributes().join('\n')}
							}
						}
					}
					report {
						${Models.ReportEntity.getAttributes().join('\n')}
					}
					doctorss {
						${Models.DoctorEntity.getAttributes().join('\n')}
					}
				}
			}`;
	}

	public static getStudyMergeOverview() {

		const modelName = lowerCaseFirst(getModelName(StudyEntity));

		return gql`
			query ${modelName}s ($args:[WhereExpressionGraph]) {
				${modelName}s (where: $args) {
					id
					studyType
					studyDate
					patient {
						firstName
						lastName
						name
						dob
						patientId
						site {
							siteName
							ahiDatastoreId
						}
					}
					studyFiless {
						id
					}
				}
			}`;
	}
	
	public static getHolterStudy() {
		const modelName = lowerCaseFirst(getModelName(StudyEntity));

		return gql`
			query ${modelName} ($args:[WhereExpressionGraph]) {
				${modelName} (where: $args) {
					id
					studyType
					sonographer
					clinicalDetails
					assignedDoctor
					ecgId
					procedureStatus
					trainee
					selectedReportTemplate
					urgent
					hl7messagesent
					studyInstanceUID
					studyDate
					measurementStructure
					accessionNumber
					patient {
						${Models.PatientEntity.getAttributes().join('\n')}
						site {
							advancedReportBuilder
							hL7ProviderId
						}
					}
					holterReport {
						${Models.HolterReportEntity.getAttributes().join('\n')}
					}
					doctorss {
						${Models.DoctorEntity.getAttributes().join('\n')}
					}
				}
			}`;
	}
	
	public static getStudyType() {
		const modelName = lowerCaseFirst(getModelName(StudyEntity));

		return gql`
			query ${modelName} ($args:[WhereExpressionGraph]) {
				${modelName} (where: $args) {
					id
					studyType
				}
			}`;
	}
	

	public listExpands = `
    patient {
        name
    }
   
	`;
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(StudyEntity.prototype, 'created');
CRUD(modifiedAttr)(StudyEntity.prototype, 'modified');
