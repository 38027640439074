import { action, computed, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsHolterReportEntity } from 'Models/Security/Acl/VisitorsHolterReportEntity';
import { SuperAdminHolterReportEntity } from 'Models/Security/Acl/SuperAdminHolterReportEntity';
import { UserHolterReportEntity } from 'Models/Security/Acl/UserHolterReportEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import * as Enums from '../Enums';
import { CRUD } from '../CRUDOptions';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';

export interface IHolterReportEntityAttributes extends IModelAttributes {
	reportStatus: Enums.holterReportStatus;
	correspondence: string;
	reportDataGenerated: string;
	reportData: string;
	analysed: boolean;
	reportingDoctor?: string;
	studyId?: string;
	study?: Models.StudyEntity | Models.IStudyEntityAttributes;
}

@entity('HolterReportEntity', 'Holter Report')
export default class HolterReportEntity extends Model implements IHolterReportEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsHolterReportEntity(),
		new SuperAdminHolterReportEntity(),
		new UserHolterReportEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
	];

	@observable
	@attribute()
	@CRUD({
		name: 'Report Status',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => AttrUtils.standardiseEnum(attr, Enums.holterReportStatusOptions),
		enumResolveFunction: makeEnumFetchFunction(Enums.holterReportStatusOptions),
		displayFunction: (attribute: Enums.holterReportStatus) => Enums.holterReportStatusOptions[attribute],
	})
	public reportStatus: Enums.holterReportStatus;

	@observable
	@attribute()
	@CRUD({
		name: 'Correspondence',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public correspondence: string;

	/**
	 * The generated report data
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Report Data Generated',
		displayType: 'textfield',
		order: 30,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public reportDataGenerated: string;

	/**
	 * Report Data
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Report Data',
		displayType: 'textfield',
		order: 40,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public reportData: string;

	/**
	 * Analysed
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Analysed',
		displayType: 'checkbox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public analysed: boolean;

	@observable
	@attribute()
	@CRUD({
		name: 'Study',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.StudyEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'studys',
			oppositeEntity: () => Models.StudyEntity,
		}),
	})
	public studyId?: string;

	@observable
	@attribute({ isReference: true })
	public study: Models.StudyEntity;

	@observable
	@attribute()
	public reportingDoctor: string;

	@CRUD({
		name: 'Patient',
		displayType: 'hidden',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		customAttributeName: 'study.patient.name',
		displayFunction: (attr, that) => (that['study'] && that['study']['patient'] ? that['study']['patient']['name'] : ''),
	})
	@computed
	public get patientName(): string {
		return this.study.patient.name;
	}

	constructor(attributes?: Partial<IHolterReportEntityAttributes>) {
		super(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IHolterReportEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.reportStatus !== undefined) {
				this.reportStatus = attributes.reportStatus;
			}
			if (attributes.correspondence !== undefined) {
				this.correspondence = attributes.correspondence;
			}
			if (attributes.reportDataGenerated !== undefined) {
				this.reportDataGenerated = attributes.reportDataGenerated;
			}
			if (attributes.reportData !== undefined) {
				this.reportData = attributes.reportData;
			}
			if (attributes.analysed !== undefined) {
				this.analysed = attributes.analysed;
			}
			if (attributes.studyId !== undefined) {
				this.studyId = attributes.studyId;
			}
			if (attributes.reportingDoctor !== undefined) {
				this.reportingDoctor = attributes.reportingDoctor;
			}
			if (attributes.study !== undefined) {
				if (attributes.study === null) {
					this.study = attributes.study;
				} else if (attributes.study instanceof Models.StudyEntity) {
					this.study = attributes.study;
					this.studyId = attributes.study.id;
				} else {
					this.study = new Models.StudyEntity(attributes.study);
					this.studyId = this.study.id;
				}
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		study {
			${Models.StudyEntity.getAttributes().join('\n')}
			patient {
				name
			}
		}
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'study',
						],
					},
				],
			},
		);
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		return this?.study?.patient?.name || this.id;
	}

	public listExpands = `
    study {
    	patient {
    		name
        }
    }
	`;
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(HolterReportEntity.prototype, 'created');
CRUD(modifiedAttr)(HolterReportEntity.prototype, 'modified');
