import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsAssetEntity } from 'Models/Security/Acl/VisitorsAssetEntity';
import { SuperAdminAssetEntity } from 'Models/Security/Acl/SuperAdminAssetEntity';
import { UserAssetEntity } from 'Models/Security/Acl/UserAssetEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import * as React from 'react';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';
import { CRUD } from '../CRUDOptions';

export interface IAssetEntityAttributes extends IModelAttributes {
	assetIdentity: string;
	siteId?: string;
	site?: Models.SiteEntity | Models.ISiteEntityAttributes;
	cardiologsFolder?: string;
}

@entity('AssetEntity', 'Asset')
export default class AssetEntity extends Model implements IAssetEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsAssetEntity(),
		new SuperAdminAssetEntity(),
		new UserAssetEntity(),
	];

	/**
     * Fields to exclude from the JSON serialization in create operations.
     */
	public static excludeFromCreate: string[] = [
	];

	/**
     * Fields to exclude from the JSON serialization in update operations.
     */
	public static excludeFromUpdate: string[] = [
	];
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Asset Identity',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public assetIdentity: string;

	@observable
	@attribute()
	@CRUD({
		name: 'Site',
		displayType: 'reference-combobox',
		headerColumn: true,
		order: 80,
		referenceTypeFunc: () => Models.SiteEntity,
		displayFunction: (attr: any, that: Model) => <p>{that['site']?.siteName}</p>,
	})
	public siteId?: string;

	@observable
	@attribute({ isReference: true })
	public site: Models.SiteEntity;
    
	@observable
	@attribute()
	@CRUD({
		name: 'Cardiologs Folder',
		displayType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public cardiologsFolder?: string;

	/**
     * Assigns fields from a passed in JSON object to the fields in this model.
     * Any reference objects that are passed in are converted to models if they are not already.
     * This function is called from the constructor to assign the initial fields.
     */
	@action
	public assignAttributes(attributes?: Partial<IAssetEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.assetIdentity !== undefined) {
				this.assetIdentity = attributes.assetIdentity;
			}
			
			if(attributes.cardiologsFolder !== undefined) {
				this.cardiologsFolder = attributes.cardiologsFolder;
			}
          
			if (attributes.site !== undefined) {
				if (attributes.site === null) {
					this.site = attributes.site;
				} else if (attributes.site instanceof Models.SiteEntity) {
					this.site = attributes.site;
					this.siteId = attributes.site.id;
				} else {
					this.site = new Models.SiteEntity(attributes.site);
					this.siteId = this.site.id;
				}
			} else if (attributes.siteId !== undefined) {
				this.siteId = attributes.siteId;
			}
		}
	}

	/**
     * Additional fields that are added to GraphQL queries when using the
     * the managed model APIs.
     */
	public defaultExpands = `
		site {
			${Models.SiteEntity.getAttributes().join('\n')}
		}
	`;

	/**
     * The save method that is called from the admin CRUD components.
     */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}

	/**
     * Returns the string representation of this entity to display on the UI.
     */
	public getDisplayName() {
		return this.assetIdentity;
	}

	public listExpands = `
		site {
			${Models.SiteEntity.getAttributes().join('\n')}
		}
	`;
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AssetEntity.prototype, 'created');
CRUD(modifiedAttr)(AssetEntity.prototype, 'modified');
