import { runInAction } from 'mobx';
import { inject, observer, useLocalStore } from 'mobx-react';
import { SiteEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import React, {useEffect, useState} from 'react';

import { Link, useNavigate } from 'react-router-dom';
import alert from 'Util/ToastifyUtils';
import { 
	Button, 
	Colors, 
	Display, 
	Sizes, 
} from 'Views/Components/Button/Button';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { withRouter, WithRouterProps }   from '../../../Util/withRouter';

const SiteTileTile = (props: WithRouterProps) => {
	const { params } = props;
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	const siteStore = useLocalStore(() => ({
		site: new SiteEntity(),
		loading: true,
		prevPath: '',
		canEdit: store.userGroups.map(ug => ug.name).includes('SuperAdmin') || (store.userType === 'SITE_ADMIN' && store.userSiteAccess.map(site => site.accessLevel === 'FULL_ACCESS' && site.siteId === params.id).includes(true)),
	}));

	const retrieveSite = () => {
		store.apolloClient.query({
			query: SiteEntity.fetchSingleSite(),
			fetchPolicy: 'network-only',
			variables: {
				args: [{
					path: 'id',
					comparison: 'equal',
					value: params.id,
				}],
			},
		}).then(res => {
			runInAction(() => {
				siteStore.site = new SiteEntity(res.data.siteEntity);
			});
			setLoading(false);
		}).catch(error => {
			console.error(error);
		});
	};

	const setPrevPath = () => {
		if (siteStore.prevPath === '') {
			runInAction(() => {
				if (store.userGroups.map(ug => ug.name).includes('SuperAdmin')  || store.userType === 'SITE_ADMIN') {
					siteStore.prevPath = '/sitedashboard';
				} else {
					siteStore.prevPath = '/profilepage';
				}
			});
		}
	};

	useEffect(() => {
		setPrevPath();
		retrieveSite();
	}, []);

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();

		const newSite = new SiteEntity(siteStore.site);
		newSite[e.target.name] = e.target.value;

		runInAction(() => {
			siteStore.site = newSite;
		});
	};

	const onSave = () => {
		siteStore.site.save().then(() => {
			alert(
				<div>
					<h6>Updated Site Details</h6>
				</div>,
				'success',
			);
		}).catch(errors => {
			alert(
				<div>
					<h6>Update Failed</h6>
					{errors}
				</div>,
				'success',
			);
		});
	};
	
	if(loading) {
		return <>loading ...</>
	} else {
		return <div className="site-container">
			<Link to={'..'}
				onClick={e => {
					e.preventDefault();
					navigate(-1);
			  }}>
				<Button colors={Colors.PrimaryLight} display={Display.Text} sizes={Sizes.Medium}
					buttonProps={{id: 'return'}} icon={{icon: 'chevron-left', iconPos: 'icon-left'}}>
					Back to {siteStore.prevPath}
					{
						siteStore.prevPath === '/sitedashboard' ? ' site dashboard' : ' profile'
					}
				</Button>
			</Link>
			<div className="site-detail">
				<h4>{siteStore.site.siteName}</h4>
			</div>
			<div className="site-content">
				<div className="site-information">
					<p>Site Information</p>
					<div className="site-information__container">
						<TextField
							model={siteStore.site}
							id="site_siteContact"
							className="information-label"
							modelProperty="primaryContactName"
							label="Site Contact"
							isDisabled={!siteStore.canEdit}
						/>
						<TextField
							model={siteStore.site}
							id="site_siteContactEmail"
							className="information-label"
							modelProperty="primaryContactEmail"
							label="Contact Email"
							isDisabled={!siteStore.canEdit}
						/>
						<TextField
							model={siteStore.site}
							id="site_siteLocation"
							className="information-label"
							modelProperty="streetAddress"
							label="Site Location"
							isDisabled={!siteStore.canEdit}
						/>
						<div id="hl7Container">
							<div id="checkboxContainer" hidden={!siteStore.canEdit}>
								<Checkbox
									model={siteStore.site}
									className="information-label"
									modelProperty="hl7MessagingEnabled"
									label="Enable HL7 Messaging"
								/>
							</div>
							<div id="textboxContainer" hidden={siteStore.canEdit}>
								<label>{siteStore.site.hl7MessagingEnabled ? 'HL7 Messaging Enabled' : 'HL7 Messaging Disabled'}</label>
							</div>
						</div>
						{siteStore.canEdit && (
							<Button colors={Colors.Primary} display={Display.Solid} sizes={Sizes.Medium}
								buttonProps={{id: 'save'}} onClick={onSave}>Save</Button>
						)}
					</div>
				</div>
			</div>
		</div>
	}
};

export default inject('store')(observer(withRouter(SiteTileTile)));
