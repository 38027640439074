
import { StudyEntity } from 'Models/Entities';
import { Sha256 } from '@aws-crypto/sha256-js';
import { HttpRequest } from '@smithy/protocol-http';
import { SignatureV4 } from '@smithy/signature-v4';
import { IoPause, IoPlay } from 'react-icons/io5';
import {
	Flex,
	Box,
	Button,
	Spinner,
	Select,
	FormControl,
	FormLabel,
	IconButton,
	Grid,
	Switch
} from '@chakra-ui/react';
import { useEffect, useState, useRef } from 'react';
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import NewStressViewerViewport, { xhrRequest } from './NewStressViewerViewport';
import { utilities } from '@cornerstonejs/tools';
import { getRenderingEngine } from '@cornerstonejs/core';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import _ from 'lodash';
import useFetchHealthImagingUrls from './useFetchHealthImagingURLs';
import { useNavigate } from 'react-router-dom';

interface NewStressImageViewerProps {
	study: StudyEntity;
	isImageViewerFullScreen: boolean;
	toggleStressCallback: ()=>void;
}

const REGION = process.env.REACT_APP_AWS_REGION;
const ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
const SERVICE = 'medical-imaging';
const HOST = 'dicom-medical-imaging.ap-southeast-2.amazonaws.com';

const signUrl = async (url: string): Promise<string> => {
	const parsedUrl = new URL(url);
	// @ts-ignore
	const queryParams = Object.fromEntries(parsedUrl.searchParams.entries());

	const signer = new SignatureV4({
		credentials: {
			accessKeyId: ACCESS_KEY_ID!,
			secretAccessKey: SECRET_ACCESS_KEY!,
		},
		service: SERVICE,
		region: REGION!,
		sha256: Sha256,
	});

	const request = new HttpRequest({
		method: 'GET',
		protocol: 'https:',
		hostname: parsedUrl.hostname,
		path: parsedUrl.pathname,
		query: queryParams,
		headers: {
			host: HOST,
			accept: 'application/dicom; transfer-syntax=1.2.840.10008.1.2.4.202',
		},
	});

	const signedRequest = await signer.sign(request);

	// @ts-ignore
	const signedUrl = `${signedRequest.protocol}//${signedRequest.hostname}${signedRequest.path}?${new URLSearchParams(signedRequest.query).toString()}`;

	return signedUrl;
};



const NewStressImageViewer = (props: NewStressImageViewerProps) => {

	const { study, toggleStressCallback } = props;
	const [visibleViewports, setVisibleViewports] = useState<any[]>([]);
	const viewportRef= useRef<any[]>([]);
	viewportRef.current = visibleViewports;
	const [viewportCurrentImages, setViewportCurrentImages] = useState<object>({});
	const [viewportCachedImages, setViewportCachedImages] = useState<string[]>([]);
	const [studyViews, setStudyViews] = useState<any>({})
	const [studyStages, setStudyStages] = useState<any>({})
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [stressEchoGroupMode, setStressEchoGroupMode] = useState<string>('view');
	const [selectedStressView, setSelectedStressView] = useState<string>('1');
	const [selectedStressStage, setSelectedStressStage] = useState<string>('1');
	const [isPlaying, setIsPlaying] = useState<boolean>(false);
	let loadedViewports: string[] = [];
	const parentContainer = useRef<HTMLDivElement | null>(null);
	const [framerateMultipier, setFramerateMultiplier] = useState<number>(1);
	const [stressViewportWidth, setViewportWidth] = useState<string>('');
	const [viewportHeight, setViewportHeight] = useState<string>('');
	const [gridTemplateRows, setGridTemplateRows] = useState<string[]>(['']);
	const [gridTemplateColumns, setGridTemplateColumns] = useState<string[]>(['']);

	const navigate = useNavigate();

	const { data, loading, error } = useFetchHealthImagingUrls(study.studyInstanceUID);

	const startUp = async () => {
		for (const dataItem of data) {
			if (dataItem.viewNumber !== undefined) {
				if (!studyViews[dataItem.viewNumber]) {
					studyViews[dataItem.viewNumber] = {
						files: [{
							url: await signUrl(dataItem.url),
							numFrames: parseInt(dataItem.numFrames),
							frameRate: parseInt(dataItem.frameRate),
							stageNumber: dataItem.stageNumber,
							sopUID : dataItem.sopUID.replace(/\./g, '')
						}],
						numberOfViews: dataItem.numberOfViews,
						name: dataItem.viewName || `View ${dataItem.viewNumber}`
					};
				} else {
					studyViews[dataItem.viewNumber].files.push({
						url: await signUrl(dataItem.url),
						numFrames: parseInt(dataItem.numFrames),
						frameRate: parseInt(dataItem.frameRate),
						stageNumber: dataItem.stageNumber,
						sopUID : dataItem.sopUID.replace(/\./g, '')
					});
				}
			}
	
			if (dataItem.stageNumber !== undefined) {
				if (!studyStages[dataItem.stageNumber]) {
					studyStages[dataItem.stageNumber] = {
						files: [{
							url: await signUrl(dataItem.url),
							numFrames: parseInt(dataItem.numFrames),
							frameRate: parseInt(dataItem.frameRate),
							sopUID : dataItem.sopUID.replace(/\./g, '')
						}],
						numberOfStages: dataItem.numberOfStages,
						name: dataItem.stageName || `Stage ${dataItem.stageNumber}`
					};
				} else {
					studyStages[dataItem.stageNumber].files.push({
						url: await signUrl(dataItem.url),
						numFrames: parseInt(dataItem.numFrames),
						frameRate: parseInt(dataItem.frameRate),
						sopUID : dataItem.sopUID.replace(/\./g, '')
					});
				}
			}
		}

		setSelectedStressView(Object.keys(studyViews)[0]);
		Object.keys(studyViews).forEach(viewKey => {
			let longestDuration = 0;
			studyViews[viewKey].files.forEach((viewFile: any) => {
				const duration = viewFile.numFrames / viewFile.frameRate;
				if (duration > longestDuration) {
					longestDuration = duration;
				}
			});

			studyViews[viewKey].files.map((viewFile: any) => {
				viewFile.dynamicFramerate = viewFile.numFrames / (longestDuration + .1);
				return viewFile;
			});

			studyViews[viewKey].files.sort((a: { stageNumber: string; }, b: { stageNumber: string; }) => {
				const nameA = parseInt(a.stageNumber);
				const nameB = parseInt(b.stageNumber);
				return nameA - nameB;
			});
		});

		Object.keys(studyStages).forEach(stageKey => {
			let longestDuration = 0;
			studyStages[stageKey].files.forEach((viewFile: any) => {
				const duration = viewFile.numFrames / viewFile.frameRate;
				longestDuration = duration > longestDuration ? duration : longestDuration;
			});

			studyStages[stageKey].files.map((viewFile: any) => {
				viewFile.dynamicFramerate =  viewFile.numFrames / longestDuration;
				return viewFile;
			});

		});

		setStudyStages(studyStages);
		setStudyViews(studyViews);
		setVisibleViewports(studyViews[Object.keys(studyViews)[0]].files);
		await preloadImagesInBatches();

	}


	const preloadImagesInBatches = async () => {
		const batchSize = 4;
		for (let i = 0; i < data.length; i += batchSize) {
			const batch = data.slice(i, i + batchSize);
			await Promise.all(
				batch.map(async image => {
					const signedUrl = await signUrl(image.url);
					await cornerstoneDICOMImageLoader.wadouri.dataSetCacheManager.load(signedUrl, xhrRequest);
				})
			);
		}
	};


	const currentImagesCallback = (fileId: string, viewport:string) => {
		viewportCurrentImages[viewport] = fileId;
		setViewportCurrentImages(Object.assign({},viewportCurrentImages));
	}

	const deselectImagesCallback = (viewport:string) => {
		delete viewportCurrentImages[viewport];
		setViewportCurrentImages(Object.assign({},viewportCurrentImages));
	}

	const cachedImagesCallback = (fileId: string) => {
		if(viewportCachedImages.indexOf(fileId) === -1) {
			viewportCachedImages.push(fileId);
			setViewportCachedImages(viewportCachedImages);
		}

	}

	const playbackReadyCallback = (fileId: string) => {
		loadedViewports.push(fileId);
		if (loadedViewports.length === visibleViewports.length) {
			loadedViewports = [];
			togglePlayback();
		}
	}

	useEffect(()=> {
		if (studyViews[selectedStressView]) {
			setVisibleViewports(studyViews[selectedStressView].files);
			togglePlayback();
		}
	},[selectedStressView]);

	useEffect(()=> {
		if (studyStages[selectedStressStage]) {
			setVisibleViewports(studyStages[selectedStressStage].files);
			togglePlayback();
		}
	},[selectedStressStage]);

	
	useEffect(()=> {
		if (stressEchoGroupMode === 'view' && Object.keys(studyViews).length) {
			const groupKey = (Object.keys(studyViews)[0]);
			setSelectedStressView(groupKey);
			setVisibleViewports(studyViews[groupKey].files);
		} else if (Object.keys(studyStages).length) {
			const groupKey = (Object.keys(studyStages)[0]);
			setSelectedStressStage(groupKey);
			setVisibleViewports(studyStages[groupKey].files);
		}
	},[stressEchoGroupMode]);


	const updateGroupMode = (groupMode: string) => {
		setStressEchoGroupMode(groupMode);
	}


	const togglePlayback = () => {
		const viewportElements = document.querySelectorAll('.viewport_element');
		if (!isPlaying) {
			viewportElements.forEach(element => {
				const studyFramerate = element.getAttribute('data-framerate');
				const numFrames = element.getAttribute('data-numframes');
				if (studyFramerate && numFrames) {
					const frameRate = parseInt(studyFramerate) * framerateMultipier;
					utilities.cine.playClip(element as HTMLDivElement, {loop:false, framesPerSecond: frameRate});
				}

			});
			setIsPlaying(true);
			viewportElements.forEach(element => {
				element.addEventListener('CORNERSTONE_CINE_TOOL_STOPPED', cornerstoneCineToolStopped);
			});
		} else {
			document.querySelectorAll('.viewport_element').forEach(element => {
				element.removeEventListener('CORNERSTONE_CINE_TOOL_STOPPED', cornerstoneCineToolStopped);
				utilities.cine.stopClip(element as HTMLDivElement);
			});
			setIsPlaying(false);
		}

	}

	const cornerstoneCineToolStopped = (e: any) => {
		const viewportElements = document.querySelectorAll('.viewport_element');
		viewportElements.forEach(element => {
			const index = element.getAttribute('data-index');
			const renderingEngineId = `myRenderingEngine_${index}`;
			const renderingEngine = getRenderingEngine(renderingEngineId);
			const studyFramerate = element.getAttribute('data-framerate');
			const numFrames = element.getAttribute('data-numframes');
			const viewportId = `VIEWPORT_${index}`;
			if (renderingEngine && studyFramerate && numFrames) {
				const viewport = renderingEngine.getViewport(viewportId);
				utilities.scroll(viewport, {delta: 0 - parseFloat(numFrames), loop: false});
				const frameRate = parseInt(studyFramerate) * framerateMultipier;
				utilities.cine.playClip(element as HTMLDivElement, {framesPerSecond: frameRate,loop:false});

			}
		});
	}

	function hasFocus(selector: string) {
		return Array
			.from(document.querySelectorAll(selector))
			.some(function(el){
				return el === document.activeElement
			});
	}

	const nextPage = () => {
		if (stressEchoGroupMode === 'view') {
			const newViewNumber = (parseInt(selectedStressView) % Object.keys(studyViews).length) + 1;
			
			setSelectedStressView(newViewNumber.toString());
		} else {
			const newStageNumber = (parseInt(selectedStressStage) % Object.keys(studyStages).length) + 1;
			setSelectedStressStage(newStageNumber.toString());
		}
	}

	const prevPage = () => {
		if (stressEchoGroupMode === 'view') {
			if (selectedStressView === '1') {
				setSelectedStressView(Object.keys(studyViews).length.toString());
			} else {
				setSelectedStressView((parseInt(selectedStressView) - 1).toString());
			}
		} else {
			if (selectedStressStage === '1') {
				setSelectedStressStage(Object.keys(studyStages).length.toString());
			} else {
				setSelectedStressStage((parseInt(selectedStressStage) - 1).toString());
			}
		}
	}

	useEffect(() => {
		// @ts-ignore
		const handleKeyDown = e => {
			const key = e.keyCode;
			if (key == 32 && !hasFocus('input, select, textarea')) {
				togglePlayback();
			}
			if (key == 40) {
				nextPage();
			}
			if (key == 38) {
				prevPage();
			}				
		};
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};

	}, [togglePlayback]);

	const updatePlaybackSpeed = (multiplier: number) => {
		if (framerateMultipier + multiplier > 0) {
			setFramerateMultiplier(framerateMultipier + multiplier);
		}
	}

	useEffect(() => {
		const viewportElements = document.querySelectorAll('.viewport_element');
		viewportElements.forEach(element => {
			element.removeEventListener('CORNERSTONE_CINE_TOOL_STOPPED', cornerstoneCineToolStopped);
			utilities.cine.stopClip(element as HTMLDivElement);
		});
		viewportElements.forEach(element => {
			const studyFramerate = element.getAttribute('data-framerate');
			const numFrames = element.getAttribute('data-numframes');
			if (studyFramerate && numFrames) {
				const frameRate = parseInt(studyFramerate) * framerateMultipier;
				utilities.cine.playClip(element as HTMLDivElement, {loop:false, framesPerSecond: frameRate});
			}

		});
		viewportElements.forEach(element => {
			element.addEventListener('CORNERSTONE_CINE_TOOL_STOPPED', cornerstoneCineToolStopped);
		});
	}, [framerateMultipier]);


	const calculateViewportWidth = () => {
		if (parentContainer.current && viewportRef.current) {
			const containerWidth: number = parentContainer.current.offsetWidth;
			const containerHeight: number = parentContainer.current.offsetHeight;
			let rowCount = 1;
			let colCount = 1;
			if (viewportRef.current.length === 1) {
				setViewportWidth('100%');
			} else if (viewportRef.current.length === 2) {
				if (containerWidth > 900) {
					setViewportWidth('50%');
					rowCount = Math.round(viewportRef.current.length/2);
					colCount = 2;
				} else {
					setViewportWidth('100%');
					rowCount = viewportRef.current.length;
				}
			} else if (viewportRef.current.length === 3) {
				if (containerWidth > 1200) {
					setViewportWidth('33%');
					rowCount = Math.round(viewportRef.current.length/3);
					colCount = 3;
				}else if (containerWidth > 300) {
					rowCount = Math.round(viewportRef.current.length/2);
					colCount = 2;
				} else {
					rowCount = viewportRef.current.length;
				}
			} else if (viewportRef.current.length === 4) {
				if (containerWidth > 350) {
					rowCount = Math.round(viewportRef.current.length/2);
					colCount = 2;
				} else {
					rowCount = viewportRef.current.length;
				}
			} else {
				if (containerWidth > 900) {
					rowCount = Math.round(viewportRef.current.length/3);
					colCount = 3;
				} else if (containerWidth > 400) {
					rowCount = Math.round(viewportRef.current.length/2);
					colCount = 2;
				} else {
					rowCount = viewportRef.current.length;
				}
			}

			if ((containerHeight / containerWidth) > .7) {
				const viewportWidthInt = (containerWidth / colCount) - 25;
				const viewportWidthPx = viewportWidthInt + 'px';
				const viewportHeightPx = (viewportWidthInt * .7) + 'px'

				setViewportWidth(viewportWidthPx);
				setViewportHeight(viewportHeightPx);
				const gridRows = Array(rowCount).fill(viewportHeightPx);
				const gridCols = Array(colCount).fill(viewportWidthPx);

				setGridTemplateRows(gridRows);
				setGridTemplateColumns(gridCols);
			} else {
				let viewportHeightInt = (containerHeight / rowCount) - 25;
				let viewportWidthInt = viewportHeightInt * 1.42;
				if (viewportWidthInt * 2 > containerWidth) {
					const factor = containerWidth / (viewportWidthInt * 2);
					viewportHeightInt = viewportHeightInt * factor;
					viewportWidthInt = containerWidth / 2;
				}
				const viewportHeightPx = viewportHeightInt + 'px'
				const viewportWidthPx = viewportWidthInt+ 'px'
				setViewportHeight(viewportHeightPx);
				setViewportWidth(viewportWidthPx);
				const gridRows = Array(rowCount).fill(viewportHeightPx);
				const gridCols = Array(colCount).fill(viewportWidthPx);

				setGridTemplateRows(gridRows);
				setGridTemplateColumns(gridCols);
			}

			// if container width:height is wider than .7, calculate max that can fit with column count wide and set height as .7 of that
			// if container width:height is less than .7, do the inverse

		}
	}

	useEffect(() => {
		const resizeObserver = new ResizeObserver(calculateViewportWidth);

		if (parentContainer.current) {
			resizeObserver.observe(parentContainer.current);
		}
		return () => {
			resizeObserver.disconnect();
		  };
	  }, [data]);

	useEffect(() => {
		calculateViewportWidth();
	}, [visibleViewports, selectedStressView, selectedStressStage, stressEchoGroupMode]);

	useEffect(() => {
		if (data.length > 0) {
			startUp();
		}
		cornerstoneDICOMImageLoader.wadouri.dataSetCacheManager.purge();
		return () => {
			cornerstoneDICOMImageLoader.wadouri.dataSetCacheManager.purge();
		};
	}, [data]);

	if (loading) {
		return (
			<Flex justify="center" align="center" height="100%">
				<Spinner size="xl" />
			</Flex>
		);
	}


	if (error) {
		return <div>Error loading images</div>;
	}
	return (
		<Flex height='100%'  ref={parentContainer}>
			
			<Flex direction='column' width='100%' position='relative'>
				<Flex mb='5px' justifyContent='space-between'>
					<Box>
						<Button colorScheme="link" onClick={() => { navigate('/') }}>
							<ChevronLeftIcon fontSize='20px'></ChevronLeftIcon> Close Study
						</Button>
					</Box>
					<Flex>
						<FormControl variant="floating" width='200px'>
							<Select color='#fff' value={stressEchoGroupMode} onChange={e=>updateGroupMode(e.target.value)}>
								<option value='view' style={{color:'#444'}}>View</option>
								<option value='stage' style={{color:'#444'}}>Stage</option>
							</Select>
							<FormLabel style={{color:'#fff', background: '#100f19'}}>Review Stress by</FormLabel>
						</FormControl>

						{stressEchoGroupMode === 'view' ? 
							<FormControl variant="floating" width='200px'>
								<Select color='#fff' value={selectedStressView} onChange={e=>setSelectedStressView(e.target.value)}>
									{Object.keys(studyViews).map(key => <option value={key} style={{color:'#444'}}>{studyViews[key].name}</option>)}
								</Select>
								<FormLabel style={{color:'#fff', background: '#100f19'}}>Select View</FormLabel>
							</FormControl>
							:
							<FormControl variant="floating" width='200px'>
								<Select color='#fff' value={selectedStressStage} onChange={e=>setSelectedStressStage(e.target.value)}>
									{Object.keys(studyStages).map(key => <option value={key} style={{color:'#444'}}>{studyStages[key].name}</option>)}
								</Select>
								<FormLabel style={{color:'#fff', background: '#100f19'}}>Select Stage</FormLabel>
							</FormControl>
						}

						<Flex>
							<Flex color='#fff' width='60px' justifyContent='center' alignItems='center'>
								{stressEchoGroupMode === 'view' ? 
									`${selectedStressView} / ${Object.keys(studyViews).length}`
									:
									`${selectedStressStage} / ${Object.keys(studyStages).length}`
								}
							</Flex>
							<IconButton
								colorScheme='blue'
								aria-label="Previous page"
								icon={<ChevronLeftIcon />}
								onClick={prevPage}
							/>
							<IconButton
								colorScheme='blue'
								aria-label="Next page"
								icon={<ChevronRightIcon />}
								onClick={nextPage}								
							/>
							<Flex color='#fff' justifyContent='flex-end' alignItems='center' width='120px'>
								<Box mr='10px' mb='4px' fontSize='16px' letterSpacing='.5px'>
							Stress
								</Box>
								<Switch colorScheme='blue' isChecked={true} onChange={() => {
									toggleStressCallback ? toggleStressCallback() : '';
								}} />
							</Flex>
						</Flex>
					</Flex>
				</Flex>



				<Flex
					position='absolute'
					bottom='20px'
					left='50%'
					transform='translateX(-50%)'
					background='#373151'
					borderRadius='5px'
					width='170px'
					padding='8px'
					alignItems='center'
					justifyContent='space-between'
					zIndex='100'
				>
					<Box>
						{isPlaying ?
							<IoPause fontSize='20px' color='#fff' onClick={()=>togglePlayback()} cursor='pointer' />
							:
							<IoPlay fontSize='20px' color='#fff' onClick={()=>togglePlayback()} cursor='pointer'  />
						}


					</Box>
					<Flex
						border='1px solid #5686ce'
						background='#373151'
						borderRadius='5px'
						padding='2px 8px'
						margin='0px 8px'
						alignItems='center'
						width='120px'
						justifyContent='space-between'
						zIndex='100'
					>
						<ChevronLeftIcon
							color='#5686ce'
							onClick={()=>updatePlaybackSpeed(-0.2)}
							cursor='pointer'
						></ChevronLeftIcon>
						<Box color='#5686ce' cursor='pointer'>{framerateMultipier.toFixed(1)}x speed</Box>
						<ChevronRightIcon
							color='#5686ce'
							onClick={()=>updatePlaybackSpeed(0.2)}
							cursor='pointer'
						></ChevronRightIcon>
					</Flex>


				</Flex>

				<Grid width='100%' height='100%' paddingBottom='50px'  maxHeight='90vh' gridTemplateRows={gridTemplateRows.join(' ')} gridTemplateColumns={gridTemplateColumns.join(' ')} justifyItems='center' alignItems='center' justifyContent='center' >
					{visibleViewports.map((studyFile, index) => 
						<NewStressViewerViewport
							url={studyFile.url}
							studyFileId={studyFile.sopUID}
							index={index} 
							frameRate={studyFile.dynamicFramerate}
							viewportHeight={viewportHeight}
							viewportWidth={stressViewportWidth}
							key={studyFile.sopUID} 
							currentFileCallback={currentImagesCallback}
							deselectFileCallback={deselectImagesCallback}
							cachedFileCallback={cachedImagesCallback}
							playbackReadyCallback={playbackReadyCallback}
							amPlaying={isPlaying}
							playbackMultiplier={framerateMultipier}></NewStressViewerViewport>
					)}
				</Grid>

			</Flex>
		</Flex>
	);
}

export default NewStressImageViewer;
