import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import {
	Flex,
	Box,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	Spinner
} from '@chakra-ui/react';
import { RenderingEngine, Enums, imageLoader, eventTarget } from '@cornerstonejs/core';
import type { Types } from '@cornerstonejs/core';
import { SERVER_URL } from 'Constants';
import { useEffect, useState, useCallback } from 'react';
import * as cornerstone from '@cornerstonejs/core';
import dicomParser from 'dicom-parser';
import ReactResizeDetector from 'react-resize-detector';
import { ZoomTool, utilities, WindowLevelTool, ToolGroupManager, Enums as csToolsEnums } from '@cornerstonejs/tools';

cornerstoneDICOMImageLoader.external.cornerstone = cornerstone;
cornerstoneDICOMImageLoader.external.dicomParser = dicomParser;


const { MouseBindings, Events: toolsEvents } = csToolsEnums;

interface StressViewportProps {
	studyFileId: string;
	index: number;
	frameRate: number;
	currentFileCallback: (arg0: string, arg1: string)=>void;
	deselectFileCallback: (arg0: string)=>void;
	cachedFileCallback: (arg0: string)=>void;
	playbackReadyCallback: (arg0: string)=>void;
	amPlaying: boolean;
	playbackMultiplier: number;
	viewportHeight: string;
	viewportWidth: string;

}

const StressViewerViewport = (props: StressViewportProps) => {

	const { studyFileId, index, frameRate, currentFileCallback, cachedFileCallback, deselectFileCallback, playbackReadyCallback, amPlaying, playbackMultiplier, viewportHeight, viewportWidth } = props;
	const [toolGroup, setToolGroup] = useState<any>(ToolGroupManager.createToolGroup(`MYTOOLGROUP_${index}`));
	const [currentFileId, setCurrentFileId] = useState<string>('');
	const [cachedStudyFiles, setCachedStudyFiles] = useState<string[]>([]);
	const [dynamicFramerate, setDynamicFramerate] = useState<number>(frameRate);
	const [framerate, setFramerate] = useState<number>(0);
	const [numFrames, setNumFrames] = useState<number>(0);

	const [isPlaying, setIsPlaying] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [displaySlider, setDisplaySlider] = useState<boolean>(false);

	const [displayControls, setDisplayControls] = useState<boolean>(true);
	const [viewport1, setViewport1] = useState<Types.IViewport>();


	const togglePlayback = () => {
		const element = document.querySelector(`#element_stress_${studyFileId}`) as HTMLDivElement;
		if (isPlaying) {
			utilities.cine.stopClip(element);
			setIsPlaying(false);

		} else {
			utilities.cine.playClip(element, {framesPerSecond: dynamicFramerate,loop:true});
			setIsPlaying(true);
		}
	}

	const scrollViewport = (delta: number) => {
		// @ts-ignore
		const currentFrameIndex = viewport1.getTargetImageIdIndex();
		if (!isPlaying && viewport1) {
			if (currentFrameIndex === 0 && delta === -1) {
				utilities.scroll(viewport1, {delta: numFrames - 1, loop: false});
			} else {
				utilities.scroll(viewport1, {delta: delta, loop: false});
			}
		}
	}

	const updateFramerate = (newFramerate: number) => {
		const element = document.querySelector(`#element_stress_${studyFileId}`) as HTMLDivElement;
		if (isPlaying) {
			setFramerate(newFramerate);
			utilities.cine.playClip(element, {framesPerSecond: newFramerate,loop:true});
		} else {
			setFramerate(newFramerate);
		}
	}


	function hasFocus(selector: string) {
		return Array
			.from(document.querySelectorAll(selector))
			.some(function(el){
				return el === document.activeElement
			});
	}

	const startUp = () => {
		const renderingEngineId = `myRenderingEngine_${index}`;
		const renderingEngine = new RenderingEngine(renderingEngineId);
		const viewportId = `CT_STACK_${index}`;
		const element = document.querySelector(`#element_stress_${studyFileId}`) as HTMLDivElement;
		element.oncontextmenu = e => e.preventDefault();
		const viewportInput: Types.PublicViewportInput = {
			viewportId,
			type: Enums.ViewportType.STACK,
			element,
			defaultOptions : {
				displayArea : {
					imageArea: [1.01, 1.01]
				}
			}
		}

		renderingEngine.enableElement(viewportInput);

		const imageIds: string[] = [];
		setIsLoading(true);
		cornerstoneDICOMImageLoader.wadouri.dataSetCacheManager.load(`${SERVER_URL}/api/files/${studyFileId}`, cornerstoneDICOMImageLoader.internal.xhrRequest)
			.then( async (dataSet: any) => {
				setCurrentFileId(studyFileId);
				currentFileCallback(studyFileId, `viewport_${index}`);
				cachedFileCallback(studyFileId);

				cachedStudyFiles.push(studyFileId)
				setCachedStudyFiles(cachedStudyFiles);
				const numFrames = dataSet.intString('x00280008');
				setFramerate(dynamicFramerate);
				setNumFrames(numFrames);
				if (!numFrames) {
					imageIds.push(`wadouri:${SERVER_URL}/api/files/${studyFileId}`);
				} else {
					for (let i = 1; i <= numFrames; i++) {
						const imageId = `wadouri:${SERVER_URL}/api/files/${studyFileId}?frame=${i}`;
						imageIds.push(imageId);
					}
				}
				const viewport = (renderingEngine.getViewport(viewportId) as Types.IStackViewport);

				setViewport1(renderingEngine.getViewport(viewportId));
				await imageLoader.loadAndCacheImages(imageIds, cornerstoneDICOMImageLoader.internal.xhrRequest);
				viewport.setStack(imageIds).then(()=>{
					setIsLoading(false);
					const element = document.querySelector(`#element_stress_${studyFileId}`) as HTMLDivElement;
					utilities.stackContextPrefetch.enable(element);
					viewport.render();

					if (toolGroup) {

						toolGroup.addTool(ZoomTool.toolName);
						toolGroup.addTool(WindowLevelTool.toolName);
						toolGroup.addViewport(viewportId, renderingEngineId);
						toolGroup.setToolActive(WindowLevelTool.toolName, {
							bindings: [
								{
									mouseButton: MouseBindings.Primary, // Left Click
								},
							],
						});
						toolGroup.setToolActive(ZoomTool.toolName, {
							bindings: [
								{
									mouseButton: MouseBindings.Secondary, // Left Click
								},
							],
						});
					}
					setIsPlaying(false);
					setDisplaySlider(false);
					setDisplayControls(false);
					playbackReadyCallback(studyFileId);

					setTimeout(()=>{
						renderingEngine.resize(true, false);
					}, 100)

				});

			});
	}

	useEffect(() => {
		startUp();
		return () => {
			deselectFileCallback(`viewport_${index}`);
		}
	}, []);

	const onResize = (studyFileId: string) => {
		console.log(studyFileId)
		const renderingEngineId = `myRenderingEngine_${index}`;
		const renderingEngine = new RenderingEngine(renderingEngineId);
		const viewportId = `CT_STACK_${index}`;
		const element = document.querySelector(`#element_stress_${studyFileId}`) as HTMLDivElement;
		const viewportInput: Types.PublicViewportInput = {
			viewportId,
			type: Enums.ViewportType.STACK,
			element,
			defaultOptions : {
				displayArea : {
					imageArea: [1.01, 1.01]
				}
			}
		};
		renderingEngine.enableElement(viewportInput);
		setIsLoading(true);
		renderingEngine.resize(true, false);
		cornerstoneDICOMImageLoader.wadouri.dataSetCacheManager.load(`${SERVER_URL}/api/files/${studyFileId}`, cornerstoneDICOMImageLoader.internal.xhrRequest)
			.then( async (dataSet: any) => {
				const numFrames = dataSet.intString('x00280008');
				const frameRate = dataSet.intString('x00082144');
				const imageIds: string[] = [];
				setFramerate(frameRate);
				setNumFrames(numFrames);
				if (!numFrames) {
					imageIds.push(`wadouri:${SERVER_URL}/api/files/${studyFileId}`);
				} else {
					for (let i = 1; i <= numFrames; i++) {
						const imageId = `wadouri:${SERVER_URL}/api/files/${studyFileId}?frame=${i}`;
						imageIds.push(imageId);
					}
				}
				const viewport = (renderingEngine.getViewport(viewportId) as Types.IStackViewport);
				setViewport1(renderingEngine.getViewport(viewportId));

				viewport.setStack(imageIds).then(()=>{
					setIsLoading(false);
					const element = document.querySelector(`#element_stress_${studyFileId}`) as HTMLDivElement;
					utilities.stackContextPrefetch.enable(element);
					viewport.render();

					if (toolGroup) {

						toolGroup.addTool(ZoomTool.toolName);
						toolGroup.addTool(WindowLevelTool.toolName);
						toolGroup.addViewport(viewportId, renderingEngineId);
						toolGroup.setToolActive(WindowLevelTool.toolName, {
							bindings: [
								{
									mouseButton: MouseBindings.Primary, // Left Click
								},
							],
						});
						toolGroup.setToolActive(ZoomTool.toolName, {
							bindings: [
								{
									mouseButton: MouseBindings.Secondary, // Left Click
								},
							],
						});
					}
					setIsPlaying(false);
					setDisplaySlider(false);
					setDisplayControls(false);
					playbackReadyCallback(studyFileId);

					if (isPlaying) {
						utilities.cine.stopClip(element);
						setIsPlaying(false);
			
					} else {
						utilities.cine.playClip(element, {framesPerSecond: dynamicFramerate,loop:true});
						setIsPlaying(true);
					}
					
					setTimeout(()=>{
						renderingEngine.resize(true, false);
					}, 100)
				});
			});

	}

	useEffect(() => {
		const handleKeyDown = (e: { keyCode: any; }) => {
			const key = e.keyCode;
			if (key == 32 && !hasFocus('input, select, textarea')) {
			}
			if (key == 39) {
				scrollViewport(1);
			}
			if (key == 37) {
				scrollViewport(-1);
			}
		};
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};

	}, [togglePlayback, scrollViewport]);

	return (
		<Box position='relative' width="100%" height='100%'>

			{isLoading ? <Box position='absolute' left='50%' top='50%' transform='translate(-50%,-50%) scale(3)'><Spinner size='xl' color='blue.500'></Spinner></Box> : ''}

			<ReactResizeDetector
				skipOnMount
				refreshMode="debounce"
				refreshRate={200}
				onResize={() => onResize(currentFileId)}
			>
				<div style={{'height': viewportHeight, 'width': viewportWidth, 'position': 'relative','overflow': 'hidden','marginBottom':'-5px'}}>
					<div id={`element_stress_${studyFileId}`} 
						className='viewport_element' 
						data-framerate={dynamicFramerate} 
						data-numframes={numFrames} 
						data-index={index} 
						style={{width: '100%', height:'100%', 'top': '0', 'left': '0',position:'absolute', opacity : isLoading ? '0' : '1'}}></div>
				</div>

			</ReactResizeDetector>

			{displaySlider && displayControls ?
				<Flex
					position='absolute'
					bottom='70px'
					left='50%'
					transform='translateX(-50%)'
					background='#373151'
					borderRadius='5px'
					width='200px'
					padding='8px'
					alignItems='center'
					justifyContent='space-between'
					border='1px solid #5686ce'
				>
					<Slider aria-label='slider-ex-1' defaultValue={framerate} min={0} max={120} step={1} onChangeEnd={val =>updateFramerate(val)}>
						<SliderTrack>
							<SliderFilledTrack />
						</SliderTrack>
						<SliderThumb />
					</Slider>
				</Flex>
				: ''
			}
		</Box>
	);
}

export default StressViewerViewport;
