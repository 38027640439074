import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { observable } from 'mobx';
import { IAcl } from '../Security/IAcl';
import { VisitorsSiteServicesReferenceManyToMany } from '../Security/Acl/VisitorsSiteServicesReferenceManyToMany';
import { SuperAdminSiteServicesReferenceManyToMany } from '../Security/Acl/SuperAdminSiteServicesReferenceManyToMany';
import { UserSiteServicesReferenceManyToMany } from '../Security/Acl/UserSiteServicesReferenceManyToMany';

export interface ISitesSiteServicesAttributes extends IModelAttributes {
	sitesId: string;
	siteServicesId: string;
	sites: Models.SiteEntity | Models.ISiteEntityAttributes;
	siteServices: Models.ServiceEntity | Models.IServiceEntityAttributes;
}

@entity('SitesSiteServices')
export default class SitesSiteServices extends Model implements ISitesSiteServicesAttributes {
	public static acls: IAcl[] = [
		new VisitorsSiteServicesReferenceManyToMany(),
		new SuperAdminSiteServicesReferenceManyToMany(),
		new UserSiteServicesReferenceManyToMany(),
	];

	@observable
	@attribute()
	public sitesId: string;

	@observable
	@attribute()
	public siteServicesId: string;

	@observable
	@attribute({ isReference: true })
	public sites: Models.SiteEntity;

	@observable
	@attribute({ isReference: true })
	public siteServices: Models.ServiceEntity;

	constructor(attributes?: Partial<ISitesSiteServicesAttributes>) {
		super(attributes);

		if (attributes) {
			if (attributes.sitesId) {
				this.sitesId = attributes.sitesId;
			}
			if (attributes.siteServicesId) {
				this.siteServicesId = attributes.siteServicesId;
			}

			if (attributes.sites) {
				if (attributes.sites instanceof Models.SiteEntity) {
					this.sites = attributes.sites;
					this.sitesId = attributes.sites.id;
				} else {
					this.sites = new Models.SiteEntity(attributes.sites);
					this.sitesId = this.sites.id;
				}
			} else if (attributes.sitesId !== undefined) {
				this.sitesId = attributes.sitesId;
			}

			if (attributes.siteServices) {
				if (attributes.siteServices instanceof Models.ServiceEntity) {
					this.siteServices = attributes.siteServices;
					this.siteServicesId = attributes.siteServices.id;
				} else {
					this.siteServices = new Models.ServiceEntity(attributes.siteServices);
					this.siteServicesId = this.siteServices.id;
				}
			} else if (attributes.siteServicesId !== undefined) {
				this.siteServicesId = attributes.siteServicesId;
			}
		}
	}
}
