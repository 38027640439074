
import * as React from "react";
import { observer } from 'mobx-react';
import { action } from 'mobx';
import If from '../If/If';
import InputWrapper from '../Inputs/InputWrapper';
import * as uuid from 'uuid';
import { DisplayType } from '../Models/Enums';
import InputsHelper from '../Helpers/InputsHelper';

export interface ITextFieldProps<T> {
	model: T;
	modelProperty: string;
	id?: string;
	name?:string;
	className?: string;
	inputClassName?: string;
	defaultToUndefined?: boolean;
	displayType?: DisplayType;
	label?: string;
	labelVisible?: boolean;
	isRequired?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	staticInput?: boolean;
	tooltip?: string;
	subDescription?: string;
	inputProps?: React.InputHTMLAttributes<Element>;
	placeholder?: string;
	clickToClear?: boolean;
	autoFocus?: boolean;
	errors?: string | string[];
	onAfterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onChangeAndBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClickToClear?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	
	children?: React.ReactNode;
}

@observer
export class TextField<T> extends React.Component<ITextFieldProps<T>, any> {
	public static defaultProps = {
		clickToClear: false,
		inputProps: {},
		className: '',
	};
	private  _input?: HTMLInputElement;
	private uuid = uuid.v4();
	private valueWhenFocused: string = '';

	componentDidMount() {
		if(this.props.autoFocus && this._input){
			this._input.focus();
		}
	}

	public render() {
		const {
			model,
			modelProperty,
			name,
			className,
			displayType,
			label,
			isRequired,
			isDisabled,
			isReadOnly,
			staticInput,
			tooltip,
			subDescription,
			clickToClear,
			placeholder,
			errors,
			inputClassName,
			
			
		} = this.props;
		const id = this.props.id || this.uuid.toString();
		const fieldId = `${id}-field`;

		const labelVisible = (this.props.labelVisible === undefined) ? true : this.props.labelVisible;
		const ariaLabel = !labelVisible ? label : undefined;
		const ariaDescribedby = InputsHelper.getAriaDescribedBy(id, tooltip, subDescription);
		
		return (
			<InputWrapper
				id={id}
				inputId={fieldId}
				className={className}
				displayType={displayType}
				isRequired={isRequired}
				staticInput={staticInput}
				tooltip={tooltip}
				subDescription={subDescription}
				label={label}
				labelVisible={labelVisible}
				errors={errors}>
				<input
					className={inputClassName}
					type="text"
					name={name}
					id={fieldId}
					value={model[modelProperty] || model[modelProperty] === 0 ? model[modelProperty] : ''}
					onChange={this.onChange}
					onBlur={this.onBlur}
					onFocus={this.onFocus}
					placeholder={placeholder ? placeholder : (label ? label : undefined)}
					disabled={isDisabled}
					readOnly={staticInput || isReadOnly}
					aria-label={ariaLabel}
					aria-describedby = {ariaDescribedby}
					ref={i => (this._input = i || undefined)}
					{...this.props.inputProps}
				/>
				<If condition={clickToClear}>
					<button className="click-to-clear icon-cross icon-right" onClick={this.onClickToClear} type="button" />
				</If>
			</InputWrapper>
		);
		
	}

	@action
	private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value: string | undefined = event.target.value;
		value = (value === '' && this.props.defaultToUndefined) ? undefined : value;
		this.props.model[this.props.modelProperty] = value;
		if (this.props.onAfterChange) {
			this.props.onAfterChange(event);
		}
	}

	@action
	private onFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.valueWhenFocused = event.target.value;
	}

	@action
	private onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
		if(this.valueWhenFocused !== event.target.value && this.props.onChangeAndBlur){
			this.props.onChangeAndBlur(event);
		}
	}

	@action
	private onClickToClear = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (this.props.onClickToClear) {
			return this.props.onClickToClear(event);
		}
		this.props.model[this.props.modelProperty] = '';
	}
}
