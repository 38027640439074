import { store } from 'Models/Store';

import React, { useEffect } from 'react';
import { runInAction, toJS } from 'mobx';
import { inject, observer, useLocalStore } from 'mobx-react';
import { PatientEntity, StudyEntity } from 'Models/Entities';
import {
	genderTypeOptions, holterReportStatusOptions, reportStatusOptions, studyType, studyTypeOptions, 
} from 'Models/Enums';
import {useNavigate, useParams} from "react-router";

import { Link } from 'react-router-dom';
import moment from 'moment';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import * as Models from 'Models/Entities';
import { IFilter } from 'Views/Components/Collection/CollectionFilterPanel';
import { makeEnumFetchFunction } from 'Util/EntityUtils';
import { ICollectionItemActionProps } from 'Views/Components/Collection/Collection';
import { SERVER_URL } from 'Constants';
import axios from 'axios'; 
import StudyCrudTile from './StudyCrudTile';
import NewStudyModal from '../Modals/NewStudyModal';
import alert from '../../../Util/ToastifyUtils';
import PreviewModal from '../Modals/PreviewModal';
import TemplatePreviewModal from '../Modals/TemplatePreviewModal';
import { ButtonGroup, Alignment } from 'Views/Components/Button/ButtonGroup';
import { Button, Colors, Display, Sizes } from 'Views/Components/Button/Button';

type transformFn<T> = (item: T, name: string) => (string | React.ReactNode);

const PatientTileTile = () => {
	const params = useParams();
	const navigate = useNavigate();
	
	const files = useLocalStore(() => ({
		holterReportPDF: '',
		holterReportTXT: '',
		echoReportPDF: '',
		echoReportTXT: '',
		previewType: 'PDF',
	}));

	const pdfPagesState = useLocalStore(() => ({
		echoReportPDF: 1,
		echoReportTXT: 1,
		holterReportPDF: 1,
		holterReportTXT: 1,
	}));

	const patientStore = useLocalStore(() => ({
		patient: new PatientEntity(),
		loading: true,
		preview: '',
	}));

	const retrievePatient = async () => {
		await store.apolloClient.query({
			query: PatientEntity.fetchSinglePatient(),
			fetchPolicy: 'network-only',
			variables: {
				args: [{
					path: 'id',
					comparison: 'equal',
					value: params.id,
				}],
			},
		}).then(res => {
			runInAction(() => {
				if (!store.userSiteAccess.map(usa => usa.siteId).includes(res.data.patientEntity.siteId) && store.userGroups[0].name !== 'SuperAdmin') {
					navigate(`${SERVER_URL}/patientdashboard/`);
					return;
				}
				patientStore.patient = res.data.patientEntity;
			});
		}).catch(error => {
			console.error(error);
		});
	};

	useEffect(() => {
		async function asyncFetchData() {
			await retrievePatient();
			
			runInAction(() => {
				patientStore.loading = false;
			});
		}
		
		asyncFetchData();
	}, []);

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();

		const newPatient = new PatientEntity(patientStore.patient);
		newPatient[e.target.name] = e.target.value;

		runInAction(() => {
			patientStore.patient = newPatient;
		});
	};

	const onSave = () => {
		patientStore.patient.savePatient();
	};

	const toggleStudyUrgency = (study: Models.StudyEntity) => {
		store.modal.show('Confirmation', 
			<div className="confirmation__modal">
				<h4>{`Do you want to set the study urgency to ${study.urgent ? 'Routine' : 'Urgent'}?`}</h4>
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
					<Button 
						colors={Colors.Primary} 
						display={Display.Solid} 
						sizes={Sizes.Medium} 
						buttonProps={{ id: 'confirm' }} 
						onClick={() => runInAction(() => {
							study.toggleUrgent();
							study.save();
							store.modal.hide();
						})}
					>
						Confirm
					</Button>
					<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={() => store.modal.hide()}>Cancel</Button>
				</ButtonGroup>
			</div>);
	};

	const transformStudyDate: transformFn<Models.StudyEntity> = (study: Models.StudyEntity) => moment(study.studyDate).format('DD/MM/YYYY');
	
	const tranformReportingDoctor: transformFn<Models.StudyEntity> = (study: Models.StudyEntity) => {
		const reportingDoctor = study.doctorss.filter(d => d.doctorType === 'REPORTING');
		
		if (reportingDoctor.length > 0) {
			return `${reportingDoctor[0].prefix} ${reportingDoctor[0].firstName} ${reportingDoctor[0].lastName}`;
		}
		
		return 'N/A';
	};
	
	const transformSite: transformFn<Models.StudyEntity> = (study: Models.StudyEntity) => study.patient.site.siteName;
	const transformStudyType: transformFn<Models.StudyEntity> = (study: Models.StudyEntity) => studyTypeOptions[study.studyType];
	const transformStudyStatus: transformFn<Models.StudyEntity> = (study: Models.StudyEntity) => {
		if (study.report) {
			if (study.report.reportStatus === 'NOT_REPORTED') {
				return (
					<Button colors={Colors.Error} display={Display.Solid} sizes={Sizes.Small}>
						{reportStatusOptions[study.report.reportStatus]}
					</Button>
				);
			}
			if (study.report.reportStatus === 'PROVISIONAL') {
				return (
					<Button colors={Colors.Warning} display={Display.Solid} sizes={Sizes.Small}>
						{reportStatusOptions[study.report.reportStatus]}
					</Button>
				);
			}
			if (study.report.reportStatus === 'CONFIRMED') {
				return (
					<Button colors={Colors.Success} display={Display.Solid} sizes={Sizes.Small}>
						{reportStatusOptions[study.report.reportStatus]}
					</Button>
				);
			}
			return (
				<Button colors={Colors.Error} display={Display.Solid} sizes={Sizes.Small}>
					NA
				</Button>
			);
		}
		if (study.holterReport) {
			if (study.holterReport.reportStatus === 'NOT_REPORTED_HOLTER') {
				return (
					<Button colors={Colors.Error} display={Display.Solid} sizes={Sizes.Small}>
						{holterReportStatusOptions[study.holterReport.reportStatus]}
					</Button>
				);
			}
			if (study.holterReport.reportStatus === 'AWAITING_CARDIOLOGIST_CONFIRMATION') {
				return (
					<Button colors={Colors.Warning} display={Display.Solid} sizes={Sizes.Small}>
						{holterReportStatusOptions[study.holterReport.reportStatus]}
					</Button>
				);
			}
			if (study.holterReport.reportStatus === 'CARDIOLOGIST_CHANGES_PENDING') {
				return (
					<Button colors={Colors.Warning} display={Display.Solid} sizes={Sizes.Small}>
						{holterReportStatusOptions[study.holterReport.reportStatus]}
					</Button>
				);
			}
			if (study.holterReport.reportStatus === 'CONFIRMED_HOLTER') {
				return (
					<Button colors={Colors.Success} display={Display.Solid} sizes={Sizes.Small}>
						{holterReportStatusOptions[study.holterReport.reportStatus]}
					</Button>
				);
			}
			if (study.holterReport.reportStatus === 'CONFIRMED_AND_COPIED') {
				return (
					<Button colors={Colors.Success} display={Display.Solid} sizes={Sizes.Small}>
						{holterReportStatusOptions[study.holterReport.reportStatus]}
					</Button>
				);
			}
			return (
				<Button colors={Colors.Error} display={Display.Solid} sizes={Sizes.Small}>
					NA
				</Button>
			);
		}
		return (
			<Button colors={Colors.Error} display={Display.Solid} sizes={Sizes.Small}>
				NA
			</Button>
		);
	};

	const transfromUrgency: transformFn<Models.StudyEntity> = (study: Models.StudyEntity) => {
		if (study.urgent) {
			return (
				<Button colors={Colors.Error} display={Display.Solid} sizes={Sizes.Small} icon={{ icon: 'chevron-down', iconPos: 'icon-right' }} onClick={() => runInAction(() => toggleStudyUrgency(study))}><span className="icon-left icon-clock">Urgent</span></Button>
			);
		}
		return (
			<Button colors={Colors.Grey} display={Display.Solid} sizes={Sizes.Small} icon={{ icon: 'chevron-down', iconPos: 'icon-right' }} buttonProps={{ id: 'routine' }} onClick={() => runInAction(() => toggleStudyUrgency(study))}>Routine</Button>
		);
	};
		
	const getHeaders = (): Array<ICollectionHeaderProps<Models.StudyEntity>> => {
		return [
			{
				name: 'viewButton',
				displayName: ' ',
				sortable: false,
				transformItem: (study: Models.StudyEntity) => (
					<Button 
						colors={Colors.Primary} 
						display={Display.Outline} 
						sizes={Sizes.Small} 
						buttonProps={{ id: 'view' }}
						onClick={() => navigate(`${SERVER_URL}/studypage/${study.id}`)}
					>
						View
					</Button>
				),
			},
			{
				name: 'studyDate',
				displayName: 'Study Date',
				sortable: true,
				transformItem: transformStudyDate,
			},
			{
				name: 'reportingDoctor',
				displayName: 'Reporting Doctor',
				sortable: true,
				transformItem: tranformReportingDoctor,
			},
			{
				name: 'site',
				displayName: 'Site',
				sortable: true,
				transformItem: transformSite,
			},
			{
				name: 'studyType',
				displayName: 'Study Type',
				sortable: true,
				transformItem: transformStudyType,
			},
			{
				name: 'studyStatus',
				displayName: 'Study Status',
				sortable: true,
				transformItem: transformStudyStatus,
			},
			{
				name: 'urgency',
				displayName: 'Urgency',
				sortable: true,
				transformItem: transfromUrgency,
			},
		];
	};

	const getFilters = (): Array<IFilter<Models.StudyEntity>> => {
		const studyDateFilter: IFilter<Models.StudyEntity> = {
			path: 'studyDate',
			comparison: 'range',
			value1: undefined,
			value2: undefined,
			active: false,
			displayType: 'datepicker',
			displayName: 'Study Date',
		};

		const studyTypeFilter: IFilter<Models.StudyEntity> = {
			path: 'studyType',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Study Type',
			enumResolveFunction: makeEnumFetchFunction(studyTypeOptions),
		};

		const siteOptions = {};
		store.userSiteAccess.forEach(usa => {
			const jsUsa = toJS(usa);
			if (jsUsa.siteId && jsUsa.site) {
				if (jsUsa.site.siteName) {
					siteOptions[jsUsa.siteId] = jsUsa.site.siteName;
				}
			}
		});

		const siteFilter: IFilter<Models.PatientEntity> = {
			path: 'site',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Site',
			enumResolveFunction: makeEnumFetchFunction(siteOptions),
		};

		const reportStatusFilter: IFilter<Models.StudyEntity> = {
			path: 'reportStatus',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Report Status',
			enumResolveFunction: makeEnumFetchFunction(reportStatusOptions),
		};

		const holterReportStatusFilter: IFilter<Models.StudyEntity> = {
			path: 'holterReportStatus',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Holter Report Status',
			enumResolveFunction: makeEnumFetchFunction(holterReportStatusOptions),
		};

		const urgencyFilter: IFilter<Models.StudyEntity> = {
			path: 'urgency',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Urgent',
			enumResolveFunction: makeEnumFetchFunction({ urgent: 'Urgent', routine: 'Routine' }),
		};

		return [studyDateFilter, studyTypeFilter, siteFilter, reportStatusFilter, holterReportStatusFilter, urgencyFilter];
	};

	const previewModal = async (item: Models.StudyEntity) => {
		const holterTypes: studyType[] = ['HOLTERTWENTYFOURHR', 'HOLTERFORTYEIGHTHR', 'HOLTEREXTENDED', 'AMBULATORY_BP', 'EVENT_MONITOR',  'EXERCISE_STRESS_ECG'];
		if (holterTypes.includes(item.studyType)) {
			await axios.get(`${SERVER_URL}/api/entity/StudyEntity/TestDataPDF/${item.id}`)
				.then(async res => runInAction(() => files.holterReportPDF = `data:application/pdf;base64,${res.data}`))
				.then(async () => {
					await axios.get(`${SERVER_URL}/api/entity/StudyEntity/TestDataTXT/${item.id}`)
						.then(async res => runInAction(() => files.holterReportTXT = res.data))
						.then(() => store.modal.show('Preview', <PreviewModal files={files} pages={pdfPagesState} reportReference="holterReport" />, { className: 'preview-wrapper' }))
						.catch(err => alert('Could not retrieve holter report txt', 'error'));
				})
				.catch(() => alert('Failed to fetch test data', 'error'));
		} else if (item.patient.site.advancedReportBuilder && (item.report?.advancedreportstring !== null && item.report?.advancedreportstring !== '')) {
			files.echoReportPDF = '';
			files.echoReportTXT = '';
			store.modal.show('Preview', <TemplatePreviewModal files={files} pages={pdfPagesState} reportReference="echoReport" study={item} />, { className: 'preview-wrapper' })
		} else {
			await axios.get(`${SERVER_URL}/api/entity/StudyEntity/EchoReportPDFBasic/${item.id}`)
				.then(async res => runInAction(() => files.echoReportPDF = `data:application/pdf;base64,${res.data}`))
				.then(async () => {
					await axios.get(`${SERVER_URL}/api/entity/StudyEntity/EchoReportTXTBasic/${item.id}`)
						.then(async res => runInAction(() => files.echoReportTXT = res.data))
						.then(() => store.modal.show('Preview', <PreviewModal files={files} pages={pdfPagesState} reportReference="echoReport" />, { className: 'preview-wrapper' }))
						.catch(err => alert('Could not retrieve echo report txt', 'error'));
				})
				.catch(() => alert('Failed to fetch echo report', 'error'));
		}
	};

	const openPreviewModal = (item: StudyEntity) => {
		previewModal(item);
	};

	const getActions = () => {
		const tableActions: Array<ICollectionItemActionProps<Models.StudyEntity>> = [];
		tableActions.push({
			action: item => {},
			label: 'Preview',
			customButton: item => (
				<Button
					colors={Colors.Warning}
					display={Display.Solid}
					sizes={Sizes.Small}
					buttonProps={{ id: 'preview-docs' }}
					onClick={(): void => openPreviewModal(item)}
				>
					Preview
				</Button>
			),
		});
		return tableActions;
	};

	const additionalActions = (): React.ReactNode[] => {
		const usersWithStudy = ['SITE_ADMIN', 'SITE_USER', 'CARDIAC_SCIENTIST'];
		if (store.userGroups.map(ug => ug.name).includes('SuperAdmin') || usersWithStudy.includes(store.userType)) {
			return [
				<Button
					key="create"
					className={Display.Solid}
					icon={{ icon: 'create', iconPos: 'icon-left' }}
					buttonProps={{
						onClick: () => store.modal.show('New Patient', 
							<NewStudyModal
								patient={patientStore.patient} 
								update={() => runInAction(() => {
									patientStore.loading = true;
									patientStore.loading = false;
								})}
							/>, 
							{ className: 'newEntity-wrapper' }),
					}}
				>
					New Study
				</Button>,
			];
		}
		return [];
	};

	return !patientStore.loading ? (
		<>
			<div className="patient-container">
				<Link to="/patientdashboard/">
					<Button colors={Colors.PrimaryLight} display={Display.Text} sizes={Sizes.Medium} buttonProps={{ id: 'return' }} icon={{ icon: 'chevron-left', iconPos: 'icon-left' }}>Back to patient list</Button>
				</Link>
				<div className="patient-detail">
					<h4>{patientStore.patient.name}</h4>
					<div className="patient-information">
						<span>
							<p className="information-label">Patient ID:</p>
							<p>{patientStore.patient.patientId}</p>
						</span>
						<span>
							<p className="information-label">D.O.B:</p>
							<p>{moment(patientStore.patient.dob).format('D MMM YYYY')}</p>
						</span>
						<span>
							<p className="information-label">Gender:</p>
							<p>{genderTypeOptions[patientStore.patient.gender]}</p>
						</span>
					</div>
				</div>
			</div>
			<div className="patients-container">
				<div>
					<h5>All studies</h5>
					<StudyCrudTile
						columns={getHeaders()}
						filters={getFilters()}
						actions={getActions()}
						additionalActions={additionalActions()}
						patient={patientStore.patient}
						getAll
					/>
				</div>
			</div>
		</>
	) : (
		<div className="patient-container">
			<p>Loading...</p>
		</div>
	);
};

export default inject('store')(observer(PatientTileTile));
