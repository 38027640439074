import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Select,
    Checkbox,
    Input,
    ButtonGroup,
    Text,
    Flex, Box,
} from '@chakra-ui/react';
import {accessLevel as AccessLevelType} from "../../../Models/Enums";

interface User {
    id: string;
    name: string;
    email: string;
}
interface SiteGroupOptionsModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (selectedGroup: string) => void;
    models: Array<any>; 
    siteGroups: Array<{ id: string | number; name: string }>
}

const SiteGroupOptionsModal: React.FC<SiteGroupOptionsModalProps> = ({ isOpen, onClose, onConfirm, models, siteGroups }) => {
    const [isClicked, setIsClicked] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState<string>('');
    const [error, setError] = useState<string>('');

    const handleConfirm = () => {
        if (isClicked) return;
        
        if (selectedGroup.trim() === '') {
            setError('Please select a group before confirming.');
            return;
        }
        
        onConfirm(selectedGroup);
        setSelectedGroup('');
        setIsClicked(true);
    };
    
    const handleCancel = () => {
        setSelectedGroup('');
        setError('');
        onClose();
    }
    
    const getSitePrompt = () => {
        if(models?.length === 1){
            return(
                <>
                    <Text fontSize="xl" color="gray.800" textAlign="center" fontWeight="bold">
                        {`${models[0].name} is being added to the following site group:`}
                    </Text>
                </>
            )
        } else{
            let numSites = models?.length || 0;
            return(
                <>
                    <Text fontSize="xl" color="gray.800" textAlign="center" fontWeight="bold">
                        {`${numSites} sites are being added to site group:`}
                    </Text>
                </>
            )
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg">
            <ModalOverlay />
            <ModalContent maxWidth="60%" width="100%">
                <ModalHeader>
                    {getSitePrompt()}
                </ModalHeader>
                <ModalBody>
                    <Flex direction="column" align="center" justify="center">
                        <Box maxHeight="200px" overflowY="auto" mb={4} borderWidth="3px" borderRadius="md" p={2} textAlign="center">
                            <Text fontSize="lg" color="gray.600" mb={1}>Sites</Text>
                            <Text fontSize="xl" color="gray.800">
                                {models.map(model => model.siteName).join(', ')}
                            </Text>
                        </Box>
                        <Box mb={8} />
                        <Flex direction="column" gap={4} width="100%">
                            <Flex justify="space-between" align="center">
                                <Flex align="center" flex="1">
                                    <Box mr={4}>
                                        <Text fontSize="lg" color="gray.800" fontWeight="bold">Site Groups:</Text>
                                    </Box>
                                    <Select
                                        placeholder="Select Site Group"
                                        value={selectedGroup}
                                        onChange={(e) => {
                                            setSelectedGroup(e.target.value);
                                        }}
                                        flex="1"
                                        textAlign="center"
                                        fontSize="lg"
                                    >
                                        {siteGroups.map(siteGroup => (
                                            <option key={siteGroup.id} value={siteGroup.id}>
                                                {siteGroup.name}
                                            </option>
                                        ))}
                                    </Select>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Flex direction="column" align="center" justify="center" width="100%">
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <ButtonGroup spacing={4}>
                            <Button colorScheme="blue" onClick={handleConfirm} fontSize="lg">
                                Confirm
                            </Button>
                            <Button variant="outline" onClick={handleCancel} fontSize="lg">
                                Cancel
                            </Button>
                        </ButtonGroup>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};


export default SiteGroupOptionsModal;
