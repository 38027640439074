import { runInAction } from 'mobx';
import { inject, observer, useLocalStore } from 'mobx-react';
import { DoctorEntity, HolterReportEntity, PatientEntity, ReportEntity, StudyEntity } from 'Models/Entities';
import { genderType, genderTypeOptions, studyType, studyTypeOptions } from 'Models/Enums';
import React from 'react';

import { TextField } from 'Views/Components/TextBox/TextBox';
import alert from 'Util/ToastifyUtils';
import { store } from 'Models/Store';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { Button, Colors, Display, Sizes } from 'Views/Components/Button/Button';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { DatePicker } from 'Views/Components/DatePicker/DatePicker';
import { TextArea } from 'Views/Components/TextArea/TextArea';

interface INewStudyState {
	firstName: string;
	lastName: string;
	patientId: string;
	dob: Date | null;
	gender: genderType | null;
	siteId: string | null;
	studyType: studyType | null;
	referringDoctorPrefix: string;
	referringDoctorFirstName: string;
	referringDoctorLastName: string;
	urgency: boolean;
	clinicalDetails: string;
	errors: { [attr: string]: string };
}

const fieldErrorMessages = {
	firstName: 'First name is required.',
	lastName: 'Last name is required.',
	patientId: 'Patient ID is required',
	dob: 'D.O.B is required',
	gender: 'Gender is required',
	siteId: 'Site is required',
	studyType: 'Study Type is required',
	referringDoctorPrefix: 'Referring doctor prefix is required',
	referringDoctorFirstName: 'Referring doctor first name is required',
	referringDoctorLastName: 'Referring doctor last name is required',
	clinicalDetails: 'Clinical Details are required',
};

interface INewStudyModalProps {
	patient: PatientEntity;
	update: () => void;
}

const NewStudyModal: React.FunctionComponent<INewStudyModalProps> = (props: INewStudyModalProps) => {
	const newStudyStore = useLocalStore(() => ({
		newStudyState: {
			firstName: props.patient.name.split(' ')[0],
			lastName: props.patient.name.split(' ').slice(1).join(' '),
			patientId: props.patient.patientId,
			dob: new Date(props.patient.dob),
			gender: props.patient.gender,
			siteId: null,
			studyType: null,
			referringDoctorPrefix: '',
			referringDoctorFirstName: '',
			referringDoctorLastName: '',
			urgency: false,
			clinicalDetails: '',
			errors: {},
		} as INewStudyState,
		site: null,
	}));

	const siteOptions = store.userGroups.map(ug => ug.name).includes('SuperAdmin') ? store.sites.map(site => ({ display: site.siteName, value: site.id })) : store.userSiteAccess.map(usa => ({ display: usa.site.siteName, value: usa.siteId }));

	const validateField = (field: string) => {
		if (newStudyStore.newStudyState[field] === '' || newStudyStore.newStudyState[field] === null) {
			runInAction(() => {
				newStudyStore.newStudyState.errors[field] = fieldErrorMessages[field];
			});
		} else {
			runInAction(() => {
				delete newStudyStore.newStudyState.errors[field];
			});
		}
	};

	const createStudyWithReport = (studyEntity: StudyEntity) => {
		const holterTypes = [studyTypeOptions.HOLTERTWENTYFOURHR, studyTypeOptions.HOLTERFORTYEIGHTHR, studyTypeOptions.HOLTEREXTENDED, studyTypeOptions.AMBULATORY_BP, studyTypeOptions.EVENT_MONITOR, studyTypeOptions.EXERCISE_STRESS_ECG];

		if (holterTypes.includes(studyTypeOptions[studyEntity.studyType])) {
			studyEntity.save({ holterReport: {}, doctorss: {} }).then(() => {
				store.modal.hide();
				alert(
					<div>
						<h6>Study Created</h6>
					</div>,
					'success',
				);
				props.update();
			}).catch(e => {
				alert(
					<div>
						<p>Could not create study</p>
					</div>,
					'error',
				);
			});
		} else {
			studyEntity.save({ report: {}, doctorss: {} }).then(() => {
				store.modal.hide();
				alert(
					<div>
						<h6>Study Created</h6>
					</div>,
					'success',
				);
				props.update();
			}).catch(e => {
				alert(
					<div>
						<p>Could not create study</p>
					</div>,
					'error',
				);
			});
		};
	};

	const onSubmit = (event: any) => {
		event.preventDefault();

		Object.keys(newStudyStore.newStudyState).forEach(field => field !== 'errors' && validateField(field));

		if (Object.keys(newStudyStore.newStudyState.errors).length === 0) {
			if (newStudyStore.newStudyState.siteId && newStudyStore.newStudyState.studyType) {
				const studyEntity = new StudyEntity({
					doctorss: [new DoctorEntity({
						prefix: newStudyStore.newStudyState.referringDoctorPrefix,
						firstName: newStudyStore.newStudyState.referringDoctorFirstName,
						lastName: newStudyStore.newStudyState.referringDoctorLastName,
						doctorType: 'REFERRING',
					})],
					clinicalDetails: newStudyStore.newStudyState.clinicalDetails,
					studyType: newStudyStore.newStudyState.studyType,
					studyDate: new Date(),
					urgent: newStudyStore.newStudyState.urgency,
					patient: props.patient,
					patientId: props.patient.id,
				});

				const holterTypes = [studyTypeOptions.HOLTERTWENTYFOURHR, studyTypeOptions.HOLTERFORTYEIGHTHR, studyTypeOptions.HOLTEREXTENDED, studyTypeOptions.AMBULATORY_BP, studyTypeOptions.EVENT_MONITOR, studyTypeOptions.EXERCISE_STRESS_ECG];

				if (holterTypes.includes(studyTypeOptions[newStudyStore.newStudyState.studyType])) {
					studyEntity.holterReport = new HolterReportEntity({
						reportStatus: 'NOT_REPORTED_HOLTER',
					});

					createStudyWithReport(studyEntity);
				} else {
					studyEntity.report = new ReportEntity({
						reportStatus: 'NOT_REPORTED',
					});

					createStudyWithReport(studyEntity);
				}
			}
		}
	};
		
	return (
		<div className="newEntity__modal">
			<h4>New Study</h4>
			<form className="newEntity" onSubmit={onSubmit}>
				<div className="double-input">
					<TextField 
						id="newStudy_firstName"
						className="newStudy-firstName"
						model={newStudyStore.newStudyState}
						modelProperty="firstName"
						label="First name"
						isDisabled
						errors={newStudyStore.newStudyState.errors.firstName} 
					/>
					<TextField 
						id="newStudy_lastName"
						className="newStudy-lastName"
						model={newStudyStore.newStudyState}
						modelProperty="lastName"
						label="Last name"
						isDisabled
						errors={newStudyStore.newStudyState.errors.lastName} 
					/>
				</div>
				<div className="double-input">
					<TextField 
						model={newStudyStore.newStudyState}
						id="newStudy_patientId"
						className="newStudy-patientId"
						modelProperty="patientId"
						label="Patient ID"
						isDisabled
						errors={newStudyStore.newStudyState.errors.patientId} 
					/>
					<DatePicker 
						model={newStudyStore.newStudyState}
						id="newStudy_dob"
						className="newStudy-dob"
						modelProperty="dob"
						label="D.O.B"
						isDisabled
						errors={newStudyStore.newStudyState.errors.dob} 
					/>
				</div>
				<div className="double-input">
					<Combobox
						model={newStudyStore.newStudyState}
						className="newStudy-studyType"
						modelProperty="studyType"
						label="Select study type"
						isRequired
						errors={newStudyStore.newStudyState.errors.studyType}
						options={Object.keys(studyTypeOptions).filter(option => option !== 'ECHO' && option !== 'STRESS_ECHO').map(option => ({ display: studyTypeOptions[option], value: option }))}
					/>
					<Combobox
						model={newStudyStore.newStudyState}
						className="newStudy-gender"
						modelProperty="gender"
						label="Select gender"
						isDisabled
						errors={newStudyStore.newStudyState.errors.gender}
						options={Object.keys(genderTypeOptions).map(option => ({ display: genderTypeOptions[option], value: option }))}
					/>
				</div>
				<Combobox
					model={newStudyStore.newStudyState}
					className="newStudy-siteId"
					modelProperty="siteId"
					label="Select site"
					isRequired
					errors={newStudyStore.newStudyState.errors.siteId}
					options={siteOptions}
				/>
				<div className="referring_doctor">
					<h4>Referring Doctor</h4>
					<div className="referring_doctor-details">
						<TextField 
							model={newStudyStore.newStudyState}
							id="newPatient_referringDoctorPrefix"
							className="newPatient-referringDoctorPrefix"
							modelProperty="referringDoctorPrefix"
							label="Prefix"
							isRequired
							errors={newStudyStore.newStudyState.errors.referringDoctorPrefix} 
						/>
						<TextField 
							model={newStudyStore.newStudyState}
							id="newPatient_referringDoctorFirstName"
							className="newPatient-referringDoctorFirstName"
							modelProperty="referringDoctorFirstName"
							label="First Name"
							isRequired
							errors={newStudyStore.newStudyState.errors.referringDoctorFirstName} 
						/>
						<TextField 
							model={newStudyStore.newStudyState}
							id="newPatient_referringDoctorLastName"
							className="newPatient-referringDoctorLastName"
							modelProperty="referringDoctorLastName"
							label="Last Name"
							isRequired
							errors={newStudyStore.newStudyState.errors.referringDoctorLastName} 
						/>
					</div>
				</div>
				<Combobox
					model={newStudyStore.newStudyState}
					className="newStudy-urgency"
					modelProperty="urgency"
					label="Urgency"
					isRequired
					errors={newStudyStore.newStudyState.errors.urgency}
					options={[{ display: 'Urgent', value: true }, { display: 'Routine', value: false }]}
				/>
				<TextArea 
					model={newStudyStore.newStudyState}
					id="newStudy_clinicalDetails"
					className="newStudy-clinicalDetails"
					modelProperty="clinicalDetails"
					label="Clinical Details"
					labelVisible
					isRequired
					errors={newStudyStore.newStudyState.errors.clinicalDetails} 
				/>
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
					<Button 
						colors={Colors.Primary} 
						display={Display.Outline} 
						sizes={Sizes.Medium} 
						buttonProps={{ id: 'cancel' }} 
						onClick={() => store.modal.hide()}
					>
						Cancel
					</Button>
					<Button 
						type="submit"
						colors={Colors.Primary} 
						display={Display.Solid} 
						sizes={Sizes.Medium} 
						buttonProps={{ id: 'submit' }} 
					>
						Create Study
					</Button>
				</ButtonGroup>
			</form>
		</div>
	);
};

export default inject('store')(observer(NewStudyModal));
