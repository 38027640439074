import {inject, observer} from 'mobx-react';
import React from 'react';
import Tabs from 'Views/Components/Tabs/Tabs';
import {withRouter, WithRouterProps} from "../../Util/withRouter";
import {Button, Colors, Display, Sizes} from "../Components/Button/Button";
import {runInAction} from "mobx";
import {SERVER_URL} from "../../Constants";
import {useNavigate} from "react-router";
import UserSiteAccessEditCrudTile from "./UserSiteAccess/UserSiteAccessEditCrudTile";
import {useParams} from "react-router-dom";

const UserAccessEditWrappingTileTile = (props: WithRouterProps) => {
	const navigate = useNavigate();
	const {id} = useParams();
	return (
			<div className="user-dashboard__container">
				<div style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: '20px'
				}}>
					<Button
						colors={Colors.Primary}
						display={Display.Solid}
						sizes={Sizes.Medium}
						buttonProps={{id: 'confirm'}}
						onClick={() => runInAction(() => {
							navigate(`${SERVER_URL}/userdashboard`);
						})}
					>
						Back
					</Button>
					<div style={{marginLeft: 'auto'}}>
						<Button
							colors={Colors.Primary}
							display={Display.Solid}
							sizes={Sizes.Medium}
							buttonProps={{id: 'create'}}
							onClick={() => runInAction(() => {
								navigate(`${SERVER_URL}/userdashboard/editsiteaccess/${id}/create`);
							})}
						>
							Add Site Access
						</Button>
					</div>
				</div>
				<div>
					<UserSiteAccessEditCrudTile/>
				</div>
			</div>
	);};
export default inject('store')(observer(withRouter(UserAccessEditWrappingTileTile)));
