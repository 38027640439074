import React, { useEffect, useRef, useState } from 'react';

import { observable, action, toJS, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { store } from 'Models/Store';
import { ModelCollection } from 'Views/Components/ModelCollection/ModelCollection';
import * as Models from 'Models/Entities';
import moment from 'moment';
import { ApiQueryParams, IOrderByCondition } from 'Views/Components/ModelCollection/ModelAPIQuery';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import { PaginationQueryOptions } from 'Models/PaginationData';
import { IFilter } from 'Views/Components/Collection/CollectionFilterPanel';
import { accessLevelOptions, genderTypeOptions, userTypeOptions } from 'Models/Enums';
import { makeEnumFetchFunction } from 'Util/EntityUtils';
import { debounce } from 'lodash';
import {ICollectionBulkActionProps, ICollectionItemActionProps} from 'Views/Components/Collection/Collection';
import { Button, Colors, Display, Sizes } from 'Views/Components/Button/Button';
import alert from 'Util/ToastifyUtils';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import {Heading} from '@chakra-ui/react'
import axios from "axios";
import {useParams} from "react-router-dom";
import TextEditButton from "../../Components/Button/TextEditButton";
import {SiteGroupEntity} from "Models/Entities";
import {useQuery} from "@apollo/client";

type transformFn<T> = (item: T, name: string) => (string | React.ReactNode);
interface IUserSiteAccessEditCrudTileProps {
	orderBy?: IOrderByCondition<Models.UserSiteAccessEntity>;
	perPage?: number;
}

interface User {
	id: string;
	name: string;
	email: string;
}
interface ISearch {
	searchTerm: string;
}

const UserSiteAccessEditCrudTile = (props: IUserSiteAccessEditCrudTileProps) => {
	const [search, setSearch] = useState({ searchTerm: '' });
	const [user, setUser] = useState<User>();
	const collectionRef = useRef<ModelCollection<Models.UserSiteAccessEntity> | null>(null);
	const defaultOrderBy: IOrderByCondition<Models.UserSiteAccessEntity> = { path: 'created', descending: true };
	const orderBy: IOrderByCondition<Models.UserSiteAccessEntity> | undefined = observable(defaultOrderBy || props.orderBy);
	const paginationQueryOptions: PaginationQueryOptions = observable(new PaginationQueryOptions());
	const { id } = useParams();

	const getUser = async () => {
		const response = await axios.get(`/api/entity/UserEntity/${id}`)
		return response.data;
	}
	
	useEffect(() => {
		const fetchUser = async () => {
			const data = await getUser();
			setUser(data)
		};
		if(id){
			fetchUser();
		}
	}, [id]);

	const {loading, data: siteGroups} = useQuery(SiteGroupEntity.fetchSiteGroupFilter())
	if(loading){
		return(<></>)
	}

	const getBulkActions = (): ICollectionBulkActionProps<Models.UserSiteAccessEntity>[] => {
		const revokeBulkAccess: ICollectionBulkActionProps<Models.UserSiteAccessEntity> = {
			label: `Bulk Revoke User Site Access`,
			showIcon: true,
			icon: 'person-remove',
			iconPos: 'icon-left',
			bulkAction: (models, event) => {
				revokeAccess(models)
			},
		};

		return [revokeBulkAccess];
	};
	const toggleAccessLevel = (usa: Models.UserSiteAccessEntity) => {
		store.modal.show('Confirmation', 
			<div className="confirmation__modal">
				<h4>{`Do you want to set the access level to ${usa.accessLevel === 'VIEW_ONLY' ? 'Full Access' : 'View Only'}?`}</h4>
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
					<Button 
						colors={Colors.Primary} 
						display={Display.Solid} 
						sizes={Sizes.Medium} 
						buttonProps={{ id: 'confirm' }} 
						onClick={() => runInAction(() => {
							usa.accessLevel = usa.accessLevel === 'VIEW_ONLY' ? 'FULL_ACCESS' : 'VIEW_ONLY';
							usa.save();
							store.modal.hide();
						})}
					>
						Confirm
					</Button>
					<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={() => store.modal.hide()}>Cancel</Button>
				</ButtonGroup>
			</div>);
	};

	const revokeAccess = (usa: Models.UserSiteAccessEntity[]) => {
		const isBulk = usa.length > 1
		store.modal.show('Confirmation', 
			<div className="confirmation__modal">
				<h4>{`Do you want to revoke ${usa[0].user.name}'s access to ${isBulk ? `${usa.length}  sites` : usa[0].site.siteName}?`}</h4>
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
					<Button 
						colors={Colors.Primary} 
						display={Display.Solid} 
						sizes={Sizes.Medium} 
						buttonProps={{ id: 'confirm' }} 
						onClick={() => runInAction(() => {
							Promise.all(usa.map(item => item.delete()))
								.then(() => {
									collectionRef.current?.refetch();
									alert('Deleted successfully', 'success');
									store.modal.hide();
								})
								.catch(errorMessage => {
									alert(
										<div className="delete-error">
											<p className="user-error">Some records could not be deleted</p>
											<p className="internal-error-title">Message:</p>
											<p className="internal-error">{errorMessage}</p>
										</div>,
										'error',
									);
								});
						})}
					>
						Confirm
					</Button>
					<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={() => store.modal.hide()}>Cancel</Button>
				</ButtonGroup>
			</div>);
	};

	const toggleHL7Messaging = (usa: Models.UserSiteAccessEntity) => {
		store.modal.show('Confirmation',
			<div className="confirmation__modal">
				<h4>{`Do you want to ${usa.hl7MessagingEnabled ? 'disable' : 'enable'} HL7 Messaging for ${usa.user.name} at site ${usa.site.siteName}?`}</h4>
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
					<Button
						colors={Colors.Primary}
						display={Display.Solid}
						sizes={Sizes.Medium}
						buttonProps={{id: 'confirm'}}
						onClick={() => runInAction(() => {
							usa.hl7MessagingEnabled = !usa.hl7MessagingEnabled;
							usa.save().then(() => {
								alert(`${usa.hl7MessagingEnabled ? 'Enabled' : 'Disabled'} HL7 Messaging`, 'success');
								store.modal.hide();
							}).catch(errorMessage => {
								alert(
									<div className="delete-error">
										<p className="user-error">This record could not be updated</p>
										<p className="internal-error-title">Message:</p>
										<p className="internal-error">{errorMessage}</p>
									</div>,
									'error',
								);
							});
						})}
					>
						Confirm
					</Button>
					<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium}
							buttonProps={{id: 'cancel'}} onClick={() => store.modal.hide()}>Cancel</Button>
				</ButtonGroup>
			</div>);
	}

		const toggleFeatureSessions = (usa: Models.UserSiteAccessEntity) => {
			store.modal.show('Confirmation',
				<div className="confirmation__modal">
					<h4>{`Do you want to ${usa.featureSessions ? 'disable' : 'enable'} Feature Sessions for ${usa.user.name} at site ${usa.site.siteName}?`}</h4>
					<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
						<Button
							colors={Colors.Primary}
							display={Display.Solid}
							sizes={Sizes.Medium}
							buttonProps={{ id: 'confirm' }}
							onClick={() => runInAction(() => {
								usa.featureSessions = !usa.featureSessions;
								usa.save().then(() => {
									alert(`${usa.featureSessions ? 'Enabled' : 'Disabled'} Feature Sessions`, 'success');
									store.modal.hide();
								}).catch(errorMessage => {
									alert(
										<div className="delete-error">
											<p className="user-error">This record could not be updated</p>
											<p className="internal-error-title">Message:</p>
											<p className="internal-error">{errorMessage}</p>
										</div>,
										'error',
									);
								});
							})}
						>
							Confirm
						</Button>
						<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={() => store.modal.hide()}>Cancel</Button>
					</ButtonGroup>
				</div>);
		};
	const tranformUserName: transformFn<Models.UserSiteAccessEntity> = (userSiteAccess: Models.UserSiteAccessEntity) => userSiteAccess?.userName;
	const tranformSiteName: transformFn<Models.UserSiteAccessEntity> = (userSiteAccess: Models.UserSiteAccessEntity) => userSiteAccess?.site?.siteName;
	const transformUserType: transformFn<Models.UserSiteAccessEntity> = (userSiteAccess: Models.UserSiteAccessEntity) => userTypeOptions[userSiteAccess?.user?.userType];
	const transformProviderNumber: transformFn<Models.UserSiteAccessEntity> = (userSiteAccess: Models.UserSiteAccessEntity) => {
		return (
			<TextEditButton
				initialText={userSiteAccess?.providerNumber ? userSiteAccess.providerNumber : ""}
				onUpdate={(newText) => {
					runInAction(() => {
						userSiteAccess.providerNumber = newText;
						userSiteAccess.save().then(() => {
							alert(`${userSiteAccess.siteName} provider number updated`);
							store.modal.hide();
						}).catch(errorMessage => {
							alert(
								<div className="delete-error">
									<p className="user-error">This record could not be updated</p>
									<p className="internal-error-title">Message:</p>
									<p className="internal-error">{errorMessage}</p>
								</div>,
								'error',
							);
						});;
					})
				}}
			/>
		);
	}
	const transformUserAccess: transformFn<Models.UserSiteAccessEntity> = (userSiteAccess: Models.UserSiteAccessEntity) => {
		if (userSiteAccess.accessLevel === 'VIEW_ONLY') {
			return (
				<Button
					colors={Colors.Secondary}
					display={Display.Solid}
					sizes={Sizes.Small}
					icon={{ icon: 'chevron-down', iconPos: 'icon-right' }}
					onClick={() => runInAction(() => toggleAccessLevel(userSiteAccess))}
				>
					View Only
				</Button>
			);
		}
		return (
			<Button
				colors={Colors.Primary}
				display={Display.Solid}
				sizes={Sizes.Small}
				icon={{ icon: 'chevron-down', iconPos: 'icon-right' }}
				onClick={() => runInAction(() => toggleAccessLevel(userSiteAccess))}
			>
				Full Access
			</Button>
		);
	};
	const transformSiteGroup: transformFn<Models.UserSiteAccessEntity> = (usa: Models.UserSiteAccessEntity) => {
		const matchingSiteGroup = siteGroups.siteGroupEntitys.find((group: { id: string | undefined; }) => group.id === usa.site.siteGroupId);
		
		if (matchingSiteGroup) {
			return matchingSiteGroup.name;
		}

		return usa.site.siteGroupId;
	}

	const getHeaders = (): Array<ICollectionHeaderProps<Models.UserSiteAccessEntity>> => {
		return [
			...(id === undefined ? [
				{
					name: 'name',
					displayName: 'Name',
					sortable: true,
					transformItem: tranformUserName,
				}
			] : []),
			{
				name: 'site',
				displayName: 'Site',
				sortable: true,
				transformItem: tranformSiteName,
			},
			{
				name: 'siteGroup',
				displayName: 'Site Group',
				sortable: true,
				transformItem: transformSiteGroup
			},
			{
				name: 'userType',
				displayName: 'User Type',
				sortable: true,
				transformItem: transformUserType,
			},
			{
				name: 'providerNumber',
				displayName: 'Provider Number',
				sortable: true,
				transformItem: transformProviderNumber,
			},
			{
				name: 'accessLevel',
				displayName: 'Access Level',
				sortable: true,
				transformItem: transformUserAccess,
			},
		];
	};

	const getActions = () => {
		const tableActions: Array<ICollectionItemActionProps<Models.UserSiteAccessEntity>> = [];

		tableActions.push({
			action: item => {},
			label: 'HL7 Messaging',
			customButton: (usa: Models.UserSiteAccessEntity) => (
				<Button 
					colors={Colors.Primary}
					display={Display.Solid} 
					sizes={Sizes.Small} 
					buttonProps={{ id: `hl7-messaging-${usa.hl7MessagingEnabled ? 'disable' : 'enable'}` }} 
					onClick={() => toggleHL7Messaging(usa)}
				>
					{`${usa.hl7MessagingEnabled ? 'Disable' : 'Enable'} HL7 Messaging`}
				</Button>
			),
		});

		tableActions.push({
			action: item => {},
			label: 'Feature Sessions',
			customButton: (usa: Models.UserSiteAccessEntity) => (
				<Button
					colors={Colors.Primary}
					display={Display.Solid}
					sizes={Sizes.Small}
					buttonProps={{ id: `feature-sessions-${usa.featureSessions ? 'disable' : 'enable'}` }}
					onClick={() => toggleFeatureSessions(usa)}
				>
					{`${usa.featureSessions ? 'Disable' : 'Enable'} Feature Sessions`}
				</Button>
			),
		});

		tableActions.push({
			action: item => {},
			label: 'Revoke Access',
			customButton: (usa: Models.UserSiteAccessEntity) => (
				<Button
					colors={Colors.Warning}
					display={Display.Solid}
					sizes={Sizes.Small}
					buttonProps={{ id: 'revoke-access' }}
					onClick={() => revokeAccess([usa])}
				>
					Revoke Access
				</Button>
			),
		});
		
		return tableActions;
	};

	const getMoreParamsFromFilters = (filters: Array<IFilter<Models.UserSiteAccessEntity>>): ApiQueryParams => {
		const params = {};

		const accessFilter = filters.find(f => f.path === 'accessLevel' && !!f.value1);
		if (accessFilter) {
			if ((accessFilter.value1 as string[]).includes('VIEW_ONLY')) {
				params['viewOnly'] = true;
			}
			if ((accessFilter.value1 as string[]).includes('FULL_ACCESS')) {
				params['fullAccess'] = true;
			}
		}

		const userTypeFilter = filters.find(f => f.path === 'userType' && !!f.value1);
		if (userTypeFilter) {
			if ((userTypeFilter.value1 as string[]).includes('CLIENT_DEVICE')) {
				params['clientDevice'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('SITE_ADMIN')) {
				params['siteAdmin'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('SITE_USER')) {
				params['siteUser'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('REPORTING_DOCTOR')) {
				params['reportingDoctor'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('CARDIAC_SCIENTIST')) {
				params['cardiacScientist'] = true;
			}
		}

		const siteFilter = filters.find(f => f.path === 'site' && !!f.value1 && (f.value1 as []).length !== 0);
		if (siteFilter) {
			params['siteFilter'] = JSON.stringify(siteFilter.value1);
		}

		const siteGroupFilter = filters.find(f => f.path === 'siteGroup' && !!f.value1 && (f.value1 as []).length !== 0);
		if (siteGroupFilter) {
			params['siteGroup'] = JSON.stringify(siteGroupFilter.value1);
		}

		return params;
	};

	const onSearchTriggered = (searchTerm: string) => {
		setSearch({ searchTerm });
	};

	const getMoreParams = (filters?: Array<IFilter<Models.UserSiteAccessEntity>>, filterApplied?: boolean): ApiQueryParams => {
		const filterParams = (filters && filterApplied) ? getMoreParamsFromFilters(filters) : {};

		if (!store.userGroups.map(ug => ug.name).includes('SuperAdmin') && !store.userGroups.map(ug => ug.name).includes('Super Administrators')) {
			filterParams['siteIds'] = JSON.stringify(store.userSiteAccess.map(usa => usa.siteId));
		}

		if (search.searchTerm) {
			filterParams['searchStr'] = search.searchTerm;
		}
		
		if(id) {
			filterParams['id'] = id;

		}

		return filterParams;
	};

	const additionalActions = (): React.ReactNode[] => [];

	const getFilters = (): Array<IFilter<Models.UserSiteAccessEntity>> => {
		const accessFilter: IFilter<Models.UserSiteAccessEntity> = {
			path: 'accessLevel',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Access Level',
			enumResolveFunction: makeEnumFetchFunction(accessLevelOptions),
		};

		const userTypeFilter: IFilter<Models.UserSiteAccessEntity> = {
			path: 'userType',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'User Type',
			enumResolveFunction: makeEnumFetchFunction(userTypeOptions),
		};

		const siteOptions = {};
		if (store.userGroups.map(ug => ug.name).includes('SuperAdmin')) {
			store.sites.forEach(site => {
				if (site.siteName) {
					siteOptions[site.id] = site.siteName;
				}
			});
		} else {
			store.userSiteAccess.forEach(usa => {
				const jsUsa = toJS(usa);
				if (jsUsa.siteId && jsUsa.site) {
					if (jsUsa.site.siteName) {
						siteOptions[jsUsa.siteId] = jsUsa.site.siteName;
					}
				}
			});
		}

		const siteFilter: IFilter<Models.UserSiteAccessEntity> = {
			path: 'site',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Site',
			enumResolveFunction: makeEnumFetchFunction(siteOptions)
		};

		let siteGroupOptions = {};
		siteGroups.siteGroupEntitys.forEach((siteGroup: { name: any; id: string | number; }) => {
			if (siteGroup.name) {
				siteGroupOptions[siteGroup.id] = siteGroup.name;
			}
		});

		const siteGroupFilter: IFilter<Models.UserSiteAccessEntity> = {
			path: 'siteGroup',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Site Group',
			enumResolveFunction: makeEnumFetchFunction(siteGroupOptions),
		};

		return [accessFilter, siteFilter, userTypeFilter, siteGroupFilter];
	};

	return (
		<div>
			{id && <>
				<Heading as={"h4"} mb={0}>Update User Site Access:</Heading>
				<Heading as={"h4"} mt={2}>{user?.name} - {user?.email}</Heading>
			</>}
			<ModelCollection
				ref={collectionRef}
				url={"/api/entity/UserSiteAccessEntity/userSiteAccessList"}
				searchStr={search.searchTerm}
				isApiQuery
				orderBy={orderBy}
				model={Models.UserSiteAccessEntity}
				headers={getHeaders()}
				actions={getActions()}
				selectableItems={true}
				onSearchTriggered={debounce(onSearchTriggered, 200)}
				perPage={paginationQueryOptions.perPage}
				idColumn="id"
				dataFields={row => ({
					created: moment(row.created).format('YYYY-MM-DD'),
					modified: moment(row.modified).format('YYYY-MM-DD'),
				})}
				getMoreParams={getMoreParams}
				filters={getFilters()}
				additionalActions={additionalActions()}
				selectedBulkActions={getBulkActions()}
				dateFormat="d/m/Y"
				altFormat="d/m/Y"
			/>
		</div>
	);
};

export default inject('store')(observer(UserSiteAccessEditCrudTile));
