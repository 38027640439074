import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { observer } from 'mobx-react';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import ProfileWrappingTile from 'Views/Tiles/Profile/ProfileWrappingTile';

@observer
class ProfilePagePage extends React.Component {
	render() {
		return (
			<SecuredPage groups={['SuperAdmin', 'User']}>
				<Navigation
					linkGroups={getFrontendNavLinks()}
					orientation={Orientation.VERTICAL}
				/>
				<div className="body-content">
					<ProfileWrappingTile {...this.props} />
				</div>
			</SecuredPage>
		)
	}
}


export default ProfilePagePage;

