import axios from 'axios';
import classNames from 'classnames';
import cornerstone from 'cornerstone-core';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import dicomParser from 'dicom-parser';
import Hammer from 'hammerjs';
import { runInAction } from 'mobx';
import { useLocalStore } from 'mobx-react';
import { StudyEntity, StudyFileEntity } from 'Models/Entities';
import moment from 'moment/moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { useNavigate } from 'react-router';
import { SERVER_URL } from '../../../../Constants';
import { store } from '../../../../Models/Store';
import { Button, Colors, Display, Sizes } from '../../../Components/Button/Button';
import { ButtonGroup } from '../../../Components/Button/ButtonGroup';

const scrollToIndex = cornerstoneTools.import('util/scrollToIndex');

cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

interface ImageViewerProps {
	study: StudyEntity;
	isImageViewerFullScreen: boolean;
}
const ImageViewer = (props: ImageViewerProps) => {
	const { study, isImageViewerFullScreen } = props;
	const navigate = useNavigate();

	let heroDicomViewer: HTMLElement | null;

	let gridDicomViewer1: HTMLElement | null;
	let gridDicomViewer2: HTMLElement | null;
	let gridDicomViewer3: HTMLElement | null;
	let gridDicomViewer4: HTMLElement | null;

	let dicomViewerBaseline: HTMLElement | null;
	let dicomViewerPeak: HTMLElement | null;
	let dicomViewerPost: HTMLElement | null;
	let dicomViewerRecovery: HTMLElement | null;
	
	const [heroId, setHeroId] = useState<number>(0)

	const cornerstoneState = useLocalStore(() => ({
		tools: [
			{
				name: 'Wwwc',
				mode: 'active',
				modeOptions: { mouseButtonMask: 1 },
			},
			{
				name: 'Zoom',
				mode: 'active',
				modeOptions: { mouseButtonMask: 2 },
			},
			{
				name: 'Pan',
				mode: 'active',
				modeOptions: { mouseButtonMask: 4 },
			},
			// Scroll 
			{ name: 'StackScrollMouseWheel', mode: 'active' },
			// Touch 
			{ name: 'PanMultiTouch', mode: 'active' },
			{ name: 'ZoomTouchPinch', mode: 'active' },
			{ name: 'StackScrollMultiTouch', mode: 'active' },
			// Measure 
			{ name: 'Length', mode: 'enabled' },
			// Area  
			{ name: 'FreehandRoi', mode: 'enabled' },
		],
		imageIds: Array(),
		isLoading: true,
		fullScreen: false,
		heroStack: Array(),
		baseStacksList: Array(),
		peakStacksList: Array(),
		postStacksList: Array(),
		recoverStacksList: Array(),
		studyViewNumber: '1',
		activeTool: '',
		isPlaying: false,
		archived: false,
		restoring: false,
		restoreProgress: false,
		archivedList: Array(),
		restoringList: Array(),
		processedFilesIndex: 0,
		processedESEFilesIndex: 0,
		viewerLoaded: false,
		currentFrameRate: 0,
	}));

	const thumbnailState = useLocalStore(() => ({
		thumbnails: Array(),
	}));

	const [viewerState, _setViewerState] = useState( 'std');
	const viewerStateRef = React.useRef(viewerState);
	const setViewerState = (data: string) => {
		viewerStateRef.current = data;
		_setViewerState(data)
	}
	
	const [previousViewerState, setPreviousViewerState] = useState('std');

	// @ts-ignore
	const metaDataProvider = (type: string, imageId: unknown) => {
		const stackData = cornerstoneTools.getToolState(heroDicomViewer, 'stack');

		if (type === 'imagePlaneModule') {
			return {
				pixelSpacing: stackData.data[0].pixelSpacing,
				rowPixelSpacing: stackData.data[0].pixelSpacing,
				columnPixelSpacing: stackData.data[0].pixelSpacing,
			};
		}
	};

	// This is what it wants shrug 
	// @ts-ignore 
	// eslint-disable-next-line consistent-return 
	const metaDataProviderESE = (type: string, imageId: unknown) => {
		const stackData = cornerstoneTools.getToolState(dicomViewerBaseline, 'stack');

		if (type === 'imagePlaneModule') {
			return {
				pixelSpacing: stackData.data[0].pixelSpacing,
				rowPixelSpacing: stackData.data[0].pixelSpacing,
				columnPixelSpacing: stackData.data[0].pixelSpacing,
			};
		}
	};

	const startUpViewer = async () => {
		cornerstoneTools.init({ touchEnabled: true });
		// Configure Cornerstone viewer 
		heroDicomViewer = document.getElementById('DICOMImageViewerStd');
		gridDicomViewer1 = document.getElementById('DICOMImageViewerGrid1');
		gridDicomViewer2 = document.getElementById('DICOMImageViewerGrid2');
		gridDicomViewer3 = document.getElementById('DICOMImageViewerGrid3');
		gridDicomViewer4 = document.getElementById('DICOMImageViewerGrid4');

		dicomViewerBaseline = document.getElementById('dicomViewerBaseline');
		dicomViewerPeak = document.getElementById('dicomViewerPeak');
		dicomViewerPost = document.getElementById('dicomViewerPost');
		dicomViewerRecovery = document.getElementById('dicomViewerRecovery');

		// Register custom metaDataProvider with CornerstoneJS 
		cornerstone.enable(heroDicomViewer);

		cornerstone.enable(gridDicomViewer1);
		cornerstone.enable(gridDicomViewer2);
		cornerstone.enable(gridDicomViewer3);
		cornerstone.enable(gridDicomViewer4);

		cornerstone.enable(dicomViewerBaseline);
		cornerstone.enable(dicomViewerPeak);
		cornerstone.enable(dicomViewerPost);
		cornerstone.enable(dicomViewerRecovery);

		await axios.get(`${SERVER_URL}/api/entity/StudyEntity/${study.id}`)
			.then(res => {
				if (res.data !== '') {
					runInAction(() => {
						study.selectedReportTemplate = res.data.selectedReportTemplate;
					});
				}
			}).catch(err => console.log(err));
	};

	const pausePlayButton = () => {
		heroDicomViewer = document.getElementById('DICOMImageViewerStd');

		gridDicomViewer1 = document.getElementById('DICOMImageViewerGrid1');
		gridDicomViewer2 = document.getElementById('DICOMImageViewerGrid2');
		gridDicomViewer3 = document.getElementById('DICOMImageViewerGrid3');
		gridDicomViewer4 = document.getElementById('DICOMImageViewerGrid4');

		dicomViewerBaseline = document.getElementById('dicomViewerBaseline');
		dicomViewerPeak = document.getElementById('dicomViewerPeak');
		dicomViewerPost = document.getElementById('dicomViewerPost');
		dicomViewerRecovery = document.getElementById('dicomViewerRecovery');

		if (cornerstoneState.isPlaying) {
			runInAction(() => {
				cornerstoneState.isPlaying = false;
			});

			if (viewerState === 'grid') {
				cornerstoneTools.stopClip(gridDicomViewer1);
				cornerstoneTools.stopClip(gridDicomViewer2);
				cornerstoneTools.stopClip(gridDicomViewer3);
				cornerstoneTools.stopClip(gridDicomViewer4);
			} else if (viewerState === 'stress') {
				cornerstoneTools.stopClip(dicomViewerBaseline);
				cornerstoneTools.stopClip(dicomViewerPeak);
				cornerstoneTools.stopClip(dicomViewerPost);
				cornerstoneTools.stopClip(dicomViewerRecovery);
			} else {
				cornerstoneTools.stopClip(heroDicomViewer);
			}
		} else {
			runInAction(() => {
				cornerstoneState.isPlaying = true;
			});

			// Need at least one image 
			if (cornerstoneState.heroStack.length > 0) {
				if (viewerState === 'grid') {
					cornerstoneTools.playClip(gridDicomViewer1);
					cornerstoneTools.playClip(gridDicomViewer2);
					cornerstoneTools.playClip(gridDicomViewer3);
					cornerstoneTools.playClip(gridDicomViewer4);
				} else if (viewerState === 'stress') {
					const stackState1 = cornerstoneTools.getToolState(dicomViewerBaseline, 'stack');
					const stackState2 = cornerstoneTools.getToolState(dicomViewerPeak, 'stack');
					const stackState3 = cornerstoneTools.getToolState(dicomViewerPost, 'stack');
					const stackState4 = cornerstoneTools.getToolState(dicomViewerRecovery, 'stack');

					// Must all be in sync with Rest 
					cornerstoneTools.playClip(dicomViewerBaseline, 1 / (stackState1.data[0].effectiveDuration / stackState1.data[0].imageIds.length));

					if (stackState2) {
						cornerstoneTools.playClip(dicomViewerPeak, 1 / (stackState1.data[0].effectiveDuration / stackState2.data[0].imageIds.length));
					}

					if (stackState3) {
						cornerstoneTools.playClip(dicomViewerPost, 1 / (stackState1.data[0].effectiveDuration / stackState3.data[0].imageIds.length));
					}

					if (stackState4) {
						cornerstoneTools.playClip(dicomViewerRecovery, 1 / (stackState1.data[0].effectiveDuration / stackState4.data[0].imageIds.length));
					}

					scrollToIndex(dicomViewerBaseline, 0);
					scrollToIndex(dicomViewerPeak, 0);
					scrollToIndex(dicomViewerPost, 0);
					scrollToIndex(dicomViewerRecovery, 0);
				} else {
					cornerstoneTools.removeToolState(heroDicomViewer, 'stack');
					const stackState = cornerstoneTools.getToolState(heroDicomViewer, 'stack');
					const { frameRate } = stackState.data[0];
					cornerstoneTools.playClip(heroDicomViewer, frameRate);
				}
			}
		}
	};

	const slowHeroSpeed = () => {
		heroDicomViewer = document.getElementById('DICOMImageViewerStd');
		cornerstoneTools.removeToolState(heroDicomViewer, 'stack');
		const stackState = cornerstoneTools.getToolState(heroDicomViewer, 'stack');

		if (cornerstoneState.heroStack.length) {
			runInAction(() => {
				cornerstoneState.isPlaying = true;
			});

			const { frameRate } = stackState.data[0];

			if (cornerstoneState.currentFrameRate === 0) {
				runInAction(() => {
					cornerstoneState.currentFrameRate = frameRate;
				});
			}

			runInAction(() => {
				cornerstoneState.currentFrameRate *= 0.8;
			});

			cornerstoneTools.playClip(heroDicomViewer, cornerstoneState.currentFrameRate);
		}
	};

	const quickenHeroSpeed = () => {
		if (cornerstoneState.heroStack.length) {
			runInAction(() => {
				cornerstoneState.isPlaying = true;
			});

			heroDicomViewer = document.getElementById('DICOMImageViewerStd');
			cornerstoneTools.removeToolState(heroDicomViewer, 'stack');
			const stackState = cornerstoneTools.getToolState(heroDicomViewer, 'stack');
			const { frameRate } = stackState.data[0];

			if (cornerstoneState.currentFrameRate === 0) {
				runInAction(() => {
					cornerstoneState.currentFrameRate = frameRate;
				});
			}

			runInAction(() => {
				cornerstoneState.currentFrameRate += (cornerstoneState.currentFrameRate * 0.2);
			});

			cornerstoneTools.playClip(heroDicomViewer, cornerstoneState.currentFrameRate);
		}
	};

	const resetHeroSpeed = () => {
		if (cornerstoneState.heroStack.length) {
			runInAction(() => {
				cornerstoneState.isPlaying = true;
			});

			heroDicomViewer = document.getElementById('DICOMImageViewerStd');
			cornerstoneTools.removeToolState(heroDicomViewer, 'stack');
			const stackState = cornerstoneTools.getToolState(heroDicomViewer, 'stack');
			const { frameRate } = stackState.data[0];

			runInAction(() => {
				cornerstoneState.currentFrameRate = frameRate;
			});

			cornerstoneTools.playClip(heroDicomViewer, frameRate);
		}
	};

	const changeStressView = (step: number) => {
		let newView = Number(cornerstoneState.studyViewNumber) + step;

		if (newView > 6) {
			newView = 1;
		} else if (newView < 1) {
			// Reset to last view 
			newView = 6;
		}

		runInAction(() => {
			cornerstoneState.studyViewNumber = newView.toString();
			cornerstoneState.processedESEFilesIndex = 0;
		});

		downloadAndViewESE(newView.toString());
		updateStress(0);
	};

	const onResize = useCallback((viewer: string) => {
		const view = document.getElementById(viewer);
		
		if (view) {
			cornerstone.resize(view);
		}

		if (viewerState === 'grid') {
			if (!isImageViewerFullScreen && previousViewerState !== 'grid') {
				// @ts-ignore
				setViewerState(previousviewerState);
				setPreviousViewerState('');
			}	
		}
	}, []);

	const updateGrid = (currentIndex: number) => {
		gridDicomViewer1 = document.getElementById('DICOMImageViewerGrid1');
		gridDicomViewer2 = document.getElementById('DICOMImageViewerGrid2');
		gridDicomViewer3 = document.getElementById('DICOMImageViewerGrid3');
		gridDicomViewer4 = document.getElementById('DICOMImageViewerGrid4');

		// Stop clip from playing on viewer 
		cornerstoneTools.stopClip(gridDicomViewer1);
		cornerstoneTools.stopClip(gridDicomViewer2);
		cornerstoneTools.stopClip(gridDicomViewer3);
		cornerstoneTools.stopClip(gridDicomViewer4);

		const promises = [];

		if (cornerstoneState.imageIds[currentIndex]) {
			promises.push(cornerstone.loadAndCacheImage(cornerstoneState.imageIds[currentIndex]).then((image: any) => {
				cornerstoneTools.clearToolState(gridDicomViewer1, 'stack');
				cornerstoneTools.addStackStateManager(gridDicomViewer1, [
					'stack',
					'playClip',
				]);
				cornerstoneTools.addToolState(gridDicomViewer1, 'stack', {
					imageIds: cornerstoneState.heroStack[currentIndex].imageIds,
					currentImageIdIndex: 0,
				});

				// Display the image 
				cornerstone.displayImage(gridDicomViewer1, image);

				// Prefetch the remaining images in the stack (?) 
				cornerstoneTools.stackPrefetch.enable(gridDicomViewer1);

				cornerstoneTools.playClip(gridDicomViewer1, cornerstoneState.heroStack[currentIndex].frameRate);
			}));
		}

		if (cornerstoneState.imageIds[currentIndex + 1]) {
			promises.push(cornerstone.loadAndCacheImage(cornerstoneState.imageIds[currentIndex + 1]).then((image: any) => {
				cornerstoneTools.clearToolState(gridDicomViewer2, 'stack');
				cornerstoneTools.addStackStateManager(gridDicomViewer2, [
					'stack',
					'playClip',
				]);
				cornerstoneTools.addToolState(gridDicomViewer2, 'stack', {
					imageIds: cornerstoneState.heroStack[currentIndex + 1].imageIds,
					currentImageIdIndex: 0,
				});

				// Display the image 
				cornerstone.displayImage(gridDicomViewer2, image);

				// Prefetch the remaining images in the stack (?) 
				cornerstoneTools.stackPrefetch.enable(gridDicomViewer2);

				cornerstoneTools.playClip(gridDicomViewer2, cornerstoneState.heroStack[currentIndex + 1].frameRate);
			}));
		}

		if (cornerstoneState.imageIds[currentIndex + 2]) {
			promises.push(cornerstone.loadAndCacheImage(cornerstoneState.imageIds[currentIndex + 2]).then((image: any) => {
				cornerstoneTools.clearToolState(gridDicomViewer3, 'stack');
				cornerstoneTools.addStackStateManager(gridDicomViewer3, [
					'stack',
					'playClip',
				]);
				cornerstoneTools.addToolState(gridDicomViewer3, 'stack', {
					imageIds: cornerstoneState.heroStack[currentIndex + 2].imageIds,
					currentImageIdIndex: 0,
				});

				// Display the image 
				cornerstone.displayImage(gridDicomViewer3, image);

				// Prefetch the remaining images in the stack (?) 
				cornerstoneTools.stackPrefetch.enable(gridDicomViewer3);

				cornerstoneTools.playClip(gridDicomViewer3, cornerstoneState.heroStack[currentIndex + 2].frameRate);
			}));
		}

		if (cornerstoneState.imageIds[currentIndex + 3] && cornerstoneState.heroStack[currentIndex + 3]) {
			promises.push(cornerstone.loadAndCacheImage(cornerstoneState.imageIds[currentIndex + 3]).then((image: any) => {
				cornerstoneTools.clearToolState(gridDicomViewer4, 'stack');
				cornerstoneTools.addStackStateManager(gridDicomViewer4, [
					'stack',
					'playClip',
				]);
				cornerstoneTools.addToolState(gridDicomViewer4, 'stack', {
					imageIds: cornerstoneState.heroStack[currentIndex + 3].imageIds,
					currentImageIdIndex: 0,
				});

				// Display the image 
				cornerstone.displayImage(gridDicomViewer4, image);

				// Prefetch the remaining images in the stack (?) 
				cornerstoneTools.stackPrefetch.enable(gridDicomViewer4);

				cornerstoneTools.playClip(gridDicomViewer4, cornerstoneState.heroStack[currentIndex + 3].frameRate);

				runInAction(() => {
					cornerstoneState.isPlaying = true;
				});
			}));
		}

		Promise.all(promises).then(() => {
			onResize('DICOMImageViewerGrid1');
			onResize('DICOMImageViewerGrid2');
			onResize('DICOMImageViewerGrid3');
			onResize('DICOMImageViewerGrid4');
		});
	};

	const updateHero = (index: number) => {
		const x = document.getElementsByClassName('cornerstone-canvas active');

		Array.from(x).forEach(el => {
			el.classList.remove('active');
			el.classList.add('checked');
		});

		// @ts-ignore 
		const thumbnail = document.getElementById('dicom-thumbnails').children[index];

		if (thumbnail) {
			thumbnail.classList.remove('checked');
			thumbnail.className += ' active';
		}
		
		heroDicomViewer = document.getElementById('DICOMImageViewerStd');

		// Stop clip from playing on viewer 
		cornerstone.enable(heroDicomViewer);
		cornerstoneTools.stopClip(heroDicomViewer);

		if (cornerstoneState.imageIds.length > 0) {
			cornerstone.loadAndCacheImage(cornerstoneState.imageIds[index]).then((image: any) => {
				// Get the state of the stack tool 
				cornerstoneTools.removeToolState(heroDicomViewer, 'stack');
				const stackState = cornerstoneTools.getToolState(heroDicomViewer, 'stack');
				// Update stack state to reflect selected thumbnail's stack 

				stackState.data[0] = cornerstoneState.heroStack[index];
				stackState.data[0].currentImageIdIndex = 0;

				cornerstoneTools.stackPrefetch.enable(heroDicomViewer);

				// Display the image 
				cornerstone.displayImage(heroDicomViewer, image);
				cornerstoneTools.playClip(heroDicomViewer, cornerstoneState.heroStack[index].frameRate);

				runInAction(() => {
					cornerstoneState.isPlaying = true;
				});
			});

			if (viewerState === 'grid') {
				updateGrid(index);
			}
		}
	};

	const toggleShortcutsModal = (): void => {
		store.setShortcutModal(!store.shortcutModal);

		if (store.shortcutModal) {
			store.modal.show('Keyboard Shortcuts', (
				<div>
					<h4>Keyboard Shortcuts</h4>
					<ul>
						<li>Ctrl + Space - Toggles Play/Pause</li>
						<li>Alt + G - Toggles Grid/Standard View</li>
						<li>Alt + H - Toggles Fullscreen View</li>
						<li>Alt + R - Toggles Report Panel</li>

						<li>End key - Next Frame</li>
						<li>Home key - Previous Frame</li>
						<li>PgUp - Previous Image</li>
						<li>PgDn - Next Image</li>
					</ul>
				</div>
			));
		} else {
			store.modal.hide();
		}
	};

	const updateStress = (index: number) => {
		dicomViewerBaseline = document.getElementById('dicomViewerBaseline');
		dicomViewerPeak = document.getElementById('dicomViewerPeak');
		dicomViewerPost = document.getElementById('dicomViewerPost');
		dicomViewerRecovery = document.getElementById('dicomViewerRecovery');

		// Stop clip from playing on viewer 
		cornerstoneTools.stopClip(dicomViewerBaseline);
		cornerstoneTools.stopClip(dicomViewerPeak);
		cornerstoneTools.stopClip(dicomViewerPost);
		cornerstoneTools.stopClip(dicomViewerRecovery);

		runInAction(() => {
			cornerstoneState.isPlaying = false;
		});

		const promises = [];

		// Have Cornerstone load the Baseline stage images for the selected view 
		if (cornerstoneState.baseStacksList[index]) {
			promises.push(cornerstone.loadAndCacheImage(cornerstoneState.baseStacksList[index].imageIds[0]).then((image: any) => {
				// Free memory for loaded DICOM file 
				cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.unload(cornerstoneState.baseStacksList[index]);

				// Display the rendered image 
				cornerstone.displayImage(dicomViewerBaseline, image);

				// Register custom metaDataProvider with CornerstoneJS 
				cornerstone.metaData.addProvider(metaDataProviderESE, 1);

				// Set the stack as tool state 
				cornerstoneTools.addStackStateManager(dicomViewerBaseline, ['stack', 'playClip']);
				cornerstoneTools.addToolState(dicomViewerBaseline, 'stack', cornerstoneState.baseStacksList[index]);
			}));
		}

		// Have Cornerstone load the Peak stage images for the selected view 
		if (cornerstoneState.peakStacksList[index]) {
			promises.push(cornerstone.loadAndCacheImage(cornerstoneState.peakStacksList[index].imageIds[0]).then((image: any) => {
				// Free memory for loaded DICOM file 
				cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.unload(cornerstoneState.peakStacksList[index]);

				// Display the rendered image 
				cornerstone.displayImage(dicomViewerPeak, image);

				// Set the stack as tool state 
				cornerstoneTools.addStackStateManager(dicomViewerPeak, ['stack', 'playClip']);
				cornerstoneTools.addToolState(dicomViewerPeak, 'stack', cornerstoneState.peakStacksList[index]);
			}));
		}

		// Have Cornerstone load the Post Peak stage images for the selected view 
		if (cornerstoneState.postStacksList[index]) {
			promises.push(cornerstone.loadAndCacheImage(cornerstoneState.postStacksList[index].imageIds[0]).then((image: any) => {
				// Free memory for loaded DICOM file 
				cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.unload(cornerstoneState.postStacksList[index]);

				// Display the rendered image 
				cornerstone.displayImage(dicomViewerPost, image);

				// Set the stack as tool state 
				cornerstoneTools.addStackStateManager(dicomViewerPost, ['stack', 'playClip']);
				cornerstoneTools.addToolState(dicomViewerPost, 'stack', cornerstoneState.postStacksList[index]);
			}));
		}

		// Have Cornerstone load the Recovery stage images for the selected view 
		if (cornerstoneState.recoverStacksList[index]) {
			promises.push(cornerstone.loadAndCacheImage(cornerstoneState.recoverStacksList[index].imageIds[0]).then((image: any) => {
				// Free memory for loaded DICOM file 
				cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.unload(cornerstoneState.recoverStacksList[index]);

				// Display the rendered image 
				cornerstone.displayImage(dicomViewerRecovery, image);

				// Set the stack as tool state 
				cornerstoneTools.addStackStateManager(dicomViewerRecovery, ['stack', 'playClip']);
				cornerstoneTools.addToolState(dicomViewerRecovery, 'stack', cornerstoneState.recoverStacksList[index]);
			}));
		}

		// Wait until all four views have finished loading (success promises returned) 
		Promise.all(promises).then(() => {
			onResize('dicomViewerBaseline');
			onResize('dicomViewerPeak');
			onResize('dicomViewerPost');
			onResize('dicomViewerRecovery');

			const stackState1 = cornerstoneTools.getToolState(dicomViewerBaseline, 'stack');
			const stackState2 = cornerstoneTools.getToolState(dicomViewerPeak, 'stack');
			const stackState3 = cornerstoneTools.getToolState(dicomViewerPost, 'stack');
			const stackState4 = cornerstoneTools.getToolState(dicomViewerRecovery, 'stack');

			// Must all be in sync with Rest 
			cornerstoneTools.playClip(dicomViewerBaseline, 1 / (stackState1.data[0].effectiveDuration / stackState1.data[0].imageIds.length));

			if (stackState2) {
				cornerstoneTools.playClip(dicomViewerPeak, 1 / (stackState1.data[0].effectiveDuration / stackState2.data[0].imageIds.length));
			}

			if (stackState3) {
				cornerstoneTools.playClip(dicomViewerPost, 1 / (stackState1.data[0].effectiveDuration / stackState3.data[0].imageIds.length));
			}

			if (stackState4) {
				cornerstoneTools.playClip(dicomViewerRecovery, 1 / (stackState1.data[0].effectiveDuration / stackState4.data[0].imageIds.length));
			}

			scrollToIndex(dicomViewerBaseline, 0);
			scrollToIndex(dicomViewerPeak, 0);
			scrollToIndex(dicomViewerPost, 0);
			scrollToIndex(dicomViewerRecovery, 0);

			runInAction(() => cornerstoneState.isPlaying = true);
		});
	};

	const changeViewerState = (newViewerState: string): void => {
		if (viewerState !== newViewerState) {
			// @ts-ignore
			setViewerState(newViewerState)

			if (newViewerState === 'grid') {
				updateGrid(heroId);
			} else if (newViewerState === 'stress') {
				updateStress(0);
			} else {
				setHeroId(heroId);
			}
		}
	};

	const generateThumbnail = (index: number) => {
		// Get JPEG from server 
		const jpg = thumbnailState.thumbnails[index];

		const thumbnailsViewer = document.getElementById('dicom-thumbnails');
		const thumbnail = document.createElement('img');
		thumbnail.setAttribute('src', `data:image/png;base64,${jpg}`);
		thumbnail.className = 'cornerstone-canvas';
		if (heroId === index) {
			thumbnail.className += ' active';
		}
		thumbnail.addEventListener('click', () => setHeroId(index));
		thumbnail.addEventListener('touchstart', () => setHeroId(index));

		if (thumbnailsViewer) {
			thumbnailsViewer.appendChild(thumbnail);
		}

		cornerstone.loadAndCacheImage(cornerstoneState.heroStack[index].imageIds[0]);

		if (!cornerstoneState.viewerLoaded) {
			cornerstone.loadAndCacheImage(cornerstoneState.heroStack[0].imageIds[0]).then((image: any) => {
				// Free memory for loaded DICOM file 
				cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.unload(cornerstoneState.heroStack[0]);

				cornerstone.displayImage(heroDicomViewer, image);
				cornerstone.displayImage(gridDicomViewer1, image);
				cornerstone.displayImage(gridDicomViewer2, image);
				cornerstone.displayImage(gridDicomViewer3, image);
				cornerstone.displayImage(gridDicomViewer4, image);

				const {
					LengthTool, WwwcTool, FreehandRoiTool, ZoomTool, PanTool,
				} = cornerstoneTools;

				cornerstoneTools.addTool(LengthTool);
				cornerstoneTools.addTool(FreehandRoiTool);
				cornerstoneTools.addTool(WwwcTool);
				cornerstoneTools.addTool(ZoomTool);
				cornerstoneTools.addTool(PanTool);

				cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 2 });
				cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 4 });

				// Register custom metaDataProvider with CornerstoneJS 
				cornerstone.metaData.addProvider(metaDataProvider, 1);

				// Set the stack as tool state 
				cornerstoneTools.addStackStateManager(heroDicomViewer, ['stack', 'playClip']);
				cornerstoneTools.addToolState(heroDicomViewer, 'stack', cornerstoneState.heroStack[0]);

				cornerstoneTools.playClip(heroDicomViewer, cornerstoneState.heroStack[0].frameRate);
			});

			runInAction(() => {
				cornerstoneState.isPlaying = true;
				cornerstoneState.viewerLoaded = true;
			});
		}
	};

	const loadAndViewImageESE = (selectedView: string, url: string) => {
		// clear out stacks 
		// Load specified DICOM file from Web Server and store in memory cache 
		cornerstoneWADOImageLoader.wadouri.dataSetCacheManager
			.load(url.replace('wadouri:', ''), cornerstoneWADOImageLoader.internal.xhrRequest)
			.then((dataSet: any) => {
				// Dataset is now loaded, get relevant metadata for the DICOM file 
				const numFrames = dataSet.intString('x00280008');
				const frameRate = dataSet.intString('x00082144');
				let deltaX = 0.0;

				// Try and get Sequence of Ultrasound Regions (0018,6011) attribute 
				const ultrasoundRegions = dataSet.elements.x00186011;
				if (ultrasoundRegions !== undefined && ultrasoundRegions.items.length > 0) {
					deltaX = ultrasoundRegions.items[0].dataSet.double('x0018602c') * 10.0;
				}

				// Try to get ESE specific metadata from DICOM file 
				const viewNumber = dataSet.intString('x00082128');
				const stageNumber = dataSet.intString('x00082122');
				const effectiveDuration = dataSet.floatString('x00180072');

				// Ignore the ESLint issue to change this from a double == to triple, it needs to be double 
				/* 
                    This is becuase the viewNumber is of type any, and the selectedView is of type string, 
                    I think that there was just a mismatch in the equality checking. 
                 */
				// eslint-disable-next-line eqeqeq 
				if (viewNumber !== undefined && stageNumber !== undefined && viewNumber == selectedView) {
					// Define base URL in correct format 
					const imageIdRoot = `${SERVER_URL}${url}`;
					// Check which stage this ESE view belongs to 
					if (stageNumber === 1) {
						// Create a stack object for the specified DICOM file 
						const baseStack = {
							stackIndex: cornerstoneState.baseStacksList.length,
							currentImageIdIndex: 0,
							imageIds: Array(),
							frameRate,
							effectiveDuration,
							pixelSpacing: deltaX,
							viewNumber,
							stageNumber,
						};

						// Populate imageIds array with URLs for each frame in DICOM file 
						for (let i = 0; i < numFrames; i++) {
							const imageId = `${imageIdRoot}?frame=${i}`;
							baseStack.imageIds.push(imageId);
						}

						// Add stack to Baseline stack list 
						runInAction(() => {
							cornerstoneState.baseStacksList[0] = baseStack;
						});
					} else if (stageNumber === 2) {
						// Create a stack object for the specified DICOM file 
						const peakStack = {
							stackIndex: cornerstoneState.peakStacksList.length,
							currentImageIdIndex: 0,
							imageIds: Array(),
							frameRate,
							effectiveDuration,
							pixelSpacing: deltaX,
							viewNumber,
							stageNumber,
						};

						// Populate imageIds array with URLs for each frame in DICOM file 
						for (let i = 0; i < numFrames; i++) {
							const imageId = `${imageIdRoot}?frame=${i}`;
							peakStack.imageIds.push(imageId);
						}

						// Add stack to Peak stack list 
						runInAction(() => {
							cornerstoneState.peakStacksList[0] = peakStack;
						});
					} else if (stageNumber === 3) {
						// Create a stack object for the specified DICOM file 
						const postStack = {
							stackIndex: cornerstoneState.postStacksList.length,
							currentImageIdIndex: 0,
							imageIds: Array(),
							frameRate,
							effectiveDuration,
							pixelSpacing: deltaX,
							viewNumber,
							stageNumber,
						};

						// Populate imageIds array with URLs for each frame in DICOM file 
						for (let i = 0; i < numFrames; i++) {
							const imageId = `${imageIdRoot}?frame=${i}`;
							postStack.imageIds.push(imageId);
						}

						// Add stack to Post Peak stack list 
						runInAction(() => {
							cornerstoneState.postStacksList[0] = postStack;
						});
					} else if (stageNumber === 4) {
						// Create a stack object for the specified DICOM file 
						const recoverStack = {
							stackIndex: cornerstoneState.recoverStacksList.length,
							currentImageIdIndex: 0,
							imageIds: Array(),
							frameRate,
							effectiveDuration,
							pixelSpacing: deltaX,
							viewNumber,
							stageNumber,
						};

						// Populate imageIds array with URLs for each frame in DICOM file 
						for (let i = 0; i < numFrames; i++) {
							const imageId = `${imageIdRoot}?frame=${i}`;
							recoverStack.imageIds.push(imageId);
						}

						// Add stack to Recovery stack list 
						runInAction(() => {
							cornerstoneState.recoverStacksList[0] = recoverStack;
						});
					}
				} else {
					// Skip selected DICOM file and free associated memory 
					cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.unload(url);
				}

				runInAction(() => {
					cornerstoneState.processedESEFilesIndex += 1;
				});

				// While there are still more DICOM files to process 
				if (cornerstoneState.processedESEFilesIndex < cornerstoneState.imageIds.length) {
					// Load next DICOM file 
					loadAndViewImageESE(selectedView, cornerstoneState.imageIds[cornerstoneState.processedESEFilesIndex]);
				}
			});
	};

	const loadAndViewImage = (url: string) => {
		// Load specified DICOM file from Web Server and store in memory cache 
		cornerstoneWADOImageLoader.wadouri.dataSetCacheManager
			.load(url.replace('wadouri:', ''), cornerstoneWADOImageLoader.internal.xhrRequest).then((dataSet: any) => {
			// Dataset is now loaded, get relevant metadata for the DICOM file 
				const numFrames = dataSet.intString('x00280008');
				const frameRate = dataSet.intString('x00082144');
				let deltaX = 0.0;

				// Try and get Sequence of Ultrasound Regions (0018,6011) attribute 
				const ultrasoundRegions = dataSet.elements.x00186011;
				if (ultrasoundRegions !== undefined && ultrasoundRegions.items.length > 0) {
					deltaX = ultrasoundRegions.items[0].dataSet.double('x0018602c') * 10.0;
				}

				// Create a stack object for the specified DICOM file 
				const stack = {
					stackIndex: cornerstoneState.processedFilesIndex,
					currentImageIdIndex: 0,
					imageIds: Array(),
					frameRate,
					pixelSpacing: deltaX,
				};

				// Define base URL in correct format 
				const imageIdRoot = `${SERVER_URL}${url}`;

				// Determine if the DICOM file contains multiple frames or is a still image 
				if (numFrames !== undefined) {
				// Populate imageIds array with URLs for each frame in DICOM file 
					for (let i = 0; i < numFrames; i++) {
						const imageId = `${imageIdRoot}?frame=${i}`;
						stack.imageIds.push(imageId);
					}
				} else {
				// Add the single image URL 
					runInAction(() => {
						stack.imageIds.push(imageIdRoot);
					});
				}

				// Add the stack of the global stacks list 
				runInAction(() => {
					cornerstoneState.heroStack.push(stack);
				});

				// Generate thumbnail for recently processed DICOM file 
				generateThumbnail(cornerstoneState.processedFilesIndex);

				// Increment processed files index 
				runInAction(() => {
					cornerstoneState.processedFilesIndex += 1;
				});

				// While there are still more DICOM files to process 
				if (cornerstoneState.processedFilesIndex < cornerstoneState.imageIds.length) {
				// Load next DICOM file 
					loadAndViewImage(cornerstoneState.imageIds[cornerstoneState.processedFilesIndex]);
				}
			});
	};

	const downloadAndViewESE = (viewNumber: string) => {
		if (cornerstoneState.imageIds.length) {
			loadAndViewImageESE(viewNumber, cornerstoneState.imageIds[cornerstoneState.processedESEFilesIndex]);
		}
	};

	const downloadAndView = () => {
		if (cornerstoneState.imageIds.length) {
			// Loop through each DICOM file for this study (start with first file initially) 
			loadAndViewImage(cornerstoneState.imageIds[cornerstoneState.processedFilesIndex]);
		}
	};

	const getAllDicoms = (): void => {
		// Get all the associated DICOMs 
		const fetchDicomPromise = [];

		// Get all available 
		fetchDicomPromise.push(
			axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/StudyDicomFileByStudy/${study.id}`)
				.then(res => {
					res.data.forEach((obj: StudyFileEntity) => {
						runInAction(() => {
							cornerstoneState.imageIds.push(`wadouri:${SERVER_URL}/api/files/${obj.studyFileId}`);
							thumbnailState.thumbnails.push(obj.thumbnail);
						});
					});
				}).finally(() => {
					runInAction(() => {
						cornerstoneState.isLoading = false;
					});
				}).catch(err => console.error(err)),
		);

		Promise.all(fetchDicomPromise).then(() => {
			// Start downloading and viewing study images 
			downloadAndView();
			// Get all ESE 
			downloadAndViewESE(cornerstoneState.studyViewNumber);
		});
		
	};

	const setFocusedFrame = (step: number) => {
		if (viewerState === 'grid') {
			gridDicomViewer1 = document.getElementById('DICOMImageViewerGrid1');
			gridDicomViewer2 = document.getElementById('DICOMImageViewerGrid2');
			gridDicomViewer3 = document.getElementById('DICOMImageViewerGrid3');
			gridDicomViewer4 = document.getElementById('DICOMImageViewerGrid4');

			const grid1stackData = cornerstoneTools.getToolState(gridDicomViewer1, 'stack');
			const grid2stackData = cornerstoneTools.getToolState(gridDicomViewer2, 'stack');
			const grid3stackData = cornerstoneTools.getToolState(gridDicomViewer3, 'stack');
			const grid4stackData = cornerstoneTools.getToolState(gridDicomViewer4, 'stack');

			let grid1FrameIndex = grid1stackData.data[0].currentImageIdIndex + step;
			let grid2FrameIndex = grid2stackData.data[0].currentImageIdIndex + step;
			let grid3FrameIndex = grid3stackData.data[0].currentImageIdIndex + step;
			let grid4FrameIndex = grid4stackData.data[0].currentImageIdIndex + step;

			if (grid1FrameIndex >= grid1stackData.data[0].imageIds.length) {
				// Reset frame to beginning 
				grid1FrameIndex = 0;
			}

			if (grid2FrameIndex >= grid2stackData.data[0].imageIds.length) {
				// Reset frame to beginning 
				grid2FrameIndex = 0;
			}

			if (grid3FrameIndex >= grid3stackData.data[0].imageIds.length) {
				// Reset frame to beginning 
				grid3FrameIndex = 0;
			}

			if (grid4FrameIndex >= grid4stackData.data[0].imageIds.length) {
				// Reset frame to beginning 
				grid4FrameIndex = 0;
			}

			scrollToIndex(gridDicomViewer1, grid1FrameIndex);
			scrollToIndex(gridDicomViewer2, grid2FrameIndex);
			scrollToIndex(gridDicomViewer3, grid3FrameIndex);
			scrollToIndex(gridDicomViewer4, grid4FrameIndex);
		} else if (viewerState === 'stress') {
			dicomViewerBaseline = document.getElementById('dicomViewerBaseline');
			dicomViewerPeak = document.getElementById('dicomViewerPeak');
			dicomViewerPost = document.getElementById('dicomViewerPost');
			dicomViewerRecovery = document.getElementById('dicomViewerRecovery');

			const dicomViewerBaselineStackData = cornerstoneTools.getToolState(dicomViewerBaseline, 'stack');
			const dicomViewerPeakStackData = cornerstoneTools.getToolState(dicomViewerPeak, 'stack');
			const dicomViewerPostStackData = cornerstoneTools.getToolState(dicomViewerPost, 'stack');
			const dicomViewerRecoveryStackData = cornerstoneTools.getToolState(dicomViewerRecovery, 'stack');

			let baseFrameIndex = dicomViewerBaselineStackData !== undefined ? dicomViewerBaselineStackData.data[0].currentImageIdIndex + step : null;
			let peakFrameIndex = dicomViewerPeakStackData !== undefined ? dicomViewerPeakStackData.data[0].currentImageIdIndex + step : null;
			let postFrameIndex = dicomViewerPostStackData !== undefined ? dicomViewerPostStackData.data[0].currentImageIdIndex + step : null;
			let recoveryFrameIndex = dicomViewerRecoveryStackData !== undefined ? dicomViewerRecoveryStackData.data[0].currentImageIdIndex + step : null;

			if (baseFrameIndex && baseFrameIndex >= dicomViewerBaselineStackData.data[0].imageIds.length) {
				// Reset frame to beginning
				baseFrameIndex = 0;
			}

			if (peakFrameIndex && peakFrameIndex >= dicomViewerPeakStackData.data[0].imageIds.length) {
				// Reset frame to beginning
				peakFrameIndex = 0;
			}

			if (postFrameIndex && postFrameIndex >= dicomViewerPostStackData.data[0].imageIds.length) {
				// Reset frame to beginning
				postFrameIndex = 0;
			}

			if (recoveryFrameIndex && recoveryFrameIndex >= dicomViewerRecoveryStackData.data[0].imageIds.length) {
				// Reset frame to beginning
				recoveryFrameIndex = 0;
			}

			scrollToIndex(dicomViewerBaseline, baseFrameIndex);
			scrollToIndex(dicomViewerPeak, peakFrameIndex);
			scrollToIndex(dicomViewerPost, postFrameIndex);
			scrollToIndex(dicomViewerRecovery, recoveryFrameIndex);
		} else {
			heroDicomViewer = document.getElementById('DICOMImageViewerStd');

			// Get index of current DICOM frame from Stack Tool state 
			const stackData = cornerstoneTools.getToolState(heroDicomViewer, 'stack');

			// Increment current index by step value (may be a negative value) 
			let currentFrameIndex = stackData.data[0].currentImageIdIndex + step;

			// Check we haven't gone beyond the bounds of imageIds array 
			if (currentFrameIndex >= stackData.data[0].imageIds.length) {
				// Reset frame to beginning 
				currentFrameIndex = 0;
			}

			// Manually scroll to this frame in DICOM Viewer 
			scrollToIndex(heroDicomViewer, currentFrameIndex);
		}
	};

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.code === 'KeyG' && event.altKey) {
			if (viewerState !== 'grid') {
				changeViewerState('grid');
			} else {
				changeViewerState('std');
			}
		}

		if (event.code === 'KeyH' && event.altKey) {
			if (viewerState !== 'full') {
				changeViewerState('full');
			} else {
				changeViewerState('std');
			}
		}

		if (event.code === 'Space' && event.ctrlKey) {
			event.stopPropagation();
			event.preventDefault();
			pausePlayButton();
		}

		if (event.code === 'Home') {
			event.preventDefault();
			setFocusedFrame(-1);
		}

		if (event.code === 'End') {
			event.preventDefault();
			setFocusedFrame(1);
		}

		if (event.code === 'PageUp') {
			event.preventDefault();

			if (viewerStateRef.current === 'stress') {
				changeStressView(-1);
			} else if (viewerStateRef.current === 'grid') {
				setHeroId(prevHeroId => {
					if (prevHeroId >= 4) {
						return prevHeroId - 4;
					} else {
						return cornerstoneState.imageIds.length - 4;
					}
				});
			} else {
				setHeroId(prevHeroId => {
					if (prevHeroId > 0) {
						return prevHeroId - 1;
					} else {
						return cornerstoneState.imageIds.length - 1;
					}
				});
			}
		}

		if (event.code === 'PageDown') {
			event.preventDefault();

			if (viewerStateRef.current === 'stress') {
				changeStressView(1);
			} else if (viewerStateRef.current === 'grid') {
				setHeroId(prevHeroId => {
					if (prevHeroId <= cornerstoneState.imageIds.length - 5) {
						return prevHeroId + 4;
					} else {
						return 0;
					}
				});
			} else {
				setHeroId(prevHeroId => {
					if (prevHeroId < cornerstoneState.imageIds.length - 1) {
						return prevHeroId + 1;
					} else {
						return 0;
					}
				});
			}
		}

	};
	

	useEffect(() => {
		updateHero(heroId)
	}, [heroId]);

	
	useEffect(() => {
		startUpViewer();

		document.addEventListener('keydown', event => handleKeyDown(event));

		getAllDicoms();

		return (): void => {
			runInAction(() => {
				cornerstoneState.imageIds = [];
			});

			cornerstone.imageCache.purgeCache();
			document.removeEventListener('keydown', event => handleKeyDown(event));
		};
	}, []);

	const isInitialRender = useRef(true);
	
	useEffect(() => {
		if (isInitialRender.current) {
			isInitialRender.current = false;
			return;
		}
		
		if (!isImageViewerFullScreen) {
			const prev = viewerState;
			if (viewerState === 'std' || viewerState === 'full') {
				// @ts-ignore
				setViewerState('grid');
			}
			
			setTimeout(() => {
				// @ts-ignore
				setViewerState(prev);
			},500)
		}
	}, [isImageViewerFullScreen])

	const prevViewerStateView = useRef<string | null>(null);

	useEffect(() => {
		if (prevViewerStateView.current === 'full' && viewerState === 'std') {
			// Perform your action here
			// @ts-ignore
			setViewerState('grid')

			setTimeout(() => {
				setViewerState('std');
			},300)
		}

		// Update the previous value of viewerState
		prevViewerStateView.current = viewerState;
	}, [viewerState]);
	
	return (
		<div className={classNames('echo-study-viewer', store.hasBackendAccess && 'top-bar')}>
			<div className="viewports-panel">
				<div className="image-controls-overlay">
					<ButtonGroup>
						<Button
							className={classNames(viewerState === 'std' && 'active')}
							display={Display.Solid}
							colors={Colors.Success}
							icon={{ icon: 'cube' }}
							onClick={() => changeViewerState('std')}
						/>
						<Button
							className={classNames(viewerState === 'grid' && 'active')}
							display={Display.Solid}
							colors={Colors.Success}
							icon={{ icon: 'grid' }}
							onClick={() => changeViewerState('grid')}
						/>
						<Button
							className={classNames(viewerState === 'full' && 'active')}
							display={Display.Solid}
							colors={Colors.Success}
							icon={{ icon: 'expand-2' }}
							onClick={() => changeViewerState('full')}
						/>
					</ButtonGroup>
				</div>

				<div
					className="view-thumbnails"
					id="dicom-thumbnails"
					style={viewerState === 'full' || viewerState === 'stress' ? { display: 'none' } : {}}
				/>
				
				<div className={classNames('hero-view', cornerstoneState.fullScreen && 'full-screen')}>
					<div className="hero-viewport-wrapper" id="important">
						<div className="hero-viewport-info">
							<Button
								display={Display.Text}
								icon={{ icon: 'chevron-left', iconPos: 'icon-left' }}
								className="back-button"
								onClick={() => navigate(-1)}
							>
								Close Study
							</Button>
							<div className="study-info"> 
								<span> 
									Name:
									{' '}
									{study.patient.name} 
								</span>
								<span> 
									D.O.B:
									{' '}
									{moment(study.patient.dob).format('DD/MM/YYYY')} 
								</span>
								<span> 
									Exam Date:
									{' '}
									{moment(study.studyDate).format('DD/MM/YYYY')} 
								</span>
							</div>
						</div>

						<div
							className="hero-viewport-grid"
							style={viewerState !== 'grid' ? { display: 'none' } : {}}
						>
							<ReactResizeDetector
								handleWidth
								handleHeight
								skipOnMount
								refreshMode="debounce"
								refreshRate={200}
								onResize={() => onResize('DICOMImageViewerGrid1')}
							>
								<div id="DICOMImageViewerGrid1" className="viewport-wrapper" />
							</ReactResizeDetector>
							<ReactResizeDetector
								handleWidth
								handleHeight
								skipOnMount
								refreshMode="debounce"
								refreshRate={200}
								onResize={() => onResize('DICOMImageViewerGrid2')}
							>
								<div id="DICOMImageViewerGrid2" className="viewport-wrapper" />
							</ReactResizeDetector>
							<ReactResizeDetector
								handleWidth
								handleHeight
								skipOnMount
								refreshMode="debounce"
								refreshRate={200}
								onResize={() => onResize('DICOMImageViewerGrid3')}
							>
								<div id="DICOMImageViewerGrid3" className="viewport-wrapper" />
							</ReactResizeDetector>
							<ReactResizeDetector
								handleWidth
								handleHeight
								skipOnMount
								refreshMode="debounce"
								refreshRate={200}
								onResize={() => onResize('DICOMImageViewerGrid4')}
							>
								<div id="DICOMImageViewerGrid4" className="viewport-wrapper" />
							</ReactResizeDetector>
						</div>

						<div
							className="hero-viewport-stress"
							style={viewerState !== 'stress' ? { display: 'none' } : {}}
						>
							<ReactResizeDetector
								handleWidth
								handleHeight
								skipOnMount
								refreshMode="debounce"
								refreshRate={200}
								onResize={() => onResize('dicomViewerBaseline')}
							>
								<div id="dicomViewerBaseline" className="viewport-wrapper" />
							</ReactResizeDetector>
							<ReactResizeDetector
								handleWidth
								handleHeight
								skipOnMount
								refreshMode="debounce"
								refreshRate={200}
								onResize={() => onResize('dicomViewerPeak')}
							>
								<div id="dicomViewerPeak" className="viewport-wrapper" />
							</ReactResizeDetector>
							<ReactResizeDetector
								handleWidth
								handleHeight
								skipOnMount
								refreshMode="debounce"
								refreshRate={200}
								onResize={() => onResize('dicomViewerPost')}
							>
								<div id="dicomViewerPost" className="viewport-wrapper" />
							</ReactResizeDetector>
							<ReactResizeDetector
								handleWidth
								handleHeight
								skipOnMount
								refreshMode="debounce"
								refreshRate={200}
								onResize={() => onResize('dicomViewerRecovery')}
							>
								<div id="dicomViewerRecovery" className="viewport-wrapper" />
							</ReactResizeDetector>
						</div>

						<ReactResizeDetector
							handleWidth
							handleHeight
							skipOnMount
							refreshMode="debounce"
							refreshRate={200}
							onResize={() => onResize('DICOMImageViewerStd')}
						>
							<div className="hero-viewport-std" style={(viewerState === 'grid' || viewerState === 'stress') ? { display: 'none' } : {}}>
								<div
									className="viewport-wrapper"
									id="DICOMImageViewerStd"
								/>
							</div>
						</ReactResizeDetector>

						<div className="function-controls-overlay">
							<ButtonGroup className="tool-menu">
								<Button
									className={classNames('function')}
									display={Display.Solid}
									colors={Colors.Secondary}
									sizes={Sizes.Small}
									onClick={() => pausePlayButton()}
								>
									{cornerstoneState.isPlaying ? 'Pause' : 'Play'}
								</Button>

								<Button
									className={classNames('function')}
									display={Display.Solid}
									colors={Colors.Secondary}
									sizes={Sizes.Small}
									onClick={() => slowHeroSpeed()}
								>
									Slower
								</Button>

								<Button
									className={classNames('function')}
									display={Display.Solid}
									colors={Colors.Secondary}
									sizes={Sizes.Small}
									onClick={() => resetHeroSpeed()}
								>
									Reset
								</Button>

								<Button
									className={classNames('function')}
									display={Display.Solid}
									colors={Colors.Secondary}
									sizes={Sizes.Small}
									onClick={() => quickenHeroSpeed()}
								>
									Faster
								</Button>
								<Button
									className={classNames(cornerstoneState.activeTool === 'Wwwc' ? 'function active' : 'function')}
									display={Display.Solid}
									colors={Colors.Secondary}
									sizes={Sizes.Small}
									onClick={() => {
										runInAction(() => cornerstoneState.activeTool = 'Wwwc');
										cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: 1 });
									}}
								>
									Contrast
								</Button>
								<Button
									className={classNames(cornerstoneState.activeTool === 'Length' ? 'function active' : 'function')}
									display={Display.Solid}
									colors={Colors.Secondary}
									sizes={Sizes.Small}
									onClick={() => {
										runInAction(() => cornerstoneState.activeTool = 'Length');
										cornerstoneTools.setToolActive('Length', { mouseButtonMask: 1 });
									}}
								>
									Measure
								</Button>
								<Button
									className={classNames(cornerstoneState.activeTool === 'FreehandRoi' ? 'function active' : 'function')}
									display={Display.Solid}
									colors={Colors.Secondary}
									sizes={Sizes.Small}
									onClick={() => {
										runInAction(() => cornerstoneState.activeTool = 'FreehandRoi');
										cornerstoneTools.setToolActive('FreehandRoi', { mouseButtonMask: 1 });
									}}
								>
									Area
								</Button>
								<Button
									className={classNames('function')}
									display={Display.Solid}
									colors={Colors.Secondary}
									sizes={Sizes.Small}
									onClick={() => toggleShortcutsModal()}
								>
									Shortcuts
								</Button>
								{study.studyType === 'STRESS_ECHO' && (
									<Button
										className={classNames(viewerState === 'stress' ? 'function active' : 'function')}
										display={Display.Solid}
										colors={Colors.Secondary}
										sizes={Sizes.Small}
										onClick={() => {
											changeViewerState('stress');
										}}
									>
										Stress Echo
									</Button>
								)}
								{viewerState === 'stress' && (
									<>
										<Button
											className={classNames('function previous')}
											display={Display.Solid}
											colors={Colors.Secondary}
											sizes={Sizes.Small}
											onClick={() => changeStressView(-1)}
										>
											Previous
										</Button>
										<Button
											className={classNames('function next')}
											display={Display.Solid}
											colors={Colors.Secondary}
											sizes={Sizes.Small}
											onClick={() => changeStressView(1)}
										>
											Next
										</Button>
									</>
								)}
							</ButtonGroup>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ImageViewer;
