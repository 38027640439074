import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsSuperAdminEntity } from 'Models/Security/Acl/VisitorsSuperAdminEntity';
import { SuperAdminSuperAdminEntity } from 'Models/Security/Acl/SuperAdminSuperAdminEntity';
import { UserSuperAdminEntity } from 'Models/Security/Acl/UserSuperAdminEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { CRUD } from '../CRUDOptions';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';

export interface ISuperAdminEntityAttributes extends IModelAttributes {
	email: string;
	auditss: Array<Models.AuditEntity | Models.IAuditEntityAttributes>;
}

@entity('SuperAdminEntity', 'Super Admin')
export default class SuperAdminEntity extends Model implements ISuperAdminEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsSuperAdminEntity(),
		new SuperAdminSuperAdminEntity(),
		new UserSuperAdminEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		'email',
	];
	

	@Validators.Email()
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Email',
		displayType: 'displayfield',
		order: 10,
		createFieldType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public email: string;

	@Validators.Length(6)
	@observable
	@CRUD({
		name: 'Password',
		displayType: 'hidden',
		order: 20,
		createFieldType: 'password',
	})
	public password: string;

	@Validators.Custom('Password Match', (e: string, target: SuperAdminEntity) => new Promise(resolve => resolve(target.password !== e ? 'Password fields do not match' : null)))
	@observable
	@CRUD({
		name: 'Confirm Password',
		displayType: 'hidden',
		order: 30,
		createFieldType: 'password',
	})
	public _confirmPassword: string;

	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Auditss',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.AuditEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'auditss',
			oppositeEntity: () => Models.AuditEntity,
		}),
	})
	public auditss: Models.AuditEntity[] = [];

	constructor(attributes?: Partial<ISuperAdminEntityAttributes>) {
		super(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ISuperAdminEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.auditss !== undefined && Array.isArray(attributes.auditss)) {
				for (const model of attributes.auditss) {
					if (model instanceof Models.AuditEntity) {
						this.auditss.push(model);
					} else {
						this.auditss.push(new Models.AuditEntity(model));
					}
				}
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		auditss {
			${Models.AuditEntity.getAttributes().join('\n')}
		}
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			auditss: {},
		};

		if (formMode === 'create') {
			relationPath['password'] = {};

			if (this.password !== this._confirmPassword) {
				throw Error('Password fields do not match');
			}
		}
		return this.save(
			relationPath,
			{
				graphQlInputType: formMode === 'create'
					? `[${this.getModelName()}CreateInput]`
					: `[${this.getModelName()}Input]`,
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'auditss',
						],
					},
				],
			},
		);
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		return this.email;
	}
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(SuperAdminEntity.prototype, 'created');
CRUD(modifiedAttr)(SuperAdminEntity.prototype, 'modified');
