

export const APPLICATION_ID = "bf7febae-b1ba-11ea-af70-6d6f75736579";

export const validator: unique symbol = Symbol('Validators');
export const validatorMap: unique symbol = Symbol('Validator Map');
export const attributes: unique symbol = Symbol('Attributes');
export const references: unique symbol = Symbol('References');
export const crudOptions: unique symbol = Symbol('CRUDOptions');
export const modelName: unique symbol = Symbol('Model Name');
export const displayName: unique symbol = Symbol('Display Name');
export const crudId: unique symbol = Symbol('Crud Unique Id');
export const fileAttributes: unique symbol = Symbol('File Attributes');



export const Symbols = {
	'validator': validator,
	'validatorMap': validatorMap,
	'attributes': attributes,
	'references': references,
	'crudOptions': crudOptions,
	'modelName': modelName,
	'displayName': displayName,
	'crudId': crudId,
	'fileAttributes': fileAttributes,


};
