import * as React from 'react';
import { useNavigate } from "react-router";
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { store } from '../../Models/Store';
import SessionsUpcomingTile from 'Views/Tiles/Sessions/SessionsUpcomingTile';
import SessionsCompletedTile from 'Views/Tiles/Sessions/SessionsCompletedTile';
import {Tab, TabList, TabPanel, TabPanels, Tabs} from '@chakra-ui/react';

const SessionPagePage = () => {
	const navigate = useNavigate();
	
	if(!store.hasSessions) {
		navigate('/profile')
	}
	
	return (
		<SecuredPage>
			<Navigation
				linkGroups={getFrontendNavLinks()}
				orientation={Orientation.VERTICAL}
			/>
			<div className="body-content">
				<div className="sessions-container">
					<Tabs isFitted variant='enclosed'>
						<TabList mb='1em'>
							<Tab>Upcoming Sessions</Tab>
							<Tab>Completed Sessions</Tab>
						</TabList>
						
						<TabPanels>
							<TabPanel key='upcoming-tile'>
								<SessionsUpcomingTile />
							</TabPanel>
							
							<TabPanel key='completed-tile'>
								<SessionsCompletedTile />,
							</TabPanel>
						</TabPanels>
						
					</Tabs>
				</div>
			</div>
		</SecuredPage>
	);
}

export default SessionPagePage;

