import Axios from 'axios';
import { SERVER_URL } from 'Constants';
import { SiteEntity } from 'Models/Entities';
import {
	Checkbox,
	Flex,
	Input,
	FormControl,
	FormLabel,
	Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, ModalCloseButton,
	Button,
	Heading,
	useToast
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { getEmailPattern } from 'Validators/Functions/Email';
import * as uuid from 'uuid';

interface newSiteModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
}

type FormValues = {
	siteName: string;
	siteContact: string;
	contactNumber: string;
	siteLocation: string;
	enableHL7Messaging: boolean;
	primaryContactEmail: string;
};


const newSiteModal = (props: newSiteModalProps) => {
	const { isOpen, onClose } = props;
	const toast = useToast();

	const{	register,
		formState: { errors },
		handleSubmit,
	} = useForm<FormValues>();

	const onSubmit = (formData: FormValues) => {
        
		Axios.get(`${SERVER_URL}/api/entity/SiteEntity/ValidateSiteName/${formData.siteName}`).then(res => {
			if (res.data.id) {
				toast({
					title: 'Site name already exists',
					position: 'bottom-right',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			} else {
				const siteEntity = new SiteEntity({
					siteName: formData.siteName,
					siteKey: uuid.v4(),
					primaryContactName: formData.siteContact,
					primaryContactNumber: formData.contactNumber,
					streetAddress: formData.siteLocation,
					hl7MessagingEnabled: formData.enableHL7Messaging,
					accessKey: uuid.v4(),
					primaryContactEmail: formData.primaryContactEmail,
				});

				siteEntity.save({ sites: {} }).then(() => {
					toast({
						title: 'Site Created',
						position: 'bottom-right',
						status: 'success',
						duration: 5000,
						isClosable: true,
					});
					onClose();
				});
			}
		}).catch(e => {
			toast({
				title: 'Site could not be created',
				position: 'bottom-right',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		});
	};

	return (
		<Modal isOpen={isOpen} onClose={()=> onClose()} isCentered>
			<form className="request" onSubmit={handleSubmit(onSubmit)} >

				<ModalOverlay />
				<ModalContent  maxH="100%" maxW="80%" className="newSiteModal" paddingTop="14px">
					<ModalCloseButton />
					<ModalBody  maxHeight="80vh" overflowY="auto" w="100%">
						<Heading as='h4' size='md' noOfLines={1}>New Site</Heading>	
						<Flex direction="column">
							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.siteName}
								margin="8px 8px 8px 0"
							>
								<Input
									type="text"
									size="lg"
									{...register('siteName', {
										required: true,
									})}
								/>
								<FormLabel>Site Name</FormLabel>
							</FormControl>

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.siteContact}
								margin="8px 8px 8px 0"
							>
								<Input
									type="text"
									size="lg"
									{...register('siteContact', {
										required: true,
									})}
								/>
								<FormLabel>Site Contact</FormLabel>
							</FormControl>

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.contactNumber}
								margin="8px 8px 8px 0"
							>
								<Input
									type="text"
									size="lg"
									{...register('contactNumber', {
										required: true,
									})}
								/>
								<FormLabel>Contact Number</FormLabel>
							</FormControl>

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.primaryContactEmail}
								margin="8px 8px 8px 0"
							>
								<Input
									type="text"
									size="lg"
									{...register('primaryContactEmail', {
										required: true,
										pattern: getEmailPattern(),
									})}
								/>
								<FormLabel>Primary Contact Email</FormLabel>
							</FormControl>

								
							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.siteLocation}
								margin="8px 8px 8px 0"
							>
								<Input
									type="text"
									size="lg"
									{...register('siteLocation', {
										required: true,
									})}
								/>
								<FormLabel>Site Location</FormLabel>
							</FormControl>

							<FormControl
								display="flex"
							>
								<Checkbox
									{...register('enableHL7Messaging', {
										required: false,
									})}
									m="0 8px 0 2px"
								/>
								<FormLabel margin="0">Enable HL7 Messaging</FormLabel>
							</FormControl>
						</Flex>	
								
					</ModalBody>

					<ModalFooter display="Flex" justifyContent="space-between">
						<Button variant='outline' onClick={()=> onClose()}>Cancel</Button>
						<Button colorScheme='blue' mr={3} type="submit">Confirm</Button>
		
					</ModalFooter>
				</ModalContent>
			</form>

		</Modal>
	);
};

export default newSiteModal;