import React, {useEffect} from 'react';
import { TextArea } from 'Views/Components/TextArea/TextArea';
import { observer, useLocalStore, useObserver } from 'mobx-react';
import {runInAction, toJS} from 'mobx';
import { store } from 'Models/Store';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { SiteEntity, StudyEntity } from 'Models/Entities';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import alert from 'Util/ToastifyUtils';

interface TemplatePreviewModalProps {
	files: any;
	pages: any;
	reportReference: string;
	study: StudyEntity;
}


const TemplatePreviewModal = observer((props: TemplatePreviewModalProps) => {
	const {
		files, reportReference, study, 
	} = props;

	const previewModalStore = useLocalStore(() => ({
		reportTemplates: new Array(),
		selectedReportTemplate: 'Default',
	}));

	const updatedFiles = useLocalStore(() => ({
		files,
	}));
	
	useEffect(() => {
		getReports();
		getReportTemplates();
		
		runInAction(() => {
			previewModalStore.selectedReportTemplate = study.getSelectedReportTemplate();
		})
	}, [])

	const getReportTemplates = (): any => {
		store.apolloClient.query({
			query: SiteEntity.fetchSingleSite(),
			fetchPolicy: 'network-only',
			variables: {
				args: [{
					path: 'id',
					comparison: 'equal',
					value: study.patient.siteId,
				}],
			},
		}).then(res => {
			runInAction(() => {
				if (previewModalStore.reportTemplates.length === 0) {
					previewModalStore.reportTemplates.push({ display: 'Default', value: 'Default' });
					const site = new SiteEntity({ ...res.data.siteEntity });
					Object.keys(site.reportTemplates).forEach((template: string) => {
						if (!previewModalStore.reportTemplates.includes(site.reportTemplates[template].templateName)) {
							previewModalStore.reportTemplates.push({
								display: site.reportTemplates[template].templateName,
								value: site.reportTemplates[template].templateName,
							});
						}
					});
					if (previewModalStore.reportTemplates.length > (site.reportTemplates.length + 1)) {
						previewModalStore.reportTemplates.pop();
					}
				}
			});
		}).catch(error => {
			console.error(error);
		});
	};

	const getReports = async (): Promise<boolean> => {
		return axios.get(`${SERVER_URL}/api/entity/StudyEntity/EchoReportTXTFromTemplate/${study.id}/${previewModalStore.selectedReportTemplate}`)
			.then(async res => runInAction(() => {
				updatedFiles.files.echoReportTXT = res.data;
			}))
			.then(async () => {
				return axios.get(`${SERVER_URL}/api/entity/StudyEntity/EchoReportPDFFromTemplate/${study.id}/${previewModalStore.selectedReportTemplate}`)
					.then(async res => runInAction(() => {
						updatedFiles.files.echoReportPDF = `data:application/pdf;base64,${res.data}`;
						return true;
					}))
					.catch(() => {
						alert('Failed to fetch echo report pdf', 'error');
						return false;
					});
			})
			.catch(() => {
				alert('Failed to fetch echo report txt', 'error');
				return false;
			});
	};

	return useObserver(() => (
		<div className="preview-wrapper">
			<div className="template-options">
				<Combobox
					model={updatedFiles.files}
					label="Preview File Type"
					options={[
						{ display: 'PDF', value: 'PDF' },
						{ display: 'Text', value: 'Text' },
					]}
					modelProperty="previewType"
					onChange={event => runInAction(() => updatedFiles.files.previewType = event.currentTarget.textContent ? event.currentTarget.textContent : '')}
				/>
				<Combobox
					model={previewModalStore.reportTemplates}
					modelProperty="value"
					label="Report Template"
					options={previewModalStore.reportTemplates}
					onChange={(event): void => runInAction(() => {
						previewModalStore.selectedReportTemplate = event.currentTarget.textContent || 'Default';
	
						getReports();
					})}
				/>
			</div>
			
			{updatedFiles.files.previewType === 'PDF' && (
				<div className="file-preview-modal">
					<embed src={updatedFiles.files[`${reportReference}PDF`]} type="application/pdf" width="100%" height="100%" />
				</div>
			)}
			
			{updatedFiles.files.previewType === 'Text' && (
				<div className="report-txt-preview">
					<TextArea
						model={updatedFiles.files}
						modelProperty={`${reportReference}TXT`}
						isDisabled
					/>
				</div>
			)}
		</div>
	));
});

export default TemplatePreviewModal;
