import { useQuery } from '@apollo/client';
import { Box, Button, CircularProgress, Flex } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { StudyEntity } from '../../../Models/Entities';
import DiaryPreview from './HolterComponents/DiaryPreview';
import HolterReportTile from './HolterComponents/HolterReportTile';
import ReferralPreview from './HolterComponents/ReferralPreview';
import TestDataPreview from './HolterComponents/TestDataPreview';
import TwelveLeadPreview from './HolterComponents/TwelveLeadPreview';
interface NewHolterStudyViewerProps {
	studyId: string;
}
const NewHolterStudyViewer = (props: NewHolterStudyViewerProps) => {
	const { studyId } = props;

	const { loading, data, error, refetch } = useQuery(StudyEntity.getHolterStudy(), {
		variables: {
			args: [{
				path: 'id',
				comparison: 'equal',
				value: studyId,
			}]
		},
		fetchPolicy: 'no-cache',
	});
	
	const navigate = useNavigate();

	const [activePanel, setActivePanel] = useState('testData');
	const [isImageViewerFullScreen, setIsImageViewerFullScreen] = useState(false);


	const testDataButtonRef = useRef<HTMLButtonElement>(null);
	const twelveLeadEcgButtonRef = useRef<HTMLButtonElement>(null);
	const referralButtonRef = useRef<HTMLButtonElement>(null);
	const diaryButtonRef = useRef<HTMLButtonElement>(null);

	if (loading) {
		return <CircularProgress isIndeterminate color='green.300'/>
	}

	const { studyEntity: study }: { studyEntity: StudyEntity } = data;
	
	const togglePanels = (newPanel: string) => {
		if (newPanel === activePanel) {
			toggleFullScreen();
		} else {
			setActivePanel(newPanel);
			setIsImageViewerFullScreen(false);
		}
	};

	const toggleFullScreen = () => {
		setIsImageViewerFullScreen(prev => !prev);
	};

	const renderPanel = () => {
		switch (activePanel) {
			case 'testData':
				return <TestDataPreview study={study} height='88vh' />;
			case 'twelveLeadEcg':
				return <TwelveLeadPreview study={study} height='88vh' />;
			case 'referral':
				return <ReferralPreview study={study} height='88vh' />;
			case 'diary':
				return <DiaryPreview study={study} height='88vh' />;
			default:
				return <>NOT REPORT</>;
		}
	};
	
	return (
		<Flex gridColumn="1 / -1" width='100vw' height='100vh' position='relative'>
			<Box flex='1' bg='grey' padding="20px" >
				<Box flex='1' paddingBottom="20px"><Button onClick={() => navigate(-1)}>back</Button> </Box>
				{renderPanel()}
			</Box>
			<Box flex='1' padding="20px" overflowY='auto' backgroundColor="#373151" color="#eeeeee" display={isImageViewerFullScreen ? 'none' : ''}>
				<HolterReportTile study={study} togglePanels={togglePanels} refetch={refetch} />
			</Box>

			<Box
				position="absolute"
				left={isImageViewerFullScreen ? `calc(100% - (${testDataButtonRef?.current?.offsetHeight}px /2))` : '50%'}
				transform="translate(-50%, -50%) rotate(90deg)"
				top="5%"
			>
				<Box
					display="flex"
					alignItems="center"
				>
					<Button
						ref={testDataButtonRef}
						onClick={() => togglePanels('testData')}
						colorScheme={activePanel === 'testData' ? 'plumPurple' : 'trueBlack'}
						marginRight="10px"
						left="50%"
					>
						Test Data
					</Button>
					
					<Button
						ref={twelveLeadEcgButtonRef}
						onClick={() => togglePanels('twelveLeadEcg')}
						colorScheme={activePanel === 'twelveLeadEcg' ? 'plumPurple' : 'trueBlack'}
						marginRight="10px"
						left="50%"
					>
						12 Lead ECG
					</Button>
					
					<Button
						ref={referralButtonRef}
						onClick={() => togglePanels('diary')}
						colorScheme={activePanel === 'diary' ? 'plumPurple' : 'trueBlack'}
						marginRight="10px"
						left="50%"
					>
						Diary
					</Button>
					
					<Button
						ref={diaryButtonRef}
						onClick={() => togglePanels('referral')}
						colorScheme={activePanel === 'referral' ? 'plumPurple' : 'trueBlack'}
						marginRight="10px"
						left="50%"
					>
						Referral
					</Button>
				</Box>
			</Box>
		</Flex>
	)
};

export default NewHolterStudyViewer;
