import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsServiceEntity } from 'Models/Security/Acl/VisitorsServiceEntity';
import { SuperAdminServiceEntity } from 'Models/Security/Acl/SuperAdminServiceEntity';
import { UserServiceEntity } from 'Models/Security/Acl/UserServiceEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import * as Enums from '../Enums';
import { CRUD } from '../CRUDOptions';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';

export interface IServiceEntityAttributes extends IModelAttributes {
	eventType: string;
	studyType: Enums.studyType;
	paediatric: boolean;

	sitess: Array<Models.SitesSiteServices | Models.ISitesSiteServicesAttributes>;
	userss: Array<Models.UsersUserServices | Models.IUsersUserServicesAttributes>;
}

@entity('ServiceEntity', 'Service')
export default class ServiceEntity extends Model implements IServiceEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsServiceEntity(),
		new SuperAdminServiceEntity(),
		new UserServiceEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
	];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Event Type',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public eventType: string;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Study Type',
		displayType: 'enum-combobox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => AttrUtils.standardiseEnum(attr, Enums.studyTypeOptions),
		enumResolveFunction: makeEnumFetchFunction(Enums.studyTypeOptions),
		displayFunction: (attribute: Enums.studyType) => Enums.studyTypeOptions[attribute],
	})
	public studyType: Enums.studyType;

	/**
	 * Can do paediatric studies
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Paediatric',
		displayType: 'checkbox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public paediatric: boolean;

	/**
	 * Site Services
	 */
	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Sites',
		displayType: 'reference-multicombobox',
		order: 40,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.SitesSiteServices,
		optionEqualFunc: makeJoinEqualsFunc('sitesId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'serviceEntity',
			oppositeEntityName: 'siteEntity',
			relationName: 'siteServices',
			relationOppositeName: 'sites',
			entity: () => Models.ServiceEntity,
			joinEntity: () => Models.SitesSiteServices,
			oppositeEntity: () => Models.SiteEntity,
		}),
	})
	public sitess: Models.SitesSiteServices[] = [];

	/**
	 * User Services
	 */
	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Users',
		displayType: 'reference-multicombobox',
		order: 50,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.UsersUserServices,
		optionEqualFunc: makeJoinEqualsFunc('usersId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'serviceEntity',
			oppositeEntityName: 'userEntity',
			relationName: 'userServices',
			relationOppositeName: 'users',
			entity: () => Models.ServiceEntity,
			joinEntity: () => Models.UsersUserServices,
			oppositeEntity: () => Models.UserEntity,
		}),
	})
	public userss: Models.UsersUserServices[] = [];

	constructor(attributes?: Partial<IServiceEntityAttributes>) {
		super(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IServiceEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.eventType !== undefined) {
				this.eventType = attributes.eventType;
			}
			if (attributes.studyType !== undefined) {
				this.studyType = attributes.studyType;
			}
			if (attributes.paediatric !== undefined) {
				this.paediatric = attributes.paediatric;
			}
			if (attributes.sitess !== undefined && Array.isArray(attributes.sitess)) {
				for (const model of attributes.sitess) {
					if (model instanceof Models.SitesSiteServices) {
						this.sitess.push(model);
					} else {
						this.sitess.push(new Models.SitesSiteServices(model));
					}
				}
			}
			if (attributes.userss !== undefined && Array.isArray(attributes.userss)) {
				for (const model of attributes.userss) {
					if (model instanceof Models.UsersUserServices) {
						this.userss.push(model);
					} else {
						this.userss.push(new Models.UsersUserServices(model));
					}
				}
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		sitess {
			${Models.SitesSiteServices.getAttributes().join('\n')}
			sites {
				${Models.SiteEntity.getAttributes().join('\n')}
				${Models.SiteEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		userss {
			${Models.UsersUserServices.getAttributes().join('\n')}
			users {
				${Models.UserEntity.getAttributes().join('\n')}
			}
		}
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			sitess: {},
			userss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'sitess',
							'userss',
						],
					},
				],
			},
		);
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		return this.id;
	}
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ServiceEntity.prototype, 'created');
CRUD(modifiedAttr)(ServiceEntity.prototype, 'modified');
