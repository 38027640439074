import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
	getModelName, getAttributes, 
} from 'Util/EntityUtils';
import { VisitorsPatientEntity } from 'Models/Security/Acl/VisitorsPatientEntity';
import { SuperAdminPatientEntity } from 'Models/Security/Acl/SuperAdminPatientEntity';
import { UserPatientEntity } from 'Models/Security/Acl/UserPatientEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';

import { lowerCaseFirst } from 'Util/StringUtils';
import { gql } from '@apollo/client';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';
import * as Enums from '../Enums';
import { CRUD } from '../CRUDOptions';

export interface IPatientEntityAttributes extends IModelAttributes {
	name: string;
	firstName?: string;
	lastName?: string;
	middleName?: string;
	prefix?: string;
	suffix?: string;
	patientId: string;
	dob: Date;
	gender: Enums.genderType;
	siteId?: string;
	site?: Models.SiteEntity | Models.ISiteEntityAttributes;
	studiess: Array<Models.StudyEntity | Models.IStudyEntityAttributes>;
}

@entity('PatientEntity', 'Patient')
export default class PatientEntity extends Model implements IPatientEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsPatientEntity(),
		new SuperAdminPatientEntity(),
		new UserPatientEntity(),
		new UserPatientEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
	];

	/**
	 * Name
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;

	@attribute()
	@observable
	public firstName: string;
	@attribute()
	@observable
	public lastName: string;
	@attribute()
	@observable
	public middleName: string;
	@attribute()
	@observable
	public prefix: string;
	@attribute()
	@observable
	public suffix: string;
	
	/**
	 * The key used to reference the patient without using their name
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Patient Id',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public patientId: string;

	/**
	 * Date of Birth
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'DOB',
		displayType: 'datepicker',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public dob: Date;

	/**
	 * Gender
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Gender',
		displayType: 'enum-combobox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => AttrUtils.standardiseEnum(attr, Enums.genderTypeOptions),
		enumResolveFunction: makeEnumFetchFunction(Enums.genderTypeOptions),
		displayFunction: (attribute: Enums.genderType) => Enums.genderTypeOptions[attribute],
	})
	public gender: Enums.genderType;

	@observable
	@attribute()
	@CRUD({
		name: 'Site',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.SiteEntity,
	})
	public siteId?: string;

	@observable
	@attribute({ isReference: true })
	public site: Models.SiteEntity;

	@observable
	@attribute({ isReference: true })
	@CRUD({
		name: 'Studiess',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.StudyEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'studiess',
			oppositeEntity: () => Models.StudyEntity,
		}),
	})
	public studiess: Models.StudyEntity[] = [];

	constructor(attributes?: Partial<IPatientEntityAttributes>) {
		super(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IPatientEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.firstName !== undefined) {
				this.firstName = attributes.firstName;
			}
			if (attributes.lastName !== undefined) {
				this.lastName = attributes.lastName;
			}
			if (attributes.middleName !== undefined) {
				this.middleName = attributes.middleName;
			}
			if (attributes.prefix !== undefined) {
				this.prefix = attributes.prefix;
			}
			if (attributes.suffix !== undefined) {
				this.suffix = attributes.suffix;
			}
			if (attributes.patientId !== undefined) {
				this.patientId = attributes.patientId;
			}
			if (attributes.dob !== undefined) {
				if (attributes.dob === null) {
					this.dob = attributes.dob;
				} else {
					this.dob = moment(attributes.dob).toDate();
				}
			}
			if (attributes.gender !== undefined) {
				this.gender = attributes.gender;
			}
			if (attributes.studiess !== undefined && Array.isArray(attributes.studiess)) {
				for (const model of attributes.studiess) {
					if (model instanceof Models.StudyEntity) {
						this.studiess.push(model);
					} else {
						this.studiess.push(new Models.StudyEntity(model));
					}
				}
			}
			if (attributes.site !== undefined) {
				if (attributes.site === null) {
					this.site = attributes.site;
				} else if (attributes.site instanceof Models.SiteEntity) {
					this.site = attributes.site;
					this.siteId = attributes.site.id;
				} else {
					this.site = new Models.SiteEntity(attributes.site);
					this.siteId = this.site.id;
				}
			} else if (attributes.siteId !== undefined) {
				this.siteId = attributes.siteId;
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL qu2eries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		site {
			${Models.SiteEntity.getAttributes().join('\n')}
			${Models.SiteEntity.getFiles().map(f => f.name).join('\n')}
		}
		studiess {
			${Models.StudyEntity.getAttributes().join('\n')}
		}
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			studiess: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'studiess',
						],
					},
				],
			},
		);
	}

	public async savePatient() {
		const relationPath = {
			studiess: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'studiess',
						],
					},
				],
			},
		);
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		return this.name;
	}

	public static fetchSinglePatient() {
		const model = new PatientEntity();
		const modelName = lowerCaseFirst(getModelName(PatientEntity));

		return gql`
			query ${modelName} ($args:[WhereExpressionGraph]) {
				${modelName} (where: $args) {
					${getAttributes(PatientEntity).join('\n')}
					${model.defaultExpands}
				}
			}`;
	}
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(PatientEntity.prototype, 'created');
CRUD(modifiedAttr)(PatientEntity.prototype, 'modified');
