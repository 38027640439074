import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {SessionEntity, StudyFileEntity} from '../../../Models/Entities';
import { store } from '../../../Models/Store';

import {
	AlertDialog, 
	AlertDialogBody, 
	AlertDialogContent, 
	AlertDialogHeader, 
	AlertDialogOverlay,
	AspectRatio,
	Box,
	Button,
	ButtonGroup, 
	Drawer, 
	DrawerBody, 
	DrawerCloseButton, 
	DrawerContent, 
	DrawerHeader, 
	DrawerOverlay,
	Flex,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	useDisclosure,
	Image
} from '@chakra-ui/react';

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import {gql, useQuery} from '@apollo/client';
import EndSessionTile from './EndSessionTile';
import {SERVER_URL} from '../../../Constants';
import axios from 'axios';
// @ts-ignore
import { each } from 'async';

const GET_COMPLETED_SESSIONS = gql`
  query sessionEntity(
    $args: [[WhereExpressionGraph]]
    $skip: Int
    $take: Int
    $orderBy: [OrderByGraph]
    $ids: [ID]
  ) {
    sessionEntitys: sessionEntitysConditional(
      conditions: $args
      skip: $skip
      take: $take
      orderBy: $orderBy
      ids: $ids
    ) {
      id
      userId
      sessionType
      sessionDate
      sessionPeriod
      countEchos
      countStressEchos
      countExerciseStress
      countIpEchos
      countIpStressEchos
      countIpExerciseStress
	  countOpPaediatricEcho
	  countIpPaediatricEcho
	  countOpDobutamineStressEcho
	  countIpDobutamineStressEcho
	  countOp12LeadECG
	  countIp12LeadECG
      imageWorklistId
      allComplete
      siteId
      open
      notes
      supervisorId
      imageWorklistId
      site {
        siteName
      }
    }
    countSessionEntitys: countSessionEntitysConditional(conditions: $args) {
      number
      __typename
    }
  }
`;

const SessionsCompletedTile = observer(() => {
	const {isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal} = useDisclosure()
	const {isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit} = useDisclosure()
	const {isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert} = useDisclosure()

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [studyFiles, setStudyFiles] = useState<any[]>([]);
	const [currentSession, setCurrentSession] = useState<SessionEntity>();

	const cancelRef = React.useRef()

	const onPageChange = (newPage: number) => {
		setCurrentPage(newPage);
	};

	const onItemsPerPageChange = (newItemsPerPage: number) => {
		setItemsPerPage(newItemsPerPage);
		setCurrentPage(1); // reset to first page
	};

	const { loading, error, data, refetch } = useQuery(GET_COMPLETED_SESSIONS, {
		variables: {
			args: [
				[
					{
						comparison: 'equal',
						path: 'userId',
						value: store.userId,
					},
				],
				[
					{
						comparison: 'equal',
						path: 'allComplete',
						value: 'true',
					},
				],
			],
			skip: 0,
			orderBy: [{ path: 'Created', descending: true }],
		},
	});

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error :(</p>;

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const itemsToShow = data.sessionEntitys.slice(startIndex, endIndex);
	
	const canGoBack = currentPage > 1;
	const canGoForward = endIndex < data.sessionEntitys.length;

	const openWorklists = (studyFiles: any[]) => {
		console.log(studyFiles)
		if (studyFiles.length < 1) {
			onOpenAlert();
		} else {
			setStudyFiles(studyFiles);
			onOpenModal();
		}
	}
	const displayWorklists = (session: SessionEntity) => {
		store.apolloClient.query({
			query: StudyFileEntity.getFetchStudyFile(),
			fetchPolicy: 'network-only',
			variables: {
				args: [{
					path: 'sessionId',
					comparison: 'equal',
					value: session.id,
				}],
			},
		}).then(r => {
			const fileDataList: any[] = [];
			each(r.data.studyFileEntitys, function(studyFile: { studyFileId: string; } , callback: () => void){
				axios.get(`${SERVER_URL}/api/files/metadata/${studyFile.studyFileId}`)
					.then(res => {
						//						fileDataList.push(res.data);

						fileDataList.push(
							{
								name : res.data.fileName,
								id: studyFile.studyFileId
							});
						callback();

					});

			}, ()=> {
				openWorklists(fileDataList)
			})

		})
	}
	
	const handleCloseEdit = () => {
		onCloseEdit();
		refetch();
	}
	
	return (
		<>
			<Table>
				<Thead>
					<Tr>
						<Th>Site</Th>
						<Th>Session Date</Th>
						<Th>Session Type</Th>
						<Th>Session Period</Th>
						<Th p='10px 12px'>OP TTX</Th>
						<Th p='10px 12px'>IP TTX</Th>
						<Th p='10px 12px'>OP ESE</Th>
						<Th p='10px 12px'>IP ESE</Th>
						<Th p='10px 12px'>OP EST</Th>
						<Th p='10px 12px'>IP EST</Th>
						<Th p='10px 12px'>OP PAED</Th>
						<Th p='10px 12px'>IP PAED</Th>
						<Th p='10px 12px'>OP DSE</Th>
						<Th p='10px 12px'>IP DSE</Th>
						<Th p='10px 12px'>OP ECG</Th>
						<Th p='10px 12px'>IP ECG</Th>
						<Th>Act10ns</Th>
					</Tr>
				</Thead>
				<Tbody>
					{itemsToShow.map((item: SessionEntity) => (
						<Tr key={item.id} onClick={() => setCurrentSession(item)}>
							<Td>{item.site.siteName}</Td>
							<Td>{new Date(item.sessionDate).toLocaleDateString('en-GB')}</Td>
							<Td>{item.sessionType}</Td>
							<Td>{item.sessionPeriod}</Td>
							<Td p='10px 12px'>{item.countEchos}</Td>
							<Td p='10px 12px'>{item.countIpEchos}</Td>
							<Td p='10px 12px'>{item.countStressEchos}</Td>
							<Td p='10px 12px'>{item.countIpStressEchos}</Td>
							<Td p='10px 12px'>{item.countExerciseStress}</Td>
							<Td p='10px 12px'>{item.countIpExerciseStress}</Td>
							<Td p='10px 12px'>{item.countOpPaediatricEcho}</Td>
							<Td p='10px 12px'>{item.countIpPaediatricEcho}</Td>
							<Td p='10px 12px'>{item.countOpDobutamineStressEcho}</Td>
							<Td p='10px 12px'>{item.countIpDobutamineStressEcho}</Td>
							<Td p='10px 12px'>{item.countOp12LeadECG}</Td>
							<Td p='10px 12px'>{item.countIp12LeadECG}</Td>
							<Td>
								<ButtonGroup>
									<Button onClick={() => displayWorklists(item)}>Worklists</Button>
									<Button onClick={() => onOpenEdit()}>Edit</Button>
								</ButtonGroup>
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>

			<Drawer placement='left' isOpen={isOpenEdit} onClose={handleCloseEdit} size='xl'>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>Edit session</DrawerHeader>

					<DrawerBody pt={10}>
						<EndSessionTile drawerToggle={onCloseEdit} currentSession={currentSession}/>
					</DrawerBody>
				</DrawerContent>
			</Drawer>

			<Flex mt="4"  alignItems="center">
				<IconButton
					aria-label="Previous page"
					icon={<FaChevronLeft />}
					isDisabled={!canGoBack}
					onClick={() => onPageChange(currentPage - 1)}
				/>
				<Button mx="2">{currentPage}</Button>
				<IconButton
					aria-label="Next page"
					icon={<FaChevronRight />}
					isDisabled={!canGoForward}
					onClick={() => onPageChange(currentPage + 1)}
				/>
				<Box alignItems="center" mt="2" ml="10">
					<Select
						value={itemsPerPage}
						onChange={e => onItemsPerPageChange(parseInt(e.target.value, 10))}
					>
						<option value="5">5</option>
						<option value="10">10</option>
						<option value="20">20</option>
					</Select>
				</Box>
			</Flex>

			<Modal onClose={onCloseModal} size='5xl' isOpen={isOpenModal}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Worklists</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{studyFiles.map(sf => (

							<div key={sf.id}>
								{sf.name.toLowerCase().indexOf('.pdf') > -1 ? 
									<AspectRatio maxWidth="1200px" maxHeight="80%" ratio={1}>
										<embed src={`${SERVER_URL}/api/files/${sf.id}#view=Fit&toolbar=1`} type="application/pdf" width="100%" height="100%" />
									</AspectRatio> : '' }
								{['.png','.jpg','.jpeg','.bmp','.gif'].some(substring=>sf?.name?.toLowerCase().includes(substring)) ? <Image src={`${SERVER_URL}/api/files/${sf.id}`} pb="20px" height="auto" /> : '' }
							</div>
						))}
					</ModalBody>
					<ModalFooter>
						<Button onClick={onCloseModal}>Close</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<AlertDialog
				isOpen={isOpenAlert}
				// @ts-ignore 
				leastDestructiveRef={cancelRef}
				onClose={onCloseAlert}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							No Worklist
						</AlertDialogHeader>

						<AlertDialogBody>
							No worklist exists for this session
						</AlertDialogBody>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	)
});

export default SessionsCompletedTile;
