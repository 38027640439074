import {inject, observer} from 'mobx-react';
import React from 'react';
import Tabs from 'Views/Components/Tabs/Tabs';
import {withRouter, WithRouterProps} from "../../Util/withRouter";
import UserAccountRequestCrudTile from './UserSiteAccess/UserAccountRequestCrudTile';
import UserSiteAccessCrudTile from './UserSiteAccess/UserSiteAccessCrudTile';
import {useNavigate} from "react-router";
import {Button, Colors, Display, Sizes} from "../Components/Button/Button";
import {runInAction} from "mobx";
import {store} from "../../Models/Store";
import {SERVER_URL} from "../../Constants";
import UserSiteRequestCrudTile from "./UserSiteAccess/UserSiteRequestCrudTile";
import SiteCrudTile from "./Sites/SiteCrudTile";

const SiteDashboardWrappingTileTile = (props: WithRouterProps) => {
	return (
		<div className="site-dashboard__container">
			<Tabs
				tabs={[
					{
						name: 'Sites',
						component: (<SiteCrudTile/>),
						key: 'sites',
					},
					{
						name: 'Site Requests',
						component: (<UserSiteRequestCrudTile/>),
						key: 'requests',
					},
				]}
				defaultTab={0}
				className={props.location.pathname.includes('create') ? 'hidden' : ''}
			/>
		</div>
	);
};

export default inject('store')(observer(withRouter(SiteDashboardWrappingTileTile)));
