
import * as React from 'react';
import { observer } from 'mobx-react';

import * as Models from 'Models/Entities';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { PageWrapper } from 'Views/Components/PageWrapper/PageWrapper';
import SecuredPage from 'Views/Components/Security/SecuredPage';

@observer
export default class ServiceEntityPage extends React.Component {



	public render() {
		let contents = null;


		contents = (
			<PageWrapper {...this.props}>
				<EntityCRUD
					modelType={Models.ServiceEntity}
					selectableItems
					dateFormat="d/m/Y"
					altFormat="d/m/Y"
					{...this.props}
				/>
			</PageWrapper>
		);


		return (
			<SecuredPage groups={["Super Administrators", "SuperAdmin","User"]}>
				{contents}
			</SecuredPage>
		);
	}



}
