import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Colors } from 'Views/Components/Button/Button';

const PageNotFound = () => {
	const navigate = useNavigate();
	
	return (
		<div className='error-info__page'>
			<div className="error-info__elements">
				<h1 className="error-info__heading">404</h1>
				<p className="error-info__info">Oops! It seems like the page you are looking for does not exist!</p>
				<Button colors={Colors.Error} onClick={() => navigate(-2)}>
					Return to Previous Page
				</Button>
			</div>
		</div>
	);
}
export default PageNotFound;



