import { observer, useLocalStore } from 'mobx-react';
import React from 'react';

import { 
	Button, 
	Colors, 
	Display, 
	Sizes,
} from 'Views/Components/Button/Button';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { store } from 'Models/Store';
import { runInAction } from 'mobx';
import {useNavigate} from "react-router-dom";

interface ISharedStudyBreakGlassProps {
	setAccepted: () => void;
}

const SharedStudyBreakGlass = observer((props: ISharedStudyBreakGlassProps) => {
    const breakGlassState = useLocalStore(() => ({
        accepted: false,
        acceptedError: false,
    }));
    const navigate = useNavigate();

	return (
		<div className="shared-study-breakGlass__modal">
            <p>This feature allows you to view studies for which you were not granted automatic access.</p>
            <p>You only have access to this study, any previous or subsequent studies this patient may have require a separate request.</p>
            <div className="termns-and-conditions-container">
                <p>
                    I understand that I am about to access information for a patient for whom I am not the primary referring doctor/physician. 
                    I acknowledge that I am doing so only for medical reasons and for the sole benefit of the patient, and in doing so am bound 
                    to keep this information private and confidential.
                </p>
                <div className="terms-and-conditions-group">
                    <input className="terms-and-conditions-checkbox" type="checkbox" value="Accepted" checked={breakGlassState.accepted} onChange={e => runInAction(() => breakGlassState.accepted = !breakGlassState.accepted)} />
                    <p className="terms-and-conditions-checkbox-label">I acknowledge and accept the termns of this confidential agreement.</p>
                </div>
                {breakGlassState.acceptedError && <p className="terms-and-conditions-checkbox-error">You must accept the terms and conditions to continue.</p>}
                <ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
                    <Button
                        colors={Colors.Secondary}
                        display={Display.Outline}
                        sizes={Sizes.Medium}
                        buttonProps={{ id: 'cancel' }}
                        onClick={() => {
                            store.modal.hide()
                            navigate('/login');
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        colors={Colors.Primary} 
                        display={Display.Solid} 
                        sizes={Sizes.Medium}
                        buttonProps={{ id: 'confirm' }}
                        onClick={() => {
                            if (breakGlassState.accepted) {
                                props.setAccepted();
                                store.modal.hide()
                            } else {
                                runInAction(() => breakGlassState.acceptedError = true)
                            }
                        }}
                    >
                        Confirm
                    </Button>
                </ButtonGroup>
            </div>
		</div>
	);
});

export default SharedStudyBreakGlass;
