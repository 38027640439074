import { observer, useLocalStore } from 'mobx-react';
import React from 'react';
import { runInAction } from 'mobx';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import { SERVER_URL } from 'Constants';
import Axios from 'axios';
import CustomSpinner from 'Views/Components/Spinner/CustomSpinner';
import { store } from 'Models/Store';
import {
	Input,
	Box,
	FormControl,
	FormLabel,
	Select,
} from '@chakra-ui/react';

interface IProvider {
	firstName: string;
	lastName: string;
	jobTitle: string;
	practice: string;
	providerId: string;
	city: string;
}

interface IProviderCRUDProps {
	providers: IProvider[];
	select: (provider: IProvider) => void;
	remove?: (provider: IProvider) => void;
	ccList: IProvider[];
	to: IProvider | null;
	type: string;
}

const ProviderCRUD = observer((props: IProviderCRUDProps) => {
	const providerCrudStore = useLocalStore(() => ({
		ccList: props.ccList,
		to: props.to,
	}));

	const renderButton = (provider: IProvider): React.ReactNode => {
		if (providerCrudStore.to === null ||
				providerCrudStore.to?.providerId !== provider?.providerId) {
			return (
				<Button
					colors={Colors.PrimaryLight}
					display={Display.Solid}
					sizes={Sizes.Small}
					onClick={() => runInAction(() => {
						props.select(provider);
						providerCrudStore.to = provider;
					})}
				>
						Select
				</Button>
			);
		}
		return (
			<Button
				colors={Colors.Warning}
				display={Display.Solid}
				sizes={Sizes.Small}
				icon={{ icon: 'minus', iconPos: 'icon-left' }}
				onClick={() => runInAction(() => {
					if (props.remove) {
						props.remove(provider);
					}
					providerCrudStore.to = null;
				})}
			>
					Remove
			</Button>
		);
		
		if (providerCrudStore.ccList && Array.isArray(providerCrudStore.ccList)) {
			if (!providerCrudStore.ccList.map(cd => cd.providerId).includes(provider.providerId)) {
				return (
					<Button
						colors={Colors.PrimaryLight}
						display={Display.Solid}
						sizes={Sizes.Small}
						icon={{ icon: 'plus', iconPos: 'icon-left' }}
						onClick={() => runInAction(() => {
							props.select(provider);
							providerCrudStore.ccList = props.ccList;
						})}
					>
						Add
					</Button>
				);
			}
		}
		return (
			<Button
				colors={Colors.Warning}
				display={Display.Solid}
				sizes={Sizes.Small}
				icon={{ icon: 'minus', iconPos: 'icon-left' }}
				onClick={() => runInAction(() => {
					if (props.remove) {
						props.remove(provider);
					}
					providerCrudStore.ccList = props.ccList;
				})}
			>
				Remove
			</Button>
		);
			
	};

	return (
		<div className="provider-list">
			<section className="collection-component">
				<section className="collection__list">
					<table>
						<thead>
							<tr className="list__header">
								<th>
								Name
								</th>
								<th>
								Job Title
								</th>
								<th>
								Practice
								</th>
								<th>
								Provider ID
								</th>
								<th>
								City
								</th>
								<th>
								Actions
								</th>
							</tr>
						</thead>
						<tbody>
							{props.providers.map(provider => (
								<tr key={`provider-${provider.providerId}`} className="collection__item">
									<td>{`${provider.firstName} ${provider.lastName}`}</td>
									<td>{provider.jobTitle}</td>
									<td>{provider.practice}</td>
									<td>{provider.providerId}</td>
									<td>{provider.city}</td>
									<td>
										{renderButton(provider)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</section>
			</section>
		</div>
	);
});

interface IAddressingModalProps {
	select: (provider: IProvider) => void;
	remove?: (provider: IProvider) => void;
	ccList: IProvider[];
	to: IProvider | null;
	type: string;
	siteId: string;
}
const AddressingModal = observer((props: IAddressingModalProps) => {
	const [searchType, setSearchType] = React.useState<string>('Name');
	const [firstName, setFirstName] = React.useState<string>('');
	const [lastName, setLastName] = React.useState<string>('');
	const [organisation, setOrganisation] = React.useState<string>('');
	const [providerID, setProviderID] = React.useState<string>('');
	const [postcode, setPostcode] = React.useState<string>('');
	const [radius, setRadius] = React.useState<string>('');
	
	const { siteId } = props;
	const addressingResults = useLocalStore(() => ({
		providers: [] as IProvider[],
		loading: false,
	}));

	const onSubmit = (e: any) => {
		e.preventDefault();

		runInAction(() => {
			addressingResults.loading = true;
		});

		let lookupString = '';

		if (searchType === 'Name') {
			lookupString += 'byname?';

			if (firstName !== '') {
				lookupString += `GIVENNAME=${firstName}`;
			}
			if (firstName !== '' && lastName !== '') {
				lookupString += '&';
			}
			if (lastName !== '') {
				lookupString += `FAMILYNAME=${lastName}`;
			}
		}

		if (searchType === 'Organisation') {
			lookupString += `byorganisation?ORGANISATION=${organisation}&FORMAT=JSON&onlineProvidersOnly=True`;
		}

		if (searchType === 'Provider ID') {
			lookupString += `byid?ID=${providerID}&FORMAT=JSON`;
		}

		if (searchType === 'Postcode') {
			lookupString += `bypostcode?POSTCODES=${postcode}&FORMAT=JSON`;
		}

		if (searchType === 'Postcode w/ Radius') {
			lookupString += `bypostcode/radius?POSTCODES=${postcode}&RADIUS=${radius}`;
		}

		Axios.post(`${SERVER_URL}/api/entity/StudyEntity/MedicalObjects/provider/lookup`,
			{
				lookupString,
				siteId,
			})
			.then(res => {
				const providersToDisplay = [] as IProvider[];

				res.data.list.forEach((provider: any) => {
					const newProvider = {} as IProvider;
					newProvider.firstName = provider.staffName.givenName;
					newProvider.lastName = provider.staffName.familyName;
					newProvider.city = provider.address[0].city;
					newProvider.providerId = provider.staffIDList[0].idNumber;
					newProvider.practice = provider.address[0].otherDesignation;
					newProvider.jobTitle = provider.staffName.prefix;

					providersToDisplay.push(newProvider);
				});

				runInAction(() => {
					addressingResults.providers = providersToDisplay;
					addressingResults.loading = false;
				});
			})
			.catch(error => {
				console.log(error);
			});
	};

	return (
		<div className="provider-lookup__modal">
			<h4>
				Provider Lookup
			</h4>
			<form onSubmit={onSubmit}>
				<FormControl
					display="flex"
					flexDir="column"
					variant="floating"
					width="300px"
				>
					<Select
						width="100%"
						height="42px"
						onChange={event=> setSearchType(event.target.value)}
					>
						<option value='Name'>Name</option>
						<option value='Postcode'>Postcode</option>
						<option value='Postcode w/ Radius'>Postcode w/ Radius</option>
						<option value='Organisation'>Organisation</option>
						<option value='Provider ID'>Provider ID</option>
					</Select>
					<FormLabel margin="-4px 0 0 0">Search By</FormLabel>
				</FormControl>
				{searchType === 'Name' && (
					<div className="multi-input">
						<Input
							placeholder="First Name"
							m="0"
							height="42px"
							type="text"
							className="ignore-styles"
							variant="outline"
							defaultValue=""
							onChange={event=> setFirstName(event.target.value)}
						/>
						<Input
							placeholder="Last Name"
							m="0"
							height="42px"
							type="text"
							className="ignore-styles"
							variant="outline"
							defaultValue=""
							onChange={event=> setLastName(event.target.value)}
						/>
					</div>
				)}
				{searchType === 'Organisation' && (
					<div className="single-input">
						<Input
							placeholder="Organisation"
							m="0"
							height="42px"
							type="text"
							className="ignore-styles"
							variant="outline"
							defaultValue=""
							onChange={event=> setOrganisation(event.target.value)}
						/>
					</div>
				)}
				{searchType === 'Provider ID' && (
					<div className="single-input">
						<Input
							placeholder="Provider ID"
							m="0"
							height="42px"
							type="text"
							className="ignore-styles"
							variant="outline"
							defaultValue=""
							onChange={event=> setProviderID(event.target.value)}
						/>
					</div>
				)}
				{searchType === 'Postcode' && (
					<div className="single-input">
						<Input
							placeholder="Post Code"
							m="0"
							height="42px"
							type="text"
							className="ignore-styles"
							variant="outline"
							defaultValue=""
							onChange={event=> setPostcode(event.target.value)}
						/>
					</div>
				)}
				{searchType === 'Postcode w/ Radius' && (
					<div className="multi-input">
						<Input
							placeholder="Post Code"
							m="0"
							height="42px"
							type="text"
							className="ignore-styles"
							variant="outline"
							defaultValue=""
							onChange={event=> setPostcode(event.target.value)}
						/>
						<Input
							placeholder="Radius"
							m="0"
							height="42px"
							type="text"
							className="ignore-styles"
							variant="outline"
							defaultValue=""
							onChange={event=> setRadius(event.target.value)}
						/>
					</div>
				)}
				<Box ml="auto">
					<Button
						colors={Colors.Primary}
						display={Display.Solid}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'submit' }}
						icon={{ icon: 'plus', iconPos: 'icon-left' }}
						type="submit"
					>
						Search
					</Button>
				</Box>

			</form>
			<div className="provider-list__wrapper">
				{addressingResults.loading ? <CustomSpinner /> : <ProviderCRUD {...props} providers={addressingResults.providers} select={props.select} to={props.to} ccList={props.ccList} />}
			</div>
			<div className="modal-nav">
				<Button
					colors={Colors.Primary}
					display={Display.Solid}
					sizes={Sizes.Medium}
					buttonProps={{ id: 'close' }}
					type="button"
					onClick={(): void => store.modal.hide()}
				>
					Close
				</Button>
			</div>
		</div>
	);
});

export default AddressingModal; 
