import { gql, useQuery } from '@apollo/client';
import { CircularProgress, Flex, Heading, Image } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { SERVER_URL } from '../../../../Constants';
import { StudyEntity } from '../../../../Models/Entities';
import { fileType }  from 'Views/Components/FileUploadChakra/FileUploader';

interface TestDataPreviewProps {
	study: StudyEntity;
	height?: string;
}

const TestDataPreviewDefaultProps = {
	height : '100vh'
}
 
const GET_STUDY_FILE = gql`
	query CheckStudyType($args: [WhereExpressionGraph]) {
	  studyFileEntitys(where: $args) {
		studyFileId
	  }
  	}`;

const TestDataPreview = (props: TestDataPreviewProps) => {
	const {study, height} = {...TestDataPreviewDefaultProps, ...props};
	const [reportUrl, setReportUrl] = useState<string | null>(null);
	const [fileData, setFileData] = useState<fileType | null>(null);
	const [metadataLoading, setMetadataLoading] = useState<boolean>(false);
	let isHolter = false;
	if (study.studyType === 'HOLTERTWENTYFOURHR'
			|| study.studyType === 'HOLTERFORTYEIGHTHR'
			|| study.studyType === 'HOLTEREXTENDED'
			|| study.studyType === 'AMBULATORY_BP'
			|| study.studyType === 'EVENT_MONITOR'
			|| study.studyType === 'EXERCISE_STRESS_ECG') {
		isHolter = true;
	}
	const { loading, data, error } = useQuery(GET_STUDY_FILE, {
		variables: {
			args: [{
				path: 'studyId',
				comparison: 'equal',
				value: study.id,
			},
			{
				path: 'FileType',
				comparison: 'equal',
				value: 'HOLTER_REPORT',
			},]
		},
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		if (data && data.studyFileEntitys.length > 0) {
			setMetadataLoading(true);			
			setReportUrl(`${SERVER_URL}/api/files/${data.studyFileEntitys[0].studyFileId}`);
			axios.get(`${SERVER_URL}/api/files/metadata/${data.studyFileEntitys[0].studyFileId}`)
				.then(res => {
					setFileData(res.data);
					setMetadataLoading(false);
				});
		}
	}, [data]);
	
	if (loading || (metadataLoading && data && data.studyFileEntitys.length > 0)) {
		return <CircularProgress isIndeterminate color='green.300' />
	}
	
	return (
		<Flex justifyContent="center" alignItems="flex-start" height={height}>
			{reportUrl && fileData && fileData.fileName ? (
				<>
					{fileData.fileName.toLowerCase().indexOf('.pdf') > -1 ? <embed src={`${reportUrl}#view=Fit&toolbar=1`} type="application/pdf" width="100%" height="100%" /> : '' }
					{['.png','.jpg','.jpeg','.bmp','.gif'].some(substring=>fileData?.fileName?.toLowerCase().includes(substring)) ? <Image src={`${reportUrl}`}pb="20px" height="auto" /> : '' }
				</>
			) : (
				<Heading as='h5' size='md'>
					{isHolter ? 'No Holter report PDF has been uploaded' : 'No Test Data has been uploaded'}
				</Heading>
			)}
		</Flex>
	);
}

export default TestDataPreview;