import * as React from 'react';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import axios from 'axios';
import { Button } from 'Views/Components/Button/Button';
import { SERVER_URL } from '../../../Constants';
import { StudyFileEntity } from '../../../Models/Entities';
import Modal from '../../Components/Modal/Modal';
import alert from '../../../Util/ToastifyUtils';

interface FileMetadata {
	id: string;
	created: string;
	modified: string;
	fileName: string;
	contentType: string;
	length: number;
}

@observer
export class StudyFilePreview extends React.Component<{ url: string; model: StudyFileEntity }> {
	@observable
	private fileName?: string = undefined;

	@observable
	private modalOpen: boolean = false;

	@action
	private setFileName = (metadata: FileMetadata) => {
		this.fileName = metadata.fileName;
	};

	public componentDidMount() {
		const { url } = this.props;
		
		if (url) {
			fetch(`${SERVER_URL}/api/files/metadata/${url}`)
				.then(x => x.json())
				.then(this.setFileName);
		}
	}
	
	private restoreArchive = () => {
		const { url, model } = this.props;
		
		axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/Restore/${url}`)
			.then(() => {
				runInAction(() => {
					model.status = 'RESTORING';
					model.save();
					this.modalOpen = false;
				});
			})
			.catch(err => console.log(err));
	};
	
	private checkRestore = () => {
		const { url, model } = this.props;

		axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/checkRestore/${url}`)
			.then(res => {
				if (!res.data) {
					runInAction(() => {
						model.status = 'AVAILABLE';
						model.save();
					});
					
					alert('Image has been restored', 'success');
				} else {
					alert('Image is still restoring', 'error');
				}
			})
			.catch(err => console.log(err));
	};
	
	public render() {
		const { model, url } = this.props;
		
		if (model.status === 'ARCHIVED') {
			return (
				<>
					<Button onClick={() => runInAction(() => this.modalOpen = true)}>Restore</Button>
			
					<Modal
						isOpen={this.modalOpen}
						label="An example modal!"
						onRequestClose={() => runInAction(() => runInAction(() => this.modalOpen = false))}
					>
						<h4>Restore archived file?</h4>
						<Button
							className="show-flash-alert-test"
							onClick={(): void => this.restoreArchive()}
						>
							Restore
						</Button>
						<Button onClick={() => runInAction(() => this.modalOpen = false)}>Cancel</Button>
					</Modal>
				</>
			);
		}
		
		if (model.status === 'RESTORING') {
			return (
				<>
					<p>Currently restoring</p>
					<Button onClick={() => runInAction(() => this.checkRestore())}>Check restore</Button>
				</>
			);
		}
		
		return (
			<a
				href={`${SERVER_URL}/api/files/${url}?download=true`}
				target="_blank"
				rel="noopener noreferrer"
				className="btn btn--icon icon-download icon-right"
			>
				{this.fileName ?? 'Download'}
			</a>
		);
	}
}
