import {inject, observer} from 'mobx-react';
import * as React from 'react';

import PatientTile from './PatientTile';

const PatientPageWrappingTileTile: React.FunctionComponent = () => {
	return (
		<>
			<PatientTile/>
		</>
	);
};

export default inject('store')(observer(PatientPageWrappingTileTile));
