import { useQuery } from '@apollo/client';
import { Alert, AlertIcon, CircularProgress, Code, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import NewCTStudyViewer from 'Views/Tiles/Study/NewCTStudyViewer';
import { StudyEntity } from '../../Models/Entities';
import CTStudyViewer from '../Tiles/Study/CTStudyViewer';
import NewEchoStudyViewer from '../Tiles/Study/NewEchoStudyViewer';
import NewHolterStudyViewer from '../Tiles/Study/NewHolterStudyViewer';
import { store } from 'Models/Store';
import Axios from 'axios';
import { SERVER_URL } from 'Constants';
import moment from 'moment';
import SharedStudyBreakGlass from 'Views/Tiles/Modals/SharedStudyBreakGlass';


const NewStudyPage = () => {
	const params = useParams();
	const [shareKeyValid, setShareKeyValid ] = useState(store.loggedIn);

	const { loading, data, error } = useQuery(StudyEntity.getStudyType(), {
		variables: {
			args: [{
				path: 'id',
				comparison: 'equal',
				value: params.id,
			}]
		}
	});

	useEffect(() => {
		if (!store.loggedIn && data) {
			if (params.shareKey) {
				Axios.get(`${SERVER_URL}/api/entity/ShareLinkEntity/${params.shareKey}`).then(
					res => {
						if (res.data != null) {
							if (moment().isBefore(moment(res.data.expiryDate)) && !res.data.revoked && res.data.studyId === params.id) {
								store.modal.show('Terms and Conditions', 
									<SharedStudyBreakGlass setAccepted={()=>{setShareKeyValid(true)}} />);
							} else {
								window.location.href = '/logout';
							}
						} else {
							window.location.href = '/logout';
						}
					},
				);
			}
		}
	}, [data])
	
	if (loading || !shareKeyValid) {
		return <CircularProgress isIndeterminate color='green.300' />
	}
	
	if (error) {
		return (
			<Stack spacing={3}>
				<Alert status='error'>
					<AlertIcon />
					There was an error loading the study: 
					<Code>{error?.message}</Code>
				</Alert>
			</Stack>
		)
	}
	
	const renderStudyViewer = () => {
		const { studyEntity: study }: { studyEntity: StudyEntity } = data;
		
		if (study.studyType === 'HOLTERTWENTYFOURHR'
			|| study.studyType === 'HOLTERFORTYEIGHTHR'
			|| study.studyType === 'HOLTEREXTENDED'
			|| study.studyType === 'AMBULATORY_BP'
			|| study.studyType === 'EVENT_MONITOR'
			|| study.studyType === 'EXERCISE_STRESS_ECG') {
			return <NewHolterStudyViewer studyId={study.id}/>;
		} else if (study.studyType === 'ECHO'
			|| study.studyType === 'STRESS_ECHO'
			|| study.studyType === 'DOBUTAMINE_STRESS_ECHO'
			|| study.studyType === 'TRANSOESOPHAGEAL_ECHO'
			|| study.studyType === 'PAEDIATRIC_ECHO') {
			return <NewEchoStudyViewer studyId={study.id} />
		} else if (study.studyType === 'CTCA'
			|| study.studyType === 'CTCS') {
			return <NewCTStudyViewer studyId={study.id} />
		} else {
			return <div>Study doesn't exist</div>
		}
	}
	
	return renderStudyViewer();

}

export default NewStudyPage;