import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { StudyEntity, StudyFileEntity } from '../../../Models/Entities';
import { SERVER_URL } from 'Constants';
import { Flex, Spinner, useToast, Image } from '@chakra-ui/react';
import { fileType } from 'Views/Components/FileUploadChakra/FileUploader';

interface NewStressECGPreviewProps {
	study?: StudyEntity;
}

const NewStressECGPreview = (props: NewStressECGPreviewProps) => {
	const { study } = props;
	const [previewData, setPreviewData] = useState<string | null>(null);
	const [loading, setLoading] = useState(true);

	const toast = useToast();

	const [studyFileData, setStudyFileData] = useState<StudyFileEntity | null>(null);
	const [fileData, setFileData] = useState<fileType | null>(null);
	const [metadataLoading, setMetadataLoading] = useState(true);


	if (!study) {
		return <>Study is not set</>
	}

	const getStressEcg = () => {
		axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/StudyStressECGFileByStudy/${study.id}`)
			.then(res => {
				res.data.forEach((file: StudyFileEntity) => {
					setStudyFileData(file);
					setPreviewData(`${SERVER_URL}/api/files/${file.studyFileId}`);
				});
			})
			.catch(err => {
				toast({
					title: 'Issue loading stress ecg',
					description: err,
					position: 'bottom-right',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const getMetaData = () => {
		if (studyFileData) {
			axios.get(`${SERVER_URL}/api/files/metadata/${studyFileData.studyFileId}`)
				.then(res => {
					setFileData(res.data);
					setMetadataLoading(false);
				});
		}
	}


	useEffect(() => {
		getStressEcg();
	}, []);

	useEffect(() => {
		getMetaData();
	}, [studyFileData]);

	return (
		<Flex
			justifyContent="center"
			alignItems="flex-start"
			height="100vh"
		>
			{loading || metadataLoading ? (
				<Spinner size="xl" />
			) : (
				previewData && fileData && fileData.fileName &&  (
					<>
						{fileData.fileName.toLowerCase().indexOf('.pdf') > -1 ? <embed src={`${previewData}#view=Fit&toolbar=1`} type="application/pdf" width="100%" height="100%" /> : ''}
						{['.png','.jpg','.jpeg','.bmp','.gif'].some(substring=>fileData?.fileName?.toLowerCase().includes(substring)) ? <Image src={`${previewData}`} width="100%" height="auto" pb="20px"/> : '' }
					</>
				)
			)}
		</Flex>
	);
}

export default NewStressECGPreview;
