export type accessLevel =
	'VIEW_ONLY' |
	'FULL_ACCESS';

export const accessLevelOptions: { [key in accessLevel]: string } = {
	VIEW_ONLY: 'View Only',
	FULL_ACCESS: 'Full Access',
};

export type doctorType =
	'REPORTING' |
	'REFERRING';

export const doctorTypeOptions: { [key in doctorType]: string } = {
	REPORTING: 'Reporting',
	REFERRING: 'Referring',
};

export type fileStatus =
	'AVAILABLE' |
	'ARCHIVED' |
	'RESTORING';

export const fileStatusOptions: { [key in fileStatus]: string } = {
	AVAILABLE: 'Available',
	ARCHIVED: 'Archived',
	RESTORING: 'Restoring',
};

export type genderType =
	'MALE' |
	'FEMALE' |
	'NA';

export const genderTypeOptions: { [key in genderType]: string } = {
	MALE: 'Male',
	FEMALE: 'Female',
	NA: 'NA',
};

export type holterReportStatus =
	'NOT_REPORTED_HOLTER' |
	'AWAITING_CARDIOLOGIST_CONFIRMATION' |
	'CARDIOLOGIST_CHANGES_PENDING' |
	'CONFIRMED_HOLTER' |
	'CONFIRMED_AND_COPIED';

export const holterReportStatusOptions: { [key in holterReportStatus]: string } = {
	NOT_REPORTED_HOLTER: 'Not Reported (Holter)',
	AWAITING_CARDIOLOGIST_CONFIRMATION: 'Awaiting Cardiologist Confirmation',
	CARDIOLOGIST_CHANGES_PENDING: 'Cardiologist Changes Pending',
	CONFIRMED_HOLTER: 'Confirmed (Holter)',
	CONFIRMED_AND_COPIED: 'Confirmed and Copied',
};

export type reportStatus =
	'NOT_REPORTED' |
	'PROVISIONAL' |
	'CONFIRMED';

export const reportStatusOptions: { [key in reportStatus]: string } = {
	NOT_REPORTED: 'Not Reported',
	PROVISIONAL: 'Provisional',
	CONFIRMED: 'Confirmed',
};

export type reportStatusCombined =
	'NOT_REPORTED' |
	'PROVISIONAL' |
	'CONFIRMED' |
	'CARDIOLOGIST_CHANGES_PENDING' |
	'CONFIRMED_AND_COPIED';

export const reportStatusCombinedOptions: { [key in reportStatusCombined]: string } = {
	NOT_REPORTED: 'Not Reported',
	PROVISIONAL: 'Provisional',
	CONFIRMED: 'Confirmed',
	CARDIOLOGIST_CHANGES_PENDING: 'Cardiologist Changes Pending',
	CONFIRMED_AND_COPIED: 'Confirmed and Copied',
}

export type sessionPeriod =
	'AM' |
	'PM' |
	'FULL_DAY';

export const sessionPeriodOptions: { [key in sessionPeriod]: string } = {
	AM: 'AM',
	PM: 'PM',
	FULL_DAY: 'Full Day',
};

export type sessionType =
	'SCANNING' |
	'ANALYSIS' |
	'DAILY_DUTIES' |
	'OTHER';

export const sessionTypeOptions: { [key in sessionType]: string } = {
	SCANNING: 'Scanning',
	ANALYSIS: 'Analysis',
	DAILY_DUTIES: 'Daily Duties',
	OTHER: 'Other',
};

export type statusType =
	'PENDING' |
	'ACTIVE' |
	'INACTIVE';

export const statusTypeOptions: { [key in statusType]: string } = {
	PENDING: 'Pending',
	ACTIVE: 'Active',
	INACTIVE: 'Inactive',
};

export type studyFileType =
	'DICOM_MEDIA' |
	'DICOM_REPORT' |
	'HOLTER_DIARY' |
	'HOLTER_ECG' |
	'HOLTER_REFERRAL' |
	'HOLTER_REPORT' |
	'ECHO_REFERRAL' |
	'STRESS_ECG_REPORT' |
	'RAW_EDF' |
	'WORKLIST';

export const studyFileTypeOptions: { [key in studyFileType]: string } = {
	DICOM_MEDIA: 'DICOM Media',
	DICOM_REPORT: 'DICOM Report',
	HOLTER_DIARY: 'Holter Diary',
	HOLTER_ECG: 'Holter ECG',
	HOLTER_REFERRAL: 'Holter Referral',
	HOLTER_REPORT: 'Holter Report',
	ECHO_REFERRAL: 'Echo Referral',
	STRESS_ECG_REPORT: 'Stress ECG Report',
	RAW_EDF: 'Raw EDF',
	WORKLIST: 'Worklist',
};

export type procedureStatusType =
	'DATA_UPLOADED' |
	'DATA_PROCESSED' |
	'ERROR_PROCESSING_DATA' |
	'ANALYSED' |
	'DEVICE_ON_PATIENT';

export const procedureStatusTypeOptions: { [key in procedureStatusType]: string } = {
	DATA_UPLOADED: 'Data Uploaded',
	DATA_PROCESSED: 'Data Processed',
	ERROR_PROCESSING_DATA: 'Error Processing Data',
	ANALYSED: 'Analysed',
	DEVICE_ON_PATIENT: 'Device on Patient'
};

export type studyType =
	'ECHO' |
	'STRESS_ECHO' |
	'DOBUTAMINE_STRESS_ECHO' |
	'TRANSOESOPHAGEAL_ECHO' |
	'PAEDIATRIC_ECHO' |
	'HOLTERTWENTYFOURHR' |
	'HOLTERFORTYEIGHTHR' |
	'HOLTEREXTENDED' |
	'AMBULATORY_BP' |
	'EVENT_MONITOR' |
	'EXERCISE_STRESS_ECG' |
	'CTCA' | 
	'CTCS';

export const studyTypeOptions: { [key in studyType]: string } = {
	ECHO: 'Echo',
	STRESS_ECHO: 'Stress Echo',
	DOBUTAMINE_STRESS_ECHO: 'Dobutamine Stress Echo',
	TRANSOESOPHAGEAL_ECHO: 'Transoesophageal Echo',
	PAEDIATRIC_ECHO: 'Paediatric Echo',
	HOLTERTWENTYFOURHR: 'Holter 24HR',
	HOLTERFORTYEIGHTHR: 'Holter 48HR',
	HOLTEREXTENDED: 'Holter Extended',
	AMBULATORY_BP: 'Ambulatory BP',
	EVENT_MONITOR: 'Event Monitor',
	EXERCISE_STRESS_ECG: 'Exercise Stress ECG',
	CTCA: 'CT Coronary Angiography',
	CTCS: 'CT Calcium Score'
};

export type userType =
	'CLIENT_DEVICE' |
	'SITE_ADMIN' |
	'SITE_USER' |
	'CARDIAC_SCIENTIST' |
	'REPORTING_DOCTOR';

export const userTypeOptions: { [key in userType]: string } = {
	CLIENT_DEVICE: 'Client Device',
	SITE_ADMIN: 'Site Admin',
	SITE_USER: 'Site User',
	CARDIAC_SCIENTIST: 'Cardiac Scientist',
	REPORTING_DOCTOR: 'Reporting Doctor',
};

