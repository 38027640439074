
import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, action, computed } from 'mobx';
import classNames from 'classnames';
import { Button, Display } from '../Button/Button';

export interface ITabConfig {
	name: string,
	component: React.ReactNode,
	key: string,
	className?: string
};

export interface ITabsProps {
	tabs: Array<ITabConfig>;
	className?: string;
	innerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
	defaultTab?: number;
	currentTab?: number;
	onTabClicked?: (tabIndex: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface ITabHeaderProps {
	tabState: { currentTab: number };
	tabIdx: number;
	tabChanged?: (() => void);
	onTabClicked?: (tabIndex: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	
	children?: React.ReactNode
}

class TabHeader extends React.Component<ITabHeaderProps, any> {
	public render() {
		return <Button display={Display.Text} onClick={this.onTabClicked}>{this.props.children}</Button>;
	}

	@action
	private onTabClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if(this.props.tabState.currentTab !== this.props.tabIdx && this.props.tabChanged){
			this.props.tabChanged();
		}
		this.props.tabState.currentTab = this.props.tabIdx;
		if (this.props.onTabClicked) {
			this.props.onTabClicked(this.props.tabIdx, event);
		}
	}
}
@observer
export default class Tabs extends React.Component<ITabsProps, any> {
	@observable
	public tabState = {
		currentTab: 0,
	};

	@computed
	public get currentTab() {
		return this.props.currentTab || this.tabState.currentTab;
	}
	constructor(props: ITabsProps, context: any) {
		super(props, context);
		if (this.props.defaultTab) {
			this.tabState.currentTab = this.props.defaultTab;
		}
	}

	public render(){
		return (
			<>
				<nav {...this.props.innerProps} className={'tabs ' + (this.props.className ? this.props.className : '')}>
					<ul>
						{this.props.tabs.map((tab, idx) => {
							return (
								<li key={tab.key} className={'tab-header' + (this.currentTab === idx ? ' selected' : '')}>
									<TabHeader tabState={this.tabState} tabIdx={idx} onTabClicked={this.props.onTabClicked}>{tab.name}</TabHeader>
								</li>
							);
						})}
					</ul>
				</nav>
				<div className={classNames(this.props.tabs[this.currentTab].className)}>
					{this.props.tabs[this.currentTab].component}
				</div>
			</>
		);
	}
}
