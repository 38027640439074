import { runInAction, toJS } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import { UserEntity, UserSiteAccessEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import React, { useEffect } from 'react'
import {useParams} from 'react-router';

import alert from 'Util/ToastifyUtils';
import ProfileTile from './ProfileTile';

const ProfileWrappingTile = observer(() => {
	const userDetails = useLocalStore(() => ({
		user: new UserEntity(),
		loading: true,
	}));
	
	const params = useParams();

	const retrieveUser = () => {
		store.apolloClient.query({
			query: UserEntity.getFetchSingleQueryProfilePage(),
			fetchPolicy: 'network-only',
			variables: {
				args: [{
					path: 'id',
					comparison: 'equal',
					value: params.id,
				}],
			},
		}).then(res => {
			runInAction(() => {
				userDetails.user = new UserEntity(res.data.userEntity);
				userDetails.loading = false;
			});
		}).catch(error => {
			console.error(error);
		});
	};

	const updateUser = (): void => {
		userDetails.user.save().then(() => {
			alert(
				<div>
					<h6>Updated Details</h6>
				</div>,
				'success',
			);
		}).catch(errors => {
			alert(
				<div>
					<h6>Update Failed</h6>
					{errors}
				</div>,
				'success',
			);
		});
	};

	const updateProviderNumber = (e: any, site: UserSiteAccessEntity) => {
		runInAction(() => userDetails.user.sitess.filter(s => s.id === site.id)[0].providerNumber = e.target.value)
	};

	const updateAsarNumber = (e: any) => {
		runInAction(() => userDetails.user.asarNumber = e.target.value)
	};

	const updateAddress = (e: any) => {
		runInAction(() => userDetails.user.address = e.target.value)
	};

	useEffect(() => {
		retrieveUser();
	}, []);

	if (!userDetails.loading) {
		return (
			<ProfileTile
				user={userDetails.user}
				loading={userDetails.loading}
				updateUser={updateUser}
				updateProviderNumber={updateProviderNumber}
				updateAsarNumber={updateAsarNumber}
				updateAddress={updateAddress}
				userType={store.userType}
			/>
		);
	}

	return <></>;
});

export default ProfileWrappingTile;
