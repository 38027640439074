import { gql, useQuery } from '@apollo/client';
import { CircularProgress, Flex, Heading, Image } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { SERVER_URL } from '../../../../Constants';
import { StudyEntity } from '../../../../Models/Entities';
import { fileType }  from 'Views/Components/FileUploadChakra/FileUploader';

interface TwelveLeadPreviewProps {
	study: StudyEntity;
	height?: string;
}

const TwelveLeadPreviewDefaultProps = {
	height : '100vh'
}

const GET_STUDY_FILE = gql`
	query CheckStudyType($args: [WhereExpressionGraph]) {
	  studyFileEntitys(where: $args) {
		studyFileId
	  }
  	}`;

const TwelveLeadPreview = (props: TwelveLeadPreviewProps) => {
	const {study, height} = {...TwelveLeadPreviewDefaultProps, ...props};
	const [twelveLeadEcgUrl, setTwelveLeadEcgUrl] = useState<string | null>(null);
	const [fileData, setFileData] = useState<fileType | null>(null);
	const [metadataLoading, setMetadataLoading] = useState<boolean>(false);

	const { loading, data, error } = useQuery(GET_STUDY_FILE, {
		variables: {
			args: [{
				path: 'studyId',
				comparison: 'equal',
				value: study.id,
			},
			{
				path: 'FileType',
				comparison: 'equal',
				value: 'HOLTER_ECG',
			},]
		},
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		if (data && data.studyFileEntitys.length > 0) {
			setMetadataLoading(true);

			setTwelveLeadEcgUrl(`${SERVER_URL}/api/files/${data.studyFileEntitys[0].studyFileId}`);
			axios.get(`${SERVER_URL}/api/files/metadata/${data.studyFileEntitys[0].studyFileId}`)
				.then(res => {
					setFileData(res.data);
					setMetadataLoading(false);
				});
		}
	}, [data || metadataLoading]);

	if (loading || (metadataLoading && data && data.studyFileEntitys.length > 0)) {
		return <CircularProgress isIndeterminate color='green.300' />
	}

	return (
		<Flex justifyContent="center" alignItems="flex-start" height={height}>
			{twelveLeadEcgUrl && fileData && fileData.fileName ? (
				<>
					{fileData.fileName.toLowerCase().indexOf('.pdf') > -1 ? <embed src={`${twelveLeadEcgUrl}#view=Fit&toolbar=1`} type="application/pdf" width="100%" height="100%" /> : '' }
					{['.png','.jpg','.jpeg','.bmp','.gif'].some(substring=>fileData?.fileName?.toLowerCase().includes(substring)) ? <Image src={`${twelveLeadEcgUrl}`} pb="20px" height="auto" /> : '' }
				</>
			) : (
				<Heading as='h5' size='md'>No 12 Lead ECG has been uploaded</Heading>
			)}
		</Flex>
	);
}

export default TwelveLeadPreview;