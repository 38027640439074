import { store } from 'Models/Store';
import { SERVER_URL } from 'Constants';
import axios from 'axios';
import alert from '../../Util/ToastifyUtils';
import {
	Flex,
	Input,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Button,
	ButtonGroup,
	useToast,
	Box,
	Heading,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate, Navigate } from 'react-router-dom';

let formSubmitted: boolean = false;
type FormValues = {
	confirmPassword: string;
	password: string;
};

export const ResetPasswordPage = () => {
		
	const params = new URLSearchParams(location.search.substring(1));
	const token = params.get('token');
	const username = params.get('username');

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<FormValues>();

	
	const navigate = useNavigate();
	const toast = useToast();
		
	const onResetPasswordClicked = (formData: FormValues) => {
				
		axios.post(
			`${SERVER_URL}/api/account/reset-password`,
			{
				token: token,
				username: username,
				password: formData.password,
			})
			.then(({ data }) => {
				alert('Your password has been reset', 'success');
				navigate('/login');

			})
			.catch(error => {
				const errorMsgs = error.response.data.errors.map((error: any) => (<p>{error.message}</p>));
				alert(
					<div>
						<h6>Password could not be changed: </h6>
						{errorMsgs}
					</div>,
					'error'
				);
			});
			
	};

	if (store.loggedIn) {
		return <Navigate replace to="/" />;
	} else {
		return (

			<Flex justifyContent="center" alignItems="center" gridArea="body">
				<Box
					flex="1"
					mb={{ base: '35%', lg: '140px' }}
				>
					<div className="reset-logo" ></div>
					<Heading as='h4' textAlign='center' margin='-10x 0 15px 0'>Reset Password</Heading>
					<form onSubmit={handleSubmit(onResetPasswordClicked)}>
						<Flex
							flexDirection="column"
							alignItems="center"
							width={{ base: '80%', lg: '60%' }}
							maxWidth="600px"
							margin="auto"
						>

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.password}
								id="login_password"
								mb="15px"
							>
								<Input
									type="password"
									size="lg"
									width="100%"
									{...register('password', {
										required: true,
										minLength: 6,
									})}
								/>
								<FormLabel>Password</FormLabel>
								{formSubmitted && errors.password?.type === 'required' ? (
									<FormErrorMessage>Password is required</FormErrorMessage>
								) : (
									''
								)}
								{formSubmitted && errors.password?.type === 'minLength' ? (
									<FormErrorMessage>
                        Password must be at least 6 characters long
									</FormErrorMessage>
								) : (
									''
								)}
							</FormControl>

							<FormControl
								display="flex"
								flexDir="column"
								variant="floating"
								isInvalid={!!errors.confirmPassword}
								id="login_confirmPassword"
								mb="15px"
							>
								<Input
									type="password"
									size="lg"
									width="100%"
									{...register('confirmPassword', {
										required: true,
										minLength: 6,
										validate: {
											passwordsMatch: (_, values) =>
												values.confirmPassword === values.password,
										},
									})}
								/>
								<FormLabel>Confirm Password</FormLabel>
								{formSubmitted &&
                    errors.confirmPassword?.type === 'required' ? (
										<FormErrorMessage>Password is required</FormErrorMessage>
									) : (
										''
									)}
								{formSubmitted &&
                    errors.confirmPassword?.type === 'minLength' ? (
										<FormErrorMessage>
                        Password must be at least 6 characters long
										</FormErrorMessage>
									) : (
										''
									)}
								{formSubmitted &&
                    errors.confirmPassword?.type === 'passwordsMatch' ? (
										<FormErrorMessage>Passwords must match</FormErrorMessage>
									) : (
										''
									)}
							</FormControl>


							<ButtonGroup mb="2" width="100%">
								<Button
									width="100%"
									type="submit"
									className="login-submit"
									id="login_submit0"
									colorScheme="blue"
									onClick={() => (formSubmitted = true)}
								>
			  					Confirm Password
								</Button>
							</ButtonGroup>
						</Flex>
					</form>
				</Box>
			</Flex>
		);
	}
};

