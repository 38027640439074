import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsDoctorEntity } from 'Models/Security/Acl/VisitorsDoctorEntity';
import { SuperAdminDoctorEntity } from 'Models/Security/Acl/SuperAdminDoctorEntity';
import { UserDoctorEntity } from 'Models/Security/Acl/UserDoctorEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { CRUD } from '../CRUDOptions';
import * as Enums from '../Enums';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';

export interface IDoctorEntityAttributes extends IModelAttributes {
	prefix: string;
	firstName: string;
	lastName: string;
	doctorType: Enums.doctorType;
	suffix: string;

	studyId?: string;
	study?: Models.StudyEntity | Models.IStudyEntityAttributes;
}

@entity('DoctorEntity', 'Doctor')
export default class DoctorEntity extends Model implements IDoctorEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsDoctorEntity(),
		new SuperAdminDoctorEntity(),
		new UserDoctorEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
	];

	/**
	 * Dr prefix string
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Prefix',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public prefix: string;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'First Name',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public firstName: string;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Last Name',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public lastName: string;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Doctor Type',
		displayType: 'enum-combobox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => AttrUtils.standardiseEnum(attr, Enums.doctorTypeOptions),
		enumResolveFunction: makeEnumFetchFunction(Enums.doctorTypeOptions),
		displayFunction: (attribute: Enums.doctorType) => Enums.doctorTypeOptions[attribute],
	})
	public doctorType: Enums.doctorType;

	/**
	 * Any suffix to the doctor that might be required e.g. M.D, FRCPath
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Suffix',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public suffix: string;

	@observable
	@attribute()
	@CRUD({
		name: 'Study',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.StudyEntity,
	})
	public studyId?: string;

	@observable
	@attribute({ isReference: true })
	public study: Models.StudyEntity;
	
	constructor(attributes?: Partial<IDoctorEntityAttributes>) {
		super(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IDoctorEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.prefix !== undefined) {
				this.prefix = attributes.prefix;
			}
			if (attributes.firstName !== undefined) {
				this.firstName = attributes.firstName;
			}
			if (attributes.lastName !== undefined) {
				this.lastName = attributes.lastName;
			}
			if (attributes.doctorType !== undefined) {
				this.doctorType = attributes.doctorType;
			}
			if (attributes.suffix !== undefined) {
				this.suffix = attributes.suffix;
			}
			if (attributes.studyId !== undefined) {
				this.studyId = attributes.studyId;
			}
			if (attributes.study !== undefined) {
				if (attributes.study === null) {
					this.study = attributes.study;
				} else if (attributes.study instanceof Models.StudyEntity) {
					this.study = attributes.study;
					this.studyId = attributes.study.id;
				} else {
					this.study = new Models.StudyEntity(attributes.study);
					this.studyId = this.study.id;
				}
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		study {
			${Models.StudyEntity.getAttributes().join('\n')}
		}
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		return `${this.firstName} ${this.lastName}`;
	}
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(DoctorEntity.prototype, 'created');
CRUD(modifiedAttr)(DoctorEntity.prototype, 'modified');
