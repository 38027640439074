import { TableDataItem } from './StudyModels';

// 

export const computedValuesMap = {
	'BSA' : getBsa,
	'MMLVIDDIndex' : getMMLVIDDIndex,
	'MMLVMASScubed' : getMMLVMASScubed,
	'MMLVMI' : getMMLVMI,
	'MMLVFSteich' : getMMLVFSteich,
	'MMLVEFteich' : getMMLVEFteich,
	'$2DLVEDV' : get2DLVEDV,
	'$2DLVESV' : get2DLVESV,
	'$2DLVFSteich' : get2DLVFSteich,
	'$2DLVEFteich' : get2DLVEFteich,
	'$2DLVIDDIndex' : get2DLVIDDIndex,
	'$2DLVMASScubed' : get2DLVMASScubed,
	'$2DLVMI' : get2DLVMI,
	'$2DLVEDVMODBPIndex' : get2DLVEDVMODBPIndex,
	'$2DLVSVMODBP' : get2DLVSVMODBP,
	'$2DLVEFMODBP' : get2DLVEFMODBP,
	'$2DSVMODA4C' : get2DSVMODA4C,
	'$2DEFMODA4C' : get2DEFMODA4C,
	'$2DSVMODA2C' : get2DSVMODA2C,
	'$2DEFMODA2C' : get2DEFMODA2C,
	'$2DLAEDVMODALIndex' : get2DLAEDVMODALIndex,
	'$2DLAEDVMODBPIndex' : get2DLAEDVMODBPIndex,
	'$2DAOROOTIndex' : get2DAOROOTIndex,
	'$2DASCAOIndex' : get2DASCAOIndex,
	'$2DRVFAC' : get2DRVFAC,
	'CWAVPGRAD': getCWAVPGRAD,
	'CAVAVTI' : getCAVAVTI,
	'CAVAVTIIndex' : getCAVAVTIIndex,
	'CAVAVMAX' : getCAVAVMAX,
	'CAVDI' : getCAVDI,
	'PWMVEARATIO' : getPWMVEARATIO,
	'PWEEPRIMERATIOSEP' : getPWEEPRIMERATIOSEP,
	'PWEEPRIMERATIOLAT' : getPWEEPRIMERATIOLAT,
	'PWEEPRIMERATIOAVE' : getPWEEPRIMERATIOAVE,
	'CMVAPHT' : getCMVAPHT,
	'CMVAVTI' : getCMVAVTI,
	'PWPVSDRATIO' : getPWPVSDRATIO,
	'PWMVADURPWPVEINARDUR' : getPWMVADURPWPVEINARDUR,
	'CWTRPGRAD' : getCWTRPGRAD,
	'CWRVSP' : getCWRVSP,
	'CWEPLAR' : getCWEPLAR,
	'CWPVPGRAD' : getCWPVPGRAD,
	'CWPAEDP' : getCWPAEDP,
	'CMRPISAERO' : getCMRPISAERO,
	'CMRPISARVOL' : getCMRPISARVOL,
};


export function getBsa(tableData: TableDataItem[]) {
	const weightIndex = tableData.findIndex(tableItem => tableItem.name === 'Weight');
	const heightIndex = tableData.findIndex(tableItem => tableItem.name === 'Height');

	if (weightIndex > -1 && heightIndex > -1) {
		return 0.007184 * (Math.pow(tableData[weightIndex].rawValue,0.425) * Math.pow(tableData[heightIndex].rawValue,0.725));
	} else {
		return null;
	}

}

export function getMMLVIDDIndex(tableData: TableDataItem[]) {
	const MMLVIDDIndex = tableData.findIndex(tableItem => tableItem.name === '(MM)LVIDD');
	const BSA = getBsa(tableData);

	if (MMLVIDDIndex > -1 && BSA) {
		const MMLVIDDValue = tableData[MMLVIDDIndex].unit === 'mm' ? tableData[MMLVIDDIndex].rawValue / 10 : tableData[MMLVIDDIndex].rawValue;
		return MMLVIDDValue / BSA;
	} else {
		return null;
	}
}

export function getMMLVMASScubed(tableData: TableDataItem[]) {
	const MMLVIDD = tableData.findIndex(tableItem => tableItem.name === '(MM)LVIDD');
	const MMLVIVSD = tableData.findIndex(tableItem => tableItem.name === '(MM)LVIVSD');
	const MMLVPWTD = tableData.findIndex(tableItem => tableItem.name === '(MM)LVPWTD');
	const BSA = getBsa(tableData);

	if (MMLVIDD > -1 && MMLVIDD > -1 && MMLVIVSD > -1 && MMLVPWTD > -1 && BSA) {
		const MMLVIDDValue = tableData[MMLVIDD].unit === 'mm' ? tableData[MMLVIDD].rawValue / 10 : tableData[MMLVIDD].rawValue;
		const MMLVIVSDValue = tableData[MMLVIVSD].unit === 'mm' ? tableData[MMLVIVSD].rawValue / 10 : tableData[MMLVIVSD].rawValue;
		const MMLVPWTDValue = tableData[MMLVPWTD].unit === 'mm' ? tableData[MMLVPWTD].rawValue / 10 : tableData[MMLVPWTD].rawValue;

		return 0.8 * 1.04 * (Math.pow(MMLVIDDValue + MMLVIVSDValue + MMLVPWTDValue,3) - Math.pow(MMLVIDDValue,3)) + 0.6;
	} else {
		return null;
	}
}

export function getMMLVMI(tableData: TableDataItem[]) {
	const MMLVMASScubed = getMMLVMASScubed(tableData);
	const BSA = getBsa(tableData);

	if (MMLVMASScubed && BSA) {
		return MMLVMASScubed / BSA;
	} else {
		return null;
	}
}

export function getMMLVEDV(tableData: TableDataItem[]) {
	const MMLVIDD = tableData.findIndex(tableItem => tableItem.name === '(MM)LVIDD');
	if (MMLVIDD > -1) {
		const MMLVIDDValue = tableData[MMLVIDD].unit === 'mm' ? tableData[MMLVIDD].rawValue / 10 : tableData[MMLVIDD].rawValue;
		return ( 7 / (2.4 + MMLVIDDValue) ) * Math.pow(MMLVIDDValue,3);
	} else {
		return null;
	}
}



export function getMMLVESV(tableData: TableDataItem[]) {
	const MMLVIDS = tableData.findIndex(tableItem => tableItem.name === '(MM)LVIDS');
	if (MMLVIDS > -1) {
		const MMLVIDSValue = tableData[MMLVIDS].unit === 'mm' ? tableData[MMLVIDS].rawValue / 10 : tableData[MMLVIDS].rawValue;
		return ( 7 / (2.4 + MMLVIDSValue) ) * Math.pow(MMLVIDSValue,3);
	} else {
		return null;
	}
}


export function getMMLVFSteich(tableData: TableDataItem[]) {
	const MMLVIDDindex = tableData.findIndex(tableItem => tableItem.name === '(MM)LVIDD');
	const MMLVIDSindex = tableData.findIndex(tableItem => tableItem.name === '(MM)LVIDS');

	if (MMLVIDDindex > -1 && MMLVIDSindex > -1 ) {
		const MMLVIDDValue = tableData[MMLVIDDindex].unit === 'mm' ? tableData[MMLVIDDindex].rawValue / 10 : tableData[MMLVIDDindex].rawValue;
		const MMLVIDSValue = tableData[MMLVIDSindex].unit === 'mm' ? tableData[MMLVIDSindex].rawValue / 10 : tableData[MMLVIDSindex].rawValue;
		return ((MMLVIDDValue - MMLVIDSValue) / MMLVIDDValue ) * 100;
	} else {
		return null;
	}
}

export function getMMLVEFteich(tableData: TableDataItem[]) {
	const MMLVEDV = getMMLVEDV(tableData);
	const MMLVESV = getMMLVESV(tableData);

	if (MMLVEDV && MMLVESV) {
		return ((MMLVEDV - MMLVESV ) / MMLVEDV) * 100;
	} else {
		return null;
	}
}


export function get2DLVEDV(tableData: TableDataItem[]) {
	const $2DLVIDDIndex = tableData.findIndex(tableItem => tableItem.name === '(2D)LVIDD');
	if ($2DLVIDDIndex > -1) {
		const $2DLVIDDValue = tableData[$2DLVIDDIndex].unit === 'mm' ? tableData[$2DLVIDDIndex].rawValue / 10 : tableData[$2DLVIDDIndex].rawValue;
		return ( 7 / (2.4 + $2DLVIDDValue) ) * Math.pow($2DLVIDDValue,3);
	} else {
		return null;
	}
}



export function get2DLVESV(tableData: TableDataItem[]) {
	const $2DLVIDSIndex = tableData.findIndex(tableItem => tableItem.name === '(2D)LVIDS');
	if ($2DLVIDSIndex > -1) {
		const $2DLVIDSValue = tableData[$2DLVIDSIndex].unit === 'mm' ? tableData[$2DLVIDSIndex].rawValue / 10 : tableData[$2DLVIDSIndex].rawValue;
		return ( 7 / (2.4 + $2DLVIDSValue) ) * Math.pow($2DLVIDSValue,3);
	} else {
		return null;
	}
}


export function get2DLVFSteich(tableData: TableDataItem[]) {
	const $2DLVIDDindex = tableData.findIndex(tableItem => tableItem.name === '(2D)LVIDD');
	const $2DLVIDSindex = tableData.findIndex(tableItem => tableItem.name === '(2D)LVIDS');
	if ($2DLVIDDindex > -1 && $2DLVIDSindex > -1 ) {
		const $2DLVIDDValue = tableData[$2DLVIDDindex].unit === 'mm' ? tableData[$2DLVIDDindex].rawValue / 10 : tableData[$2DLVIDDindex].rawValue;
		const $2DLVIDSValue = tableData[$2DLVIDSindex].unit === 'mm' ? tableData[$2DLVIDSindex].rawValue / 10 : tableData[$2DLVIDSindex].rawValue;

		return (($2DLVIDDValue - $2DLVIDSValue ) / $2DLVIDDValue ) * 100;
	} else {
		return null;
	}
}

export function get2DLVEFteich(tableData: TableDataItem[]) {
	const $2DLVEDV = get2DLVEDV(tableData);
	const $2DLVESVindex = get2DLVESV(tableData);

	if ($2DLVEDV && $2DLVESVindex) {
		return (($2DLVEDV - $2DLVESVindex ) / $2DLVEDV) * 100;
	} else {
		return null;
	}
}

export function get2DLVIDDIndex(tableData: TableDataItem[]) {
	const $2DLVIDDIndex = tableData.findIndex(tableItem => tableItem.name === '(2D)LVIDD');
	const BSA = getBsa(tableData);

	if ($2DLVIDDIndex > -1 && BSA) {
		const $2DLVIDDValue = tableData[$2DLVIDDIndex].unit === 'mm' ? tableData[$2DLVIDDIndex].rawValue / 10 : tableData[$2DLVIDDIndex].rawValue;
		return $2DLVIDDValue / BSA;
	} else {
		return null;
	}
}

export function get2DLVMASScubed(tableData: TableDataItem[]) {
	const $2DLVIDDIndex = tableData.findIndex(tableItem => tableItem.name === '(2D)LVIDD');
	const $2DLVIVSDIndex = tableData.findIndex(tableItem => tableItem.name === '(2D)LVIVSD');
	const $2DLVPWTDIndex = tableData.findIndex(tableItem => tableItem.name === '(2D)LVPWTD');
	if ($2DLVIDDIndex > -1 && $2DLVIVSDIndex > -1 && $2DLVPWTDIndex > -1) {
		const $2DLVIDDValue = tableData[$2DLVIDDIndex].unit === 'mm' ? tableData[$2DLVIDDIndex].rawValue / 10 : tableData[$2DLVIDDIndex].rawValue;
		const $2DLVIVSDValue = tableData[$2DLVIVSDIndex].unit === 'mm' ? tableData[$2DLVIVSDIndex].rawValue / 10 : tableData[$2DLVIVSDIndex].rawValue;
		const $2DLVPWTDValue = tableData[$2DLVPWTDIndex].unit === 'mm' ? tableData[$2DLVPWTDIndex].rawValue / 10 : tableData[$2DLVPWTDIndex].rawValue;
		return 0.8 * 1.04 * (Math.pow($2DLVIDDValue + $2DLVIVSDValue + $2DLVPWTDValue,3) - Math.pow($2DLVIDDValue,3)) + 0.6;
	} else {
		return null;
	}
}

export function get2DLVMI(tableData: TableDataItem[]) {
	const $2DLVMASScubed = get2DLVMASScubed(tableData);
	const BSA = getBsa(tableData);
	if ($2DLVMASScubed && BSA) {
		return $2DLVMASScubed / BSA;
	} else {
		return null;
	}
}

export function get2DLVEDVMODBPIndex(tableData: TableDataItem[]) {
	const $2DLVEDVMODBPIndex = tableData.findIndex(tableItem => tableItem.name === '(2D)LVEDVMODBP');
	const BSA = getBsa(tableData);

	if ($2DLVEDVMODBPIndex > -1 && BSA) {
		return tableData[$2DLVEDVMODBPIndex].rawValue / BSA;
	} else {
		return null;
	}
}

export function get2DLVSVMODBP(tableData: TableDataItem[]) {
	const $2DLVEDVMODBP = tableData.findIndex(tableItem => tableItem.name === '(2D)LVEDVMODBP');
	const $2DLVESVMODBP = tableData.findIndex(tableItem => tableItem.name === '(2D)LVESVMODBP');
	if ($2DLVEDVMODBP > -1 && $2DLVESVMODBP > -1) {
		return tableData[$2DLVEDVMODBP].rawValue - tableData[$2DLVESVMODBP].rawValue;
	} else {
		return null;
	}
}

export function get2DLVEFMODBP(tableData: TableDataItem[]) {
	const $2DLVEDVMODBP = tableData.findIndex(tableItem => tableItem.name === '(2D)LVEDVMODBP');
	const $2DLVESVMODBP = tableData.findIndex(tableItem => tableItem.name === '(2D)LVESVMODBP');
	if ($2DLVEDVMODBP > -1 && $2DLVESVMODBP > -1) {
		return ((tableData[$2DLVEDVMODBP].rawValue - tableData[$2DLVESVMODBP].rawValue)/tableData[$2DLVEDVMODBP].rawValue)*100;
	} else {
		return null;
	}
}

export function get2DSVMODA4C(tableData: TableDataItem[]) {
	const $2DLVEDVMODA4C = tableData.findIndex(tableItem => tableItem.name === '(2D)LVEDVMODA4C');
	const $2DLVESVMODA4C = tableData.findIndex(tableItem => tableItem.name === '(2D)LVESVMODA4C');
	if ($2DLVEDVMODA4C > -1 && $2DLVESVMODA4C > -1) {
		return tableData[$2DLVEDVMODA4C].rawValue - tableData[$2DLVESVMODA4C].rawValue;
	} else {
		return null;
	}
}

export function get2DEFMODA4C(tableData: TableDataItem[]) {
	const $2DLVEDVMODA4C = tableData.findIndex(tableItem => tableItem.name === '(2D)LVEDVMODA4C');
	const $2DLVESVMODA4C = tableData.findIndex(tableItem => tableItem.name === '(2D)LVESVMODA4C');
	if ($2DLVEDVMODA4C > -1 && $2DLVESVMODA4C > -1) {
		return ((tableData[$2DLVEDVMODA4C].rawValue - tableData[$2DLVESVMODA4C].rawValue)/tableData[$2DLVEDVMODA4C].rawValue)*100;
	} else {
		return null;
	}
}

export function get2DSVMODA2C(tableData: TableDataItem[]) {
	const $2DLVEDVMODA2C = tableData.findIndex(tableItem => tableItem.name === '(2D)LVEDVMODA2C');
	const $2DLVESVMODA2C = tableData.findIndex(tableItem => tableItem.name === '(2D)LVESVMODA2C');
	if ($2DLVEDVMODA2C > -1 && $2DLVESVMODA2C > -1) {
		return tableData[$2DLVEDVMODA2C].rawValue - tableData[$2DLVESVMODA2C].rawValue;
	} else {
		return null;
	}
}

export function get2DEFMODA2C(tableData: TableDataItem[]) {
	const $2DLVEDVMODA2C = tableData.findIndex(tableItem => tableItem.name === '(2D)LVEDVMODA2C');
	const $2DLVESVMODA2C = tableData.findIndex(tableItem => tableItem.name === '(2D)LVESVMODA2C');
	if ($2DLVEDVMODA2C > -1 && $2DLVESVMODA2C > -1) {
		return ((tableData[$2DLVEDVMODA2C].rawValue - tableData[$2DLVESVMODA2C].rawValue)/tableData[$2DLVEDVMODA2C].rawValue)*100;
	} else {
		return null;
	}
}

export function get2DLAEDVMODALIndex(tableData: TableDataItem[]) {
	const $2DLAEDVMODAL = tableData.findIndex(tableItem => tableItem.name === '(2D)LAEDVMODAL');
	const BSA = getBsa(tableData);

	if ($2DLAEDVMODAL > -1 && BSA) {
		return tableData[$2DLAEDVMODAL].rawValue / BSA;
	} else {
		return null;
	}
}

export function get2DLAEDVMODBPIndex(tableData: TableDataItem[]) {
	const $2DLAEDVMODBP = tableData.findIndex(tableItem => tableItem.name === '(2D)LAEDVMODBP');
	const BSA = getBsa(tableData);

	if ($2DLAEDVMODBP > -1 && BSA) {
		return tableData[$2DLAEDVMODBP].rawValue / BSA;
	} else {
		return null;
	}
}

export function get2DAOROOTIndex(tableData: TableDataItem[]) {
	const $2DAOROOT = tableData.findIndex(tableItem => tableItem.name === '(2D)AOROOT');
	const BSA = getBsa(tableData);

	if ($2DAOROOT > -1 && BSA) {
		const $2DAOROOTValue = tableData[$2DAOROOT].unit === 'mm' ? tableData[$2DAOROOT].rawValue / 10 : tableData[$2DAOROOT].rawValue;
		return $2DAOROOTValue / BSA;
	} else {
		return null;
	}
}

export function get2DASCAOIndex(tableData: TableDataItem[]) {
	const $2DASCAOIndex = tableData.findIndex(tableItem => tableItem.name === '(2D)ASCAO');
	const BSA = getBsa(tableData);

	if ($2DASCAOIndex > -1 && BSA) {
		const $2DASCAOValue = tableData[$2DASCAOIndex].unit === 'mm' ? tableData[$2DASCAOIndex].rawValue / 10 : tableData[$2DASCAOIndex].rawValue;
		return $2DASCAOValue / BSA;
	} else {
		return null;
	}
}

export function getCAVAVTI(tableData: TableDataItem[]) {
	const $2DLVOTD = tableData.findIndex(tableItem => tableItem.name === '(2D)LVOTD');
	const PWLVOTVTI = tableData.findIndex(tableItem => tableItem.name === 'PW-LVOTVTI');
	const CWAVVTI = tableData.findIndex(tableItem => tableItem.name === 'CW-AVVTI');

	if ($2DLVOTD > -1 && PWLVOTVTI > -1 && CWAVVTI > -1) {
		const $2DLVOTDValue = tableData[$2DLVOTD].unit === 'mm' ? tableData[$2DLVOTD].rawValue / 10 : tableData[$2DLVOTD].rawValue;
		const PWLVOTVTIValue = tableData[PWLVOTVTI].unit === 'mm' ? tableData[PWLVOTVTI].rawValue / 10 : tableData[PWLVOTVTI].rawValue;
		const CWAVVTIValue = tableData[CWAVVTI].unit === 'mm' ? tableData[CWAVVTI].rawValue / 10 : tableData[CWAVVTI].rawValue;
		return ((( Math.pow($2DLVOTDValue,2) * 0.785) * PWLVOTVTIValue) / CWAVVTIValue);
	} else {
		return null;
	}
}

export function getCAVAVTIIndex(tableData: TableDataItem[]) {
	const CAVAVTI = getCAVAVTI(tableData);
	const BSA = getBsa(tableData);

	if (CAVAVTI && BSA) {
		return CAVAVTI / BSA;
	} else {
		return null;
	}
}

export function getCAVAVMAX(tableData: TableDataItem[]) {
	const $2DLVOTD = tableData.findIndex(tableItem => tableItem.name === '(2D)LVOTD');
	const LVOTVMAX = tableData.findIndex(tableItem => tableItem.name === 'PW-LVOTVMAX');
	const CWAVVMAX = tableData.findIndex(tableItem => tableItem.name === 'CW-AVVMAX');

	if ($2DLVOTD > -1 && LVOTVMAX > -1 && CWAVVMAX > -1) {
		const $2DLVOTDValue = tableData[$2DLVOTD].unit === 'mm' ? tableData[$2DLVOTD].rawValue / 10 : tableData[$2DLVOTD].rawValue;
		const LVOTVMAXValue = tableData[LVOTVMAX].unit === 'mm/s' ? tableData[LVOTVMAX].rawValue / 10 : tableData[LVOTVMAX].rawValue;
		const CWAVVMAXValue = tableData[CWAVVMAX].unit === 'mm/s' ? tableData[CWAVVMAX].rawValue / 10 : tableData[CWAVVMAX].rawValue;

		return ((( Math.pow($2DLVOTDValue,2) * 0.785) * LVOTVMAXValue) / CWAVVMAXValue);
	} else {
		return null;
	}
}

export function getCAVDI(tableData: TableDataItem[]) {
	const PWLVOTVTI = tableData.findIndex(tableItem => tableItem.name === 'PW-LVOTVTI');
	const CWAVVTI = tableData.findIndex(tableItem => tableItem.name === 'CW-AVVTI');

	if (PWLVOTVTI > -1 && CWAVVTI > -1) {
		const PWLVOTVTIValue = tableData[PWLVOTVTI].unit === 'mm' ? tableData[PWLVOTVTI].rawValue / 10 : tableData[PWLVOTVTI].rawValue;
		const CWAVVTIValue = tableData[CWAVVTI].unit === 'mm' ? tableData[CWAVVTI].rawValue / 10 : tableData[CWAVVTI].rawValue;
		return PWLVOTVTIValue / CWAVVTIValue;
	} else {
		return null;
	}
}

export function getPWMVEARATIO(tableData: TableDataItem[]) {
	const PWMVEMAX = tableData.findIndex(tableItem => tableItem.name === 'PW-MVEMAX');
	const PWMVAMAX = tableData.findIndex(tableItem => tableItem.name === 'PW-MVAMAX');

	if (PWMVEMAX > -1 && PWMVAMAX > -1) {
		return tableData[PWMVEMAX].rawValue / tableData[PWMVAMAX].rawValue;
	} else {
		return null;
	}
}

export function getPWEEPRIMERATIOSEP(tableData: TableDataItem[]) {
	const PWMVEMAX = tableData.findIndex(tableItem => tableItem.name === 'PW-MVEMAX');
	const PWEPRIMESEP = tableData.findIndex(tableItem => tableItem.name === 'PW-EPRIMESEP');
	if (PWMVEMAX === -1 && PWEPRIMESEP === -1) {
		return null;
	}
	const PWMVEMAXvalue = tableData[PWMVEMAX].unit === 'm/s' ? tableData[PWMVEMAX].rawValue * 100 : tableData[PWMVEMAX].rawValue;
	const PWEPRIMESEPvalue = tableData[PWEPRIMESEP].unit === 'm/s' ? tableData[PWEPRIMESEP].rawValue * 100 : tableData[PWEPRIMESEP].rawValue;

	return PWMVEMAXvalue / PWEPRIMESEPvalue;
}

export function getPWEEPRIMERATIOLAT(tableData: TableDataItem[]) {
	const PWMVEMAX = tableData.findIndex(tableItem => tableItem.name === 'PW-MVEMAX');
	const EPRIMELAT = tableData.findIndex(tableItem => tableItem.name === 'PW-EPRIMELAT');

	if (PWMVEMAX === -1 && EPRIMELAT === -1) {
		return null;
	}
	const PWMVEMAXvalue = tableData[PWMVEMAX].unit === 'm/s' ? tableData[PWMVEMAX].rawValue * 100 : tableData[PWMVEMAX].rawValue;
	const EPRIMELATvalue = tableData[EPRIMELAT].unit === 'm/s' ? tableData[EPRIMELAT].rawValue * 100 : tableData[EPRIMELAT].rawValue;
	return PWMVEMAXvalue / EPRIMELATvalue;
}

export function getPWEEPRIMERATIOAVE(tableData: TableDataItem[]) {
	const PWEEPRIMERATIOLAT = getPWEEPRIMERATIOLAT(tableData);
	const PWEEPRIMERATIOSEP = getPWEEPRIMERATIOSEP(tableData);

	if (PWEEPRIMERATIOLAT && PWEEPRIMERATIOSEP) {
		return (PWEEPRIMERATIOLAT + PWEEPRIMERATIOSEP)/2;
	} else {
		return null;
	}
}

export function getCMVAPHT(tableData: TableDataItem[]) {
	const CWMVPHT = tableData.findIndex(tableItem => tableItem.name === 'CW-MVPHT');

	if (CWMVPHT > -1) {
		return 220/tableData[CWMVPHT].rawValue;
	} else {
		return null;
	}
}

export function getCMVAVTI(tableData: TableDataItem[]) {
	const $2DLVOTD = tableData.findIndex(tableItem => tableItem.name === '(2D)LVOTD');
	const PWLVOTVTI = tableData.findIndex(tableItem => tableItem.name === 'PW-LVOTVTI');
	const CWMVVTI = tableData.findIndex(tableItem => tableItem.name === 'CW-MVVTI');

	if ($2DLVOTD > -1 && PWLVOTVTI && -1 && CWMVVTI > -1) {
		const $2DLVOTDValue = tableData[$2DLVOTD].unit === 'mm' ? tableData[$2DLVOTD].rawValue / 10 : tableData[$2DLVOTD].rawValue;
		const PWLVOTVTIValue = tableData[PWLVOTVTI].unit === 'mm' ? tableData[PWLVOTVTI].rawValue / 10 : tableData[PWLVOTVTI].rawValue;
		const CWMVVTIValue = tableData[CWMVVTI].unit === 'mm' ? tableData[CWMVVTI].rawValue / 10 : tableData[CWMVVTI].rawValue;
		return (( Math.pow($2DLVOTDValue,2) * 0.785) * PWLVOTVTIValue) / CWMVVTIValue;
	} else {
		return null;
	}
}

export function getPWPVSDRATIO(tableData: TableDataItem[]) {
	const PWPVEINSVMAX  = tableData.findIndex(tableItem => tableItem.name === 'PW-PVEINSVMAX');
	const PWPVEINDVMAX = tableData.findIndex(tableItem => tableItem.name === 'PW-PVEINDVMAX');

	if (PWPVEINSVMAX > -1 && PWPVEINDVMAX > -1) {
		return tableData[PWPVEINSVMAX].rawValue / tableData[PWPVEINDVMAX].rawValue;
	} else {
		return null;
	}
}

export function getPWMVADURPWPVEINARDUR(tableData: TableDataItem[]) {
	const PWMVADUR = tableData.findIndex(tableItem => tableItem.name === 'PW-MVADUR');
	const PWPVEINARDUR = tableData.findIndex(tableItem => tableItem.name === 'PW-PVEINARDUR');

	if (PWMVADUR > -1 && PWPVEINARDUR > -1) {
		return tableData[PWMVADUR].rawValue - tableData[PWPVEINARDUR].rawValue;
	} else {
		return null;
	}
}


export function getCWTRPGRAD(tableData: TableDataItem[]) {
	const CWTRVMAX = tableData.findIndex(tableItem => tableItem.name === 'CW-TRVMAX');

	if (CWTRVMAX > -1) {
		let CWTRVMAXValue = tableData[CWTRVMAX].rawValue;

		if (tableData[CWTRVMAX].unit === 'cm/s') {
			CWTRVMAXValue =  tableData[CWTRVMAX].rawValue / 100;
		} else if(tableData[CWTRVMAX].unit === 'mm/s') {
			CWTRVMAXValue = tableData[CWTRVMAX].rawValue / 1000;
		}
		
		return 4 * Math.pow(CWTRVMAXValue,2)
	} else {
		return null;
	}
}

export function getCWRVSP(tableData: TableDataItem[]) {
	const CWTRPGRAD = getCWTRPGRAD(tableData);
	const CWRAP = tableData.findIndex(tableItem => tableItem.name === 'CW-RAP');


	if (CWRAP > -1 && CWTRPGRAD) {
		return CWTRPGRAD + tableData[CWRAP].rawValue;
	} else {
		return null;
	}
}

export function getCWEPLAR(tableData: TableDataItem[]) {
	const CWTRVMAX = tableData.findIndex(tableItem => tableItem.name === 'CW-TRVMAX');
	const PWEEPRIMERATIOAVE = getPWEEPRIMERATIOAVE(tableData);

	if (CWTRVMAX > -1 && PWEEPRIMERATIOAVE) {
		return tableData[CWTRVMAX].rawValue / PWEEPRIMERATIOAVE;
	} else {
		return null;
	}
}

export function getCWPVPGRAD(tableData: TableDataItem[]) {
	const CWPVVMAX = tableData.findIndex(tableItem => tableItem.name === 'CW-PVVMAX');

	if (CWPVVMAX > -1) {
		let CWPVVMAXValue = tableData[CWPVVMAX].rawValue;
		
		if (tableData[CWPVVMAX].unit === 'cm/s') {
			CWPVVMAXValue =  tableData[CWPVVMAX].rawValue / 100;
		} else if(tableData[CWPVVMAX].unit === 'mm/s') {
			CWPVVMAXValue = tableData[CWPVVMAX].rawValue / 1000;
		}
		
		return 4 * (Math.pow(CWPVVMAXValue,2))
	} else {
		return null;
	}
}

export function getCWPAEDP(tableData: TableDataItem[]) {
	const CWPREPGRAD = tableData.findIndex(tableItem => tableItem.name === 'CW-PREPGRAD');
	const CWRAP = tableData.findIndex(tableItem => tableItem.name === 'CW-RAP');


	if (CWRAP > -1 && CWPREPGRAD > -1) {
		return tableData[CWPREPGRAD].rawValue + tableData[CWRAP].rawValue;
	} else {
		return null;
	}
}

export function get2DRVFAC(tableData: TableDataItem[]) {
	const $2DRVAREAD = tableData.findIndex(tableItem => tableItem.name === '(2D)RVAREAD');
	const $2DRVAREAS = tableData.findIndex(tableItem => tableItem.name === '(2D)RVAREAS');

	if ($2DRVAREAD > -1 && $2DRVAREAS > -1) {
		return ((tableData[$2DRVAREAD].rawValue - tableData[$2DRVAREAS].rawValue) / tableData[$2DRVAREAD].rawValue) * 100;
	} else {
		return null;
	}
}

export function getCWAVPGRAD(tableData: TableDataItem[]){
	const CWAVVMAX = tableData.findIndex(tableItem => tableItem.name === 'CW-AVVMAX');
	
	if (CWAVVMAX > -1) {
		let CWAVVMAXValue = tableData[CWAVVMAX].rawValue;

		if (tableData[CWAVVMAX].unit === 'cm/s') {
			CWAVVMAXValue =  tableData[CWAVVMAX].rawValue / 100;
		} else if(tableData[CWAVVMAX].unit === 'mm/s') {
			CWAVVMAXValue = tableData[CWAVVMAX].rawValue / 1000;
		}
		
		return 4 * Math.pow(CWAVVMAXValue,2);
	} else {
		return null
	}
}

export function getCMRPISAERO(tableData: TableDataItem[]) {
	const PWMRPISARADIUS = tableData.findIndex(tableItem => tableItem.name === 'PW-MRPISARADIUS');
	const PWMRPISAALIASVMAX = tableData.findIndex(tableItem => tableItem.name === 'PW-MRPISAALIASVMAX');
	const CWMRPISAVMAX = tableData.findIndex(tableItem => tableItem.name === 'CW-MRPISAVMAX');

	let PWMRPISAVFR = null;
	if (PWMRPISARADIUS > -1 && PWMRPISAALIASVMAX > -1) {
		PWMRPISAVFR = 2 * 3.1416 * Math.pow(tableData[PWMRPISARADIUS].rawValue,2) * tableData[PWMRPISAALIASVMAX].rawValue;
	}

	if (PWMRPISAVFR && CWMRPISAVMAX > -1) {
		return PWMRPISAVFR / tableData[CWMRPISAVMAX].rawValue
	} else {
		return null;
	}
}


export function getCMRPISARVOL(tableData: TableDataItem[]) {
	const CMRPISAERO = getCMRPISAERO(tableData)
	const CWMRPISAVTI = tableData.findIndex(tableItem => tableItem.name === 'CW-MRPISAVTI');
	if (CMRPISAERO && CWMRPISAVTI > -1) {
		return CMRPISAERO * tableData[CWMRPISAVTI].rawValue
	} else {
		return null;
	}
}