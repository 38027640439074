import React, {useState, useRef, useEffect, FormEvent} from 'react';
import { observable, runInAction, toJS } from 'mobx';
import { inject, observer, useLocalStore } from 'mobx-react';
import { store } from 'Models/Store';
import { ModelCollection } from 'Views/Components/ModelCollection/ModelCollection';
import * as Models from 'Models/Entities';
import moment from 'moment';
import { ApiQueryParams, IOrderByCondition } from 'Views/Components/ModelCollection/ModelAPIQuery';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import { PaginationQueryOptions } from 'Models/PaginationData';
import { IFilter } from 'Views/Components/Collection/CollectionFilterPanel';
import { genderTypeOptions } from 'Models/Enums';
import { makeEnumFetchFunction } from 'Util/EntityUtils';
import { ICollectionItemActionProps } from 'Views/Components/Collection/Collection';
import { 
	Colors, 
	Display, 
	Sizes, 
	Button,
} from 'Views/Components/Button/Button';
import { SERVER_URL } from 'Constants';
import {Link, useNavigate} from 'react-router-dom';
import { debounce } from 'lodash';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import alert from 'Util/ToastifyUtils';
import { NewPatientModal } from '../Modals/NewPatientModal';
import { ContextMenu } from '../../Components/ContextMenu/ContextMenu';
import { contextMenu } from 'react-contexify';
import {PatientEntity} from 'Models/Entities';
import {
	Select,
	Flex,
	Input,
	FormControl,
	FormLabel,
	Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
	Button as ChakraButton,

} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

type transformFn<T> = (item: T, name: string) => (string | React.ReactNode);

type FormValues = {
	name: string;
	patientId: string;
	gender: string;
	dob: string;
};

interface IPatientCrudTileProps {
	orderBy?: IOrderByCondition<Models.PatientEntity>;
	perPage?: number;
}

const PatientCrudTile = inject('store')(observer((props: IPatientCrudTileProps) => {
	const [search, setSearch] = useState({ searchTerm: '' });
	const [editPatientModalOpen, setEditPatientModalOpen] = useState<boolean>(false);
	const [newPatientModalOpen, setNewPatientModalOpen] = useState<boolean>(false);
	const [patientData, setPatientData] = useState<PatientEntity>();

	const collectionRef = useRef<ModelCollection<Models.StudyEntity> | null>(null);
	const defaultOrderBy: IOrderByCondition<Models.PatientEntity> = { path: 'created', descending: true };
	const orderBy: IOrderByCondition<Models.PatientEntity> | undefined = observable(defaultOrderBy || props.orderBy);
	const paginationQueryOptions: PaginationQueryOptions = observable(new PaginationQueryOptions());

	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
	} = useForm<FormValues>();
	
	const navigate = useNavigate();
	const tranformName: transformFn<Models.PatientEntity> = (patient: Models.PatientEntity) => patient.name;
	const tranformPatientId: transformFn<Models.PatientEntity> = (patient: Models.PatientEntity) => patient.patientId;
	const transformDob: transformFn<Models.PatientEntity> = (patient: Models.PatientEntity) => moment(patient.dob).format('DD/MM/YYYY');
	const transformGender: transformFn<Models.PatientEntity> = (patient: Models.PatientEntity) => genderTypeOptions[patient.gender];
	const transformLastExamination: transformFn<Models.PatientEntity> = (patient: Models.PatientEntity) => {
		if (patient.studiess.length > 0) {
			return moment(patient.studiess.sort((a, b) => (moment(a.studyDate).isAfter(b.studyDate) ? -1 : 1))[0].studyDate).format('DD/MM/YYYY');
		} 
		return 'No Studies';
	};
	const transformPreviousStudies: transformFn<Models.PatientEntity> = (patient: Models.PatientEntity) => {
		if (patient.studiess.length) {
			return `${patient.studiess.length} Studies`;
		} 
		return 'No Studies';
	};
	const deletePatient = (patient: Models.PatientEntity) => {
		store.modal.show('Confirmation', 
			<div className="confirmation__modal">
				<h4>{`Do you want delete the patient ${patient.name}?`}</h4>
				<h5>By deleting this patient you will also be deleting all studies associated with this patient.</h5>
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
					<Button 
						colors={Colors.Primary} 
						display={Display.Solid} 
						sizes={Sizes.Medium} 
						buttonProps={{ id: 'confirm' }} 
						onClick={() => {
							patient.delete().then(() => {
								collectionRef.current?.refetch();
								alert(
									<div className="delete-success">
										<p className="success-message">Deleted Patient</p>
									</div>,
									'success',
								);
							}).catch(error => {
								alert(
									<div className="delete-failure">
										<p className="error-message">{error}</p>
									</div>,
									'error',
								);
							});
							store.modal.hide();
						}}
					>
						Confirm
					</Button>
					<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={() => store.modal.hide()}>Cancel</Button>
				</ButtonGroup>
			</div>);
	};

	const savePatient = (formData: FormValues) => {
		if (patientData) {
			patientData.name = formData.name;
			patientData.patientId = formData.patientId;
			patientData.gender = genderTypeOptions[formData.gender];
			patientData.dob = new Date(formData.dob);
			patientData.save()
				.then(() => {
					alert('Patient is updated', 'success');
					setEditPatientModalOpen(false);
				}).catch(() => {
					alert('Patient is unable to be updated', 'error');
				});
		}
	}

	const editPatient = (patient: PatientEntity) => {
		if (store.userGroups.map(ug => ug.name).includes('SuperAdmin')) {
			navigate(`/admin/patiententity/edit/${patient.id}`)
		} else {
			if (store.userSiteAccess[0].accessLevel === 'FULL_ACCESS') {
				setValue('name', patient.name);
				setValue('patientId', patient.patientId);
				setValue('gender', patient.gender);
				setValue('dob', moment(patient.dob).format('YYYY-MM-DD'));
				setPatientData(patient);
				setEditPatientModalOpen(true);
			} else {
				alert("You don't have access to edit patient details", 'error' )
			}
		}
	}

	const getHeaders = (): Array<ICollectionHeaderProps<Models.PatientEntity>> => [
		{
			name: 'name',
			displayName: 'Name',
			sortable: true,
			transformItem: tranformName,
		},
		{
			name: 'patientId',
			displayName: 'Patient Id',
			sortable: true,
			transformItem: tranformPatientId,
		},
		{
			name: 'dob',
			displayName: 'D.O.B',
			sortable: true,
			transformItem: transformDob,
		},
		{
			name: 'gender',
			displayName: 'Gender',
			sortable: true,
			transformItem: transformGender,
		},
		{
			name: 'transformpatientLastExamination',
			displayName: 'Last Examination',
			sortable: true,
			transformItem: transformLastExamination,
		},
		{
			name: 'previousStudies',
			displayName: 'Previous Studies',
			sortable: true,
			transformItem: transformPreviousStudies,
		},
	];

	const getMoreParamsFromFilters = (filters: Array<IFilter<Models.PatientEntity>>): ApiQueryParams => {
		const params = {};

		const dobFilter = filters.find(f => f.path === 'dob' && !!f.value1 && !!f.value2);
		if (dobFilter) {
			params['dobStart'] = dobFilter.value1;
			params['dobEnd'] = dobFilter.value2;
		}

		const lastExaminationFilter = filters.find(f => f.path === 'lastExamination' && !!f.value1 && !!f.value2);
		if (lastExaminationFilter) {
			params['lastExaminationStart'] = lastExaminationFilter.value1;
			params['lastExaminationEnd'] = lastExaminationFilter.value2;
		}

		const genderFilter = filters.find(f => f.path === 'gender' && !!f.value1);
		if (genderFilter) {
			if ((genderFilter.value1 as string[]).includes('MALE')) {
				params['genderMale'] = true;
			}
			if ((genderFilter.value1 as string[]).includes('FEMALE')) {
				params['genderFemale'] = true;
			}
			if ((genderFilter.value1 as string[]).includes('NA')) {
				params['genderNA'] = true;
			}
		}

		const siteFilter = filters.find(f => f.path === 'site' && !!f.value1 && (f.value1 as []).length !== 0);
		if (siteFilter) {
			params['siteFilter'] = JSON.stringify(siteFilter.value1);
		}

		return params;
	};

	const onSearchTriggered = (searchTerm: string) => {
		setSearch({ searchTerm });
	};

	const getMoreParams = (filters?: Array<IFilter<Models.PatientEntity>>, filterApplied?: boolean): ApiQueryParams => {
		const filterParams = (filters && filterApplied) ? getMoreParamsFromFilters(filters) : {};

		if (!store.userGroups.map(ug => ug.name).includes('SuperAdmin') && !store.userGroups.map(ug => ug.name).includes('Super Administrators')) {
			filterParams['siteIds'] = JSON.stringify(store.userSiteAccess.map(usa => usa.siteId));
		}

		if (search.searchTerm) {
			filterParams['searchStr'] = search.searchTerm;
		}

		return filterParams;
	};

	const additionalActions = (): React.ReactNode[] => {
		const usersWithAddPatient = ['SITE_ADMIN', 'SITE_USER', 'CARDIAC_SCIENTIST'];
		if (usersWithAddPatient.includes(store.userType) || store.userGroups[0].name === 'SuperAdmin') {
			return [
				<Button
					key="create"
					className={Display.Solid}
					icon={{ icon: 'create', iconPos: 'icon-left' }}
					buttonProps={{ onClick: () => setNewPatientModalOpen(true) }}
				>
					New Patient
				</Button>,
			];
		}
		return [];
	};
	
	const getFilters = (): Array<IFilter<Models.PatientEntity>> => {
		const dobFilter: IFilter<Models.PatientEntity> = {
			path: 'dob',
			comparison: 'range',
			value1: undefined,
			value2: undefined,
			active: false,
			displayType: 'datepicker',
			displayName: 'D.O.B',
		};

		const lastExaminationFilter: IFilter<Models.PatientEntity> = {
			path: 'lastExamination',
			comparison: 'range',
			value1: undefined,
			value2: undefined,
			active: false,
			displayType: 'datepicker',
			displayName: 'Last Examination',
		};

		const genderFilter: IFilter<Models.PatientEntity> = {
			path: 'gender',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Gender',
			enumResolveFunction: makeEnumFetchFunction(genderTypeOptions),
		};

		const siteOptions = {};
		store.userSiteAccess.forEach(usa => {
			const jsUsa = toJS(usa);
			if (jsUsa.siteId && jsUsa.site) {
				if (jsUsa.site.siteName) {
					siteOptions[jsUsa.siteId] = jsUsa.site.siteName;
				}
			}
		});

		const siteFilter: IFilter<Models.PatientEntity> = {
			path: 'site',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Site',
			enumResolveFunction: makeEnumFetchFunction(siteOptions)
		};

		return [dobFilter, lastExaminationFilter, genderFilter, siteFilter];
	};
	
	const getActions = () => {
		const tableActions: Array<ICollectionItemActionProps<Models.PatientEntity>> = [];

		tableActions.push({
			action: item => {},
			label: 'More...',
			customButton: (patient: Models.PatientEntity) => (
				<div>
					<Button 
						colors={Colors.Primary} 
						display={Display.Outline} 
						sizes={Sizes.Small} 
						buttonProps={{ id: 'confirm' }}
						onClick={e => contextMenu.show({ event: e, id: `context-menu-${patient.id}` })}
					>
						More...
					</Button>
					<ContextMenu
						menuId={`context-menu-${patient.id}`}
						actions={[
							{ label: 'Edit', icon: 'edit', iconPos: 'icon-left', onClick: (): void => editPatient(patient) },
							{ label: 'Delete', icon: 'bin-full', iconPos: 'icon-left', onClick: (): void => deletePatient(patient) },
						]}
					/>
					
				</div>
			),
		});

		tableActions.push({
			action: item => {},
			label: 'View',
			customButton: (patient: any) => <Link to={`${SERVER_URL}/patientprofile/${patient.id}`}><Button colors={Colors.Primary} display={Display.Text} sizes={Sizes.Small} icon={{ icon: 'chevron-right', iconPos: 'icon-right' }} buttonProps={{ id: 'cancel' }}>View</Button></Link>,
		});

		return tableActions;
	};

	const page = useLocalStore(() => ({
		isLoading: true,
	}));
	
	useEffect(() => {
		runInAction(() => {
			page.isLoading = false;
		})
	}, [])

	return (
		!page.isLoading ? (
			<div>
				<ModelCollection
					url="/api/entity/PatientEntity/patient_list"
					searchStr={search.searchTerm}
					isApiQuery
					orderBy={orderBy}
					model={Models.PatientEntity}
					headers={getHeaders()}
					actions={getActions()}
					selectableItems={false}
					onSearchTriggered={debounce(onSearchTriggered, 200)}
					onSearchChange={debounce(onSearchTriggered, 200)}
					perPage={paginationQueryOptions.perPage}
					idColumn="id"
					dataFields={row => ({
						created: moment(row.created).format('YYYY-MM-DD'),
						modified: moment(row.modified).format('YYYY-MM-DD'),
					})}
					getMoreParams={getMoreParams}
					filters={getFilters()}
					additionalActions={additionalActions()}
					dateFormat="d/m/Y"
					altFormat="d/m/Y"
				/>

				<NewPatientModal
					isOpen={newPatientModalOpen}
					onClose={()=>{setNewPatientModalOpen(false)}}
					onConfirm={()=>{setNewPatientModalOpen(false)}}
				/>
				<Modal isOpen={editPatientModalOpen} onClose={()=> setEditPatientModalOpen(false)} size="lg">
					<form className="request" onSubmit={handleSubmit(savePatient)} >

						<ModalOverlay />
						<ModalContent>
							<ModalHeader>Update Patient Details</ModalHeader>
							<ModalCloseButton />
							<ModalBody>
								<Flex direction="column">
									<FormControl
										display="flex"
										flexDir="column"
										variant="floating"
										isInvalid={!!errors.name}
										margin="8px 0px"
									>
										<Input
											type="text"
											size="lg"
											{...register('name', {
												required: true,
											})}
										/>
										<FormLabel>Name</FormLabel>
									</FormControl>

									<FormControl
										display="flex"
										flexDir="column"
										variant="floating"
										isInvalid={!!errors.patientId}
										margin="8px 0px"

									>
										<Input
											type="text"
											size="lg"
											{...register('patientId', {
												required: true,
											})}
										/>
										<FormLabel>Paitent ID</FormLabel>
									</FormControl>

									<FormControl
										display="flex"
										flexDir="column"
										variant="floating"
										isInvalid={!!errors.gender}
										id="gender"
										width="100%"
										margin="8px 0px"
									>
										<Select 
											width="100%"
											height="42px"
											{...register('gender', {
												required: true,
											})}>
											{makeEnumFetchFunction(genderTypeOptions).map(option => (<option key={option.value} value={option.value}>{option.display}</option>))}
										</Select>

										<FormLabel margin="-4px 0 0 0">Gender</FormLabel>
									</FormControl>

									<FormControl
										display="flex"
										flexDir="column"
										variant="floating"
										isInvalid={!!errors.dob}
										id="dob"
										width="100%"
										margin="8px 0px"
									>

										<Input
											width="100%"
											m="0 6px"
											type="date"
											size="lg"
											{...register('dob', {
												required: true,
											})}
										/>

										<FormLabel margin="-4px 0 0 0">Date of Birth</FormLabel>
									</FormControl>
								</Flex>

							</ModalBody>

							<ModalFooter>
								<ChakraButton variant='ghost' onClick={()=> setEditPatientModalOpen(false)}>Cancel</ChakraButton>
								<ChakraButton colorScheme='blue' mr={3} type="submit">Confirm</ChakraButton>
					
							</ModalFooter>
						</ModalContent>
					</form>

				</Modal>
			</div>
		) : <></>
	);
}));

export default PatientCrudTile;
