import * as React from 'react';
import {action} from 'mobx';
import { store } from "Models/Store";
import {Navigate, Route, Routes} from 'react-router';
import * as Pages from './Pages';
import Logout from "./Components/Logout/Logout";
import PageNotFound from './Pages/PageNotFound';
import PageAccessDenied from './Pages/PageAccessDenied';
import Topbar from "./Components/Topbar/Topbar";
import { WithRouterProps, withRouter } from 'Util/withRouter';
import { ProtectedRoute } from './Components/Auth/ProtectedRoute';

class Frontend extends React.Component<WithRouterProps> {
	@action
	private setAppLocation = () => {
		store.appLocation = 'frontend';
	}

	public componentDidMount() {
		this.setAppLocation();
	}
	
	public render() {
		return (
			<>
				<div className="body-container">
					<Topbar currentLocation="frontend"/>
					<div className="frontend">
						<>
							<Routes>
								<Route path="/login" element={<Pages.LoginPage />} />
								<Route path="/logout" element={<Logout />} />
								<Route path="/register" element={<Pages.RegistrationPage />} />
								<Route path="/confirm-email" element={<Pages.RegistrationConfirmPage />} />
								<Route path="/reset-password-request" element={<Pages.ResetPasswordRequestPage />} />
								<Route path="/reset-password" element={<Pages.ResetPasswordPage />} />
								<Route path={`/404`} element={<PageNotFound />} />
								<Route path={`/403`} element={<PageAccessDenied />} />
								<Route path={`/studypage/:id/:shareKey`} element={<Pages.NewStudyPage />} />
								<Route path="/patientdashboard" element={<ProtectedRoute><Pages.PatientDashboardPage /></ProtectedRoute>} />
								<Route path={`/profilepage/:id`} element={<ProtectedRoute><Pages.ProfilePagePage /></ProtectedRoute>} />
								<Route path={`/studypage/:id`} element={<ProtectedRoute><Pages.NewStudyPage /></ProtectedRoute>} />
								<Route path={`/sessionpage`} element={<ProtectedRoute><Pages.SessionPagePage /></ProtectedRoute>} />
								<Route path={`/sessionpage/end/:id`} element={<ProtectedRoute><Pages.EndSessionPage /></ProtectedRoute>} />
								<Route path={`/patientprofile/:id`} element={<ProtectedRoute><Pages.PatientPagePage /></ProtectedRoute>} />
								<Route path={`/site/:id`} element={<ProtectedRoute><Pages.SitePage /></ProtectedRoute>} />
								<Route path={`/sitedashboard/*`} element={<ProtectedRoute><Pages.SiteDashboardPage /></ProtectedRoute>} />
								<Route path={`/devicedashboard`} element={<ProtectedRoute><Pages.DeviceDashboardPage /></ProtectedRoute>} />
								<Route path={`/userdashboard`} element={<ProtectedRoute><Pages.UsereDashboardPage /></ProtectedRoute>} key={"userDashboard"}/>
								<Route path={`/userdashboard/editsiteaccess/:id`} element={<ProtectedRoute><Pages.UserSiteAccessEditPage /></ProtectedRoute>} key={"editSiteAccess"}/>
								<Route path={`/userdashboard/editsiteaccess/:ids/create`} element={<ProtectedRoute><Pages.UserSiteAccessCreatePage /></ProtectedRoute>} key={"createSiteAccess"}/>
								<Route path={`/distributereport/:id`} element={<ProtectedRoute><Pages.DistributeReportPage /></ProtectedRoute>} />
								<Route path="/" element={<Navigate to={`/patientdashboard`} />} />
								<Route element={<PageNotFound />} />
							</Routes>
						</>
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(Frontend);